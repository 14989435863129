import Card from "../../../components/Card";
import styles from "./Modaly.module.sass";
import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import {
  filesUploadReklamaceUrl,
  reklamacieSQLDoklady,
  reklamacieSQLStavSkl,
  reklamacieSQLSuroviny,
  reklamacieSQLZapisUrl,
  zoznamObjednanychPoloziekExtUrl,
} from "../../../features/urlHelper";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../../features/errorDialog";
import Dropzone from "../../../components/DropZone";
import ModalVyberZoZoznamu from "../../../components/Modaly/ModalVyberZoZoznamu";
import ModalReklamaceDokoncena from "../../../components/Modaly/ModalReklamaceDokoncena";
import moment from "moment";
import Select from "react-select";
import useDarkMode from "@fisch0920/use-dark-mode";
import {
  LocalizationProvider,
  csCZ,
  LicenseInfo,
} from "@mui/x-date-pickers-pro";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "dayjs/locale/cs";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Icon from "../../../components/Icon";
import { validateNumberWithMaxDecimals } from "../../../features/validateNumberWithMaxDecimals";
import LoadingOverlay from "react-loading-overlay-nextgen";

const App = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const darkMode = useDarkMode(false);
  const [loading, setLoading] = useState(true);

  const [width, setWidth] = useState(0);
  const componentRef = useRef(null);

  const [row, setRow] = useState({});
  const [loadingRekl, setLoadingRekl] = useState(false);
  //const [komentar, setKomentar] = useState("");

  //modal dokoncena
  const [visible_dokoncena, setVisible_dokoncena] = useState(false);
  const [data_dokoncena, setData_dokoncena] = useState([]);

  //modal vyber zoznamu
  const [typ, setTyp] = useState("");
  const [idField, setIdField] = useState("");
  const [visible, setVisible] = useState(false);
  const [stavSkladuRef, setStavSkladuRef] = useState(false);
  const [visibleDoklady, setVisibleDoklady] = useState(false);
  const [data, setData] = useState([]);
  const [valuePolozka, setValuePolozka] = useState({});
  const [valueDoklad, setValueDoklad] = useState({});
  const [removeAll, setRemoveAll] = useState(true);
  const [reklam_polozky, setReklam_polozky] = useState([
    {
      id: 0,
      mnozstvo: 0,
      typ_rekl: null,
      datum: null,
      komentar: "",
      fotky: [],
    },
  ]);
  const [options, setOptions] = useState([
    { label: "Nižší množství", value: "0", display: "Dodáno nižší množství" },
    {
      label: "ŠK – zasílám zpět",
      value: "1",
      display: "Špatná kvalita – položku zasílám fyzicky zpět na ZMT",
    },
    {
      label: "Vyšší množství",
      value: "2",
      display: "Dodáno vyšší množství (položku si ponechám)",
    },
  ]); //0=nizsi mnozstvi, 1=kvalita, 2=vyssi mnozstvi

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#F1B600" },
      },
      width: "30%",
    },
    csCZ
  );

  const stylingRangePicker = {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& .MuiInputBase-root, MuiOutlinedInput-root": {
      color: "#82878c",
      fontFamily: "Inter, sans-serif",
      display: "flex",
      alighnItems: "center",
      justifyContent: "center",
      fontWeight: 400,
      fontSize: 15,
      backgroundColor: !darkMode.value ? "white" : "#cccccc",
      borderRadius: 2,
      borderWidth: 0,
      height: 40,
      // borderStyle: "none"
    },
    // "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary Mui-error MuiInputBase-formControl MuiInputBase-adornedEnd css-15czysk-MuiInputBase-root-MuiOutlinedInput-root":{
    //   display: "none",
    // },
    "& .MuiInputBase-root, MuiOutlinedInput-root": {
      color: "#82878c",
      fontFamily: "Inter, sans-serif",
      display: "flex",
      alighnItems: "center",
      justifyContent: "center",
      fontWeight: 400,
      fontSize: 15,
      backgroundColor: !darkMode.value ? "white" : "#cccccc",
      borderRadius: 2,
      border: 0,
      height: 40,
    },

    "& .MuiTypography-root, MuiFormLabel-root": {
      color: "#82878c",
    },
    "& .separator-true": {
      height: 30,
      borderBottom: `3px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#989899",
    },
    "& .MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled": {
      WebkitTextFillColor: "#989899",
    },
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
  };

  useEffect(() => {
    // Získanie šírky komponentu pri načítaní komponentu
    if (componentRef.current) {
      setWidth(componentRef.current.getBoundingClientRect().width);
    }

    // Voliteľne: Aktualizácia šírky pri zmene veľkosti okna
    const handleResize = () => {
      if (componentRef.current) {
        setWidth(componentRef.current.getBoundingClientRect().width);
      }
    };

    window.addEventListener("resize", handleResize);

    // Odstránenie event listeneru pri odpojení komponentu
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  useEffect(() => {
    if (visible) nacitajStavSkladu(valuePolozka.znacka);
  }, [valuePolozka]);

  function dajFieldPolozky(fields) {
    var retazec = "";
    fields.map((field) => {
      if (valuePolozka[field] !== undefined)
        retazec = retazec + valuePolozka[field] + " ";
    });
    return retazec;
  }

  const removeFields = (index) => {
    let data_ = [...reklam_polozky];
    data_.splice(index, 1);
    setReklam_polozky(data_);
  };

  function dajFieldDokladu(fields) {
    var retazec = "";
    fields.map((field) => {
      if (valueDoklad[field] !== undefined)
        if (field === "datum") {
          retazec = retazec + moment(valueDoklad[field]).format("DD.MM.YYYY");
        } else {
          retazec = retazec + valueDoklad[field] + " ";
        }
    });
    return retazec;
  }

  async function nacitajSuroviny() {
    await axios
      .post(
        reklamacieSQLSuroviny,
        {
          odberatel:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          pobocka:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        setIdField("znacka");
        var newData = response.data.map((x) => {
          return { ...x, nadpis: x.text, label: x.znacka };
        });
        setData(newData);
        setValueDoklad({});
        setReklam_polozky([
          {
            id: 0,
            mnozstvo: 0,
            typ_rekl: null,
            datum: null,
            komentar: "",
            fotky: [],
          },
        ]);
        setVisible(true);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function nacitajStavSkladu(object) {
    await axios
      .post(
        reklamacieSQLStavSkl,
        {
          odberatel:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          pobocka:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          znacka: object.znacka,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        var obj = { ...object };
        obj.stav_skladu = response.data;
        setValuePolozka(obj);
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function nacitajDoklady() {
    if (valuePolozka.znacka === undefined) {
      swal("Chyba!", "Nejprve vyberte položku.", "error");
      return;
    }
    await axios
      .post(
        reklamacieSQLDoklady,
        {
          polozka: valuePolozka.znacka,
          odberatel:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          pobocka:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        setIdField("dl_vodica");
        var newData = response.data.map((x) => {
          return {
            ...x,
            nadpis: x.dl_vodica,
            label: moment(x.datum).format("DD.MM.YYYY"),
          };
        });
        setData(newData);
        setVisibleDoklady(true);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function vytvoritReklamaciu() {
    const updatedReklam_polozky = reklam_polozky.map((item, index) => ({
      ...item, // Zachová ostatní atributy beze změny
      objem: Number(item.mnozstvo),
      typ_reklamace: Number(item.typ_rekl.value),
      poradie_reklamace: index,
    }));
    swal({
      content: {
        element: "div",
        attributes: {
          innerHTML:
            '</div style="font-weight: 300;"> <div style="color: green;">Opravdu chcete vytvořit reklamaci?</div></div>',
        },
      },
      icon: "info",
      buttons: ["Ne ", "Ano"],
    }).then(async (willShow) => {
      if (willShow) {
        await axios
          .post(
            reklamacieSQLZapisUrl,
            {
              polozka: valuePolozka.znacka,
              doklad_dod: valueDoklad.dl_vodica,
              datum_dod: new Date(valueDoklad.datum).toISOString(),
              puv_objem: Number(valueDoklad.mnozstvo),
              //poznamka: komentar,
              polozky: updatedReklam_polozky,
              odberatel:
                logindata.UserData.ico !== undefined
                  ? logindata.UserData.ico
                  : null,
              pobocka:
                logindata.UserData.vybrane_stredisko !== undefined
                  ? logindata.UserData.vybrane_stredisko.kod
                  : null,
            },
            {
              headers: { Authorization: "Bearer " + logindata.Token },
            }
          )
          .then(function (response) {
            //console.log(response);
            response.data.ids.map((x) => {
              var reklam = updatedReklam_polozky.find(
                (y) => x.poradie === y.poradie_reklamace
              );
              //console.log(reklam);
              if (reklam.fotky !== undefined) {
                reklam.fotky.map(async (file) => {
                  const formData = new FormData();
                  formData.append("files", file);
                  console.log(formData);
                  await axios
                    .post(filesUploadReklamaceUrl + x.id, formData, {
                      headers: {
                        Authorization: "Bearer " + logindata.Token,
                      },
                    })
                    .then(function (response) {
                      console.log("respreklamace dokončenie.");
                    })
                    .catch(function (error) {
                      errorHandleDialog(error.response, navigate, dispatch);
                    });
                });
              }
            });
            setRemoveAll(!removeAll);
            setValuePolozka({});
            setValueDoklad({});
            setReklam_polozky([
              {
                id: 0,
                mnozstvo: 0,
                typ_rekl: null,
                datum: null,
                komentar: "",
                fotky: [],
              },
            ]);

            setLoadingRekl(false);
            //console.log(reklam);
            setData_dokoncena(response.data.rekl_dats);
            setVisible_dokoncena(true);
          })
          .catch(function (error) {
            errorHandleDialog(error.response, navigate, dispatch);
            setLoadingRekl(false);
          });
      } else {
        setLoadingRekl(false);
      }
    });
  }

  function dajVysku(height) {
    if (window.innerWidth > 767) {
      return height - 178;
    } else {
      return height - 130;
    }
  }

  function getWidth(width) {
    if (width > 2500) {
      return width - 408;
    } else if (width < 2500 && width > 767) {
      return width - 164;
    } else {
      return width - 24;
    }
  }

  function pocetRekl(reklam_polozky) {
    //zatial
    return 2;
    if (
      reklam_polozky.find(
        (polozka) =>
          polozka.typ_rekl !== null &&
          ["0", "2"].includes(polozka.typ_rekl.value)
      )
    ) {
      return 2;
    } else return 3;
  }

  return (
    <Card>
      <LoadingOverlay
        active={loadingRekl}
        className={styles.LoadingOverlay}
        spinner
        text={"Zapisuji reklamaci"}
      >
        <div style={{ height: dajVysku(window.innerHeight) }}>
          <div className={styles.nadpis_dokoncit}>
            <div className={styles.nadpis}>Nová reklamace</div>
            <div
              onClick={() => {
                // zadan polozka
                if (dajFieldPolozky(["text", "znacka"]) === "") {
                  swal("Chyba!", "Nejsou vyplněna povinná pole!", "error");
                  return;
                }
                //zadany doklad
                if (dajFieldDokladu(["dl_vodica", "datum"]) === "") {
                  swal("Chyba!", "Nejsou vyplněna povinná pole!", "error");
                  return;
                }
                //vyplnene povinne polia
                var chyba = false;
                reklam_polozky.map((rek) => {
                  if (rek.mnozstvo === 0) {
                    chyba = true;
                    return;
                  }
                  if (rek.typ_rekl === null) {
                    chyba = true;
                    return;
                  }
                  if (rek.fotky.length === 0 && rek.typ_rekl.value === "1") {
                    chyba = true;
                    return;
                  }
                  if (rek.komentar === "") {
                    chyba = true;
                    return;
                  }
                });
                if (chyba) {
                  swal("Chyba!", "Nejsou vyplněna povinná pole!", "error");
                  return;
                }

                var rkls = reklam_polozky.filter((x) => x.value !== "2");
                var sum = 0;
                var err_pocet = 0;
                var err = false;
                rkls.map((rek) => {
                  if (["0", "2"].includes(rek.typ_rekl.value)) {
                    err_pocet++;
                  }
                  sum += rek.mnozstvo;
                  //ignoruje sa pri validacii poctu reklamovaneho mnozstva ak sa zadalo vyššie množstvo
                  if (
                    sum + valueDoklad.reklamovane > valueDoklad.mnozstvo &&
                    rek.typ_rekl.value !== "2"
                  ) {
                    err = true;
                  }
                });
                if (err) {
                  swal({
                    title: "Chyba!",
                    content: {
                      element: "div",
                      attributes: {
                        innerHTML:
                          "<div>" +
                          "<div>Součet reklamovaného množství je vyšší</div>" +
                          "<div>než množství v přijatém dokladu!</div>" +
                          "</div>",
                      },
                    },
                    icon: "error",
                  });
                  return;
                }
                if (err_pocet > 1) {
                  swal({
                    title: "Chyba!",
                    content: {
                      element: "div",
                      attributes: {
                        innerHTML:
                          "<div>" +
                          "<div>Nelze kombynovat Nižší a Vyšší množství!</div>" +
                          "</div>",
                      },
                    },
                    icon: "error",
                  });
                  return;
                }
                setLoadingRekl(true);
                vytvoritReklamaciu();
              }}
              className={styles.dokoncit}
            >
              Dokončit
            </div>
          </div>
          <div
            style={{ height: dajVysku(window.innerHeight) - 30 }}
            className={styles.main}
          >
            <div className={styles.left}>
              <div className={styles.polozka}>
                <div className={styles.input_nadpis_5}>
                  <div>Položka</div>
                  <div
                    className={
                      dajFieldPolozky(["text", "znacka"]) === ""
                        ? styles.nazov_s_req
                        : styles.nazov_s
                    }
                    onClick={() => {
                      //console.log("tusom");
                      nacitajSuroviny();
                    }}
                  >
                    <div className={styles.nazov_text_1}>
                      {dajFieldPolozky(["znacka"])}
                    </div>
                    <div className={styles.nazov_text_2}>
                      {dajFieldPolozky(["text"])}
                    </div>
                    <div
                      onClick={() => {
                        nacitajSuroviny();
                      }}
                      className={styles.nazov_lupa}
                    >
                      <i class="fa fa-search"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.polozka}>
                <div className={styles.input_nadpis_4}>
                  <div>Měrná jednotka</div>
                  <div className={styles.nazov}>{dajFieldPolozky(["mj"])}</div>
                </div>
                <div className={styles.input_nadpis_4}>
                  {logindata.UserData.typErp !== "B" ? (
                    <></>
                  ) : (
                    <>
                      <div>Stav skladu</div>
                      <div className={styles.nazov}>
                        {dajFieldPolozky(["stav_skladu"])}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={styles.doklad}>
                <div className={styles.input_nadpis_5}>
                  <div>Doklad</div>
                  <div
                    className={
                      dajFieldDokladu(["dl_vodica", "datum"]) === ""
                        ? styles.nazov_s_req
                        : styles.nazov_s
                    }
                    onClick={() => {
                      nacitajDoklady();
                    }}
                  >
                    <div className={styles.nazov_text_1}>
                      {dajFieldDokladu(["dl_vodica"])}
                    </div>
                    <div className={styles.nazov_text_2}>
                      {dajFieldDokladu(["datum"])}
                    </div>
                    <div
                      onClick={() => {
                        nacitajDoklady();
                      }}
                      className={styles.nazov_lupa}
                    >
                      <i class="fa fa-search"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.doklad}>
                <div className={styles.input_nadpis_4}>
                  <div>Dle dokladu přijato</div>
                  <div className={styles.nazov}>
                    {dajFieldDokladu(["mnozstvo"])}
                  </div>
                </div>
                <div className={styles.input_nadpis_4}>
                  <div>Již reklamováno</div>
                  <div className={styles.nazov}>
                    {dajFieldDokladu(["reklamovane"])}
                  </div>
                </div>
              </div>
              {/* <div className={styles.koment}>
              <div>Komentář</div>
              <textarea
                onInput={(e) => setKomentar(e.target.value)}
                input={komentar}
                className={
                  komentar === "" ? styles.textarea_req : styles.textarea
                }
              ></textarea>
            </div>
            <div className={styles.fotky}>
              <div>Fotky reklamované položky</div>
              <Dropzone
                //disabled={true}
                text={"Vložte fotky"}
                // myFiles={myFiles}
                // setMyFiles={setMyFiles}
                onChange={(e)=>console.log(e)}
              />
            </div> */}
            </div>
            <div className={styles.right}>
              <div className={styles.zoznam_mnozstiev}>
                {reklam_polozky.map((polozka, index_) => {
                  //console.log(polozka);
                  return (
                    <div key={polozka.id}>
                      <div className={styles.doklad_add}>
                        <div className={styles.close}>
                          <div className={styles.btn}></div>
                          <button
                            className={styles.btn}
                            onClick={() => {
                              console.log(reklam_polozky.length > 1);
                              if (reklam_polozky.length > 1) {
                                var index = reklam_polozky.indexOf(polozka);
                                removeFields(index);
                              } else {
                                setRemoveAll(!removeAll);
                                setReklam_polozky([
                                  {
                                    id: 0,
                                    mnozstvo: 0,
                                    typ_rekl: null,
                                    datum: null,
                                    komentar: "",
                                    fotky: [],
                                  },
                                ]);
                              }
                            }}
                          >
                            <Icon name="close" size="20" />
                          </button>
                        </div>
                        <div className={styles.doklad_layout}>
                          <div className={styles.input_nadpis_3}>
                            <div>Reklamované množství</div>
                            <input
                              id={index_}
                              ref={componentRef}
                              pattern="[0-9]+([\.][0-9]{1,2})?"
                              onClick={() =>
                                document.getElementById(index_).select()
                              }
                              type="number"
                              value={
                                polozka.mnozstvo === 0 ? "" : polozka.mnozstvo
                              }
                              className={
                                polozka.mnozstvo > 0
                                  ? styles.nazov_input
                                  : styles.nazov_input_req
                              }
                              onChange={(e) => {
                                if (valuePolozka.znacka === undefined) {
                                  swal(
                                    "Chyba!",
                                    "Nejprve vyberte položku.",
                                    "error"
                                  );
                                  return;
                                }
                                if (valuePolozka.znacka === undefined) {
                                  swal(
                                    "Chyba!",
                                    "Nejprve vyberte doklad.",
                                    "error"
                                  );
                                  return;
                                }
                                console.log(valuePolozka, valueDoklad);
                                const newItems = [...reklam_polozky];
                                var index = newItems.indexOf(polozka);
                                newItems[index] = {
                                  ...newItems[index],
                                  mnozstvo: validateNumberWithMaxDecimals(
                                    Number(e.target.value),
                                    valuePolozka.des_miesta
                                  )
                                    ? Number(e.target.value)
                                    : polozka.mnozstvo,
                                };
                                setReklam_polozky(newItems);
                              }}
                            ></input>
                          </div>
                          <div className={styles.input_nadpis_6}>
                            <div>Typ reklamace</div>
                            <div className={styles.select_area}>
                              <Select
                                //name={params_.id}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#FAEECB ",
                                    primary50: "#FAEECB ",
                                    primary: "#F1B600",
                                  },
                                })}
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    //width: "100%",
                                    borderColor:
                                      polozka.typ_rekl === null
                                        ? "#F1B600"
                                        : "#b3b3b3",
                                    borderRadius: 8,
                                    borderWidth: 2,
                                    width: (width / 30) * 70,
                                    backgroundColor: !darkMode.value
                                      ? "white"
                                      : "#262b30",
                                  }),
                                  option: (base, state) => ({
                                    ...base,
                                    width: "100%",
                                    borderRadius: 8,
                                    color: "#6F767E",
                                  }),
                                  singleValue: (base, state) => ({
                                    ...base,
                                    width: "100%",
                                    borderRadius: 3,
                                    color: "#82878c",
                                  }),
                                  menu: (base, state) => ({
                                    ...base,
                                    width: "100%",
                                    borderRadius: 0,
                                    marginTop: 0,
                                    backgroundColor: !darkMode.value
                                      ? "white"
                                      : "#262b30",
                                  }),
                                }}
                                className={styles.select}
                                value={polozka.typ_rekl}
                                isClearable={false}
                                isSearchable={false}
                                //algoritmus na to aby sa už vybrané možnosti nezobrazovali v možnostiach
                                options={
                                  reklam_polozky.length === 1
                                    ? options
                                    : options.filter(
                                        (x) =>
                                          !reklam_polozky.find(
                                            (y) => x === y.typ_rekl
                                          )
                                      )
                                }
                                // Display custom text in the dropdown
                                formatOptionLabel={(option) => option.display}
                                // Display different text in the input field
                                getOptionLabel={(option) => option.label}
                                onChange={(e) => {
                                  if (valuePolozka.znacka === undefined) {
                                    swal(
                                      "Chyba!",
                                      "Nejprve vyberte položku.",
                                      "error"
                                    );
                                    return;
                                  }
                                  if (valuePolozka.znacka === undefined) {
                                    swal(
                                      "Chyba!",
                                      "Nejprve vyberte doklad.",
                                      "error"
                                    );
                                    return;
                                  }
                                  // // podminka nemôžeme zdať vx ten isty typ rekl
                                  // if (
                                  //   reklam_polozky.find((y) => y.typ_rekl === e)
                                  // ) {
                                  //   swal(
                                  //     "Chyba!",
                                  //     "Vyberte jiný typ reklamace.",
                                  //     "error"
                                  //   );
                                  //   return;
                                  // }
                                  // if (
                                  //   ["0", "2"].includes(e.value) &&
                                  //   reklam_polozky.find(
                                  //     (y) =>
                                  //       y.typ_rekl !== null &&
                                  //       ["0", "2"].includes(y.typ_rekl.value)
                                  //   )
                                  // ) {
                                  //   swal(
                                  //     "Chyba!",
                                  //     "Nelze kombynovat Nižší a Vyšší množství.",
                                  //     "error"
                                  //   );
                                  //   return;
                                  // }
                                  const newItems = [...reklam_polozky];
                                  var index = newItems.indexOf(polozka);
                                  newItems[index] = {
                                    ...newItems[index],
                                    typ_rekl: e,
                                  };
                                  setReklam_polozky(newItems);
                                }}
                              />
                            </div>
                          </div>
                          {/* <div className={styles.input_nadpis_3}>
                          <div>Datum spotřeby položky</div>
                          <span>
                            <ThemeProvider theme={theme}>
                              <LocalizationProvider
                                adapterLocale="cs"
                                localeText={
                                  csCZ.components.MuiLocalizationProvider
                                    .defaultProps.localeText
                                }
                                dateAdapter={AdapterMoment}
                              >
                                <DatePicker
                                  disabled
                                  value={moment.utc(polozka.datum)}
                                  onChange={(param, e) => {
                                    const newItems = [...reklam_polozky];
                                    var index = newItems.indexOf(polozka);
                                    newItems[index] = {
                                      ...newItems[index],
                                      datum: param._d.toISOString(),
                                    };
                                    setReklam_polozky(newItems);
                                  }}
                                  sx={stylingRangePicker}
                                />
                              </LocalizationProvider>
                            </ThemeProvider>
                          </span>
                        </div> */}
                        </div>
                        <div className={styles.doklad_layout_2}>
                          <div className={styles.koment}>
                            <div>Komentář</div>
                            <textarea
                              // onInput={(e) => {
                              //   const newItems = [...reklam_polozky];
                              //   var index = newItems.indexOf(polozka);
                              //   newItems[index] = {
                              //     ...newItems[index],
                              //     komentar: e.target.value,
                              //   };
                              //   setReklam_polozky(newItems);
                              // }}
                              onChange={(e) => {
                                if (valuePolozka.znacka === undefined) {
                                  swal(
                                    "Chyba!",
                                    "Nejprve vyberte položku.",
                                    "error"
                                  );
                                  return;
                                }
                                if (valuePolozka.znacka === undefined) {
                                  swal(
                                    "Chyba!",
                                    "Nejprve vyberte doklad.",
                                    "error"
                                  );
                                  return;
                                }
                                const newItems = [...reklam_polozky];
                                var index = newItems.indexOf(polozka);
                                newItems[index] = {
                                  ...newItems[index],
                                  komentar: e.target.value,
                                };
                                setReklam_polozky(newItems);
                              }}
                              //input={polozka.komentar}
                              value={polozka.komentar}
                              className={
                                polozka.komentar === ""
                                  ? styles.textarea_req
                                  : styles.textarea
                              }
                            ></textarea>
                          </div>
                          <div className={styles.fotky}>
                            <div>Fotky reklamované položky</div>
                            <Dropzone
                              //disabled={true}
                              text={"Vložte fotky"}
                              acceptedFiles={"image/jpeg,image/png"}
                              value={polozka.fotky}
                              required={
                                polozka.fotky.length === 0 &&
                                polozka.typ_rekl !== null &&
                                polozka.typ_rekl.value === "1"
                              }
                              onChange={(param) => {
                                if (param.length > 0) {
                                  if (valuePolozka.znacka === undefined) {
                                    swal(
                                      "Chyba!",
                                      "Nejprve vyberte položku.",
                                      "error"
                                    );
                                    return;
                                  }
                                  if (valuePolozka.znacka === undefined) {
                                    swal(
                                      "Chyba!",
                                      "Nejprve vyberte doklad.",
                                      "error"
                                    );
                                    return;
                                  }
                                  if (polozka.typ_rekl === null) {
                                    swal(
                                      "Chyba!",
                                      "Nejprve vyberte typ reklamace.",
                                      "error"
                                    );
                                    return;
                                  }
                                }
                                const newItems = [...reklam_polozky];
                                var index = newItems.indexOf(polozka);
                                newItems[index] = {
                                  ...newItems[index],
                                  fotky: param,
                                };
                                setReklam_polozky(newItems);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className={styles.input_icon}>
                      <div
                        onClick={() => {
                          if (reklam_polozky.length > 1) removeFields(index);
                        }}
                        className={styles.icon}
                      >
                        <i class="fa fa-trash-o"></i>
                      </div>
                    </div> */}
                    </div>
                  );
                })}
              </div>
              <div className={styles.pridat_do_zoznamu}>
                {reklam_polozky.length < pocetRekl(reklam_polozky) ? (
                  <div
                    onClick={() => {
                      if (reklam_polozky.length < pocetRekl(reklam_polozky)) {
                        const maxId = reklam_polozky.reduce(
                          (max, item) => (item.id > max ? item.id : max),
                          0
                        );
                        setReklam_polozky([
                          ...reklam_polozky,
                          {
                            id: maxId + 1,
                            mnozstvo: 0,
                            typ_rekl: null,
                            datum: null,
                            komentar: "",
                            fotky: [],
                          },
                        ]);
                      }
                    }}
                    className={styles.pridat}
                  >
                    <i class="fa fa-plus"></i>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <ModalVyberZoZoznamu
          visible={visible}
          setVisible={setVisible}
          data={data}
          typ={""}
          idField={idField}
          value={valuePolozka}
          onChange={(e) => {
            if (logindata.UserData.vybrane_stredisko !== undefined)
              nacitajStavSkladu(e);
            else setValuePolozka(e);
          }}
        ></ModalVyberZoZoznamu>
        <ModalVyberZoZoznamu
          visible={visibleDoklady}
          setVisible={setVisibleDoklady}
          data={data}
          typ={""}
          idField={idField}
          value={valueDoklad}
          onChange={(e) => {
            setValueDoklad(e);
          }}
        ></ModalVyberZoZoznamu>
        <ModalReklamaceDokoncena
          visible={visible_dokoncena}
          setVisible={setVisible_dokoncena}
          data={data_dokoncena}
        ></ModalReklamaceDokoncena>
      </LoadingOverlay>
    </Card>
  );
};
export default App;
