import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import cn from "classnames";
import styles from "./Messages.module.sass";
import Icon from "../../Icon";

import ModalZmenaPobocky from "./../../Modaly/ModalZmenaPobocky";

import ModalEditTagovPolozky from "./../../Modaly/ModalEditTagovPolozky";
import ModalZobrazHtml from "./../../Modaly/ModalZobrazHtml";
import ModalZobrazPdf from "./../../Modaly/ModalZobrazPdf";
import ModalZobrazHtml2 from "./../../Modaly/ModalZobrazHtml2";
import ModalPanel from "./../../Modaly/ModalPanel";
import { ClickAwayListener } from "@material-ui/core";

const akce = [
  {
    title: "Příjem surovin",
    name: "prijemSurovin",
    avatar: "/images/content/akce1.png",
  },
  // {
  //   title: "Příjem surovin - Žiželice",
  //   name: "prijemSurovinZ",
  //   avatar: "/images/content/akce1.png",
  // },
  {
    title: "Reklamace",
    name: "reklamace",
    avatar: "/images/content/akce2.png",
  },
  {
    title: "Malá inventura",
    name: "malaInventura",
    avatar: "/images/content/akce3.png",
  },
  {
    title: "Převod surovin",
    name: "prevodSurovin",
    avatar: "/images/content/akce4.png",
  },
  {
    title: "Nabídnout surovinu",
    name: "nabidnoutSurovinu",
    avatar: "/images/content/akce5.png",
  },
  {
    title: "Stav skladu",
    name: "stavSkladu",
    avatar: "/images/content/akce6.png",
  },
];

const Create = ({ className }) => {
  const navigate = useHistory();

  const [visible, setVisible] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setVisible(false)}>
      <div
        className={cn(styles.messages, className, {
          [styles.active]: visible,
        })}
      >
        {window.innerWidth > 1056 ? (
          <button
            className={cn("button", styles.button)}
            onClick={() => setVisible(!visible)}
          >
            <Icon name="add" size="24" />
            <span>Vytvořit</span>
          </button>
        ) : (
          <button
            className={cn("button", styles.button)}
            onClick={() => setVisible(!visible)}
          >
            <Icon name="add" size="24" />
          </button>
        )}
        <div className={styles.body}>
          <div className={styles.top}>
            <div className={styles.title}>Možné akce</div>
          </div>
          <div className={styles.list}>
            {akce.map((x, index) => (
              <div
                key={index}
                className={cn(styles.item, className)}
                onClick={() => {
                  if (x.name === "prijemSurovinZ") {
                    navigate.push("/vytvorit/prijemSurovin");
                    //dispatch(modal({ data: {...modaldata , PrijemSurovin : true } }))
                  }
                  if (x.name === "prijemSurovin") {
                    navigate.push("/vytvorit/prijemky");
                    //dispatch(modal({ data: {...modaldata , ExNaPs : true } }))
                  }
                  if (x.name === "malaInventura") {
                    navigate.push("/vytvorit/malaInventura");
                    // dispatch(modal({ data: {...modaldata , MalaInventura : true } }))
                  }
                  if (x.name === "reklamace") {
                    navigate.push("/vytvorit/reklamace");
                    //dispatch(modal({ data: {...modaldata , Reklamace : true } }))
                  }
                  if (x.name === "nabidnoutSurovinu") {
                    navigate.push("/vytvorit/nabidnoutPobocke");
                    //dispatch(modal({ data: {...modaldata , NabidnoutPobocke : true } }))
                  }
                  if (x.name === "stavSkladu") {
                    navigate.push("/vytvorit/stavSkladu");
                    //dispatch(modal({ data: {...modaldata , StavSkladu : true } }))
                  }
                  if (x.name === "prevodSurovin") {
                    navigate.push("/vytvorit/prevodSurovin");
                    //dispatch(modal({ data: {...modaldata , PrevodSurovin : true } }))
                  }
                  setVisible(!visible);
                }}
              >
                <div className={cn(styles.avatar)}>
                  <img src={x.avatar} alt="Avatar" />
                </div>
                <div className={styles.details}>
                  <div className={styles.line}>
                    <div className={styles.subtitle}>{x.title}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        
      </div>
    </ClickAwayListener>
  );
};

export default Create;
