import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import { useSelector, useDispatch } from "react-redux";
import Icon from "../../../../components/Icon";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGridPremium, LicenseInfo, csCZ } from "@mui/x-data-grid-premium";
import { getLicensePremium } from "../../../../features/License";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ContentLoader from "react-content-loader";
import useDarkMode from "@fisch0920/use-dark-mode";
import swal from "sweetalert";
import {
  Form,
  FormData,
  loadingForm,
} from "../../../../components/Modaly/ModalForm/redux/FormSlice";
import ModalForm from "../../../../components/Modaly/ModalForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { formRunUrl, menuRunUrl } from "../../../../features/urlHelper";
import { errorHandleDialog } from "../../../../features/errorDialog";
import Select from "react-select";
// data

const data = [];

const Table = ({
  className,
  data,
  setData,
  druhVydeje,
  setDruhVydeje,
  options,
  formName,
  setFormName,
  poznaka,
  setPoznamka,
  loading,
  priznak,
}) => {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const darkMode = useDarkMode(false);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const Formdata = useSelector(FormData);
  LicenseInfo.setLicenseKey(getLicensePremium());
  const [visibleForm, setVisibleForm] = useState(false);

  const removeFields = (index) => {
    let data_ = [...data];
    data_.splice(index, 1);
    setData(data_);
  };

  function getWidth(width) {
    if (width > 2500) {
      return width - 456;
    } else if (width < 2500 && width > 767) {
      return width - 212;
    } else {
      return width - 48;
    }
  }

  async function call_form(volba, data = null) {
    await axios
      .post(
        formRunUrl,
        {
          formName: formName,
          formValues: data,
          volba: volba,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        if (response.data.typ === "route") {
          navigate.push(response.data.url);
        } else if (response.data.typ === "enter") {
          console.log("enterData", response.data.enterData);

          dispatch(loadingForm());
          setFormName(response.data.enterData.formName);
          dispatch(Form(response.data.enterData));
          setVisibleForm(true);
          document
            .getElementsByName(
              response.data.enterData.values[
                Object.keys(response.data.enterData.values)[0]
              ].id
            )[0]
            .focus();
        }
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  const stylingTable = {
    border: 0,

    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      color: darkMode.value ? "white" : "#666d74",
      borderRight: `1px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .MuiDataGrid-overlay": {
      backgroundColor: "transparent",
      color: darkMode.value ? "white" : "#ACACAC",
    },
    "& .MuiInputBase-input": {
      color: darkMode.value ? "white" : "#ACACAC",
    },
    "& .MuiSvgIcon-root": {
      color: "#a7acaf",
    },
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
      "& .MuiInputBase-root": {
        height: "100%",
      },
    },
    "& .MuiDataGrid-cell": {
      padding: 0.5,
    },
    "& .Mui-error": {
      backgroundColor: "red",
      color: "white",
    },
    width: getWidth(window.innerWidth),
    height: 500,
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
  };

  const theme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
    },
  });
  const columns = [
    {
      field: "nazov",
      headerName: "Položka",
      width: 280,
      groupable: false,
      renderCell: (params) => (
        <>
          <div className={styles.nazov}>
            <div className={styles.avatar}>
              <img src={"/images/content/bageta2.png"} alt="Avatar" />
            </div>
            <div className={styles.nazov_kod}>
              <div>{params.row.nazov}</div>
              <div className={styles.znacka}>
                <div className={styles.znackaText}>{params.row.znacka}</div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      field: "mj",
      headerName: "Mj",
      width: 30,
      headerAlign: "center",
      renderCell: (params) =>{
        return <div className={styles.cell}>{params.value}</div>;
      }
    },
    {
      field: (priznak !== "OdpisS" ? "druh_peciva" : "stav_skladu"),
      headerName: (priznak !== "OdpisS" ? "Druh pečiva" : "Stav skladu"),
      width: (getWidth(window.innerWidth) - 390) * 0.25,
      minWidth: 100,
      type: "number",
      headerAlign: "center",
      valueFormatter: (params) => {
        if(priznak === "OdpisS"){
          const valueFormatted = Number(params.value);

          const value = valueFormatted
            .toFixed(0)
            .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");
  
          return `${value}`;
        }
        return `${params.value}`;
      },
      renderCell: (params) =>{
        if(priznak === "OdpisS"){
          const valueFormatted = Number(params.value);

          const value = valueFormatted
            .toFixed(0)
            .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");
  
          return <div className={styles.cell}>{value}</div>;
        }
        return <div className={styles.cell}>{params.value}</div>;
      }
    },

    {
      field: "vydaj",
      headerName: "Množství k odpisu",
      width: (getWidth(window.innerWidth) - 390) * 0.25,
      minWidth: 100,
      headerAlign: "center",
      type: "number",
      renderCell: (params) =>{
       
          const valueFormatted = Number(params.value);

          const value = valueFormatted
            .toFixed(0)
            .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");
  
          return <div className={styles.cell}>{value}</div>;
      }
    },
    {
      field: "poznamka",
      headerName: "Poznámka",
      width: (getWidth(window.innerWidth) - 390) * 0.5,
      minWidth: 150,
      headerAlign: "center",
      type: "number",
    },
    {
      field: "stav",
      headerName: "",
      width: 60,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <div className={styles.icons}>
            <div
              onClick={() => {
                var varpol1 = {
                  ...Formdata.data.values.varpol1,
                  value: params.row.znacka,
                  selectorLabel: params.row.nazov,
                };
                var varpol2 = {
                  ...Formdata.data.values.varpol2,
                  value: (priznak === "OdpisS" ? params.row.stav_skladu : params.row.vydaj),
                };
                var varpol3 = {
                  ...Formdata.data.values.varpol3,
                  value: params.row.mj,
                };
                var varpol4 = {
                  ...Formdata.data.values.varpol4,
                  value: (priznak === "OdpisS" ? params.row.vydaj : params.row.druh_peciva),
                };
                var varpol5 = {
                  ...Formdata.data.values.varpol5,
                  value: params.row.poznamka,
                };
                var varpol6 = {
                  ...Formdata.data.values.varpol6,
                  value: params.row.dan,
                };
                var data = {
                  varpol1: varpol1,
                  varpol2: varpol2,
                  varpol3: varpol3,
                  varpol4: varpol4,
                  varpol5: varpol5,
                  varpol6: varpol6,
                };
                call_form("edit", data);
              }}
              className={styles.icon1}
            >
              <Icon name="edit" size="20"></Icon>
            </div>
            <div
              onClick={() => {
                swal({
                  title: "Chcete smazat položku?",
                  icon: "info",
                  buttons: ["Ne ", "Ano"],
                  dangerMode: true,
                }).then(async (willShow) => {
                  if (willShow) {
                    removeFields(data.indexOf(params.row));
                  }
                });
              }}
              className={styles.icon2}
            >
              <Icon name="trash" size="20"></Icon>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className={styles.vyd_kom}>
        <div className={styles.kom}>
          <div>Druh výdeje</div>
          <Select
            theme={(theme) => ({
              ...theme,
              borderRadius: 8,
              colors: {
                ...theme.colors,
                primary25: "#FAEECB ",
                primary50: "#FAEECB ",
                primary: "#F1B600",
              },
            })}
            styles={{
              control: (base, state) => ({
                ...base,
                width: 250,
                borderRadius: 3,
                backgroundColor: !darkMode.value ? "white" : "#262b30",
              }),
              option: (base, state) => ({
                ...base,
                borderRadius: 3,
                color: "#6F767E",
              }),
              singleValue: (base, state) => ({
                ...base,
                borderRadius: 3,
                color: "#82878c",
              }),
              menu: (base, state) => ({
                ...base,
                borderRadius: 0,
                marginTop: 0,
                backgroundColor: !darkMode.value ? "white" : "#262b30",
              }),
            }}
            className={styles.select}
            value={druhVydeje}
            isClearable={false}
            isSearchable={false}
            options={options}
            onChange={(e) => {
              setDruhVydeje(e);
            }}
          />
        </div>
        <div className={styles.kom}>
          <div>Komentář</div>
          <textarea
            onInput={(e) => setPoznamka(e.target.value)}
            input={poznaka}
            className={styles.textarea}
          ></textarea>
        </div>
      </div>

      <div className={styles.spacebeetween}>
        <button
          onClick={() => {
            call_form("add");
          }}
          className={styles.buttonPS}
        >
          <div className={styles.buttonPSI}>
            <Icon name="add" size="20" />
          </div>
          <div className={styles.buttonPST}>Přidat položku</div>
        </button>
      </div>

      <div>
        {loading ? (
          <ContentLoader
            speed={2}
            width={"100%"}
            height={300}
            backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
            foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
          >
            <rect x="3%" y="5%" rx="3" ry="3" width={"100%"} height="80%" />
          </ContentLoader>
        ) : (
          <>
            <ThemeProvider theme={theme}>
              <DataGridPremium
                sx={stylingTable}
                localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                {...{
                  rows: data,
                  columns: columns,
                }}
                columnHeaderHeight={25}
                disableAutoFocus
                disableColumnMenu
                defaultGroupingExpansionDepth={-1}
                hideFooter
                rowHeight={40}
                groupingColDef={{
                  leafField: "nazov",
                  headerClassName: "weekend6_help_nadpis",
                  cellClassName: "weekend6_help_cell",
                }}
              />
            </ThemeProvider>
          </>
        )}
        <ToastContainer />
        <ModalForm
          priznak={priznak}
          visible={visibleForm}
          setVisible={setVisibleForm}
          className={styles}
          formName={formName}
          height={400}
          data={data}
          setData={setData}
        />
      </div>
    </>
  );
};

export default Table;
