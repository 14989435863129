import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useHistory } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUrl,
  notifikaciaUrl,
  zmenaHeslaUrl,
} from "../../features/urlHelper";
import swal from "sweetalert";
import { TailSpin } from "react-loader-spinner";
import { loginData } from "../SignIn/redux/loginSlice";
import { errorHandleDialog } from "../../features/errorDialog";

const App = ({}) => {
  const heightWindow = use100vh();

  const [log, setLog] = useState(true);
  const [logText, setLogText] = useState("");
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [loading, setLoading] = useState(false);
  const [isDevelop, setIsDevelop] = useState(
    window.location.hostname === "localhost" ? true : false
  );

  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [password, setPassword] = useState("");

  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );

  async function zmenaHesla(password, newPassword) {
    setLoading(true);
    await axios
      .post(
        zmenaHeslaUrl,
        {
          username: logindata.UserData.username,
          password: password,
          newPassword: newPassword,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        setPassword("");
        setNewPassword1("");
        setNewPassword2("");
        swal("Změna hesla", "Změna hesla proběhla úspěšně.", "success");
      })
      .catch(function (error) {
        console.log(error.response);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  function ohodnotSíluHesla(heslo) {
    // Délka hesla
    var délka = heslo.length;

    // Existence kombinace malých a velkých písmen
    var obsahujeMaláPísmena = /[a-z]/.test(heslo);
    var obsahujeVelkáPísmena = /[A-Z]/.test(heslo);

    // Existence čísel
    var obsahujeČísla = /\d/.test(heslo);

    // Existence speciálních znaků
    var obsahujeSpeciálníZnaky = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(heslo);

    // Výpočet bodů na základě různých faktorů
    var body = 0;
    body += délka >= 8 ? 2 : 0;
    body += obsahujeMaláPísmena && obsahujeVelkáPísmena ? 2 : 0;
    body += obsahujeČísla ? 2 : 0;
    body += obsahujeSpeciálníZnaky ? 3 : 0;

    // Klasifikace síly hesla
    if (body <= 4) {
      return "Slabé heslo";
    } else if (body <= 8) {
      return "Střední heslo";
    } else {
      return "Silné heslo";
    }
  }

  function dajFarbu(retazec) {
    // eslint-disable-next-line default-case
    switch (retazec) {
      case "Slabé heslo":
        return "red";

      case "Střední heslo":
        return "orange";

      default:
        return "green";
    }
  }

  return (
    <div className={styles.login} style={{ height: window.innerHeight - 122 }}>
      <div className={styles.wrapper}>
        <div className={cn("h2", styles.title)}>Změna hesla</div>
        <div className={styles.head}></div>
        <div
          // onKeyPress={async (e) => {
          //   if (e.key === "Enter") {
          //     loginTo(name, password);
          //   }
          // }}
          className={styles.body}
        >
          {/* <div className={styles.subtitle}>
            Pro změnu zadejte své přihlašovací heslo a vytvořte si nové
            </div> */}
          <div>Původní heslo</div>
          <TextInput
            className={styles.field1}
            name="password"
            type="password"
            placeholder="Původní heslo"
            required
            icon="lock"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <div>Nové heslo</div>
          <TextInput
            className={styles.field2}
            name="password"
            type="password"
            placeholder="Nové heslo"
            required
            icon="lock"
            value={newPassword1}
            onChange={(e) => {
              setNewPassword1(e.target.value);
            }}
          />
          {newPassword1.length > 2 ? (
            <div className={styles.silaHesla}>
              <p>Síla hesla:</p>
              <p style={{ color: dajFarbu(ohodnotSíluHesla(newPassword1)) }}>
                {ohodnotSíluHesla(newPassword1)}
              </p>
            </div>
          ) : (
            <div></div>
          )}

          <div>Potvrdit heslo</div>
          <TextInput
            className={styles.field2}
            name="password"
            type="password"
            placeholder="Potvrdit heslo"
            required
            icon="lock"
            value={newPassword2}
            onChange={(e) => {
              if (
                e.target.value !== newPassword1 &&
                newPassword1.length > 3 &&
                newPassword2 !== ""
              ) {
                setLog(false);
                setLogText("Hesla se neshodují");
              } else {
                setLog(true);
                setLogText("");
              }
              setNewPassword2(e.target.value);
            }}
          />
          <div
            hidden={log}
            style={{ whiteSpace: "pre-wrap" }}
            className={styles.badLogin}
          >
            {logText}
          </div>
          <button
            disabled={newPassword1 !== newPassword2}
            onClick={() => {
              zmenaHesla(password, newPassword2);
            }}
            className={styles.button_sign_in}
          >
            Změnit heslo
          </button>
        </div>
      </div>
    </div>
  );
};

export default App;
