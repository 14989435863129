import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({
  id,
  item,
  value,
  onChange,
  activeTable,
  setActiveTable,
  activeId,
  setActiveId,
}) => {

  return (
    <>
      <div className={cn(styles.row)}>
        <div className={styles.col}>
          <div className={styles.item}>
            {window.innerWidth < 500 ? (
              <></>
            ) : (
              <div className={styles.avatar}>
                <img src="/images/content/bageta.png" alt="Avatar" />
              </div>
            )}
            <div>
              <span
                className={styles.nazov}
                style={{
                  fontSize: 13,
                }}
              >
                {item.nazov}
              </span>
              <br />
              <span
                style={{
                  color: "#C9C9C9",
                  fontSize: 12,
                }}
              >
                {item.znacka}
              </span>
            </div>
          </div>
        </div>

        <div
          className={styles.col}
          style={{
            fontSize: 13,
          }}
        >
          <div className={styles.text}>{item.stav}</div>
        </div>
        <div
          className={styles.col}
          style={{
            fontSize: 13,
          }}
        >
          <div className={styles.text}>{item.mj}</div>
        </div>

        <div
          className={styles.col}
          style={{
            fontSize: 13,
          }}
        >
          <div className={styles.text}>{item.dan}
          </div>
        </div>
        
      </div>
    </>
  );
};

export default Row;
