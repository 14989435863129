import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../Icon";
import Modal from "../../Modal";
import { modal, modalData } from "../redux/modalSlice";
import styles from "./Modal.module.sass";
import { login, loginData } from "../../../screens/SignIn/redux/loginSlice";
import axios from "axios";
import { getPdf } from "../../../features/getPdf";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
const App = ({ className, visible, setVisible, data }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();

  const modaldata = useSelector(modalData);

  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Získaj dátum vo formáte YYYY-MM-DD
    const datePart = date.toISOString().split("T")[0];
    // Získaj čas vo formáte HH:MM:SS
    const timePart = date.toTimeString().split(" ")[0];
    return `${datePart}T${timePart}`;
  };

  return (
    <Modal
      outerClassName={styles.outerZaznamProblemu}
      widthModal={420}
      heightModal={350}
      visible={visible}
    >
      <div className={styles.ico_Title}>
        <button
          onClick={() => {
            setVisible(false);
          }}
        >
          <Icon name="close" size="20" />
        </button>
      </div>
      <div>
        <div className={styles.nadpis}>
          <div>Reklamace je úspěšne dokončena</div>
        </div>
        {data.map((rekl) => {
          return (
            <div className={styles.rekl_line}>
              PPřejít na detail reklamace{" "}
              <u
                onClick={() => {
                  //console.log(formatDate(rekl.datum));
                  navigate.push(
                    `/reklamaceSQLDetail?datum=${formatDate(
                      rekl.datum
                    )}&cis_rekl=${rekl.cis_rekl}&priznak=${"nova"}`
                  );
                }}
                className={styles.rekl}
              >
                {rekl.cis_rekl}
              </u>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
export default App;
