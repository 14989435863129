import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import remove_accents from "remove-accents";
import { useSelector, useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import useDarkMode from "@fisch0920/use-dark-mode";
import axios from "axios";
import {
  loadingProhlizeniSestav,
  prohlizeniSestav,
  prohlizeniSestavData,
} from "./redux/ProhlizeniSestavSlice";
import { login, loginData } from "../../SignIn/redux/loginSlice";
import { searchData } from "../../../components/Search/redux/searchSlice";
import {
  HtmlUrl,
  prevodSurovinUrl,
  sestavyUrl,
} from "../../../features/urlHelper";
import { getPdf } from "../../../features/getPdf";
import {
  DataGridPremium,
  LicenseInfo,
  csCZ,
  useGridApiRef,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { getLicensePremium } from "../../../features/License";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { modal, modalData } from "../../../components/Modaly/redux/modalSlice";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { errorHandleDialog } from "../../../features/errorDialog";

const Table = ({ className, activeTable, setEditRowsModel }) => {
  const darkMode = useDarkMode(false);
  const navigate = useHistory();
  const notify_error = (text) => toast.error(text);
  LicenseInfo.setLicenseKey(getLicensePremium());
  const dataGridRef = useGridApiRef(null);
  const dispatch = useDispatch();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const modaldata = useSelector(modalData);
  const prohlizeniSestavdata = useSelector(prohlizeniSestavData);
  const searchdata = useSelector(searchData);

  function getWidth(width) {
    if (width > 2500) {
      return width - 456;
    } else if (width < 2500 && width > 767) {
      return width - 212;
    } else {
      return width - 116;
    }
  }

  function isInt(n) {
    return n % 1 === 0;
  }

  const stylingTable = {
    border: 0,

    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      color: darkMode.value ? "white" : "#666d74",
      borderRight: `1px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },

    "& .MuiSvgIcon-root": {
      color: "#a7acaf",
    },
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
      "& .MuiInputBase-root": {
        height: "100%",
      },
    },
    "& .MuiDataGrid-cell": {
      padding: 2,
    },
    "& .Mui-error": {
      backgroundColor: "red",
      color: "white",
    },
    width: getWidth(window.innerWidth),
    height: window.innerHeight > 500 ? window.innerHeight - 360 : 500,
    justifyContent: "center",
    alighnItems: "center",
  };

  const columns = [
    {
      field: "datum",
      headerName: "Datum a čas",
      width: 210,
      headerAlign: "center",
      groupable: false,
      renderCell: (params) => (
        <>
          <div className={styles.icons}>
            <button
              disabled={!params.row.html}
              onClick={async () => {
                await axios
                  .get(HtmlUrl + params.row.id, {
                    headers: { Authorization: "Bearer " + logindata.Token },
                  })
                  .then(function (response) {
                    console.log("html", response.data);
                    dispatch(
                      modal({
                        data: {
                          ...modaldata,
                          ZobrazHtml: true,
                          HtmlData: response.data,
                          ZobrazHtml_print: params.row.id,
                        },
                      })
                    );
                  })
                  .catch(function (error) {
                    errorHandleDialog(error.response, navigate, dispatch);
                  });
              }}
              className={params.row.html ? styles.icon : styles.icon_disabled}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M19 9C19 9.55229 19.4477 10 20 10C20.5523 10 21 9.55229 21 9V5C21 3.89543 20.1046 3 19 3H15C14.4477 3 14 3.44772 14 4C14 4.55228 14.4477 5 15 5L17.5858 5L11.2929 11.2929C10.9024 11.6834 10.9024 12.3166 11.2929 12.7071C11.6834 13.0976 12.3166 13.0976 12.7071 12.7071L19 6.41421V9Z" />
                <path d="M3 6C3 4.34315 4.34315 3 6 3H11C11.5523 3 12 3.44772 12 4C12 4.55228 11.5523 5 11 5H6C5.44772 5 5 5.44772 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V13C19 12.4477 19.4477 12 20 12C20.5523 12 21 12.4477 21 13V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6Z" />
              </svg>
            </button>
            <button
              disabled={!params.row.pdf}
              onClick={() => {
                getPdf(params.row.id, logindata.Token, dispatch, navigate);
              }}
              className={params.row.pdf ? styles.icon : styles.icon_disabled}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M7 5V7H5C3.34315 7 2 8.34315 2 10V16C2 17.6569 3.34315 19 5 19H7C7 20.6569 8.34315 22 10 22H14C15.6569 22 17 20.6569 17 19H19C20.6569 19 22 17.6569 22 16V10C22 8.34315 20.6569 7 19 7H17V5C17 3.34315 15.6569 2 14 2H10C8.34315 2 7 3.34315 7 5ZM17 17H19C19.5523 17 20 16.5523 20 16V10C20 9.44771 19.5523 9 19 9H5C4.44772 9 4 9.44772 4 10V16C4 16.5523 4.44772 17 5 17H7V16C6.44772 16 6 15.5523 6 15C6 14.4477 6.44772 14 7 14H17C17.5523 14 18 14.4477 18 15C18 15.5523 17.5523 16 17 16V17ZM15 16H9V19C9 19.5523 9.44772 20 10 20H14C14.5523 20 15 19.5523 15 19V16ZM14 4H10C9.44771 4 9 4.44772 9 5V7H15V5C15 4.44772 14.5523 4 14 4Z" />
                <path d="M18 12C18.5523 12 19 11.5523 19 11C19 10.4477 18.5523 10 18 10C17.4477 10 17 10.4477 17 11C17 11.5523 17.4477 12 18 12Z" />
              </svg>
            </button>
          </div>
          <div>{params.row.datum}</div>
        </>
      ),
    },
    {
      field: "doklad",
      headerName: "Jméno",
      width: 120,
      headerAlign: "center",
      groupable: false,
    },
    {
      field: "velkost",
      headerName: "Velikost",
      width: 120,
      headerAlign: "center",
      groupable: false,
      type: "number",
      valueFormatter: (params) => {
        const valueFormatted = Number(params.value);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
    },
    {
      field: "poradie",
      headerName: "Doklad",
      width: 120,
      headerAlign: "center",
      groupable: false,
    },
    {
      field: "obdobie",
      headerName: "Období",
      width: 120,
      headerAlign: "center",
    },
    {
      field: "detail",
      headerName: "Detail",
      width: getWidth(window.innerWidth) - 700,
      minWidth: 170,
      headerAlign: "center",
    },
  ];

  async function result() {
    await axios
      .get(sestavyUrl + logindata.UserData.vybrane_stredisko.kod, {
        headers: { Authorization: "Bearer " + logindata.Token },
      })
      .then(function (response) {
        console.log("sestavy", response.data);
        const data = response.data.map((obj, index) => ({ ...obj, id: index }));
        dispatch(loadingProhlizeniSestav());
        dispatch(prohlizeniSestav(response.data));
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }
  const theme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
    },
  });

  useEffect(() => {
    result();
  }, []);

  return (
    <div
      style={{
        height: window.innerHeight > 550 ? window.innerHeight - 350 : 600,
      }}
      className={cn(styles.wrapper1, className)}
    >
      {prohlizeniSestavdata.loading ? (
        <ContentLoader
          speed={2}
          width={"100%"}
          height={300}
          backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
          foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
        >
          <rect x="3%" y="5%" rx="3" ry="3" width={"100%"} height="80%" />
        </ContentLoader>
      ) : (
        <div className={cn(styles.wrapper, className)}>
          <ThemeProvider theme={theme}>
            {" "}
            <DataGridPremium
              ref={dataGridRef}
              sx={stylingTable}
              localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
              {...{
                rows: prohlizeniSestavdata.data,
                columns: columns,
              }}
              disableAutoFocus
              disableColumnMenu
              defaultGroupingExpansionDepth={-1}
              hideFooter
              rowHeight={40}
              onCellEditStop={async function (params, event) {
                //event.defaultMuiPrevented = true;
                if (event.code === "Enter") {
                  console.log(dataGridRef.current);
                  console.log(event.current);

                  let row = prohlizeniSestavdata.data.filter(
                    (row) => row.id === params.id
                  );
                  let index = prohlizeniSestavdata.data.indexOf(row[0]);
                  let data = [...prohlizeniSestavdata.data];
                  let row_copy = { ...row[0] };
                  row_copy[params.field] = event.target.value;
                  data[index] = row_copy;
                  dispatch(loadingProhlizeniSestav());
                  dispatch(prohlizeniSestav(data));
                  console.log(params.id);
                }
              }}
              //rowGroupingModel={rowGroupingModel}
            />
          </ThemeProvider>
        </div>
        // </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Table;
