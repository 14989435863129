import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../Icon";
import Modal from "../../Modal";
import { modal, modalData } from "./../redux/modalSlice";
import styles from "./Modal.module.sass";
import { login, loginData } from "../../../screens/SignIn/redux/loginSlice";
import { menuOblubeneUrl } from "../../../features/urlHelper";
import swal from "sweetalert";
import axios from "axios";
import { useHistory } from "react-router";
import { getLicensePremium } from "../../../features/License";
import useDarkMode from "@fisch0920/use-dark-mode";
import { csCZ, LicenseInfo } from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { errorHandleDialog } from "../../../features/errorDialog";

const App = ({ className, rows, loading, setRows, title, typ, visible, setVisible }) => {
  const modaldata = useSelector(modalData);

  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");

  const dispatch = useDispatch();
  const navigate = useHistory();
  const darkMode = useDarkMode(false);
  LicenseInfo.setLicenseKey(getLicensePremium());

  function getWidth(width) {
    if (width >= 700) {
      return 660;
    } else {
      return width - 96;
    }
  }

  const theme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
    },
  });

  const stylingTable = {
    border: 0,

    marginTop: 4,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
"&::-webkit-scrollbar": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      color: darkMode.value ? "white" : "#666d74",
      borderRight: `1px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },

    "& .MuiSvgIcon-root": {
      color: "#a7acaf",
    },
    "& .MuiDataGrid-cell:focus": {
      outline: 0,
    },
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
      "& .MuiInputBase-root": {
        height: "100%",
      },
    },
    "& .MuiDataGrid-cell": {
      padding: 2,
      borderRight: `1px solid transparent`,
    },
    "& .Mui-error": {
      backgroundColor: "red",
      color: "white",
    },
    width: getWidth(window.innerWidth),
    maxWidth: 660,
    height: window.innerHeight > 500 ? window.innerHeight - 300 : 500,
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
  };

  const columns = [
    {
      field: "text2",
      headerName: "",
      width: getWidth(window.innerWidth) - 100,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      groupable: false,
    },
    {
      field: "star",
      headerName: "",
      width: 40,
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <div className={styles.star}>
            <i class="fa fa-star"></i>
          </div>
        </>
      ),
    },
  ];

  async function ZmenaOblibene(reorder) {
    await axios
      .post(
        menuOblubeneUrl,
        {
          reorder: {
            oldKey: rows[reorder.oldIndex].key,
            targetKey: rows[reorder.targetIndex].key,
          },
          typ: typ,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        setRows(response.data.menu);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch)
      });
  }

  return (
    <Modal
      outerClassName={styles.outerZaznamProblemu}
      visible={visible}
      //visible={true}
    >
      <div className={styles.ico_Title}>
        <div className={styles.title}>
          Změna pořadí <div className={styles.vzatvorke}>({title})</div>
        </div>
        <button
          onClick={() => {
           setVisible(false)
          }}
        >
          <Icon name="close" size="20" />
        </button>
      </div>
      {!loading ? (
        <>
          <ThemeProvider theme={theme}>
            {" "}
            <DataGridPremium
              sx={stylingTable}
              localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
              columnHeaderHeight={0}
              rowHeight={40}
              hideFooter
              rows={rows}
              columns={columns}
              rowReordering
              disableRowSelectionOnClick
              onRowOrderChange={(params) => {
                console.log(params);
                ZmenaOblibene(params);
              }}
            />
          </ThemeProvider>
        </>
      ) : (
        <></>
      )}
    </Modal>
  );
};
export default App;
