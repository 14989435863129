import { createSlice } from '@reduxjs/toolkit';

export const pickerObjednavkySlice = createSlice({
    name: 'pickerObjednavky',
    initialState: {
        value: "",
    },
    reducers: {
        pickerObjednavky: (state, action) => {
            state.value = action.payload.data
        },

    },
});

export const { pickerObjednavky } = pickerObjednavkySlice.actions;

export const pickerObjednavkyData = state => state.pickerObjednavkydata.value;


export default pickerObjednavkySlice.reducer;
