import { createSlice } from '@reduxjs/toolkit';

export const pickerTagySlice = createSlice({
    name: 'pickerTagy',
    initialState: {
        tagy: [],
    },
    reducers: {
        pickerTagy: (state, action) => {
            console.log("data tagy")
            state.tagy = action.payload
        },

    },
});

export const { pickerTagy } = pickerTagySlice.actions;

export const pickerTagyData = state => state.pickerTagydata;


export default pickerTagySlice.reducer;
