import Modal from "../../Modal";
import Icon from "../../Icon";
import styles from "./Modal.module.sass";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modal, modalData } from "./../redux/modalSlice";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import Image from "../../Image";
import Switch from "./switch";
import DatePicker from "react-datepicker";
import cs from "date-fns/locale/cs";
import { otvSchvalenieUrl } from "../../../features/urlHelper";
import axios from "axios";
import { login, loginData } from "../../../screens/SignIn/redux/loginSlice";
import TimePicker from "./TimePicker";
import swal from "sweetalert";
import {
  kalendar,
  loadingKalendar,
} from "../../../screens/Kalendar/Kalendar/redux/kalendarSlice";
import {
  loadingZmeny,
  zmeny,
} from "../../../screens/Kalendar/Zmeny/redux/zmenySlice";
import { useHistory } from "react-router";

const App = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const modaldata = useSelector(modalData);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");

  return (
    <Modal outerClassName={styles.outer} visible={modaldata.Vyjimka}>
      <div className={styles.ico_Title}>
        <div className={styles.pic_around}>
          <Image
            className={styles.pic}
            src="/images/content/shop.png"
            srcDark="/images/content/shop.png"
          />
          Výjimka od - do
        </div>
        <div className={styles.ico}>
          <button
            className={styles.icon}
            onClick={() => {
              dispatch(modal({ data: { ...modaldata, Vyjimka: false } }));
              clearAllBodyScrollLocks();
            }}
          >
            <Icon name="close" size="20" />
          </button>
        </div>
      </div>
      <br />
      <div>
        Doba platnosti <Icon className={styles.icon} name="info" size="20" />
      </div>

      <div className={styles.dateArea}>
        <div className={styles.od}>Od</div>
        <div>
          <DatePicker
            locale={cs}
            dateFormat={"dd.MM.yyyy"}
            calendarClassName={styles.calendar}
            className={styles.date}
            selected={new Date(modaldata.VyjimkaDate.datum_od)}
            onChange={(e) => {
              dispatch(
                modal({
                  data: {
                    ...modaldata,
                    VyjimkaDate: {
                      ...modaldata.VyjimkaDate,
                      datum_od: new Date(e),
                    },
                  },
                })
              );
            }}
          ></DatePicker>
        </div>
        <div className={styles.do}>Do</div>
        <div>
          <DatePicker
            locale={cs}
            dateFormat={"dd.MM.yyyy"}
            calendarClassName={styles.calendar}
            className={styles.date}
            selected={new Date(modaldata.VyjimkaDate.datum_do)}
            onChange={(e) => {
              dispatch(
                modal({
                  data: {
                    ...modaldata,
                    VyjimkaDate: {
                      ...modaldata.VyjimkaDate,
                      datum_do: new Date(e),
                    },
                  },
                })
              );
            }}
          ></DatePicker>
        </div>
      </div>
      <br />
      <div>
        Nová provozní doba{" "}
        <Icon className={styles.icon} name="info" size="20" />
      </div>

      <div className={styles.dateAreaLine}>
        <div className={styles.dateArea}>
          <Image
            className={styles.pic}
            src="/images/content/shop.png"
            srcDark="/images/content/shop.png"
          />
          <TimePicker
            disabled={!modaldata.VyjimkaDate.otvor_on}
            title={"otvor_od"}
          />
          <TimePicker
            disabled={!modaldata.VyjimkaDate.otvor_on}
            title={"otvor_do"}
          />
        </div>

        <div className={styles.text}>
          <div>{modaldata.VyjimkaDate.otvor_on ? "Otevřeno" : "Zavřeno"} </div>
          <div className={styles.Switch}>
            <Switch
              id={0}
              isOn={modaldata.VyjimkaDate.otvor_on}
              handleToggle={(e) => {
                dispatch(
                  modal({
                    data: {
                      ...modaldata,
                      VyjimkaDate: {
                        ...modaldata.VyjimkaDate,
                        otvor_on: e.target.checked,
                      },
                    },
                  })
                );
              }}
              onColor="#F1B600"
            />
          </div>
        </div>
      </div>

      <div className={styles.dateAreaLine}>
        <div className={styles.dateArea}>
          <Image
            className={styles.pic}
            src="/images/content/cycle.png"
            srcDark="/images/content/cycle.png"
          />
          <TimePicker
            disabled={!modaldata.VyjimkaDate.rozvoz_on}
            title={"rozvoz_od"}
          />
          <TimePicker
            disabled={!modaldata.VyjimkaDate.rozvoz_on}
            title={"rozvoz_do"}
          />
        </div>

        <div className={styles.text}>
          <div>
            {modaldata.VyjimkaDate.rozvoz_on ? "Rozváží se" : "Nerozváží se"}{" "}
          </div>
          <div className={styles.Switch}>
            <Switch
              id={1}
              isOn={modaldata.VyjimkaDate.rozvoz_on}
              handleToggle={(e) => {
                dispatch(
                  modal({
                    data: {
                      ...modaldata,
                      VyjimkaDate: {
                        ...modaldata.VyjimkaDate,
                        rozvoz_on: e.target.checked,
                      },
                    },
                  })
                );
              }}
              onColor="#F1B600"
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonArea}>
        <button
          onClick={async () => {
            console.log();

            await axios
              .put(
                otvSchvalenieUrl,
                {
                  stredisko: logindata.UserData.vybrane_stredisko.kod,
                  typ: "vynimka",
                  id: modaldata.VyjimkaDate.id,
                  vynimka: modaldata.VyjimkaDate,
                },
                {
                  headers: { Authorization: "Bearer " + logindata.Token },
                }
              )
              .then(function (response) {
                dispatch(loadingKalendar());
                dispatch(loadingZmeny());
                dispatch(modal({ data: { ...modaldata, Vyjimka: false } }));
                dispatch(kalendar(response.data.kalendar));
                dispatch(zmeny(response.data.zmeny));
              })
              .catch(function (error) {
                if (error.response.status === 400) {
                  swal("Chyba!", error.response.data, "error");
                } else if (error.response.status === 401) {
                  swal(
                    "Odhlášení",
                    "Promiňte ale délka vašeho přihlášení uplynula, a proto jste byli odhlášeni ze systému.",
                    "info"
                  ).then(() => {
                    navigate.push("/");
                    //dispatch(login({ data: {} }));
                    window.sessionStorage.setItem("logindata", JSON.stringify({}))
                  });
                } else if (
                  error.response.status > 401 &&
                  error.response.status < 500
                ) {
                  swal(
                    "Chyba!",
                    "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
                    "error"
                  );
                } else {
                  swal(
                    "Chyba!",
                    " Nastala chyba na serveri ERP, zkuste to později.",
                    "error"
                  );
                }
              });
          }}
          className={styles.button}
        >
          Potvrdit
        </button>
      </div>
    </Modal>
  );
};
export default App;
