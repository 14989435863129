import React, { useState } from "react";
import { useEffect } from "react";
import cn from "classnames";
import styles from "./oblibene.module.sass";
import Card from "../../../components/Card";
import Menu from "./Menu";

const App = ({ className }) => {


 
  return (
    <Card bezPaddingu={false} className={cn(styles.card, className)}>
      <Menu />
    </Card>
  );
};

export default App;
