import { createSlice } from '@reduxjs/toolkit';

export const kalendarSlice = createSlice({
    name: 'kalendar',
    initialState: {
        data: {kalendar: []},
        loading: true,
    },
    reducers: {
        kalendar: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingKalendar: (state) => {
            console.log("loading: true")
            state.loading = true
        }

    },
});

export const { kalendar, loadingKalendar } = kalendarSlice.actions;

export const kalendarData = state => state.kalendardata;


export default kalendarSlice.reducer;

