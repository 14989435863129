import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import Icons from "../../../components/isons";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Table from "./Table";
import React, { useState } from "react";

const App = ({ className }) => {
  return (
    <div>
      <div className={styles.upperContent2}>
        <div className={styles.upperContentLeft}>
          <span style={{ fontWeight: "bold" }}>Závoz ID</span>
          <br />
          <span style={{ color: "gray" }}>123456</span>
        </div>
        <div>
          <span style={{ fontWeight: "bold" }}>Komentář ze Žiželic</span>
          <br />
          <span style={{ color: "gray" }}>Zítra dovezeme zbytek</span>
        </div>
      </div>
      <div className={styles.headModal}>
        <div className={styles.count}>6/8</div>
      </div>
      <br />
      {/* <Search className={styles.searchHead} /> */}
      <br />
      <div className={cn(styles.row)}>
        <Table className={styles.table} />
      </div>
    </div>
  );
};
export default App;
