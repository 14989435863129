import { createSlice } from '@reduxjs/toolkit';

export const PanelDetailSlice = createSlice({
    name: 'PanelDetail',
    initialState: {
        data: [],
        loading: true,
    },
    reducers: {
        PanelDetail: (state, action) => {
            console.log("loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingPanelDetail: (state) => {
            console.log("loading: true")
            state.loading = true
        }
    },
});

export const { PanelDetail, loadingPanelDetail } = PanelDetailSlice.actions;

export const PanelDetailData = state => state.PanelDetaildata;


export default PanelDetailSlice.reducer;
