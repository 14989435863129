import { createSlice } from '@reduxjs/toolkit';

export const pickerTagyObjednavky_typERP_BBSlice = createSlice({
    name: 'pickerTagyObjednavky_typERP_BB',
    initialState: {
        tagy: [],
    },
    reducers: {
        pickerTagyObjednavky_typERP_BB: (state, action) => {
            console.log("data tagy", action.payload)
            state.tagy = action.payload
        },

    },
});

export const { pickerTagyObjednavky_typERP_BB } = pickerTagyObjednavky_typERP_BBSlice.actions;

export const pickerTagyObjednavky_typERP_BBData = state => state.pickerTagyObjednavky_typERP_BBdata;


export default pickerTagyObjednavky_typERP_BBSlice.reducer;
