import { createSlice } from '@reduxjs/toolkit';

export const zmenaSlice = createSlice({
    name: 'zmena',
    initialState: {
        data: [],
        loading: true,
        isVybrate: false,
        dodaciList: "",
        comment: "",
    },
    reducers: {
        zmena: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingZmena: (state) => {
            console.log("loading: true")
            state.loading = true
        },
        vybrate: (state, action) => {
            state.isVybrate = action.payload
        },
        setDodaciList: (state, action) => {
            state.dodaciList = action.payload
        },
        setComment: (state, action) => {
            state.dodaciList = action.payload
        },

    },
});

export const { zmena, loadingZmena, vybrate, setDodaciList, setComment} = zmenaSlice.actions;

export const zmenaData = state => state.zmenadata;


export default zmenaSlice.reducer;
