import { createSlice } from '@reduxjs/toolkit';

export const tablePrevodSurovinSlice = createSlice({
    name: 'prevodSurovinTable',
    initialState: {
        data: [],
        loading: true,
    },
    reducers: {
        prevodSurovinTable: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingPrevodSurovinTable: (state) => {
            console.log("loading: true")
            state.loading = true
        }
    },
});

export const { prevodSurovinTable, loadingPrevodSurovinTable } = tablePrevodSurovinSlice.actions;

export const prevodSurovinTableData = state => state.prevodSurovinTabledata;


export default tablePrevodSurovinSlice.reducer;
