import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import Card from "../../../../components/Card";
import cn from "classnames";
import remove_accents from "remove-accents";
import { useSelector, useDispatch } from "react-redux";
import Icon from "../../../../components/Icon";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGridPremium, LicenseInfo, csCZ } from "@mui/x-data-grid-premium";
import { getLicensePremium } from "../../../../features/License";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ContentLoader from "react-content-loader";
import useDarkMode from "@fisch0920/use-dark-mode";
import swal from "sweetalert";
import {
  Form,
  FormData,
  loadingForm,
} from "../../../../components/Modaly/ModalForm/redux/FormSlice";
import ModalForm from "../../../../components/Modaly/ModalForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import {
  //ObjednavkyOstatneObjednavkaUrl,
  formRunUrl,
  getUrl,
  menuRunUrl,
  VypisDokladuHTMLObjOstUrl,
  VypisDokladuHTMLReklamaciiSQLUrl,
  VypisDokladuHTMLUrl,
} from "../../../../features/urlHelper";
import { errorHandleDialog } from "../../../../features/errorDialog";
import {
  DatePicker,
  LocalizationProvider,
  csCZ as cz,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import {
  modal,
  modalData,
} from "../../../../components/Modaly/redux/modalSlice";
// data

const data = [];

const Table = ({
  className,
  cis_rekl,
  data,
  prilohy,
  date,
  priznak,
  loading,
  title,
}) => {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const darkMode = useDarkMode(false);
  const modaldata = useSelector(modalData);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const Formdata = useSelector(FormData);
  const notify_error = (text) => toast.error(text);
  LicenseInfo.setLicenseKey(getLicensePremium());
  const [formName, setFormName] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  function getWidth(width) {
    if (width > 2500) {
      console.log(width - 388);
      return width - 388;
    } else if (width < 2500 && width > 767) {
      return width - 192;
    } else {
      return width - 48;
    }
  }

  function dajVysku(height) {
    if (window.innerWidth > 767) {
      return height - 178;
    } else {
      return height - 130;
    }
  }

  const stylingTable = {
    border: 0,

    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      color: darkMode.value ? "white" : "#666d74",
      borderRight: `1px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .MuiDataGrid-overlay": {
      backgroundColor: "transparent",
      color: darkMode.value ? "white" : "#ACACAC",
    },
    "& .MuiInputBase-input": {
      color: darkMode.value ? "white" : "#ACACAC",
    },
    "& .MuiSvgIcon-root": {
      color: "#a7acaf",
    },
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
      "& .MuiInputBase-root": {
        height: "100%",
      },
    },
    "& .MuiDataGrid-cell": {
      padding: 0.5,
    },
    "& .MuiDataGrid-withBorderColor": {
      borderColor: "transparent",
    },
    "& .Mui-error": {
      backgroundColor: "red",
      color: "white",
    },
    width: getWidth(window.innerWidth),
    height: window.innerHeight - 605,
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
  };

  const theme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
    },
  });
  const columns = [
    {
      field: "datum",
      headerName: "",
      minWidth: 150,
      width: (getWidth(window.innerWidth) - 50) * 0.1,
      headerAlign: "center",
      alighnItems: "center",
      renderHeader: (params) => {
        return (
          <div className={styles.header}>
            <div className={styles.f_line}>Datum zadání</div>
            <div className={styles.s_line}>reklamace</div>
          </div>
        );
      },
      renderCell: (params) => {
        var date_ = moment(params?.value).format("DD.MM.YYYY");
        return <div className={styles.cell_center}>{date_}</div>;
      },
    },
    {
      field: "x__adduser",
      hideable: false,
      headerName: "Vytvořil",
      minWidth: 120,
      width: (getWidth(window.innerWidth) - 50) * 0.1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <div className={styles.cell_center}>{params?.value}</div>;
      },
    },
    {
      field: "datum_reseni",
      headerName: "",
      minWidth: 150,
      width: (getWidth(window.innerWidth) - 50) * 0.1,
      headerAlign: "center",
      renderHeader: (params) => {
        return (
          <div className={styles.header}>
            <div className={styles.f_line}>Datum uzavření</div>
            <div className={styles.s_line}>reklamace</div>
          </div>
        );
      },
      renderCell: (params) => {
        var date_ = moment(params?.value).format("DD.MM.YYYY");
        return <div className={styles.cell_center}>{date_}</div>;
      },
    },
    {
      field: "stav",
      hideable: false,
      headerName: "Výsledek reklamace",
      minWidth: 150,
      width: (getWidth(window.innerWidth) - 50) * 0.12,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        var styl = "";
        var con_styl = "";
        var text = "";
        var icon = "";
        switch (params.value) {
          case "1_ODESLANA":
            styl = "polozka_neutral";
            con_styl = "icon_neutral";
            icon = "fa fa-send-o";
            text = "Odeslaná";
            break;

          case "2_V_RESENI":
            styl = "polozka_neutral";
            con_styl = "icon_neutral";
            icon = "fa fa-clock-o";
            text = "V řešení";
            break;

          case "6_STORNOVANA":
            styl = "polozka_neuz";
            con_styl = "icon_red";
            icon = "fa fa-trash";
            text = "Stornovaná";
            break;

          case "10_UZNANA_END":
          case "10_UZNANA":
            styl = "polozka_vyb";
            con_styl = "icon_green";
            icon = "fa fa-check";
            text = "Uznaná";
            break;

          case "11_NEUZNANA_END":
          case "11_NEUZNANA":
            styl = "polozka_neuz";
            con_styl = "icon_red";
            icon = "fa fa-close";
            text = "Neuznaná";
            break;

          case "12_CUZNANA_END":
          case "12_CUZNANA":
            styl = "polozka_res";
            con_styl = "icon_orange";
            icon = "fa fa-minus";
            text = "Částečně uznaná";
            break;
        }
        return (
          <div className={styles[styl]}>
            <div className={styles[con_styl]}>
              <i class={icon}></i>
            </div>
            <div>{text}</div>
          </div>
        );
      },
    },
    {
      field: "res_wms_typ",
      hideable: false,
      headerName: "Způsob uzavření reklamace",
      minWidth: 120,
      width: (getWidth(window.innerWidth) - 50) * 0.1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        var typy = [
          {
            title:
              "Odpis na ZMT – položka se ztratila nebo poničila na skladě či cestou",
            label: "Odpis na ZMT",
            value: "REKL/ODPIS",
          },
          {
            title:
              "Přeskladnit na ZMT – položka se našla nebo se zpracuje jinak",
            label: "Přeskladnit na ZMT",
            value: "INVENTURA",
          },
          {
            title:
              "Převod na středisko výroby – položka vyrobena ve špatné kvalitě",
            label: "Převod na středisko výroby",
            value: "REKL/VYR",
          },
        ];
        var typ = typy.find((x) => x.value === params.value);
        return (
          <div title={typ?.title} className={styles.cell_center}>
            {typ?.label}
          </div>
        );
      },
    },
    {
      field: "polozka",
      headerName: "Položka",
      minWidth: 200,
      width: (getWidth(window.innerWidth) - 50) * 0.18,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <div className={styles.nazov}>
            <div className={styles.avatar}>
              <img src={"/images/content/bageta2.png"} alt="Avatar" />
            </div>
            <div className={styles.nazov_kod}>
              <div>{params.row.nazov}</div>
              <div className={styles.znacka}>
                <div className={styles.znackaText}>{params.row.polozka}</div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      field: "mj",
      headerName: "MJ",
      minWidth: 55,
      width: (getWidth(window.innerWidth) - 50) * 0.04,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <div className={styles.cell_center}>{params.value}</div>;
      },
    },
    {
      field: "objem",
      headerName: "",
      minWidth: 100,
      width: (getWidth(window.innerWidth) - 50) * 0.09,
      type: "number",
      headerAlign: "center",
      renderHeader: (params) => {
        return (
          <div className={styles.header}>
            <div className={styles.f_line}>Množství</div>
            <div className={styles.s_line}>uznané</div>
          </div>
        );
      },
      renderCell: (params) => {
        const valueFormatted = Number(
          params.row.objem - params.row.objem_neuznany
        );

        const value = valueFormatted
          .toFixed(params.row.des_mista)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");
        return <div className={styles.cell_center}>{value}</div>;
      },
    },
    {
      field: "objem_neuznany",
      headerName: "",
      minWidth: 100,
      width: (getWidth(window.innerWidth) - 50) * 0.09,
      type: "number",
      headerAlign: "center",
      renderHeader: (params) => {
        return (
          <div className={styles.header}>
            <div className={styles.f_line}>Množství</div>
            <div className={styles.s_line}>neuznané</div>
          </div>
        );
      },
      renderCell: (params) => {
        const valueFormatted = Number(params.value);

        const value = valueFormatted
          .toFixed(params.row.des_mista)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");
        return <div className={styles.cell_center}>{value}</div>;
      },
    },
    {
      field: "vyr_datum",
      headerName: "",
      minWidth: 120,
      width: (getWidth(window.innerWidth) - 50) * 0.09,
      headerAlign: "center",
      renderHeader: (params) => {
        return (
          <div className={styles.header}>
            <div className={styles.f_line}>Datum</div>
            <div className={styles.s_line}>spotřeby</div>
          </div>
        );
      },
      renderCell: (params) => {
        return <div className={styles.cell_center}>{params.value}</div>;
      },
    },
  ];

  function formatujDatum(isoString) {
    const date_ = new Date(isoString);

    // Extracting day, month, and year
    const day = String(date_.getDate()).padStart(2, "0");
    const month = String(date_.getMonth() + 1).padStart(2, "0"); // Měsíce jsou indexovány od 0, proto +1
    const year = date_.getFullYear();

    // Formatting into dd.mm.yyyy
    return `${day}.${month}.${year}`;
  }
  function back() {
    switch (priznak) {
      case "base":
        navigate.push("/reklamaceSQL");
        break;
      case "nova":
        navigate.push("/reklamaceSQL_nova");
        break;
      case "otvorena":
        navigate.push("/reklamaceSQL/resitel/otvorena");
        break;
      case "uzavreta":
        navigate.push("/reklamaceSQL/resitel/uzavreta");
        break;
      default:
        navigate.push("/");
        break;
    }
  }

  return (
    <Card>
      <div style={{ height: dajVysku(window.innerHeight) }}>
        <div className={styles.nadpis}>
          <div>Reklamace č. {cis_rekl}</div>
        </div>
        <div className={styles.spacebeetween}>
          <button
            onClick={() => {
              back();
            }}
            className={styles.buttonPS}
          >
            <div className={styles.buttonPSI}>
              <Icon name="arrow-left" size="20" />
            </div>
            <div className={styles.buttonPST}>Zpět</div>
          </button>
          <div>Datum reklamace {formatujDatum(date)}</div>
        </div>

        <div>
          {loading ? (
            <ContentLoader
              speed={2}
              width={"100%"}
              height={300}
              backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
              foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
            >
              <rect x="3%" y="5%" rx="3" ry="3" width={"100%"} height="80%" />
            </ContentLoader>
          ) : (
            <>
              <ThemeProvider theme={theme}>
                <DataGridPremium
                  sx={stylingTable}
                  localeText={
                    csCZ.components.MuiDataGrid.defaultProps.localeText
                  }
                  {...{
                    rows: data,
                    columns: columns,
                  }}
                  columnHeaderHeight={40}
                  disableAutoFocus
                  disableColumnMenu
                  defaultGroupingExpansionDepth={-1}
                  hideFooter
                  rowHeight={40}
                  groupingColDef={{
                    leafField: "nazov",
                    headerClassName: "weekend6_help_nadpis",
                    cellClassName: "weekend6_help_cell",
                  }}
                />
              </ThemeProvider>
            </>
          )}
          <div className={styles.koment}>
            <div>Komentář od ZMT</div>
            <textarea
              disabled
              value={data[0] !== undefined && data[0].poznamka_reseni}
              className={styles.textarea}
            ></textarea>
          </div>
          <div className={styles.fotky}>
            {prilohy.map((priloha) => {
              var url =
                getUrl() +
                `api/reklamaceSQL/priloha/get/${priloha.id}/${false}`;
              //console.log(url);
              return (
                <img
                  onClick={() => {
                    var name = priloha.filename;
                    window.open(url, name, "width=800, height=600");
                    return false;
                  }}
                  src={url}
                  alt={priloha.filename}
                  width="128"
                  height="128"
                />
              );
            })}
          </div>
          <div className={styles.objednat_area}>
            <button
              onClick={async () => {
                await axios
                  .post(
                    VypisDokladuHTMLReklamaciiSQLUrl,
                    {
                      doklad: cis_rekl,
                      stredisko:
                        logindata.UserData.vybrane_stredisko !== undefined
                          ? logindata.UserData.vybrane_stredisko.kod
                          : null,
                      ico:
                        logindata.UserData.ico !== undefined
                          ? logindata.UserData.ico
                          : null,
                      fotky: prilohy,
                      url: getUrl()
                    },
                    {
                      headers: { Authorization: "Bearer " + logindata.Token },
                    }
                  )
                  .then(function (response) {
                    console.log(response)
                    if (response.data.html !== null) {
                      dispatch(
                        modal({
                          data: {
                            ...modaldata,
                            ZobrazHtml: true,
                            HtmlData: response.data.html,
                            ZobrazHtml_print: response.data.print,
                            ZobrazHtml_text: response.data.text,
                          },
                        })
                      );
                    }
                  })
                  .catch(function (error) {
                    errorHandleDialog(error.response, navigate, dispatch);
                  });
              }}
              className={styles.polozka_doklad}
            >
              <div className={styles.icon_pdf}>
                <i class="fa fa-file-pdf-o"></i>
              </div>

              <div>Doklad k reklamaci {cis_rekl}</div>
            </button>
            <button
              onClick={() => {
                back();
              }}
              className={styles.button_objednat}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Table;
