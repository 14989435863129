import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Item.module.sass";
import moment from "moment";

const Item = ({ className, item, onClose }) => {
  return (
    <Link
      className={cn(
        styles.item,
        { [styles.new]: item.precitane === undefined },
        className
      )}
      //to={item.url}
      //onClick={onClose}
    >
      <div className={styles.avatar}>
        <div className={styles.bell}>
          <i class="fa fa-bell"></i>
        </div>
      </div>
      <div className={styles.details}>
        <div className={styles.line}>
          <div className={styles.subtitle}>
            <div>{item.hlavicka}</div>
            <div className={styles.time}>
              {moment(item.datum).format("LT")}
            </div>
            {/* <div>{item.odosielatel + " -> " + item.stredisko}</div> */}
          </div>
          <div className={styles.time}>
            <div>{moment(item.datum).format("DD.MM")}</div>
          </div>
        </div>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: item.sprava }}
        ></div>
      </div>
    </Link>
  );
};

export default Item;
