import React, { useState } from "react";
import { useEffect } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Item from "./Item";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Chart from "./Chart";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import {
    login,
    loginData
} from './../../SignIn/redux/loginSlice';
import { commentsUrl, prehladUrl, soutezUrl } from "./../../../features/urlHelper"
import ContentLoader from "react-content-loader"
import useDarkMode from "@fisch0920/use-dark-mode";
import {
    overviewData,
    overview,
    loadingOverview
} from './redux/overviewSlice';
import swal from "sweetalert";
import { Login } from "@mui/icons-material";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../../features/errorDialog";



const nav = [
    {
        title: "Tržba",
    },
    {
        title: "Transakce",
    },

];


const Overview = ({ className }) => {
    const dispatch = useDispatch()
    const navigate = useHistory()
    const darkMode = useDarkMode(false);
    const [activeIndex, setActiveIndex] = useState(0);

    //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
    const overviewdata = useSelector(overviewData);
    


    useEffect(() => {
        dispatch(loadingOverview())
        result()
    }, []);

    async function result() {
        await axios.get(prehladUrl + logindata.UserData.vybrane_stredisko.kod + "/" + logindata.UserData.datum, { headers: { Authorization: 'Bearer ' + logindata.Token } })
            .then(function (response) {
                console.log(response.data)
                dispatch(overview(response.data))
              
            })
            .catch(function (error) {
                errorHandleDialog(error.response, navigate, dispatch)
            })
    }


    return (
        <Card
        bezPaddingu={false}
            className={cn(styles.card, className)}
            title="Dnešní přehled"
            classTitle="title-red"
           
        >
            {
                overviewdata.loading
                    ? <ContentLoader
                        speed={2}
                        width={"100%"}
                        height={300}
                        //viewBox="0 0 400 100"
                        backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
                        foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}

                    >
                            <rect x="3%" y="5%" rx="3" ry="3" width={"45%"} height="50%" />
                            <rect x="52%" y="5%" rx="3" ry="3" width={"45%"} height="50%" />

                            <rect x="7%" y="80%" rx="3" ry="3" width={"4%"} height="48%" />
                            <rect x="16%" y="63%" rx="3" ry="3" width={"4%"} height="48%" />
                            <rect x="25%" y="72%" rx="3" ry="3" width={"4%"} height="48%" />
                            <rect x="34%" y="91%" rx="3" ry="3" width={"4%"} height="48%" />
                            <rect x="43%" y="63%" rx="3" ry="3" width={"4%"} height="48%" />
                            <rect x="52%" y="75%" rx="3" ry="3" width={"4%"} height="48%" />
                            <rect x="61%" y="71%" rx="3" ry="3" width={"4%"} height="48%" />
                            <rect x="70%" y="69%" rx="3" ry="3" width={"4%"} height="48%" />
                            <rect x="79%" y="79%" rx="3" ry="3" width={"4%"} height="48%" />
                            <rect x="88%" y="59%" rx="3" ry="3" width={"4%"} height="48%" />
                    

                    </ContentLoader>
                    : <div className={styles.overview}>
                        <div className={styles.nav}>
                            <Item
                                className={cn(styles.item, {
                                    [styles.active]: 0 === activeIndex,
                                })}

                                onActive={() => setActiveIndex(0)}
                                item={nav[0]}
                                p_={overviewdata.data.priemerna_uctenka}
                                p_4={overviewdata.data.priemerna_uctenka4}
                                data={overviewdata.data.trzby?.dnes.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, '$1 ') + " Kč"}
                                tooltip={overviewdata.data.trzby}
                                icon={"shopping-bag"}
                                color={"#B1E5FC"}
                                t_={"trzby"}
                                mena={logindata.UserData.vybrane_stredisko.mena}
                            />
                            <Item
                                className={cn(styles.item, {
                                    [styles.active]: 1 === activeIndex,
                                })}

                                onActive={() => setActiveIndex(1)}
                                item={nav[1]}
                                p_={overviewdata.data.oneskorenie_perc}
                                tooltip={overviewdata.data.transakcie}
                                data={overviewdata.data.transakcie?.dnes + " x"}
                                icon={"shopping-bag"}
                                color={"#CABDFF"}
                                t_={"transakce"}
                            />
                        </div>
                        <div >
                            {activeIndex === 0 && <Chart data={overviewdata.data.grafTrzba} t={"trzba"}  />}
                            {activeIndex === 1 && <Chart data={overviewdata.data.grafTransakcia} t={"transakce"} />}
                        </div>
                    </div>
            }
           
            </Card>
    );
};

export default Overview;
