import React, { useState } from "react";
import { useEffect } from "react";
import cn from "classnames";
import styles from "./Zmeny.module.sass";
import Card from "../../../components/Card";
import {
  commentsUrl,
  otvSchvalenieUrl,
  prehladUrl,
  soutezUrl,
} from "./../../../features/urlHelper";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import { login, loginData } from "./../../SignIn/redux/loginSlice";
import { zmeny, zmenyData, loadingZmeny } from "./redux/zmenySlice";
import swal from "sweetalert";
import useDarkMode from "@fisch0920/use-dark-mode";
import Image from "../../../components/Image";
import Icon from "../../../components/Icon";
import { kalendar, loadingKalendar } from "../Kalendar/redux/kalendarSlice";
import { modal, modalData } from "../../../components/Modaly/redux/modalSlice";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../../features/errorDialog";


const App = ({ className }) => {
  const dispatch = useDispatch();
  const darkMode = useDarkMode(false);
  const navigate = useHistory();
  const modaldata = useSelector(modalData);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const zmenydata = useSelector(zmenyData);

  const [show_all, setShow_all] = useState(false);

  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
    dispatch(loadingZmeny());
    result();
  }, []);

  async function result() {
    await axios
      .get(otvSchvalenieUrl + "/" + logindata.UserData.vybrane_stredisko.kod, {
        headers: { Authorization: "Bearer " + logindata.Token },
      })
      .then(function (response) {
        console.log("otvSchvalenieUrl", response.data);
        dispatch(zmeny(response.data));
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }
  return (
    <Card
    bezPaddingu={false}
      className={cn(styles.card, className)}
      title="Změny"
      classTitle="title-blue"
    >
      {zmenydata.loading ? (
        <ContentLoader
          speed={2}
          width={"100%"}
          height={200}
          backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
          foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
        >
          {[0, 1, 2, 3].map((i) =>
            [0, 1, 2, 3, 4, 5, 6].map((j) => (
              <rect
                key={j}
                x={0}
                y={8 + i * (200 / 4)}
                rx="3"
                ry="3"
                width={"100%"}
                height={0.2 * 200}
              />
            ))
          )}
        </ContentLoader>
      ) : (
        <div className={styles.zmeny}>
          <div className={styles.divider}>
            <div>Období</div>
            <div>Akce</div>
          </div>
          {zmenydata.data.map((zmena, index) => {
            if (index < 8 || show_all) {
              return (
                <div className={styles.zmena}>
                  <div
                    onClick={() => {
                      console.log("zmena");
                    }}
                    className={styles.text}
                  >
                    {zmena.typ_detail === "vynimka" ? (
                      <Icon
                        className={styles.icon}
                        name="arrows-up-down"
                        size="18"
                      ></Icon>
                    ) : (
                      <Icon
                        className={styles.icon}
                        name="repeat"
                        size="18"
                      ></Icon>
                    )}
                    {zmena.text}
                  </div>
                  <div className={styles.icons}>
                    <div
                      onClick={() => {
                        if (zmena.typ_detail === "vynimka") {
                          dispatch(
                            modal({
                              data: {
                                ...modaldata,
                                Vyjimka: true,
                                VyjimkaDate: {
                                  ...modaldata.VyjimkaDate,
                                  id: zmena.id,
                                  otvor_on:
                                    zmena.vynimka.otvor_od === ""
                                      ? false
                                      : true,
                                  rozvoz_on:
                                    zmena.vynimka.rozvoz_od === ""
                                      ? false
                                      : true,
                                  datum_od: zmena.vynimka.datum_od,
                                  datum_do: zmena.vynimka.datum_do,
                                  otvor_od: zmena.vynimka.otvor_od,
                                  otvor_do: zmena.vynimka.otvor_do,
                                  rozvoz_od: zmena.vynimka.rozvoz_od,
                                  rozvoz_do: zmena.vynimka.rozvoz_do,
                                },
                              },
                            })
                          );
                        }
                        if (zmena.typ_detail === "zmena") {
                          dispatch(
                            modal({
                              data: {
                                ...modaldata,
                                TrvalaZmena: true,
                                TrvalaZmenaDate: {
                                  ...modaldata.TrvalaZmenaDate,
                                  id: zmena.id,
                                  datum_od: zmena.zmena.datum_od,
                                  dni: zmena.zmena.dni,
                                },
                              },
                            })
                          );
                        }
                      }}
                      className={styles.icon1}
                    >
                      <Icon name="edit" size="20"></Icon>
                    </div>
                    <div
                      onClick={async () => {
                        swal({
                          title: zmena.text,
                          text: "Chcete vymazat?",
                          icon: "warning",
                          buttons: ["Ne ", "Ano"],
                          dangerMode: true,
                        }).then(async (willDelete) => {
                          if (willDelete) {
                            await axios
                              .delete(otvSchvalenieUrl + "/" + zmena.id, {
                                headers: {
                                  Authorization: "Bearer " + logindata.Token,
                                },
                              })
                              .then(function (response) {
                                dispatch(loadingZmeny());
                                dispatch(loadingKalendar());
                                dispatch(kalendar(response.data.kalendar));
                                dispatch(zmeny(response.data.zmeny));
                              })
                              .catch(function (error) {
                                errorHandleDialog(
                                  error.response,
                                  navigate,
                                  dispatch
                                );
                              });
                          }
                        });
                      }}
                      className={styles.icon2}
                    >
                      <Icon name="trash" size="20"></Icon>
                    </div>
                  </div>
                </div>
              );
            }
          })}
          <div
            onClick={() => {
              setShow_all(!show_all);
            }}
            hidden={zmenydata.data.length < 6}
            className={styles.vsechny_zmeny}
          >
            {show_all
              ? "Sbalit"
              : "Všechny změny (" + zmenydata.data.length + ")"}
          </div>
        </div>
      )}
    </Card>
  );
};

export default App;
