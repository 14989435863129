import React, { useState, useEffect, useRef } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import remove_accents from "remove-accents";
import { useSelector, useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import useDarkMode from "@fisch0920/use-dark-mode";
import axios from "axios";
import { searchData } from "../../../components/Search/redux/searchSlice";
import {
  DataGridPremium,
  LicenseInfo,
  csCZ,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
  GridFooterContainer,
  GridFooter,
  GridPagination,
} from "@mui/x-data-grid-premium";
import { getLicensePremium } from "../../../features/License";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login, loginData } from "../../../screens/SignIn/redux/loginSlice";
import {
  EvidenceUrl,
  notifikaciaUrl,
  panelEnterUrl,
  paneldefUrl,
  prevodSurovinUrl,
} from "../../../features/urlHelper";
import {
  evidenceTable,
  evidenceTableData,
  loadingEvidenceTable,
} from "./redux/tableEvidenceSlice";
import swal from "sweetalert";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useHistory } from "react-router";
import Icon from "../../Icon";
import { errorHandleDialog } from "../../../features/errorDialog";
import { ClickAwayListener } from "@material-ui/core";
import { width } from "@mui/system";
import ModalForm from "../../Modaly/ModalForm";
import { Form, loadingForm } from "../../Modaly/ModalForm/redux/FormSlice";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  HttpClient,
} from "@microsoft/signalr";
import {
  setConnectionCiselniky,
  setConnectionCiselnikyID,
  setConnectionLoaded,
  signalRData,
} from "../../../screens/SignIn/redux/signalRSlice";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import MenuItemDrp from "@mui/material/MenuItem";
import MenuDrp from "@mui/material/Menu";
const Table = ({
  className,
  columns,
  setSelectedRow,
  selectedRow,
  paginationData,
  setPaginationData,
  rowSelectionModel,
  setRowSelectionModel,
  visiblePanel,
  setvisiblePanel,
  dataset,
  idFieldName,
  setVisibleForm,
  visibleForm,
  formName,
  setFormName,
}) => {
  const darkMode = useDarkMode(false);
  const notify_error = (text) => toast.error(text);
  LicenseInfo.setLicenseKey(getLicensePremium());
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const evidenceTabledata = useSelector(evidenceTableData);
  const signalRdata = useSelector(signalRData);
  const searchdata = useSelector(searchData);
  const connection = useSelector(signalRData);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [params_to_menu, setParams_to_menu] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, value, params) => {
    console.log(params);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const darkTheme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
      mode: darkMode.value ? "dark" : "light",
    },
  });

  const [options, setOptions] = React.useState([
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "100", label: "100" },
  ]);
  const [defaultOption, setDefaultOptions] = React.useState(options[0]);
  const [filterVisible, setFilterVisible] = React.useState(false);
  const [autofocus, setAutofocus] = React.useState(false);

  //const [filterModel, setFilterModel] = React.useState({items: [], linkOperator: "and", quickFilterValues: [], quickFilterLogicOperator: "and"});

  function getWidth(width) {
    if (width > 2500) {
      return width - 456;
    } else if (width < 2500 && width > 767) {
      return width - 212;
    } else {
      return width;
    }
  }
  function lastPage() {
    console.log(paginationData.count, paginationData.pagination.pageSize);
    return (
      (paginationData.count -
        (paginationData.count % paginationData.pagination.pageSize)) /
      paginationData.pagination.pageSize
    );
  }

  const stylingTable = {
    border: 0,
    paddingTop: 2,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-selectedRowCount": {
      visibility: "hidden",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      color: darkMode.value ? "white" : "#666d74",
      borderRight: `1px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .MuiToolbar-root-MuiTablePagination-toolbar": {
      color: darkMode.value ? "white" : "#666d74",
    },

    "& .MuiSvgIcon-root": {
      color: "#a7acaf",
    },
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
      "& .MuiInputBase-root": {
        height: "100%",
      },
    },
    "& .MuiDataGrid-cell": {
      padding: 1,
    },
    "& .MuiBadge-badge": {
      //color: "transparent",
      fontSize: 8,
    },
    "& .MuiSvgIcon-root": {
      padding: "0.8px",
    },
    "& .Mui-error": {
      backgroundColor: "red",
      color: "white",
    },
    width: getWidth(window.innerWidth),
    height: window.innerHeight - (window.innerWidth < 767 ? 115 : 202),
    justifyContent: "center",
    alighnItems: "center",
    color: darkMode.value ? "white" : "#666d74",
  };

  window.addEventListener("popstate", function () {
    if (
      (connection.connectionCiselniky !== undefined ||
        connection.connectionCiselniky !== null) &&
      connection.connectionCiselniky
    ) {
      try {
        connection.connectionCiselniky.stop();
      } catch (e) {
        console.log(e);
      }
    }
  });

  useEffect(() => {
    if (
      (connection.connectionCiselniky !== undefined ||
        connection.connectionCiselniky !== null) &&
      connection.connectionCiselniky
    ) {
      try {
        connection.connectionCiselniky.stop();
      } catch (e) {
        console.log(e);
      }
    }
  }, [HubConnectionState.Reconnecting]);

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(
        notifikaciaUrl +
          "?username=" +
          (logindata.UserData === undefined ? "" : logindata.UserData.username)
      )
      .withAutomaticReconnect()
      .build();
    dispatch(setConnectionCiselniky(connect));
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    console.log("connection", connection.connectionCiselniky);
    if (connection.connectionCiselniky) {
      console.log("hub notification start");
      try {
        connection.connectionCiselniky
          .start()
          .then(() => {
            //ziskame notificationID ( kvoli update)
            connection.connectionCiselniky
              .invoke("GetConnectionId")
              .then(function (connectionID) {
                dispatch(setConnectionCiselnikyID(connectionID));
                console.log("connectionID", connectionID);
              });
            connection.connectionCiselniky.on("ReceiveMessage", (message) => {
              console.log("ReceiveMessage", message);
              var dset = window.location.pathname.split("/").pop();

              console.log(dset);
              console.log(message.data.dataset);
              console.log("dataset", dataset);
              console.log("pagination dataset", paginationData.dataset);
              if (
                message.typ === "PANEL_REFRESH" &&
                dset === message.data.dataset
              ) {
                console.log("PANEL_REFRESH");
                changeRows(
                  paginationData.pagination.pageSize,
                  paginationData.pagination.page,
                  paginationData.order,
                  paginationData.filter,
                  dset
                );
                // window.location.reload();
              }
            });
          })
          .catch((error) => console.log(error));
      } catch (error) {
        console.log(error);
      }
    }
  }, [connection.connectionCiselniky]);

  const handleKeyDown = (event) => {
    console.log(event.key, paginationData.pagination.count);
    if (event.ctrlKey && event.key === "Home") {
      changeRows(
        paginationData.pagination.pageSize,
        0,
        paginationData.order,
        paginationData.filter
      );
      console.log("Stisknuto Ctrl + Home");
    }
    if (event.ctrlKey && event.key === "End") {
      changeRows(
        paginationData.pagination.pageSize,
        lastPage(),
        paginationData.order,
        paginationData.filter
      );

      console.log("Stisknuto Ctrl + End");
    }
    if (event.key === "PageUp") {
      if (paginationData.pagination.page < lastPage()) {
        console.log("PageUp");
        changeRows(
          paginationData.pagination.pageSize,
          paginationData.pagination.page + 1,
          paginationData.order,
          paginationData.filter
        );
      }
    }
    if (event.key === "PageDown") {
      if (paginationData.pagination.page > 0) {
        console.log("PageDown");
        changeRows(
          paginationData.pagination.pageSize,
          paginationData.pagination.page - 1,
          paginationData.order,
          paginationData.filter
        );
      }
    }
  };

  async function EnterEvent(data) {
    await axios
      .post(
        panelEnterUrl,
        {
          dataset: dataset,
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          idFieldValue: data.row[idFieldName],
          idFieldName: idFieldName,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log("enter event", response.data);

        if (response.data.typ === "formdef") {
          console.log(response.data);
          dispatch(loadingForm());
          setFormName(response.data.form.formName);
          dispatch(Form(response.data.form));

          if (
            document.getElementsByName(
              response.data.form.values[
                Object.keys(response.data.form.values)[0]
              ].id
            )[0] !== undefined
          ) {
            document
              .getElementsByName(
                response.data.form.values[
                  Object.keys(response.data.form.values)[0]
                ].id
              )[0]
              .focus();
          }
          // if (document.getElementsByName("element")[0] !== undefined) {
          //   console.log("klikám")
          //   document.getElementsByName("element")[0].click();
          // }
          setVisibleForm(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function changeRows(
    pageSize_,
    page_,
    sortModel_,
    filterModel_,
    dataset_ = dataset
  ) {
    await axios
      .post(
        EvidenceUrl,
        {
          ...{
            ...paginationData,
            dataset: dataset_,
            typErp: logindata.UserData.typErp,
          },
          pagination: {
            page: page_,
            pageSize: pageSize_,
          },
          order: sortModel_,
          filter: filterModel_,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log(" evidence change", response.data);
        setPaginationData({
          ...paginationData,
          dataset: response.data.dataset,
          count: response.data.count,
          order: response.data.order,
          filter: response.data.filter,
          pagination: {
            page: page_,
            pageSize: pageSize_,
          },
        });

        const newData = response.data.data.map((row, index) => {
          return { ...row, id: row[response.data.idFieldName] };
        });
        dispatch(evidenceTable(newData));
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }
  function search() {
    setAutofocus(false);
    dispatch(loadingEvidenceTable());
    changeRows(
      paginationData.pagination.pageSize,
      paginationData.pagination.page,
      paginationData.order,
      paginationData.filter
    );
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className={styles.toolbar}>
          <div>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
          </div>
          <div className={styles.search}>
            <GridToolbarQuickFilter
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  search();
                }
              }}
              sx={{
                width: window.innerWidth > 500 ? 180 : 100,
              }}
              onClick={() => {
                setAutofocus(true);
              }}
              onBlur={() => {
                search();
              }}
              debounceMs={0} // time before applying the new quick filter value
              autoFocus={autofocus}
              autoComplete="off"
              name="quicksearch"
              placeholder="Hledejte"
            />

            <button
              title="Filtruj"
              onClick={() => {
                search();
              }}
              className={styles.buttonZmena}
            >
              <Icon name="filter" size="20" />
            </button>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  function CustomFooter() {
    return (
      <ThemeProvider theme={darkTheme}>
        {/* <GridFooterContainer> */}
        <div className={styles.footer}>
          <GridFooter></GridFooter>
          <div className={styles.arrows}>
            <div
              className={styles.arrow}
              onClick={() => {
                if (paginationData.pagination.page > 0) {
                  changeRows(
                    paginationData.pagination.pageSize,
                    0,
                    paginationData.order,
                    paginationData.filter
                  );
                }
              }}
            >
              <div
                title={
                  paginationData.pagination.page > 0
                    ? "Jít na první stránku"
                    : ""
                }
                className={
                  paginationData.pagination.page > 0
                    ? styles.arrow_a
                    : styles.arrow_p
                }
              >
                <i class="fas fa-angle-double-left"></i>
              </div>
            </div>
            <div
              className={styles.arrow}
              onClick={() => {
                if (
                  paginationData.pagination.page *
                    paginationData.pagination.pageSize +
                    (paginationData.count %
                      paginationData.pagination.pageSize) <
                  paginationData.count
                ) {
                  changeRows(
                    paginationData.pagination.pageSize,
                    lastPage(),
                    paginationData.order,
                    paginationData.filter
                  );
                }
              }}
            >
              <div
                title={
                  paginationData.pagination.page *
                    paginationData.pagination.pageSize +
                    (paginationData.count %
                      paginationData.pagination.pageSize) <
                  paginationData.count
                    ? "Jít na poslední stránku"
                    : ""
                }
                className={
                  paginationData.pagination.page *
                    paginationData.pagination.pageSize +
                    (paginationData.count %
                      paginationData.pagination.pageSize) <
                  paginationData.count
                    ? styles.arrow_a
                    : styles.arrow_p
                }
              >
                <i class="fas fa-angle-double-right"></i>
              </div>
            </div>
          </div>
        </div>
        {/* </GridFooterContainer> */}
      </ThemeProvider>
    );
  }

  function handleRowClick(params, event) {
    if (!visiblePanel) {
      //console.log("prepisujem row click");
      const clickedRow = params;
      //const isRowSelected = clickedRow.row.id === selectedRow?.id;
      setSelectedRow(clickedRow);
      setRowSelectionModel([]);
    }
  }

  function handleOutClick() {
    if (!visiblePanel) {
      //console.log("prepisujem out click");
      setRowSelectionModel([]);
      setSelectedRow(null);
    }
  }

  return (
    <div className={styles.card} style={{ height: "100%" }}>
      <form
        className="rcw-sender"
        onKeyDown={(e) => {
          handleKeyDown(e);
        }}
      >
        {evidenceTabledata.loading ? (
          <ContentLoader
            speed={2}
            width={getWidth(window.innerWidth)}
            height={"100%"}
            backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
            foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
          >
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
              (i) =>
                [0, 1, 2, 3, 4, 5, 6].map((j) => (
                  <rect
                    key={j}
                    x={0}
                    y={2 + i * (window.innerHeight / 20)}
                    rx="3"
                    ry="3"
                    width={"100%"}
                    height={10}
                  />
                ))
            )}
          </ContentLoader>
        ) : (
          <ThemeProvider theme={darkTheme}>
            <ClickAwayListener
              onClickAway={() => {
                handleOutClick();
              }}
            >
              <DataGridPremium
                sx={stylingTable}
                localeText={{
                  ...csCZ.components.MuiDataGrid.defaultProps.localeText,
                  toolbarColumns: "",
                  toolbarFilters: "",
                }}
                paginationModel={paginationData.pagination}
                disableColumnPinning
                disableColumnMenu
                defaultGroupingExpansionDepth={-1}
                rowHeight={39}
                columnHeaderHeight={36}
                //autoFocus={true}
                pagination
                loading={evidenceTabledata.loading}
                rows={evidenceTabledata.data}
                columns={columns}
                sortingOrder={["desc", "asc"]}
                rowCount={paginationData.count}
                pageSizeOptions={[10, 50, 100, 1000]}
                paginationMode="server"
                sortingMode="server"
                filterMode="server"
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      admin_bb: false,
                      admin_croco: false,
                      admin_obj: false,
                      manager_bb: false,
                    },
                  },
                }}
                slots={{
                  toolbar: CustomToolbar,
                  //footer: CustomFooter,
                }}
                onCellKeyDown={async (data, event) => {
                  if (event.code === "Enter") {
                    EnterEvent(data);
                    // event po entery
                    // swal("Huráá!", "Úspešne sme vykonli event na enter", "info");
                    if (event.ctrlKey) {
                      // swal(
                      //   "Huráá!",
                      //   "Úspešne sme vykonli event na enter + ctrl",
                      //   "info"
                      // );
                    }
                  }
                }}
                onRowDoubleClick={(e, a) => {
                  // event po entery
                  // swal("Huráá!", "Úspešne sme vykonli event na enter", "info");
                  //dispatch(loadingForm());
                  EnterEvent(e);
                  if (a.ctrlKey) {
                    // swal(
                    //   "Huráá!",
                    //   "Úspešne sme vykonli event na enter + ctrl",
                    //   "info"
                    // );
                  }
                }}
                onRowClick={(e) => {
                  handleRowClick(e);
                }}
                onPaginationModelChange={(newModel) => {
                  console.log(newModel);
                  changeRows(
                    newModel.pageSize,
                    newModel.page,
                    paginationData.order,
                    paginationData.filter
                  );
                }}
                sortModel={paginationData.order}
                onSortModelChange={(newSortModel) => {
                  changeRows(
                    paginationData.pagination.pageSize,
                    paginationData.pagination.page,
                    newSortModel,
                    paginationData.filter
                  );
                }}
                filterModel={paginationData.filter}
                onFilterModelChange={(newFilterModel, event) => {
                  setPaginationData({
                    ...paginationData,
                    filter: newFilterModel,
                  });
                }}
                onPreferencePanelOpen={() => {
                  setFilterVisible(true);
                }}
                onPreferencePanelClose={(params, event) => {
                  setFilterVisible(false);
                  if (params.openedPanelValue === "filters") {
                    changeRows(
                      paginationData.pagination.pageSize,
                      paginationData.pagination.page,
                      paginationData.order,
                      paginationData.filter
                    );
                  }
                }}
                rowSelectionModel={rowSelectionModel}

                //rowGroupingModel={rowGroupingModel}
              />
            </ClickAwayListener>
          </ThemeProvider>
        )}

        <ModalForm
          visible={visibleForm}
          setVisible={setVisibleForm}
          formName={formName}
        />
        <ToastContainer />
      </form>
    </div>
  );
};

export default Table;
