import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../Icon";
import Modal from "../../Modal";
import { modal, modalData } from "./../redux/modalSlice";
import styles from "./Modal.module.sass";
import { loginData } from "../../../screens/SignIn/redux/loginSlice";
import axios from "axios";

const App = ({ className }) => {
  const dispatch = useDispatch();

  const modaldata = useSelector(modalData);

  
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");

  return (
    <Modal
      outerClassName={styles.outerZaznamProblemu}
      visible={modaldata.ZobrazPdf}
    >
       <div className={styles.ico_Title}>
        <div></div>
        <button
          onClick={() => {
            dispatch(
              modal({ data: { ...modaldata, ZobrazPdf: false } })
            );
          }}
        >
          <Icon name="close" size="20" />
        </button>
      </div>
      <embed src={modaldata.PdfData} width="4OO" height="600" type="application/pdf" />
    </Modal>
  );
};
export default App;
