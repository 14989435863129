import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { postExDodSurovinyUrl } from "../../../features/urlHelper";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import swal from "sweetalert";
import Table from "./Table";

import { modal, modalData } from "../../../components/Modaly/redux/modalSlice";
import { getPdf } from "../../../features/getPdf";
import { useHistory } from "react-router";
import { da } from "date-fns/locale";

const App = ({ className }) => {
  const dispatch = useDispatch();
  const modaldata = useSelector(modalData);
  const navigate = useHistory();

  const [data, setData] = useState([]);
  const [druhVydeje, setDruhVydeje] = useState(null);
  const [poznamka, setPoznamka] = useState("");
  const [loading, setLoading] = useState(false);

  const options = [
    { value: "ODPIS   ", label: "Odpis" },
    { value: "LIKVIDAC", label: "Likvidace" },
    { value: "SVACINY ", label: "Svačiny" },
    { value: "MARKETIN", label: "Marketing" },
    { value: "VZORKY  ", label: "Vzorky" },
  ];
  //ODPIS, SVACINY, VZORKY, LIKVIDAC, MARKETIN

  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );

  const [formName, setFormName] = useState("OdpisySur");
  const [priznak, setPriznak] = useState("OdpisS");
  const [button1Active, setButton1Active] = useState(true);
  const [button2Active, setButton2Active] = useState(false);
  const [loader, setLoader] = useState(false);

  function dajVysku(height) {
    if (window.innerWidth > 767) {
      return height - 178;
    } else {
      return height - 130;
    }
  }

  return (
    <Card>
      <div style={{ height: dajVysku(window.innerHeight) }}>
        <div className={styles.headModalENP}>
          <div className={styles.headModalTlacidla}>
            <button
              onClick={() => {
                setButton1Active(true);
                setButton2Active(false);
                setFormName("OdpisySur");
                setPriznak("OdpisS")
                setData([]);
                setPoznamka("");
                setDruhVydeje(null);
              }}
              className={
                button1Active
                  ? styles.headModalButtonsActive
                  : styles.headModalButtons
              }
            >
              Odpis surovin
            </button>
            <button
              onClick={() => {
                setButton1Active(false);
                setButton2Active(true);
                setFormName("OdpisyZbozi");
                setPriznak("OdpisZ");
                setData([]);
                setPoznamka("");
                setDruhVydeje(null);
              }}
              className={
                button2Active
                  ? styles.headModalButtonsActive
                  : styles.headModalButtons
              }
            >
              Odpis zboží
            </button>
          </div>
          <div className={styles.headModalTlacidlaZ}>
            <div className={styles.center}>
              {loader ? (
                <button
                  className={
                    window.innerWidth > 700
                      ? styles.buttonZmena
                      : styles.headModalButtons
                  }
                >
                  <>
                    <TailSpin
                      height="24"
                      width="24"
                      color="white"
                      ariaLabel="loading"
                      wrapperStyle
                      wrapperClass
                    />
                    {window.innerWidth > 700 ? "Dokončit" : ""}
                  </>
                </button>
              ) : (
                <button
                  disabled={loader}
                  onClick={async () => {

                    if(druhVydeje === null){
                      swal("Chyba!", "Nejsou vyplněna všechna povinna pole! - druh výdeje", "error");
                    }
                    if(data.length === 0){
                      swal("Chyba!", "Nejsou zadané položky k odpisu!", "error");
                    }

                    setLoader(true);

                    await axios
                      .post(
                        postExDodSurovinyUrl,
                        {
                          stredisko: logindata.UserData.vybrane_stredisko.kod,
                          poznamka: poznamka,
                          pohyby: data,
                          druhVydeje: druhVydeje,
                        },
                        {
                          headers: {
                            Authorization: "Bearer " + logindata.Token,
                          },
                        }
                      )
                      .then(function (response) {
                        if (response.data.html !== null) {
                          dispatch(
                            modal({
                              data: {
                                ...modaldata,
                                ZobrazHtml: true,
                                HtmlData: response.data.html,
                                ZobrazHtml_print: response.data.print,
                                ZobrazHtml_text: response.data.text,
                              },
                            })
                          );
                        }
                        if (
                          response.data.html === null &&
                          response.data.print !== null
                        ) {
                          swal({
                            title: response.data.text,
                            text: response.data.print
                              ? "Chcete zobrazit doklad?"
                              : "",
                            icon: "info",
                            buttons: response.data.print
                              ? ["Ne ", "Ano"]
                              : null,
                            dangerMode: true,
                          }).then(async (willShow) => {
                            if (willShow && response.data.print) {
                              console.log(response.data);

                              getPdf(
                                response.data.print,
                                logindata.Token,
                                dispatch,
                                navigate
                              );
                            }
                          });
                        }
                      })
                      .catch(function (error) {
                        console.log(error.response.data);
                        if (error.response.status === 400) {
                          swal("Chyba!", error.response.data, "error");
                        } else if (error.response.status === 401) {
                          navigate.push("/");
                          //dispatch(login({ data: {} }));
                          window.sessionStorage.setItem(
                            "logindata",
                            JSON.stringify({})
                          );
                        } else if (
                          error.response.status > 401 &&
                          error.response.status < 500
                        ) {
                          swal(
                            "Chyba!",
                            "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
                            "error"
                          );
                        } else {
                          swal(
                            "Chyba!",
                            " Nastala chyba na serveri ERP, zkuste to později.",
                            "error"
                          );
                        }
                        setLoader(false);
                      });
                  }}
                  className={
                    window.innerWidth > 700
                      ? styles.buttonZmena
                      : styles.buttonZmena
                  }
                >
                  {window.innerWidth > 700 ? (
                    <>
                      <Icon name="check" size="24" />
                      Dokončit
                    </>
                  ) : (
                    <Icon name="check" size="24" />
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
        <Table
          data={data}
          setData={setData}
          druhVydeje={druhVydeje}
          setDruhVydeje={setDruhVydeje}
          options={options}
          poznamka={poznamka}
          setPoznamka={setPoznamka}
          loading={loading}
          setLoading={setLoading}
          formName={formName}
          setFormName={setFormName}
          priznak={priznak}
        />
      </div>
    </Card>
  );
};
export default App;
