import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    value: {
      searchPrijemSurovin: "",
      searchPrijemSurovinOdJinePobocky: "",
      searchPrevodSurovin: "",
      searchNakupVHotovosti: "",
      searchexDod: "",
      searchZmenaPobocky: "",
      searchMalaInventura: "",
      searchObjednavky: "",
      searchEvidence: "",
      menu: "",
      searchZmena: "",
      searchObjednavky_typERP_O: "",
      searchUsersActivity: "",
      searchObjednavky_typERP_BB: "",
      searchVyberZoZoznamu: "",
    },
  },
  reducers: {
    search: (state, action) => {
      state.value = action.payload.data;
    },
  },
});

export const { search } = searchSlice.actions;

export const searchData = (state) => state.searchdata.value;

export default searchSlice.reducer;
