import React, { useState } from "react";
import { useEffect } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import Tooltip from "@mui/material/Tooltip";
import Card from "./../../../components/Card";
import Icon from "../../../components/WeatherIcon";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import { login, loginData } from "./../../SignIn/redux/loginSlice";
import { pocasieUrl } from "./../../../features/urlHelper";
import ContentLoader from "react-content-loader";
import useDarkMode from "@fisch0920/use-dark-mode";
import { pocasieData, pocasie, loadingPocasie } from "./redux/pocasieSlice";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../../features/errorDialog";

const Overview = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const darkMode = useDarkMode(false);

  const [day1, setDay1] = useState(false);
  const [day2, setDay2] = useState(true);
  const [day3, setDay3] = useState(true);

  const [scrollBar, setScrollBar] = useState("list_without_scrollbar");

  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const pocasiedata = useSelector(pocasieData);

  useEffect(() => {
    dispatch(loadingPocasie());
    result();
  }, []);

  async function result() {
    await axios
      .get(
        pocasieUrl +
          logindata.UserData.vybrane_stredisko.kod +
          "/" +
          logindata.UserData.datum,
        { headers: { Authorization: "Bearer " + logindata.Token } }
      )
      .then(function (response) {
        console.log(window.location.hostname);
        dispatch(pocasie(response.data));
        //dispatch(loadingPocasie({ data: false }))
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <div
      onMouseLeave={() => {
        setScrollBar("list_without_scrollbar");
      }}
      onMouseEnter={() => {
        setScrollBar("list");
      }}
    >
      <Card bezPaddingu={false} className={cn(styles.card, className)}>
        {pocasiedata.loading ? (
          <ContentLoader
            speed={2}
            width={"100%"}
            height={100}
            backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
            foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
          >
            <rect x="0%" y="0%" rx="3" ry="3" width={"100%"} height="100%" />
          </ContentLoader>
        ) : (
          <div className={styles.card}>
            <div className={styles[scrollBar]}>
              <div
                onClick={() => {
                  setDay1(false);
                  setDay2(true);
                  setDay3(true);
                }}
                className={day1 ? styles.box : styles.boxpicked}
              >
                <Tooltip
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {pocasiedata.data[0]?.description +
                        "\r\n" +
                        "Oblačnost: " +
                        pocasiedata.data[0]?.cloud_cover +
                        "\r\n" +
                        "Srážky: " +
                        pocasiedata.data[0]?.precipitation +
                        "\r\n"}
                    </span>
                  }
                >
                  <div className={day1 ? styles.item : styles.itempicked}>
                    <div className={styles.counter}>
                      {pocasiedata.data[0]?.day_description}
                    </div>
                    <div className={day1 ? styles.icon : styles.iconpicked}>
                      <Icon name={pocasiedata.data[0]?.icon} size="25" />
                    </div>
                    <div className={styles.counter}>
                      {pocasiedata.data[0]?.temp}
                    </div>
                  </div>
                </Tooltip>
                {pocasiedata.data[0]?.hours.map((x, index) => (
                  <Tooltip
                    key={index}
                    title={
                      <span style={{ whiteSpace: "pre-line" }}>
                        {pocasiedata.data[0]?.hours[index]?.description +
                          "\r\n" +
                          "Oblačnost: " +
                          pocasiedata.data[0]?.hours[index]?.cloud_cover +
                          "\r\n" +
                          "Srážky: " +
                          pocasiedata.data[0]?.hours[index]?.precipitation +
                          "\r\n"}
                      </span>
                    }
                  >
                    <div hidden={day1} className={styles.item} key={index}>
                      <div className={styles.counter}>{x.hour}</div>
                      <div className={styles.icon}>
                        <Icon name={x.icon} size="25" />
                      </div>
                      <div className={styles.counter}>{x.temp}</div>
                    </div>
                  </Tooltip>
                ))}
              </div>
              <div
                onClick={() => {
                  setDay1(true);
                  setDay2(false);
                  setDay3(true);
                }}
                className={day2 ? styles.box : styles.boxpicked}
              >
                <Tooltip
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {pocasiedata.data[1]?.description +
                        "\r\n" +
                        "Oblačnost: " +
                        pocasiedata.data[1]?.cloud_cover +
                        "\r\n" +
                        "Srážky: " +
                        pocasiedata.data[1]?.precipitation +
                        "\r\n"}
                    </span>
                  }
                >
                  <div className={day2 ? styles.item : styles.itempicked}>
                    <div className={styles.counter}>
                      {pocasiedata.data[1]?.day_description}
                    </div>
                    <div className={day2 ? styles.icon : styles.iconpicked}>
                      <Icon name={pocasiedata.data[1]?.icon} size="25" />
                    </div>
                    <div className={styles.counter}>
                      {pocasiedata.data[1]?.temp}
                    </div>
                  </div>
                </Tooltip>
                {pocasiedata.data[1]?.hours.map((x, index) => (
                  <Tooltip
                    key={index}
                    title={
                      <span style={{ whiteSpace: "pre-line" }}>
                        {pocasiedata.data[1]?.hours[index]?.description +
                          "\r\n" +
                          "Oblačnost: " +
                          pocasiedata.data[1]?.hours[index]?.cloud_cover +
                          "\r\n" +
                          "Srážky: " +
                          pocasiedata.data[1]?.hours[index]?.precipitation +
                          "\r\n"}
                      </span>
                    }
                  >
                    <div hidden={day2} className={styles.item} key={index}>
                      <div className={styles.counter}>{x.hour}</div>
                      <div className={styles.icon}>
                        <Icon name={x.icon} size="25" />
                      </div>
                      <div className={styles.counter}>{x.temp}</div>
                    </div>
                  </Tooltip>
                ))}
              </div>
              <div
                onClick={() => {
                  setDay1(true);
                  setDay2(true);
                  setDay3(false);
                  //
                }}
                className={day3 ? styles.box : styles.boxpicked}
              >
                <Tooltip
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {pocasiedata.data[2]?.description +
                        "\r\n" +
                        "Oblačnost: " +
                        pocasiedata.data[2]?.cloud_cover +
                        "\r\n" +
                        "Srážky: " +
                        pocasiedata.data[2]?.precipitation +
                        "\r\n"}
                    </span>
                  }
                >
                  <div className={day3 ? styles.item : styles.itempicked}>
                    <div className={styles.counter}>
                      {pocasiedata.data[2]?.day_description}
                    </div>
                    <div className={day3 ? styles.icon : styles.iconpicked}>
                      <Icon name={pocasiedata.data[2]?.icon} size="25" />
                    </div>
                    <div className={styles.counter}>
                      {pocasiedata.data[2]?.temp}
                    </div>
                  </div>
                </Tooltip>
                {pocasiedata.data[2]?.hours.map((x, index) => (
                  <Tooltip
                    key={index}
                    title={
                      <span style={{ whiteSpace: "pre-line" }}>
                        {pocasiedata.data[2]?.hours[index]?.description +
                          "\r\n" +
                          "Oblačnost: " +
                          pocasiedata.data[2]?.hours[index]?.cloud_cover +
                          "\r\n" +
                          "Srážky: " +
                          pocasiedata.data[2]?.hours[index]?.precipitation +
                          "\r\n"}
                      </span>
                    }
                  >
                    <div hidden={day3} className={styles.item} key={index}>
                      <div className={styles.counter}>{x.hour}</div>
                      <div className={styles.icon}>
                        <Icon name={x.icon} size="25" />
                      </div>
                      <div className={styles.counter}>{x.temp}</div>
                    </div>
                  </Tooltip>
                ))}
              </div>
            </div>
          </div>
        )}
      </Card>
      <TooltipGlodal />
    </div>
  );
};

export default Overview;
