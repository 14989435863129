import React from "react";
import cn from "classnames";
import styles from "./Chart.module.sass";
import Icon from "./../../../../components/WeatherIcon";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import useDarkMode from "@fisch0920/use-dark-mode";
import { useSelector, useDispatch } from "react-redux";
import {
  login,
  loginData,
} from "./../../../../screens/SignIn/redux/loginSlice";

const Chart = (data, { t, pocasie }) => {
  const darkMode = useDarkMode(false);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  function ciarkaApomlcka(temp) {
    if (temp === "") {
      return "-";
    } else {
      return ", ";
    }
  }

  const CustomTooltip = ({ active, payload, label }) => {
    const pocko = data.data?.filter((day) => day.interval === label);
    if (active && payload && payload.length) {
      if (data.t === "trzba") {
        return (
          <div
            className="custom-tooltip"
            style={{
              backgroundColor: "rgba(39,43,48,100)",
              borderRadius: 5,
              padding: 2,
              color: "white",
              fontSize: "small",
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <div
              style={{ fontSize: "medium" }}
              className="label"
            >{`${label}h - ${label + 1}h`}</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "px",
              }}
            >
              <div style={{ color: "#FFBC99" }} className="label">
                {"Dnes: "}
              </div>
              <div>
                <div
                  style={{ color: "#FFBC99", paddingLeft: "10px" }}
                  className="label"
                >
                  {payload[0].value
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ") +
                    " " +
                    logindata.UserData.vybrane_stredisko.mena}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <div style={{ color: "#B5E4CA" }} className="label">
                {"Minulý týden: "}
              </div>
              <div>
                <div
                  style={{ color: "#B5E4CA", paddingLeft: "10px" }}
                  className="label"
                >
                  {payload[1].value
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ") +
                    " " +
                    logindata.UserData.vybrane_stredisko.mena}
                </div>
                <div
                  style={{
                    color: "#B5E4CA",
                    paddingLeft: "10px",
                    fill: "white",
                  }}
                  className="label"
                >
                  {" "}
                  <Icon
                    name={pocko[0].pocasie_minulyTyzden.ikona}
                    size="20"
                  />{" "}
                  {pocko[0].pocasie_minulyTyzden.teplota +
                    ciarkaApomlcka(pocko[0].pocasie_minulyTyzden.zrazky) +
                    pocko[0].pocasie_minulyTyzden.zrazky}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ color: "#83BF6E" }} className="label">
                {"Minulý rok: "}
              </div>
              <div>
                <div
                  style={{ color: "#83BF6E", paddingLeft: "10px" }}
                  className="label"
                >
                  {payload[2].value
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ") +
                    " " +
                    logindata.UserData.vybrane_stredisko.mena}
                </div>

                <div
                  style={{
                    color: "#83BF6E",
                    paddingLeft: "10px",
                    fill: "white",
                  }}
                  className="label"
                >
                  <Icon name={pocko[0].pocasie_minulyTyzden.ikona} size="20" />{" "}
                  {pocko[0].pocasie_minulyRok.teplota +
                    ciarkaApomlcka(pocko[0].pocasie_minulyTyzden.zrazky) +
                    pocko[0].pocasie_minulyRok.zrazky}
                </div>
              </div>
            </div>
          </div>
        );
      }
      if (data.t === "transakce") {
        return (
          <div
            className="custom-tooltip"
            style={{
              backgroundColor: "rgba(39,43,48,100)",
              borderRadius: 5,
              padding: 2,
              color: "white",
              fontSize: "small",
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <div
              style={{ fontSize: "medium" }}
              className="label"
            >{`${label}h - ${label + 1}h`}</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "px",
              }}
            >
              <div style={{ color: "#FFBC99" }} className="label">
                {"Dnes: "}
              </div>
              <div>
                <div
                  style={{ color: "#FFBC99", paddingLeft: "10px" }}
                  className="label"
                >
                  {payload[0].value
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ") + " x"}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <div style={{ color: "#B5E4CA" }} className="label">
                {"Minulý týden: "}
              </div>
              <div>
                <div
                  style={{ color: "#B5E4CA", paddingLeft: "10px" }}
                  className="label"
                >
                  {payload[1].value
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ") + " x"}
                </div>
                <div
                  style={{
                    color: "#B5E4CA",
                    paddingLeft: "10px",
                    fill: "white",
                  }}
                  className="label"
                >
                  {" "}
                  <Icon
                    name={pocko[0].pocasie_minulyTyzden.ikona}
                    size="20"
                  />{" "}
                  {pocko[0].pocasie_minulyTyzden.teplota +
                    ciarkaApomlcka(pocko[0].pocasie_minulyTyzden.zrazky) +
                    pocko[0].pocasie_minulyTyzden.zrazky}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ color: "#83BF6E" }} className="label">
                {"Minulý rok: "}
              </div>
              <div>
                <div
                  style={{ color: "#83BF6E", paddingLeft: "10px" }}
                  className="label"
                >
                  {payload[2].value
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ") + " x"}
                </div>
                <div
                  style={{
                    color: "#83BF6E",
                    paddingLeft: "10px",
                    fill: "white",
                  }}
                  className="label"
                >
                  {" "}
                  <Icon
                    name={pocko[0].pocasie_minulyTyzden.ikona}
                    size="20"
                  />{" "}
                  {pocko[0].pocasie_minulyRok.teplota +
                    ciarkaApomlcka(pocko[0].pocasie_minulyTyzden.zrazky) +
                    pocko[0].pocasie_minulyRok.zrazky}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return null;
  };

  return (
    <div className={styles.chart}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data.data}
          margin={{
            top: 0,
            right: 20,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid
            strokeDasharray="none"
            stroke={darkMode.value ? "#272B30" : "#EFEFEF"}
            horizontal={false}
            vertical={false}
          />
          <XAxis
            dataKey="interval"
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 12, fontWeight: "500", fill: "#9A9FA5" }}
            padding={{ left: 10 }}
          />
          <YAxis
            /*tickFormatter={DataFormater}*/
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 12, fontWeight: "500", fill: "#9A9FA5" }}
          />
          <Tooltip
            content={<CustomTooltip />}

            /*contentStyle={{*/
            /*    backgroundColor: "#272B30",*/
            /*    borderColor: "rgba(255, 255, 255, 0.12)",*/
            /*    borderRadius: 8,*/
            /*    boxShadow:*/
            /*        "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",*/
            /*}}*/
            /*labelStyle={{ fontSize: 12, fontWeight: "500", color: "#fff" }}*/
            /*itemStyle={{*/
            /*    padding: 0,*/
            /*    textTransform: "capitalize",*/
            /*    fontSize: 12,*/
            /*    fontWeight: "600",*/
            /*    color: "#fff",*/
            /*}}*/
          />
          {/*<Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px', margin: '10px' }} />*/}
          <Bar dataKey="dnes" fill="#FFBC99" />
          <Bar dataKey="minulyTyzden" fill="#B5E4CA" />
          <Bar dataKey="minulyRok" fill="#83BF6E" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
