import React, { useState, useEffect, useRef } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import remove_accents from "remove-accents";
import { useSelector, useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import useDarkMode from "@fisch0920/use-dark-mode";
import axios from "axios";
import { searchData } from "../../../components/Search/redux/searchSlice";
import {
  DataGridPremium,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  LicenseInfo,
  csCZ,
} from "@mui/x-data-grid-premium";
import { getLicensePremium } from "../../../features/License";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login, loginData } from "../../SignIn/redux/loginSlice";
import swal from "sweetalert";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../../features/errorDialog";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import Icon from "../../../components/Icon";
const Table = ({ className, columns, title, button, loading, data }) => {
  const darkMode = useDarkMode(false);
  const notify_error = (text) => toast.error(text);
  LicenseInfo.setLicenseKey(getLicensePremium());
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const searchdata = useSelector(searchData);
  const darkTheme = createTheme({
    palette: {
      primary: { main: "#28a437" },
      mode: darkMode.value ? "dark" : "light",
    },
  });

  function getWidth(width) {
    console.log(width)
    if (width > 2500) {
      return width - 388;
    } else if (width < 2500 && width > 767) {
      return width - 144;
    } else {
      return width;
    }
  }

  const stylingTable = {
    border: 0,
    paddingTop: 0,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-selectedRowCount": {
      visibility: "hidden",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      color: darkMode.value ? "white" : "#666d74",
      borderRight: `1px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .MuiToolbar-root-MuiTablePagination-toolbar": {
      color: darkMode.value ? "white" : "#666d74",
    },

    "& .MuiSvgIcon-root": {
      color: "#a7acaf",
    },
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
      "& .MuiInputBase-root": {
        height: "100%",
      },
    },
    "& .MuiDataGrid-cell": {
      padding: 1,
    },
    "& .MuiBadge-badge": {
      //color: "transparent",
      fontSize: 8,
    },
    "& .MuiDataGrid-withBorderColor": {
      borderColor: "transparent"
    },
    "& .MuiSvgIcon-root": {
      padding: "0.8px",
    },
    "& .Mui-error": {
      backgroundColor: "red",
      color: "white",
    },
    "& .MuiDataGrid-cell": {
      width: 20
    },
    width: getWidth(window.innerWidth),
    height: window.innerHeight - 152,
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
    color: darkMode.value ? "white" : "#666d74",
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className={styles.GridToolbarContainer}>
          <GridToolbarQuickFilter autoComplete="off" />
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <div className={styles.card} >
      {loading ? (
        <ContentLoader
          speed={2}
          width={getWidth(window.innerWidth)}
          height={"100%"}
          backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
          foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
        >
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((i) =>
            [0, 1, 2, 3, 4, 5, 6].map((j) => (
              <rect
                key={j}
                x={0}
                y={2 + i * (window.innerHeight / 20)}
                rx="3"
                ry="3"
                width={"100%"}
                height={10}
              />
            ))
          )}
        </ContentLoader>
      ) : (
        <ThemeProvider theme={darkTheme}>
          <DataGridPremium
            sx={stylingTable}
            localeText={{
              ...csCZ.components.MuiDataGrid.defaultProps.localeText,
              toolbarColumns: "",
              toolbarFilters: "",
            }}
            disableColumnPinning
            disableColumnMenu
            rowHeight={50}
            columnHeaderHeight={36}
            rows={data}
            columns={columns}
            sortingOrder={["desc", "asc"]}
            slots={{ toolbar: CustomToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
              pagination: {
                labelDisplayedRows: ('Počet položek')
              }
            }}
          />
        </ThemeProvider>
        // </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Table;
