import React from "react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../Icon";
import Modal from "../../Modal";
import { modal, modalData } from "./../redux/modalSlice";
import styles from "./Modal.module.sass";
import { login, loginData } from "../../../screens/SignIn/redux/loginSlice";
import axios from "axios";
import { PanelData, loadingPanel, Panel } from "./redux/PanelSlice";
import Switch from "./switch";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Tooltip from "@mui/material/Tooltip";
import {
  LocalizationProvider,
  csCZ,
  LicenseInfo,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
//import {  } from "@mui/x-license-pro";
import { getLicensePremium } from "../../../features/License";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "dayjs/locale/cs";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/cs";
import Select from "react-select";
import ModalSelectorPanel from "./../ModalSelectorPanel";
import ModalForm from "./../ModalForm";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import swal from "sweetalert";
import { useHistory } from "react-router";
import {
  EvidenceUrl,
  panelSaveUrl,
  validationPanelUrl,
  validationUrl,
} from "../../../features/urlHelper";
import moment from "moment";
import useDarkMode from "@fisch0920/use-dark-mode";
import { Key, SentimentSatisfiedAltSharp } from "@mui/icons-material";
import { errorHandleDialog } from "../../../features/errorDialog";
import {
  evidenceTable,
  evidenceTableData,
} from "../../EvidenceTableUniversal/Table/redux/tableEvidenceSlice";
import { Form, FormData, loadingForm } from "../ModalForm/redux/FormSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { signalRData } from "../../../screens/SignIn/redux/signalRSlice";
import SWITCH2 from "./SWITCH2";
LicenseInfo.setLicenseKey(getLicensePremium());
const App = ({
  className,
  visible,
  setVisible,
  dataset,
  volba,
  setVolba,
  paginationData,
  datasetId,
  setPaginationData,
  rowSelectionModel,
  setRowSelectionModel,
  idFieldName,
}) => {
  const notify_error = (text) => toast.error(text);
  const dispatch = useDispatch();
  const navigate = useHistory();
  const darkMode = useDarkMode(false);

  const signalRdata = useSelector(signalRData);
  const Paneldata = useSelector(PanelData);
  const Formdata = useSelector(FormData);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const [params, setParams] = useState({});
  const [params_value, setParams_value] = useState("");
  const [ModalSelectorPanelData, setModalSelectorPanelData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState([]);
  const [id, setId] = useState("");
  const [titleSelector, setTitleSelector] = useState("");
  const [ModalSelectorPanelVisible, setModalSelectorPanelVisible] =
    useState(false);
  const [visibleForm, setVisibleForm] = useState(false);
  const [formName, setFormName] = useState("");
  const [paramsPanel, setParamsPanel] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [selectorData, setSelectorData] = useState([]);
  const [selectorCount, setSelectorCount] = useState(0);
  const [selectorPage, setSelectorPage] = useState(0);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#F1B600" },
      },
      width: "30%",
    },
    csCZ
  );

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  function setPack(title_as_id, Paneldata, pack) {
    // let newPaneldata = { ...Paneldata };
    // let newData = { ...Paneldata.data };
    // let newSections = { ...Paneldata.data.sections };
    Paneldata.data.sections.map((section, index) => {
      if (section.title === title_as_id) {
        let newSetion = { ...Paneldata.data.sections[index], pack: !pack };
        let newSections = [...Paneldata.data.sections];
        newSections[index] = newSetion;
        let newData = { ...Paneldata.data, sections: newSections };
        dispatch(Panel(newData));
      }
    });
  }

  function returnString(volba) {
    if (volba === "view") {
      return " - detail položky";
    }
    if (volba === "edit") {
      return " - editace položky";
    }
    if (volba === "add") {
      return " - přidání položky";
    }
  }

  function returnButton(volba) {
    if (volba === "view") {
      return "OK";
    }
    if (volba === "edit") {
      return "Uložit";
    }
    if (volba === "add") {
      return "Přidat";
    }
  }

  const stylingRangePicker = {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      color: "#82878c",
      fontFamily: "Inter, sans-serif",
      height: 7,
      display: "flex",
      alighnItems: "center",
      justifyContent: "center",
      fontWeight: 400,
      fontSize: 15,
      backgroundColor: !darkMode.value ? "white" : "#262b30",
      borderRadius: 2,
    },

    "& .MuiTypography-root, MuiFormLabel-root": {
      color: "#82878c",
    },
    "& .separator-true": {
      height: 30,
      borderBottom: `3px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#989899",
    },
    "& .MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled": {
      WebkitTextFillColor: "#989899",
    },
    width: "60%",
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
  };

  const stylingPicker = {
    ...stylingRangePicker,
    width: "60%",
  };

  function pocetDesatinnychMiest(cislo) {
    let retazec = cislo.toString();
    let zoznam = retazec.split(".");

    if (zoznam.length > 1) {
      return zoznam[1].length;
    } else {
      return 0;
    }
  }

  // async function search(selector, id_) {
  //   await axios
  //     .post(
  //       EvidenceUrl,
  //       {
  //         dataset: selector.dataset, // meno evidencie, ktoru prezeras
  //         stredisko: logindata.UserData.vybrane_stredisko.kod,
  //         pagination: {
  //           pageSize: 100, // pocet poloziek na stranu
  //           page: 1, // cislo strany – cislujeme do 0
  //         },
  //         sourcePanel: {
  //           id: id_,
  //           dataset: paginationData.dataset,
  //           datasetIndex: paginationData.datasetIndex,
  //           panelValues: Paneldata.data.values,
  //           volba: volba,
  //         },
  //         search: searchValue,
  //       },
  //       {
  //         headers: { Authorization: "Bearer " + logindata.Token },
  //       }
  //     )
  //     .then(function (response) {
  //       console.log("searchData", response.data);
  //       setSelectorData(response.data.data);
  //       setSelectorCount(response.data.count);
  //       setModalSelectorPanelVisible(true);
  //       setLoading(false);
  //     })
  //     .catch(function (error) {
  //       errorHandleDialog(error.response, navigate, dispatch);
  //     });
  // }

  function urobZCisielStringy() {
    const values = Object.keys(Paneldata.data.values).reduce(
      (accumulator, key) => {
        if (Paneldata.data.values[key].inputType === "number")
          accumulator.insert({
            ...Paneldata.data.values[key],
            value: Paneldata.data.values[key].value.toString(),
          });
        else accumulator.insert(Paneldata.data.values[key]);
      },
      {}
    );
    // console.log({ ...values });
    // console.log(Paneldata.data.values);
    //return values;
  }

  async function ulozit() {
    //console.log("connectionID", evidenceTabledata.connectionID);
    await axios
      .post(
        panelSaveUrl,
        {
          data: {
            ...paginationData,
            dataset: dataset,
            typErp: logindata.UserData.typErp,
          },
          panel: {
            datasetId: datasetId,
            dataset: dataset,
            datasetIndex: paginationData.datasetIndex,
            stredisko: paginationData.stredisko,
            volba: volba,
            panelValues: Paneldata.data.values,
          },
          connectionID: signalRdata.connectionCiselnikyID,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(async function (response) {
        const newData = response.data.value.data.map((row, index) => {
          return { ...row, id: row[response.data.value.idFieldName] };
        });
        dispatch(evidenceTable(newData));
        setRowSelectionModel([response.data.value.selectedRow]);
        setVisible(false);
        console.log("ok");
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 400) {
          swal("Chyba!", error.response.data.err_msg, "error").then(() => {
            if (
              document.getElementsByName(error.response.data.id)[0] !==
              undefined
            )
              document.getElementsByName(error.response.data.id)[0].focus();
          });
        } else if (error.response.status === 401) {
          navigate.push("/");
          //dispatch(login({ data: {} }));
          window.sessionStorage.setItem("logindata", JSON.stringify({}));
        } else if (error.response.status > 401 && error.response.status < 500) {
          swal(
            "Chyba!",
            "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
            "error"
          );
        } else {
          swal(
            "Chyba!",
            " Nastala chyba na serveri ERP, zkuste to později.",
            "error"
          );
        }
      });
  }

  async function pridat() {
    console.log(Paneldata.data.values);
    await axios
      .post(
        panelSaveUrl,
        {
          data: {
            ...paginationData,
            dataset: dataset,
            typErp: logindata.UserData.typErp,
          },
          panel: {
            datasetId: datasetId,
            dataset: dataset,
            datasetIndex: paginationData.datasetIndex,
            stredisko: paginationData.stredisko,
            volba: volba,
            panelValues: Paneldata.data.values,
          },
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(async function (response) {
        console.log(response);
        const newData = response.data.value.data.map((row, index) => {
          return { ...row, id: row[response.data.value.idFieldName] };
        });
        dispatch(evidenceTable(newData));
        setRowSelectionModel([response.data.value.selectedRow]);
        setPaginationData({
          ...paginationData,
          count: response.data.value.count,
          pagination: response.data.value.pagination,
        });
        setVisible(false);
        console.log("ok");
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 400) {
          swal("Chyba!", error.response.data.err_msg, "error").then(() => {
            if (
              document.getElementsByName(error.response.data.id)[0] !==
              undefined
            )
              document.getElementsByName(error.response.data.id)[0].focus();
          });
        } else if (error.response.status === 401) {
          navigate.push("/");
          //dispatch(login({ data: {} }));
          window.sessionStorage.setItem("logindata", JSON.stringify({}));
        } else if (error.response.status > 401 && error.response.status < 500) {
          swal(
            "Chyba!",
            "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
            "error"
          );
        } else {
          swal(
            "Chyba!",
            " Nastala chyba na serveri ERP, zkuste to později.",
            "error"
          );
        }
      });
  }

  async function selector(selector, value, id, page_) {
    //console.log(value)
    await axios
      .post(
        EvidenceUrl,
        {
          dataset: selector.dataset, // meno evidencie, ktoru prezeras
          typErp: logindata.UserData.typErp,
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          pagination: {
            pageSize: 50, // pocet poloziek na stranu
            page: page_, // cislo strany – cislujeme do 0
          },
          sourcePanel: {
            id: id,
            dataset: dataset,
            datasetIndex: paginationData.datasetIndex,
            panelValues: Paneldata.data.values,
            volba: volba,
          },
          search: value.toString(),
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        setTitleSelector(response.data.title);
        setFields([selector.id, selector.text]);
        setId(id);
        const array = [...selectorData, ...response.data.data];
        if (page_ === 0) setSelectorData(response.data.data);
        else setSelectorData(array);
        setSelectorCount(response.data.count);
        setModalSelectorPanelVisible(true);
        setLoading(false);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function validatioPickerRange(params_, value) {
    await axios
      .post(
        validationPanelUrl,
        {
          // idFieldValue: datasetId,
          // idFieldName: idFieldName,
          datasetId: datasetId,
          dataset: dataset,
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          volba: volba,
          value: value[0],
          id: params_.id,
          panelValues: Paneldata.data.values,
          fieldname: params_.fieldName[0],
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(async function (response) {
        await axios
          .post(
            validationPanelUrl,
            {
              datasetId: datasetId,
              dataset: dataset,
              stredisko:
                logindata.UserData.vybrane_stredisko !== undefined
                  ? logindata.UserData.vybrane_stredisko.kod
                  : null,
              volba: volba,
              value: value[1],
              id: params_.id,
              panelValues: Paneldata.data.values,
              fieldname: params_.fieldName[1],
            },
            {
              headers: { Authorization: "Bearer " + logindata.Token },
            }
          )
          .then(function (response) {
            if (response.data.waitWindow != null) {
              notify_error(response.data.waitWindow);
            }
            if (response.data.odpoved != null) {
              swal({
                title: response.data.odpoved.text,
                icon: "warning",
                buttons: { ...response.data.odpoved.buttons },
                closeOnEsc: true,
              }).then((value) => {
                switch (value) {
                  case 1:
                  case 2:
                  case 3:
                  case 4:
                    validationPanel(params_, params_.value, value, value);
                    break;
                  default:
                    validationPanel(params_, params_.value, 0, null);
                    swal.close();
                }
              });
            }
            if (response.data.action != null) {
              dispatch(loadingForm());
              setParamsPanel(params_);
              setFormName(response.data.action.formName);
              dispatch(Form(response.data.action.enterData));
              setVisibleForm(true);
              document
                .getElementsByName(
                  response.data.enterData.values[
                    Object.keys(response.data.enterData.values)[0]
                  ].id
                )[0]
                .focus();
            }

            let newData_ = Paneldata.data.values;
            if (response.data.newPanelValues !== undefined) {
              const newData = {
                ...Paneldata.data,
                values: response.data.newPanelValues,
              };
              if (params_.selector == null) {
                dispatch(loadingPanel());
                dispatch(Panel(newData));
              }
              newData_ = response.data.newFormValues;
            }

            if (params_.selector != null) {
              dataHandling(
                params_,
                value,
                response.data.data[params_.selector.text[0].column],
                newData_
              );
            }

            console.log("ok");
          })
          .catch(function (error) {
            errorHandleDialog(error.response, navigate, dispatch);
          });
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  // async function validationSelector(params_, value) {
  //   await axios
  //     .post(
  //       validationPanelUrl,
  //       {
  //         datasetId: datasetId,
  //         dataset: dataset,
  //         stredisko: logindata.UserData.vybrane_stredisko.kod,
  //         volba: volba,
  //         value: value,
  //         id: params_.id,
  //         panelValues: Paneldata.data.values,
  //       },
  //       {
  //         headers: { Authorization: "Bearer " + logindata.Token },
  //       }
  //     )
  //     .then(function (response) {
  //       console.log(response.data);
  //       // if (response.data.odpoved != null) {
  //       //   swal({
  //       //     title: response.data.odpoved.text,
  //       //     icon: "warning",
  //       //     buttons: { ...response.data.odpoved.buttons },
  //       //     closeOnEsc: true,
  //       //   }).then((value) => {
  //       //     switch (value) {
  //       //       case 1:
  //       //       case 2:
  //       //       case 3:
  //       //       case 4:
  //       //         validationPanel(params_, params_.value, value, value);
  //       //         break;
  //       //       default:
  //       //         validationPanel(params_, params_.value, 0, null);
  //       //         swal.close();
  //       //     }
  //       //   });
  //       // } else {
  //       //   dataHandling(
  //       //     params_,
  //       //     value,
  //       //     response.data.data[params_.selector.text[0].column]
  //       //   );
  //       // }

  //       if (response.data.odpoved != null) {
  //         swal({
  //           title: response.data.odpoved.text,
  //           icon: "warning",
  //           buttons: { ...response.data.odpoved.buttons },
  //           closeOnEsc: true,
  //         }).then((value) => {
  //           switch (value) {
  //             case 1:
  //             case 2:
  //             case 3:
  //             case 4:
  //               validationPanel(params_, params_.value, value, value);
  //               break;
  //             default:
  //               validationPanel(params_, params_.value, 0, null);
  //               swal.close();
  //           }
  //         });
  //       }
  //       if (response.data.action != null) {
  //         setVisibleForm(true);
  //         dispatch(loadingForm());
  //         setParamsPanel(params_);
  //         setFormName(response.data.action.formName);
  //         dispatch(Form(response.data.action.enterData));
  //       }
  //       if (response.data.newPanelValues !== undefined) {
  //         const newData = {
  //           ...Paneldata.data,
  //           values: response.data.newPanelValues,
  //         };

  //         console.log("response", response.data.newPanelValues);
  //         dispatch(loadingPanel());
  //         dispatch(Panel(newData));
  //       }

  //       if (response.data.waitWindow != null) {
  //         notify_error(response.data.waitWindow);
  //       }
  //     })
  //     .catch(function (error) {
  //       if (error.response.status === 400) {
  //         if (params_.selector != null) {
  //           dataHandling(params_, value, "");
  //           setParams(params_);
  //           setSearchValue(value);
  //           setLoading(true);
  //           selector(params_.selector, value, params_.id);
  //         }
  //       } else if (error.response.status === 401) {
  //         navigate.push("/");
  //         dispatch(login({ data: {} }));
  //       } else if (error.response.status > 401 && error.response.status < 500) {
  //         swal(
  //           "Chyba!",
  //           "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
  //           "error"
  //         );
  //       } else {
  //         swal(
  //           "Chyba!",
  //           " Nastala chyba na serveri ERP, zkuste to později.",
  //           "error"
  //         );
  //       }
  //     });
  // }

  async function validationPanel(
    params_,
    value,
    _stepValidValue = 0,
    odpovedAnswer = 0
  ) {
    let noveParams = { ...params_, value: value };
    let noveValues = {
      ...Paneldata.data.values,
      [params_.fieldName]: noveParams,
    };
    console.log(datasetId, idFieldName);
    await axios
      .post(
        validationPanelUrl,
        {
          // idFieldValue: datasetId.toString(),
          // idFieldName: idFieldName,
          datasetId: datasetId,
          dataset: dataset,
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          volba: volba,
          value: value,
          id: params_.id,
          panelValues: noveValues,
          formValues: Formdata.data.values,
          stepValid: 1 + _stepValidValue,
          odpovedAnswer: odpovedAnswer,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        if (response.data.waitWindow != null) {
          notify_error(response.data.waitWindow);
        }
        if (response.data.odpoved != null) {
          swal({
            title: response.data.odpoved.text,
            icon: "warning",
            buttons: { ...response.data.odpoved.buttons },
            closeOnEsc: true,
          }).then((value) => {
            switch (value) {
              case 1:
              case 2:
              case 3:
              case 4:
                validationPanel(params_, params_.value, value, value);
                break;
              default:
                validationPanel(params_, params_.value, 0, null);
                swal.close();
            }
          });
        }

        if (response.data.action != null) {
          console.log(response.data.action);
          setVisibleForm(true);
          dispatch(loadingForm());
          setParamsPanel(params_);
          setParams_value(value);
          setFormName(response.data.action.formName);
          dispatch(Form(response.data.action.enterData));
          //return;
        }
        console.log(response.data);
        let newData_ = Paneldata.data.values;
        if (response.data.newPanelValues !== undefined) {
          const newData = {
            ...Paneldata.data,
            values: response.data.newPanelValues,
          };
          if (params_.selector == null || params_.selector === undefined) {
            dispatch(loadingPanel());
            dispatch(Panel(newData));
          }
          console.log(response.data.newPanelValues);
          newData_ = response.data.newPanelValues;
        }
        if (params_.selector != null && response.data.data !== undefined) {
          console.log(response.data.data);
          if (params_.selector.text.length > 1) {
            dataHandling(
              params_,
              value,
              response.data.data[params_.selector.text[0].column] +
                " " +
                response.data.data[params_.selector.text[1].column],
              newData_
            );
          } else {
            console.log(params_.selector.text[0].column);
            dataHandling(
              params_,
              value,
              response.data.data[params_.selector.text[0].column],
              newData_
            );
          }
        }
        console.log("ok");
      })
      .catch(function (error) {
        console.log(error);
        if (params_.selector != null) {
          dataHandling(params_, value, "");
          setParams(params_);
          setSearchValue(value);
          setLoading(true);
          setSelectorCount(0);
          selector(params_.selector, value, params_.id, 0);
        } else {
          errorHandleDialog(error.response, navigate, dispatch);
        }
      });
  }

  function dataHandling(
    params_,
    value_,
    value2_,
    data = Paneldata.data.values
  ) {
    console.log(value2_);
    let noveParams = {};
    if (value2_ !== null) {
      noveParams = { ...params_, value: value_, selectorLabel: value2_ };
    } else {
      noveParams = { ...params_, value: value_ };
    }

    let noveValues = {
      ...data,
      [params_.fieldName]: noveParams,
    };
    let novyObjekt = { ...Paneldata.data, values: noveValues };
    console.log(novyObjekt);
    dispatch(Panel(novyObjekt));
  }

  function getComponent(params_) {
    //console.log(params_)
    switch (params_.inputType) {
      case "text":
        return (
          <input
            autoComplete="off"
            id={params_.id}
            name={params_.id}
            onClick={() => {
              document.getElementsByName(params_.id)[0].select();
            }}
            style={{
              width: params_.inputWidth > 560 ? "100%" : params_.inputWidth,
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                if (params_.rules !== undefined && params_.rules.validating) {
                  validationPanel(params_, params_.value);
                }
              }
            }}
            className={styles.input}
            type="text"
            disabled={volba === "view" || params_.when_fcia === "disabled"}
            value={params_.value}
            onChange={(e) => {
              if (e.target.value.length > params_.maxLength) {
                swal(
                  "Chyba!",
                  "Neplatná hodnota! Dodržte počet znakú" + params_.maxLength,
                  "error"
                );
              } else {
                dataHandling(params_, e.target.value);
              }
            }}
          />
        );
      case "textArea":
        return (
          <textarea
            autoComplete="off"
            id={params_.id}
            name={params_.id}
            onClick={() => {
              document.getElementsByName(params_.id)[0].select();
            }}
            style={{
              width: params_.inputWidth > 560 ? "100%" : params_.inputWidth,
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                if (params_.rules !== undefined && params_.rules.validating) {
                  validationPanel(params_, params_.value);
                }
              }
            }}
            className={styles.input_textArea}
            type="text"
            disabled={volba === "view" || params_.when_fcia === "disabled"}
            value={params_.value}
            onChange={(e) => {
              if (e.target.value.length > params_.maxLength) {
                swal(
                  "Chyba!",
                  "Neplatná hodnota! Dodržte počet znakú" + params_.maxLength,
                  "error"
                );
              } else {
                dataHandling(params_, e.target.value);
              }
            }}
          />
        );
      case "planeText":
        return (
          <div
            autoComplete="off"
            id={params_.id}
            name={params_.id}
            style={{
              width: "100%",
            }}
            //className={styles.vetaInfo}
            type="text"
            disabled={true}
          >
            {params_.value}
          </div>
        );
      case "vetaInfo":
        return (
          <div
            autoComplete="off"
            id={params_.id}
            name={params_.id}
            style={{
              width: "100%",
            }}
            className={styles.vetaInfo}
            type="text"
            disabled={true}
          >
            {params_.value}
          </div>
        );
      case "password":
        return (
          <input
            autoComplete="new-password"
            type="password"
            id={params_.id}
            name={params_.id}
            onClick={() => {
              document.getElementsByName(params_.id)[0].select();
            }}
            style={{
              width: params_.inputWidth > 560 ? "100%" : params_.inputWidth,
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                if (params_.rules !== undefined && params_.rules.validating) {
                  validationPanel(params_, params_.value);
                }
              }
            }}
            className={styles.input}
            disabled={volba === "view" || params_.when_fcia === "disabled"}
            value={params_.value}
            onChange={(e) => {
              if (e.target.value.length > params_.maxLength) {
                swal(
                  "Chyba!",
                  "Neplatná hodnota! Dodržte počet znakú" + params_.maxLength,
                  "error"
                );
              } else {
                dataHandling(params_, e.target.value);
              }
            }}
          />
        );
      case "number":
        return (
          <input
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                if (params_.rules !== undefined && params_.rules.validating) {
                  validationPanel(params_, params_.value);
                }
              }
            }}
            id={params_.id}
            style={{
              width: params_.inputWidth > 560 ? "100%" : params_.inputWidth,
            }}
            className={styles.input}
            type="number"
            name={params_.id}
            onClick={() => {
              document.getElementsByName(params_.id)[0].select();
            }}
            disabled={volba === "view" || params_.when_fcia === "disabled"}
            value={params_.value}
            onChange={(e) => {
              if (
                pocetDesatinnychMiest(e.target.value) >
                params_.rules.numericScale
              ) {
                swal(
                  "Chyba!",
                  "Neplatná hodnota! Dodržte počet desetinných míst" +
                    String(params_.rules.numericScale),
                  "error"
                );
              } else if (
                params_.rules.min > Number(e.target.value) ||
                Number(e.target.value) > params_.rules.max
              ) {
                swal(
                  "Chyba!",
                  "Neplatná hodnota! Dodržte interval" +
                    String(params_.rules.min) +
                    " - " +
                    String(params_.rules.max),
                  "err or"
                );
              } else {
                dataHandling(params_, Number(e.target.value));
              }
            }}
          />
        );
      case "date":
        return (
          <span>
            <ThemeProvider theme={theme}>
              <LocalizationProvider
                adapterLocale="cs"
                localeText={
                  csCZ.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
                dateAdapter={AdapterMoment}
              >
                <DatePicker
                  id={params_.id}
                  name={params_.id}
                  views={params_.pole}
                  disabled={
                    volba === "view" || params_.when_fcia === "disabled"
                  }
                  value={moment.utc(params_.value)}
                  // onClose={() => {

                  //   if (
                  //     params_.rules !== undefined &&
                  //     params_.rules.validating
                  //   ) {
                  //     validationPanel(params_, params_.value);
                  //   }
                  //   console.log("on close", e)
                  // }}
                  onChange={(param, e) => {
                    console.log(param._d.toISOString());
                    dataHandling(params_, param._d.toISOString());
                    if (
                      params_.rules !== undefined &&
                      params_.rules.validating
                    ) {
                      validationPanel(params_, param._d.toISOString());
                    }
                  }}
                  sx={stylingPicker}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </span>
        );

        case "time":
          return (
            <input
              autoComplete="off"
              id={params_.id}
              name={params_.id}
              onClick={() => {
                document.getElementsByName(params_.id)[0].select();
              }}
              style={{
                width: params_.inputWidth > 560 ? "100%" : params_.inputWidth,
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  if (params_.rules !== undefined && params_.rules.validating) {
                    validationPanel(params_, params_.value);
                  }
                }
              }}
              className={styles.input}
              type="text"
              disabled={volba === "view" || params_.when_fcia === "disabled"}
              value={params_.value}
              onChange={(e) => {
                if (e.target.value.length > 5) {
                  swal(
                    "Chyba!",
                    "Neplatná hodnota! Dodržte počet znakú" + params_.maxLength,
                    "error"
                  );
                } else {
                  dataHandling(params_, e.target.value);
                }
              }}
            />
          );

      case "select":
        let options = params_.selectValues;
        let value = null;
        params_.selectValues?.map((value_) => {
          if (params_.value.toLowerCase() === value_.value.toLowerCase()) {
            value = value_;
          }
        });

        return (
          <>
            <Select
              name={params_.id}
              theme={(theme) => ({
                ...theme,
                borderRadius: 8,
                colors: {
                  ...theme.colors,
                  primary25: "#FAEECB ",
                  primary50: "#FAEECB ",
                  primary: "#F1B600",
                },
              })}
              isDisabled={volba === "view" || params_.when_fcia === "disabled"}
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  backgroundColor: !darkMode.value ? "white" : "#262b30",
                }),
                option: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  color: "#6F767E",
                }),
                singleValue: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  color: "#82878c",
                }),
                menu: (base, state) => ({
                  ...base,
                  borderRadius: 0,
                  marginTop: 0,
                  backgroundColor: !darkMode.value ? "white" : "#262b30",
                }),
              }}
              className={styles.select}
              value={value}
              isClearable={false}
              isSearchable={false}
              options={options}
              onChange={(e) => {
                dataHandling(params_, e.value);
                validationPanel(params_, e.value);
              }}
            />
          </>
        );
      case "select_group":
        let group_options = params_.selectGroupValues;
        let group_value = null;
        params_.selectGroupValues?.map((options) => {
          options.options?.map((value_) => {
            if (params_.value.toLowerCase() === value_.value.toLowerCase())
              group_value = value_;
          });
        });

        return (
          <>
            <Select
              name={params_.id}
              isSearchable={true}
              theme={(theme) => ({
                ...theme,
                borderRadius: 8,
                colors: {
                  ...theme.colors,
                  primary25: "#FAEECB ",
                  primary50: "#FAEECB ",
                  primary: "#F1B600",
                },
              })}
              isDisabled={volba === "view" || params_.when_fcia === "disabled"}
              //isOptionDisabled={volba === "view" || params_.when_fcia === "disabled"}
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  backgroundColor: !darkMode.value ? "white" : "#262b30",
                }),
                option: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  color: "#6F767E",
                }),
                singleValue: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  color: "#82878c",
                }),
                menu: (base, state) => ({
                  ...base,
                  borderRadius: 0,
                  marginTop: 0,
                  backgroundColor: !darkMode.value ? "white" : "#262b30",
                }),
              }}
              className={styles.select}
              value={group_value}
              isClearable={false}
              options={group_options}
              formatGroupLabel={formatGroupLabel}
              onChange={(e) => {
                dataHandling(params_, e.value);
                validationPanel(params_, e.value);
              }}
            />
          </>
        );
      case "select_group_special":
        let group_options_special = params_.selectGroupValues;
        let group_value_special = null;
        params_.selectGroupValues?.map((options) => {
          options.options?.map((value_) => {
            if (params_.value.toLowerCase() === value_.value.toLowerCase())
              group_value_special = value_;
          });
        });

        return (
          <>
            <Select
              name={params_.id}
              isSearchable={true}
              theme={(theme) => ({
                ...theme,
                borderRadius: 8,
                colors: {
                  ...theme.colors,
                  primary25: "#FAEECB ",
                  primary50: "#FAEECB ",
                  primary: "#F1B600",
                },
              })}
              isDisabled={volba === "view" || params_.when_fcia === "disabled"}
              //isOptionDisabled={volba === "view" || params_.when_fcia === "disabled"}
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  backgroundColor: !darkMode.value ? "white" : "#262b30",
                }),
                option: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  color: "#6F767E",
                }),
                singleValue: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  color: "#82878c",
                }),
                menu: (base, state) => ({
                  ...base,
                  borderRadius: 0,
                  marginTop: 0,
                  backgroundColor: !darkMode.value ? "white" : "#262b30",
                }),
              }}
              className={styles.select}
              value={{
                value:
                  group_value_special !== null ? group_value_special.value : "",
                label:
                  group_value_special !== null ? group_value_special.value : "",
              }}
              //getOptionLabel={(option) => option.value}
              getComponent
              isClearable={false}
              options={group_options_special}
              formatGroupLabel={formatGroupLabel}
              onChange={(e) => {
                dataHandling(params_, e.value);
                validationPanel(params_, e.value);
              }}
            />
          </>
        );
      case "selector":
        return (
          <div className={styles.selector}>
            <div className={styles.inptLbl}>
              <input
                id={params_.id}
                name={params_.id}
                onClick={() => {
                  document.getElementsByName(params_.id)[0].select();
                }}
                disabled={volba === "view" || params_.when_fcia === "disabled"}
                className={styles.input}
                value={params_.value}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    validationPanel(params_, params_.value);
                    //validationSelector(params_, params_.value);
                  }
                }}
                onChange={(param) => {
                  dataHandling(params_, param.target.value, "");
                }}
                type="text"
              />

              <div className={styles.label}>{params_.selectorLabel}</div>
            </div>

            <button
              disabled={volba === "view" || params_.when_fcia === "disabled"}
              onClick={() => {
                setParams(params_);
                setSearchValue("");
                setLoading(true);
                setSelectorPage(0);
                selector(params_.selector, "", params_.id, 0);
              }}
              className={styles.button}
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        );
      case "checkbox":
        return (
          <>
            <input
              disabled={volba === "view" || params_.when_fcia === "disabled"}
              onClick={(e) => {
                if (params_.value) {
                  dataHandling(params_, false);
                  validationPanel(params_, false);
                } else {
                  dataHandling(params_, true);
                  validationPanel(params_, true);
                }
              }}
              checked={params_.value}
              id="cb1"
              type="checkbox"
              name={params_.id}
              className={styles.checkbox}
            />
          </>
        );
      case "switch_":
        return (
          <>
            <SWITCH2 />
          </>
        );
      case "radio":
        return (
          <>
            <div selected={params_.value} onChange={() => {}}>
              {params_.selectValues.map((value) => {
                //console.log(value)
                return (
                  <div className={styles.checkboxLine}>
                    <div>{value.label}</div>{" "}
                    <input
                      disabled={
                        volba === "view" || params_.when_fcia === "disabled"
                      }
                      onClick={() => {
                        dataHandling(params_, value.value);
                        validationPanel(params_, value.value);
                      }}
                      checked={params_.value === value.value}
                      id="cb1"
                      type="radio"
                      value={value.value}
                      name={params_.id}
                      className={styles.checkbox}
                    />
                  </div>
                );
              })}
            </div>
          </>
        );
      case "date_interval":
        return (
          <span>
            <ThemeProvider theme={theme}>
              <LocalizationProvider
                adapterLocale="cs"
                localeText={{
                  ...csCZ.components.MuiLocalizationProvider.defaultProps
                    .localeText,
                  start: "Od",
                  end: "Do",
                }}
                dateAdapter={AdapterMoment}
              >
                <DemoContainer components={["DateRangePicker"]}>
                  <DateRangePicker
                    id={params_.id}
                    name={params_.id}
                    disabled={
                      volba === "view" || params_.when_fcia === "disabled"
                    }
                    onClose={() => {
                      if (
                        params_.rules !== undefined &&
                        params_.rules.validating
                      ) {
                        validatioPickerRange(params_, params_.value);
                        //validationPanel(params_, params_.value);
                      }
                    }}
                    onChange={(param) => {
                      dataHandling(params_, [
                        param[0]._d.toISOString(),
                        param[1] === null
                          ? param[0]._d.toISOString()
                          : param[1]._d.toISOString(),
                      ]);
                    }}
                    // value={[
                    //   moment.utc(params_.value[0] !== undefined ? params_.value[0] : null ),
                    //   moment.utc(params_.value[1] !== undefined ? params_.value[1] : null ),
                    // ]}
                    value={[
                      params_.value !== undefined
                        ? moment.utc(params_.value[0])
                        : null,
                      params_.value !== undefined
                        ? moment.utc(params_.value[1])
                        : null,
                    ]}
                    sx={stylingRangePicker}
                    calendars={window.innerWidth < 642 ? 1 : 2}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </ThemeProvider>
          </span>
        );
      default:
        return <div>{params_.value}</div>;
    }
  }

  return (
    <>
      <Modal outerClassName={styles.outerZaznamProblemu} visible={visible}>
        <>
          {Paneldata.loading ? (
            <>
              <button
                onClick={() => {
                  setVisible(false);
                }}
              >
                <Icon name="close" size="20" />
              </button>
            </>
          ) : (
            <>
              <div className={styles.ico_Title}>
                <div>{Paneldata.data.title + returnString(volba)}</div>
                <button
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  <Icon name="close" size="20" />
                </button>
              </div>
              <div
                style={{
                  height:
                    window.innerHeight < 900 ? window.innerHeight - 150 : 800,
                }}
                className={styles.content}
              >
                {Paneldata.data.sections.map((section) => {
                  return (
                    <div>
                      <div
                        onClick={() => {
                          if (section.separator !== undefined) {
                            setPack(section.title, Paneldata, section.pack);
                          }
                        }}
                        className={
                          section.separator !== undefined
                            ? styles.sectionTitle_separator
                            : styles.sectionTitle
                        }
                      >
                        {section.title}
                        {section.separator !== undefined ? (
                          <>
                            {section.pack ? (
                              <div>
                                <i class="fa fa-angle-down"></i>
                              </div>
                            ) : (
                              <div>
                                <i class="fa fa-angle-up"></i>
                              </div>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      {section.pack ? (
                        <></>
                      ) : (
                        <div className={styles.panel}>
                          {section.fieldsets.map((polozky) => {
                            return (
                              <div className={styles.title_polozky_row}>
                                <div className={styles.title_polozky_row_title}>
                                  {polozky.title ? polozky.title : ""}
                                </div>
                                <div className={styles.polozky_row}>
                                  {polozky.fields.map((polozka) => {
                                    return (
                                      <div
                                        className={styles.help}
                                        style={{ width: polozka.inputWidth }}
                                      >
                                        {Paneldata.data.values[polozka]
                                          .when_fcia === "hidden" ? (
                                          <></>
                                        ) : (
                                          <>
                                            <div
                                              className={
                                                Paneldata.data.values[polozka]
                                                  .inputType === "checkbox" ||
                                                Paneldata.data.values[polozka]
                                                  .inputType === "switch_"
                                                  ? styles.polozkavyjimka
                                                  : styles.polozka
                                              }
                                            >
                                              <div className={styles.title}>
                                                <div>
                                                  {
                                                    Paneldata.data.values[
                                                      polozka
                                                    ].label
                                                  }
                                                </div>
                                                {Paneldata.data.values[polozka]
                                                  .hlp_msg === undefined ? (
                                                  <></>
                                                ) : (
                                                  <Tooltip
                                                    title={
                                                      Paneldata.data.values[
                                                        polozka
                                                      ].hlp_msg
                                                    }
                                                  >
                                                    <i
                                                      className={styles.info}
                                                      class="fa fa-info-circle"
                                                      style={{
                                                        color: "#F1B600",
                                                        cursor: "pointer",
                                                      }}
                                                    ></i>
                                                  </Tooltip>
                                                )}
                                              </div>
                                              <div>
                                                {getComponent(
                                                  Paneldata.data.values[polozka]
                                                )}
                                              </div>
                                            </div>
                                            <div>
                                              {Paneldata.data.values[polozka]
                                                .separator ? (
                                                <div
                                                  className={
                                                    styles.separator_text
                                                  }
                                                >
                                                  {
                                                    Paneldata.data.values[
                                                      polozka
                                                    ].separator_text
                                                  }
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <div className={styles.saveArea}>
            <div
              onClick={() => {
                if (volba == "view") {
                  setVisible(false);
                }
                if (volba === "edit") {
                  ulozit();
                }
                if (volba === "add") {
                  pridat();
                }
              }}
              className={styles.save}
            >
              {returnButton(volba)}
            </div>
          </div>
        </>
      </Modal>
      <ModalForm
        priznak="FORM_DAJ"
        visible={visibleForm}
        setVisible={setVisibleForm}
        className={styles}
        formName={formName}
        params_={paramsPanel}
        dataset={dataset}
        datasetId={datasetId}
        idFieldName={idFieldName}
        validationPanel={validationPanel}
        params_value={params_value}
      />
      <ModalSelectorPanel
        className={styles}
        visible={ModalSelectorPanelVisible}
        setVisible={setModalSelectorPanelVisible}
        loading={loading}
        fields={fields}
        params={params}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        titleSelector={titleSelector}
        id={id}
        validation={validationPanel}
        selectorData={selectorData}
        selectorCount={selectorCount}
        search={selector}
        page={selectorPage}
        setPage={setSelectorPage}
      />
      <ToastContainer />
    </>
  );
};
export default App;
