import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./Home.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";
import Ukoly from "./Ukoly";
//import PopularProducts from "../../components/PopularProducts";
import Info_o_smene from "./Info_o_smene";
import Oblibene from "./Oblibene";
import Soutez from "./Soutez";
import Comments from "./Comments";
import RefundRequests from "../../components/RefundRequests";
import Rozvozy from "./Rozvozy";
import Pocasie from "./Pocasie";
import Ludia from "./Ludia";
import { useSelector, useDispatch } from "react-redux";
import { login, loginData } from "./../../screens/SignIn/redux/loginSlice";
import { useHistory } from "react-router";

const Home = () => {
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");

  useEffect(() => {
    console.log("logindata", JSON.parse(sessionStorage.logindata));
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className={styles.row}>
        <div
          className={
            logindata.UserData.typErp === "C" ? styles.col_C : styles.col
          }
        >
          {logindata.UserData.typErp === "C" ? (
            <>
              {window.innerWidth > 1056 ? (
                <>
                  <Info_o_smene className={styles.card} views="1" />
                  <Ukoly className={styles.card} />
                </>
              ) : (
                <>
                  <Oblibene className={styles.card} />
                </>
              )}
            </>
          ) : (
            <>
              <Pocasie className={styles.card} />
              <Overview className={styles.card} />
              <Ludia className={styles.card} />
              <Rozvozy className={styles.card} />
            </>
          )}
        </div>
        <div
          className={
            logindata.UserData.typErp === "C" ? styles.col_C : styles.col
          }
        >
          {logindata.UserData.typErp === "C" ? (
            <>
              {window.innerWidth > 1056 ? (
                <>
                  <Oblibene className={styles.card} />
                </>
              ) : (
                <>
                  <Info_o_smene className={styles.card} views="1" />
                  <Ukoly className={styles.card} />
                </>
              )}
            </>
          ) : (
            <>
              <Info_o_smene className={styles.card} views="1" />
              <Soutez className={styles.card} views="1" />
              <Ukoly className={styles.card} />
              <Comments className={styles.card} />
            </>
          )}
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Home;
