import React, { useEffect, useState, useRef } from "react";
import Icon from "../../Icon";
import Modal from "../../Modal";
import styles from "./Modal.module.sass";
import {
  DataGridPremium,
  LicenseInfo,
  csCZ,
  GridRowModes,
} from "@mui/x-data-grid-premium";
//import {  } from "@mui/x-license-premium";
import { getLicensePremium } from "../../../features/License";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "dayjs/locale/cs";
import "moment/locale/cs";

import useDarkMode from "@fisch0920/use-dark-mode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import swal from "sweetalert";
import LoadingOverlay from "react-loading-overlay-nextgen";
import { Height } from "@mui/icons-material";
import { validateNumberWithMaxDecimals } from "../../../features/validateNumberWithMaxDecimals";
import { useDispatch, useSelector } from "react-redux";
import { search, searchData } from "../../Search/redux/searchSlice";

const App = ({
  className,
  visible,
  setVisible,
  visible_modalKosik,
  setVisible_modalKosik,
  data,
  buttony,
  objednat_data,
  objednat,
  setIsActive,
  setLoaderText,
  hlavicka,
  nacitaj_kosik,
  datum_active,
  setDatum_active,
  vynulovat_kosik,
  aktualizuj_kosik,
  layoutText,
  setLayoutText,
  layoutActive,
  setLayoutActive,
  zapis,
  zapis_,
  dataObjednavky,
  row,
  setRow,
  newMnozstvo,
  setNewMnozstvo,
  typ_objednavky,
}) => {
  LicenseInfo.setLicenseKey(getLicensePremium());

  const darkMode = useDarkMode(false);
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const searchdata = useSelector(searchData);

  const darkTheme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
      mode: darkMode.value ? "dark" : "light",
    },
  });

  useEffect(() => {
    if (document.getElementsByName("ico_title")[0] !== undefined) {
      document.getElementsByName("ico_title")[0].click();
    }
  }, [visible]);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#F1B600" },
      },
    },
    csCZ
  );

  function daj_balne_mn(znacka) {
    for (var row of dataObjednavky) {
      if (row.znacka.trim() === znacka.trim()) {
        return row.balne_mn;
      }
    }
    return 0;
  }
  function back() {
    setVisible(false);
  }

  function validnyDatum() {
    return moment(new Date(datum_active)).format("D.M.YYYY");
  }

  function denVTydnu() {
    moment.locale("cs");
    let dayOfWeek = moment(new Date(datum_active)).format("dddd");
    return dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
  }

  function vyber_stop_obj_znacky(datum) {
    let znacky = [];
    for (var row_ of dataObjednavky) {
      for (var bunka of row_.stlpce) {
        if (new Date(bunka.datum).getTime() === datum.setHours(0, 0, 0, 0)) {
          if (bunka.stop_obj) {
            znacky.push(bunka.znacka.trim());
          }
        }
        continue;
      }
    }
    return znacky;
  }

  function existuje_nahrada(znacka) {
    for (var row_ of dataObjednavky) {
      if (row_.znacka.trim() === znacka.trim() && row_.nahrada.trim() !== "") {
        return true;
      }
    }
    return false;
  }

  function daj_balne_mn(znacka) {
    for (var row of dataObjednavky) {
      if (row.znacka.trim() === znacka.trim()) {
        return row.balne_mn;
      }
    }
    return 0;
  }

  function daj_nahradu(znacka) {
    for (var row of dataObjednavky) {
      if (row.znacka.trim() === znacka.trim()) {
        return row.nahrada;
      }
    }
    return "";
  }

  async function kontrola_na_nahradu() {
    //kontrola na nahradu
    var now = new Date();
    now.setHours(0, 0, 0, 0);
    let num_of_affected_rows = 0;
    let nahrada = false; // Vlajka pre nahradu

    if (
      existuje_nahrada(row.znacka.trim()) &&
      vyber_stop_obj_znacky(datum_active).includes(row.znacka.trim())
    ) {
      await swal({
        content: {
          element: "div",
          attributes: {
            innerHTML:
              "Položka " +
              row.znacka +
              " " +
              row.nazov +
              " není skladem a bude vymazána z objednávky. \n Alternativa je " +
              daj_nahradu(row.znacka.trim()) +
              ". \n Chcete přepnout na alternativu?",
          },
        },
        icon: "info",
        buttons: ["Ne ", "Ano"],
      }).then(async (willShow) => {
        if (willShow) {
          await zapis_(
            datum_active,
            0,
            row.znacka,
            objednat_data.scrollPosition,
            row.datum + row.znacka.trim(),
            hlavicka
          );
          await setVisible(false);
          await setVisible_modalKosik(false);
          num_of_affected_rows = null;
          await dispatch(
            search({
              data: {
                ...searchdata,
                ["searchObjednavky_typERP_" + typ_objednavky]: daj_nahradu(
                  row.znacka.trim()
                ),
              },
            })
          );
        } else {
          num_of_affected_rows++;
          await zapis_(
            datum_active,
            0,
            row.znacka,
            objednat_data.scrollPosition,
            row.datum + row.znacka.trim(),
            hlavicka
          );
          buttony.map(async (button, index) => {
            var date = new Date(button.datum);
            if (date.getTime() === datum_active.getTime()) {
              //console.log("Modal kosik 199")
              await aktualizuj_kosik(button.datum, index, false);
            }
          });
        }
      });
    }

    return num_of_affected_rows;
  }

  async function zmaz_vsetky_stp_obj_polozky(polozky) {
    //kontrola na nahradu
    let num_of_affected_rows = 0;
    for (var polozka of polozky) {
      await swal({
        content: {
          element: "div",
          attributes: {
            innerHTML:
              "Položka " +
              polozka.znacka +
              " " +
              polozka.nazov +
              " není skladem a bude vymazána z objednávky.",
          },
        },
        icon: "info",
      }).then(async (willShow) => {
        if (willShow) {
          num_of_affected_rows++;
          await zapis_(
            datum_active,
            0,
            polozka.znacka,
            objednat_data.scrollPosition,
            polozka.datum + polozka.znacka.trim(),
            hlavicka
          );
          buttony.map(async (button, index) => {
            var date = new Date(button.datum);
            if (date.getTime() === datum_active.getTime()) {
              //console.log("Modal kosik 233")
              await aktualizuj_kosik(button.datum, index, false);
            }
          });
        }
      });
    }
    return num_of_affected_rows;
  }

  return (
    <>
      <Modal
        widthModal={window.innerWidth > 400 ? 400 : window.innerWidth}
        heightModal={window.innerHeight > 400 ? 400 : window.innerHeight}
        outerClassName={styles.outerZaznamProblemu}
        visible={visible}
      >
        <div name={"ico_title"} className={styles.ico_Title}>
          <div className={styles.btn}></div>
          <div>Úprava objednaného množství</div>
          <button
            className={styles.btn}
            onClick={() => {
              setVisible(false);
            }}
          >
            <Icon name="close" size="20" />
          </button>
        </div>
        <div className={styles.content} ref={elementRef} id="element">
          <div className={styles.img_zna_naz}>
            <div>
              <img src={"/images/content/bageta.png"} alt="Avatar" />
            </div>
            <div>
              <div>{row.znacka}</div>
              <div>{row.nazov}</div>
            </div>
          </div>
          <div className={styles.inputy}>
            Původní množství
            <div className={styles.inp_mj}>
              <input disabled className={styles.input} value={row.mnozstvo} />
              {row.mj}
            </div>
            Nové množství
            <div className={styles.inp_mj}>
              <input
                type="number"
                className={styles.input}
                value={newMnozstvo}
                name="inp_mj"
                id="inp_mj"
                onClick={() => {
                  document.getElementsByName("inp_mj")[0].select();
                  document.getElementsByName("inp_mj")[0].focus();
                }}
                onChange={(e) => {
                  console.log(Number(e.target.value), row.des_mista);
                  if (
                    validateNumberWithMaxDecimals(
                      Number(e.target.value),
                      row.des_mista
                    )
                  ) {
                    setNewMnozstvo(e.target.value);
                  }
                }}
              />
              {row.mj}
            </div>
          </div>
          <div className={styles.buttonUlozit}>
            <div
              className={styles.save}
              onClick={async () => {
                swal({
                  title: "Opravdu chcete změnit položku v košíku?",
                  icon: "info",
                  buttons: ["Ne ", "Ano"],
                }).then(async (willShow) => {
                  if (willShow) {
                    let total_num_of_affected_rows = 0;
                    setLayoutActive(true);
                    setLayoutText("Měním množství položky");
                    setVisible(false);

                    //kontrola na nahradu
                    var num_kontrola_na_nahradu = await kontrola_na_nahradu();

                    if (num_kontrola_na_nahradu !== null) {
                      total_num_of_affected_rows =
                        total_num_of_affected_rows + num_kontrola_na_nahradu;
                    } else {
                      await setVisible(false);
                      await setLayoutActive(false);
                      return;
                    }

                    //vytvorenie zoznamu poloziek so stop_obj
                    let zoznamu_poloziek_so_stop_obj = [];

                    if (
                      vyber_stop_obj_znacky(datum_active).includes(
                        row.znacka.trim()
                      ) &&
                      !existuje_nahrada(row.znacka.trim())
                    ) {
                      zoznamu_poloziek_so_stop_obj.push(row);
                    }

                    //vymazanie všetkých stop_obj poloziek
                    total_num_of_affected_rows =
                      total_num_of_affected_rows +
                      (await zmaz_vsetky_stp_obj_polozky(
                        zoznamu_poloziek_so_stop_obj
                      ));

                    //vzhodi ma ak je stop obj
                    if (1 === total_num_of_affected_rows) {
                      await setVisible(false);
                      await setLayoutActive(false);
                      return;
                    }

                    var balne_mn = await daj_balne_mn(row.znacka);
                    await zapis(
                      Number(newMnozstvo),
                      balne_mn,
                      row.des_mista,
                      row.mj,
                      datum_active,
                      row.znacka,
                      objednat_data.scrollPosition,
                      row.datum + row.znacka.trim(),
                      hlavicka
                    );
                  }
                });
              }}
            >
              Uložit
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
};
export default App;
