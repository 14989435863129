import Card from "../../../components/Card";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Search from "../../../components/Search";
import Table from "./Table";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import {
  VypisDokladuHTMLUrl,
  menuAkciaUrl,
  notifikaciaUrl,
  reklMenuAkciaUrl,
  stornoReklamaceUrl,
  zoznamOdpisovUrl,
  zoznamReklamaciiSQLUrl,
} from "../../../features/urlHelper";
import swal from "sweetalert";
import { useHistory } from "react-router";
import Delete from "../../../components/Delete";
import { errorHandleDialog } from "../../../features/errorDialog";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { modal, modalData } from "../../../components/Modaly/redux/modalSlice";

const App = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const modaldata = useSelector(modalData);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [options, setOptions] = useState([]);
  const [option, setOption] = useState({ value: "", label: "Vše" });

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  function getWidth(width) {
    if (width > 2500) {
      return width - 408;
    } else if (width < 2500 && width > 767) {
      return width - 194;
    } else {
      return width;
    }
  }

  function dajVysku(height) {
    if (window.innerWidth > 767) {
      return height - 178;
    } else {
      return height - 130;
    }
  }

  const columns = [
    {
      field: "datum",
      hideable: false,
      headerName: "Datum odpisu",
      width:
        (getWidth(window.innerWidth) - 70) *
        0.33,
      headerAlign: "center",
      sortable: true,
      filterable: false,
      valueFormatter: (params) => moment(params?.value).format("DD.MM.YYYY"),
      renderCell: (params) => {
        return <div className={styles.polozka_center}>{moment(params?.value).format("DD.MM.YYYY")}</div>;
      },
    },
    {
      field: "poradie",
      hideable: false,
      headerName: "Číslo odpisu",
      width:
        (getWidth(window.innerWidth) - 70) *
        0.34,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <div className={styles.polozka_center}>{params.value}</div>;
      },
    },
    {
      field: "uct_doklad",
      hideable: true,
      headerName: "Druh výdeje",
      width:
        (getWidth(window.innerWidth) - 70) *
        0.33,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <div className={styles.polozka_center}>{params.value}</div>;
      },
    },
    {
      field: "action",
      headerName: "",
      headerAlign: "center",
      //cellClassName: 'actions',
      width: 50,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className={styles.actions}>
            <div
              title="Detail odpisu"
              className={styles.icon_eye}
              onClick={async () => {
                console.log(params.row.datum);
                navigate.push(
                  `/reklamaceSQLDetail?datum=${params.row.datum}&cis_rekl=${
                    params.row.reklamace
                  }&priznak=${"base"}`
                );
              }}
            >
              <i class="fa fa-eye"></i>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    result();
  }, []);

  async function result() {
    await axios
      .post(
        zoznamOdpisovUrl,
        {
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log(response);
        setData(response.data);
        const newOptions = response.data.map((item) => item.uct_doklad);
        const uniqesOptions = [...new Set(newOptions)];
        const uniqesOptions_ = uniqesOptions.map((item) => {
          switch (item) { 
            case "ODPIS   ":
              return { value: item, label: "Odpis" };
            case "SVACINY ":
              return { value: item, label: "Svačiny" };
            case "VZORKY  ":
              return { value: item, label: "Vzorky" };
            case "LIKVIDAC":
              return { value: item, label: "Likvidace" };
            case "MARKETIN":
              return { value: item, label: "Marketing" };
          }
          return;
        });
        var uniquesOptionsReverse = [
          ...uniqesOptions_,
          { value: "", label: "Vše" },
        ];
        setOptions(uniquesOptionsReverse.reverse());
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <Card>
      <div style={{ height: dajVysku(window.innerHeight) }}>
        <div className={styles.nadpis}>
          <div>Přehled odpisů</div>
        </div>
        <Table
          columns={columns}
          data={data}
          setData={setData}
          loading={loading}
          option={option}
          options={options}
          setOption={setOption}
          setOptions={setOptions}
        />
      </div>
    </Card>
  );
};
export default App;
