import { createSlice } from "@reduxjs/toolkit";

export const tableEvidenceSlice = createSlice({
  name: "evidenceTable",
  initialState: {
    data: [],
    loading: true,
  },
  reducers: {
    evidenceTable: (state, action) => {
      console.log("data + loading: false");
      state.data = action.payload;
      state.loading = false;
    },

    loadingEvidenceTable: (state) => {
      console.log("loading: true");
      state.loading = true;
    },
  },
});

export const {
  evidenceTable,
  loadingEvidenceTable,
} = tableEvidenceSlice.actions;

export const evidenceTableData = (state) => state.evidenceTabledata;

export default tableEvidenceSlice.reducer;
