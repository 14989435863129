import React, { useState } from "react";
import cn from "classnames";
import styles from "./Dropdown.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import Icon from "../../Icon";
import { getItem } from "localforage";
import Dropdown_v_2 from "./../Dropdown_v_2";
const Dropdown = ({
  className,
  item,
  visibleSidebar,
  setValue,
  onClose,
  title,
}) => {
  const { pathname } = useLocation();
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    setVisible(!visible);
  };

  const Head = () => {
    return (
      <button
        className={cn(
          styles.head,
          {
            [styles.active]: pathname.includes(item.slug),
          },
          { [styles.wide]: visibleSidebar }
        )}
        onClick={() => {
          if (!visibleSidebar) {
            setVisible(true);
            setValue(true);
          } else {
            handleClick();
          }
        }}
      >
        {item.icon === "" ? (
          <div className={styles.FontIco}>{item.title.slice(0, 1)}</div>
        ) : (
          <div className={styles.icon}>
            <i class={"fa fa-" + `${item.icon}`} style={{ fontSize: 20 }}></i>
          </div>
        )}

        {item.title[1] === "."
          ? item.title.slice(2, item.title.length)
          : item.title}
        <Icon name="arrow-down" size="24" />
      </button>
    );
  };

  return (
    <div
      className={cn(
        styles.dropdown,
        className,
        { [styles.active]: visible },
        {
          [styles.active]: pathname.includes(item.slug),
        },
        { [styles.wide]: visibleSidebar }
      )}
      title={title}
    >
      {item.add ? (
        <div
          className={cn(styles.top, {
            [styles.active]: pathname.startsWith("/products/add"),
          })}
        >
          <Head />
          <Link
            className={cn(styles.add, {
              [styles.active]: pathname.startsWith("/products/add"),
            })}
            to="/products/add"
            onClick={() => {
              handleClick();
            }}
          >
            <Icon name="plus" size="10" />
          </Link>
        </div>
      ) : (
        <Head />
      )}
      <div className={styles.body}>
        {item.dropdown.map((x, index) =>
          x.url ? (
            <NavLink
              className={styles.link}
              activeClassName={styles.active}
              to={x.url}
              key={index}
              onClick={() => {
                setValue(false);
              }}
              exact
            >
              {x.title}
              {x.counter ? (
                <div
                  className={styles.counter}
                  style={{ backgroundColor: x.colorCounter }}
                >
                  {x.counter}
                </div>
              ) : (
                <Icon name="arrow-next" size="24" />
              )}
            </NavLink>
          ) : (
            // eslint-disable-next-line react/jsx-pascal-case
            <Dropdown_v_2
              className={styles.dropdown}
              visibleSidebar={visibleSidebar}
              setValue={setValue}
              key={index}
              item={x}
              title={x.title}
              //onClick={() => setVisibleDrp1(!visibleDrp1)}
              //onClose={() => setVisible(!visible)}
            />
          )
        )}
      </div>
    </div>
  );
};

export default Dropdown;
