import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Search from "../../../components/Search";
import Table from "./Table";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import {
  VypisDokladuHTMLUrl,
  menuAkciaUrl,
  notifikaciaUrl,
  reklMenuAkciaUrl,
  zoznamReklamaciiSQLUzavreteUrl,
} from "../../../features/urlHelper";
import swal from "sweetalert";
import { useHistory } from "react-router";
import Delete from "../../../components/Delete";
import { errorHandleDialog } from "../../../features/errorDialog";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { modal, modalData } from "../../../components/Modaly/redux/modalSlice";
import {
  signalRData,
  setConnectionReklamace,
  setConnectionReklamaceID,
} from "../../SignIn/redux/signalRSlice";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";

const App = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const modaldata = useSelector(modalData);
  const connection = useSelector(signalRData);

  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [option, setOption] = useState({ value: "", label: "Vše" });
  const [data, setData] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [params_to_menu, setParams_to_menu] = React.useState(null);

  const open = Boolean(anchorEl);

  function handleClickListItem(event, id) {
    //console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
    setCurrentRow(id);
  }

  const handleMenuItemClick = (event, value, params) => {
    setAnchorEl(null);
    menu_akcia(value, params.row.reklamace);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  function getWidth(width) {
    if (width > 2500) {
      return width - 408;
    } else if (width < 2500 && width > 767) {
      return width - 194;
    } else {
      return width;
    }
  }

  function dajVysku(height) {
    if (window.innerWidth > 767) {
      return height - 178;
    } else {
      return height - 130;
    }
  }
  window.addEventListener("popstate", function () {
    if (
      (connection.connectionReklamace !== undefined ||
        connection.connectionReklamace !== null) &&
      connection.connectionReklamace
    ) {
      try {
        connection.connectionReklamace.stop();
      } catch (e) {
        console.log(e);
      }
    }
  });

  useEffect(() => {
    if (
      (connection.connectionReklamace !== undefined ||
        connection.connectionReklamace !== null) &&
      connection.connectionReklamace
    ) {
      try {
        connection.connectionReklamace.stop();
      } catch (e) {
        console.log(e);
      }
    }
  }, [HubConnectionState.Reconnecting]);

  useEffect(() => {
    //removeAllConn();
    const connect = new HubConnectionBuilder()
      .withUrl(
        notifikaciaUrl +
          "?username=" +
          (logindata.UserData === undefined ? "" : logindata.UserData.username)
      )
      .withAutomaticReconnect()
      .build();
    dispatch(setConnectionReklamace(connect));
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (connection.connectionReklamace) {
      try {
        console.log("hub notification start");
        connection.connectionReklamace
          .start()
          .then(() => {
            //ziskame notificationID ( kvoli update)
            connection.connectionReklamace
              .invoke("GetConnectionId")
              .then(function (connectionID) {
                dispatch(setConnectionReklamaceID(connectionID));
                //console.log("connectionID", connectionID);
              })
              .catch((error) => console.log(error));

            connection.connectionReklamace.on(
              "ReceiveMessage",
              async (message) => {
                if (message.typ === "REKLAMACE_UZAVRENE_REFRESH") {
                  console.log("REKLAMACE_UZAVRENE_REFRESH");
                  //console.log(message.data.data);
                  setData(message.data.data);
                }
              }
            );
          })
          .catch((error) => console.log(error));
      } catch (error) {
        console.log(error);
      }
    }
  }, [connection.connectionReklamace]);

  const columns = [
    {
      field: "datum",
      hideable: false,
      headerName: "Datum",
      width: (getWidth(window.innerWidth) - 70) * 0.14,
      headerAlign: "center",
      sortable: true,
      filterable: false,
      renderCell: (params) => {
        var date_ = moment(params?.value).format("DD.MM.YYYY");
        return <div className={styles.cell_center}>{date_}</div>;
      },
    },
    {
      field: "reklamace",
      hideable: false,
      headerName: "Číslo reklamace",
      width: (getWidth(window.innerWidth) - 70) * 0.14,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className={styles.polozka_reklamace}>
            <div>{params.row.reklamace}</div>
          </div>
        );
      },
    },
    {
      field: "odberatel",
      hideable: false,
      headerName: "Kod odběratele",
      width: (getWidth(window.innerWidth) - 110) * 0.14,
      headerAlign: "center",
      sortable: false,
      filterable: true,
      renderHeader: (params) => {
        switch (option.value) {
          case "":
            return (
              <div className={styles.header}>
                <div className={styles.f_line}>Kod odběratele/</div>
                <div className={styles.s_line}>Kod střediska</div>
              </div>
            );
          case "Ext":
            return <div className={styles.header}>Kod odběratele</div>;
          case "Int":
          case "BB":
          case "Žž":
            return <div className={styles.header}>Kod střediska</div>;
        }
      },
      renderCell: (params) => {
        switch (params.row.wms_special4) {
          case "Ext":
            return (
              <div className={styles.cell_center}>{params.row.odberatel}</div>
            );
          case "Int":
          case "BB":
          case "Žž":
            return (
              <div className={styles.cell_center}>{params.row.pobocka}</div>
            );
        }
      },
    },
    {
      field: "polozka",
      hideable: true,
      headerName: "Kod odběratele",
      width: (getWidth(window.innerWidth) - 110) * 0.14,
      headerAlign: "center",
      sortable: false,
      filterable: true,
      renderCell: (params) => {
        return <div className={styles.cell_center}>{params.value}</div>;
      },
    },
    {
      field: "odberatel_nazov",
      hideable: false,
      headerName: "Odběratel",
      width: (getWidth(window.innerWidth) - 110) * 0.19,
      headerAlign: "center",
      sortable: false,
      filterable: true,
      renderHeader: (params) => {
        switch (option.value) {
          case "":
            return (
              <div className={styles.header}>
                <div className={styles.f_line}>Odběratele/</div>
                <div className={styles.s_line}>Středisko</div>
              </div>
            );
          case "Ext":
            return <div className={styles.header}>Oodběratel</div>;
          case "Int":
          case "BB":
          case "Žž":
            return <div className={styles.header}>Středisko</div>;
        }
      },
      renderCell: (params) => {
        switch (params.row.wms_special4) {
          case "Ext":
            return (
              <div className={styles.cell_center}>
                {params.row.odberatel_nazov}
              </div>
            );
          case "Int":
          case "BB":
          case "Žž":
            return (
              <div className={styles.cell_center}>
                {params.row.pobocka_nazov}
              </div>
            );
        }
      },
    },
    {
      field: "stav",
      hideable: false,
      headerName: "Výsledek řešení",
      width: (getWidth(window.innerWidth) - 70) * 0.14,
      headerAlign: "center",
      sortable: false,
      filterable: true,
      renderCell: (params) => {
        //console.log(params);
        var styl = "";
        var con_styl = "";
        var text = "";
        var icon = "";
        switch (params.value) {
          case "1_ODESLANA":
            styl = "polozka_neutral";
            con_styl = "icon_neutral";
            icon = "fa fa-send-o";
            text = "Odeslaná";
            break;

          case "2_V_RESENI":
            styl = "polozka_neutral";
            con_styl = "icon_neutral";
            icon = "fa fa-clock-o";
            text = "V řešení";
            break;

          case "10_UZNANA_END":
            styl = "polozka_vyb";
            con_styl = "icon_green";
            icon = "fa fa-check";
            text = "Uznaná + ukončena";
            break;

          case "10_UZNANA":
            styl = "polozka_vyb";
            con_styl = "icon_green";
            icon = "fa fa-check";
            text = "Uznaná";
            break;

          case "11_NEUZNANA_END":
            styl = "polozka_neuz";
            con_styl = "icon_red";
            icon = "fa fa-close";
            text = "Neuznaná + ukončena";
            break;

          case "11_NEUZNANA":
            styl = "polozka_neuz";
            con_styl = "icon_red";
            icon = "fa fa-close";
            text = "Neuznaná";
            break;

          case "12_CUZNANA_END":
            styl = "polozka_res";
            con_styl = "icon_orange";
            icon = "fa fa-minus";
            text = "Částečně uznaná + ukončena";
            break;

          case "12_CUZNANA":
            styl = "polozka_res";
            con_styl = "icon_orange";
            icon = "fa fa-minus";
            text = "Částečně uznaná";
            break;
        }
        return (
          <div className={styles[styl]}>
            <div className={styles[con_styl]}>
              <i class={icon}></i>
            </div>
            <div>{text}</div>
          </div>
        );
      },
    },
    {
      field: "nazov",
      hideable: false,
      headerName: "Položky",
      width: (getWidth(window.innerWidth) - 70) * 0.25,
      headerAlign: "center",
      sortable: false,
      filterable: true,
      renderCell: (params) => {
        return (
          <div className={styles.polozka}>
            <div className={styles.popis}>
              <div className={styles.nazov}>{params.row.nazov}</div>
              <div className={styles.znacka}>{params.row.polozka}</div>
            </div>
            <div className={styles.content}>
              <div className={styles.obrazok}>
                <img src="/images/content/bageta_1.jpg" alt={params.row.text} />
              </div>
              <div className={styles.overlay_text}>
                <p>{params.row.objem + params.row.mj}</p>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      headerAlign: "center",
      //cellClassName: 'actions',
      width: 50,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className={styles.actions}>
            <div
              title="Detail reklamace"
              className={styles.icon_eye}
              onClick={async () => {
                navigate.push(
                  `/reklamaceSQLDetail?datum=${params.row.datum}&cis_rekl=${
                    params.row.reklamace
                  }&priznak=${"uzavreta"}`
                );
              }}
            >
              <i class="fa fa-eye"></i>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    result(logindata.UserData.ico);
  }, []);

  async function menu_akcia(akcia, cis_rekl) {
    await axios
      .post(
        reklMenuAkciaUrl,
        {
          menu_akcia: akcia,
          //stredisko: logindata.UserData.vybrane_stredisko !== undefined ? logindata.UserData.vybrane_stredisko.kod : null,
          odberatel:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          pobocka:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          reklamace: cis_rekl,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        setData(response.data.data);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function result() {
    console.log(logindata.UserData.ico);
    await axios
      .post(
        zoznamReklamaciiSQLUzavreteUrl,
        {
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log(response);
        setData(response.data);
        const newOptions = response.data.map((item) => item.wms_special4);
        const uniqesOptions = [...new Set(newOptions)];
        const uniqesOptions_ = uniqesOptions.map((item) => {
          switch (item) {
            case "Ext":
              return { value: item, label: "Externí zákazníci " };
            case "Int":
              return { value: item, label: "Interní střediska " };
            case "BB":
              return { value: item, label: "Pobočky BB " };
            case "Žž":
              return { value: item, label: "Žiželice " };
          }
          return;
        });
        var uniquesOptionsReverse = [...uniqesOptions_, { value: "", label: "Vše" }];
        setOptions(uniquesOptionsReverse.reverse());
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <Card>
      <div style={{ height: dajVysku(window.innerHeight) }}>
        <div className={styles.nadpis}>
          <div>Přehled uzavřených reklamací</div>
        </div>
        <Table
          columns={columns}
          data={data}
          loading={loading}
          options={options}
          option={option}
          setOption={setOption}
        />
      </div>
    </Card>
  );
};
export default App;
