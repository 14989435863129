import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Icon from "../Icon";
import styles from "./DropZone.module.sass";
import cn from "classnames";

export default function DropZone({
  disabled = false,
  text,
  size = 3, // Max počet súborov
  onChange,
  acceptedFiles = "image/jpeg,image/png", // Povolené typy súborov
  maxFileSize = 9437184, // Max veľkosť súboru (v bajtoch), 1MB = 1048576 bajtov - mame 9MB
  removeAl,
  required,
  value

}) {
  //const [myFiles, setMyFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      console.log("Accepted files:", acceptedFiles);
      console.log("Rejected files:", rejectedFiles);

      if (rejectedFiles.length > 0) {
        console.log(
          "Některé soubory nie sú povolené alebo prekračujú veľkostný limit."
        );
      }
      if (acceptedFiles.length < size) {
        const updatedFiles = [...value, ...acceptedFiles];
        //setMyFiles(updatedFiles);

        // Zavolá onChange, ak je definovaný
        if (onChange) {
          onChange(updatedFiles);
        }
      }
    },
    [value, onChange]
  );

  useEffect(() => {
    onChange([]);
  }, [removeAl]);

  const { getRootProps, getInputProps } = useDropzone({
    disabled: disabled,
    onDrop,
    accept: acceptedFiles, // Povolené typy súborov
    maxSize: maxFileSize, // Max veľkosť súboru
    maxFiles: size, // Max počet súborov
  });

  const removeFile = (file) => {
    const newFiles = [...value];
    newFiles.splice(newFiles.indexOf(file), 1);
    //setMyFiles(newFiles);
    if (onChange) {
      onChange(newFiles);
    }
  };

  const removeAll = () => {
    //setMyFiles([]);

    // Zavolá onChange pri odstránení všetkých súborov
    if (onChange) {
      onChange([]);
    }
  };

  function formatSizeUnits(bytes) {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + " GB";
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + " MB";
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes > 1) {
      bytes = bytes + " bytes";
    } else if (bytes == 1) {
      bytes = bytes + " byte";
    } else {
      bytes = "0 bytes";
    }
    return bytes;
  }

  const files = value.map((file) => (
    <div key={file.path} className={styles.row}>
      <div className={styles.file_around}>
        <div lassName={styles.file}>
          <div className={styles.IconFile}>
            <i className="fa fa-file-image-o"></i>
          </div>
          <div className={styles.IconFileText} title={file.path}>
            {file.path.slice(0, 6)} ...
          </div>
          <div className={styles.IconFileText}>
            {formatSizeUnits(file.size)}
          </div>
        </div>
        <div
          className={styles.IconClose}
          onClick={() => {
            removeFile(file);
          }}
        >
          <Icon name="close" size="12" />
        </div>
      </div>
    </div>
  ));

  return (
    <section className={required ? styles.drop_zone_aroud_req : styles.drop_zone_aroud}>
      <div {...getRootProps({ className: styles.dropzone })}>
        <input {...getInputProps()} />
        <p>{text}</p>
        <i className="fa fa-cloud-upload"></i>
      </div>
      <div className={styles.dropzone_under}>
        <div className={styles.files}>{files}</div>
      </div>
    </section>
  );
}
