export function openNewWindow (logindata, url) {
  // Otvoríme nové okno
  const newWindow = window.open(url, "_blank");

  // Nastavíme sessionStorage v novom okne
  if (newWindow) {
    newWindow.sessionStorage.setItem("logindata", JSON.stringify(logindata));
  } else {
    console.error("Nepodarilo sa otvoriť nové okno");
  }
}
