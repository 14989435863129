import React, { useEffect, useState, useRef } from "react";
import Icon from "../../Icon";
import Modal from "../../Modal";
import styles from "./Modal.module.sass";
import {
  DataGridPremium,
  LicenseInfo,
  csCZ,
  GridRowModes,
} from "@mui/x-data-grid-premium";
//import {  } from "@mui/x-license-premium";
import { getLicensePremium } from "../../../features/License";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "dayjs/locale/cs";
import "moment/locale/cs";

import useDarkMode from "@fisch0920/use-dark-mode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import swal from "sweetalert";
import { INDEXEDDB } from "localforage";
import LoadingOverlay from "react-loading-overlay-nextgen";
import ModalKosikEdit from "..//ModalKosikEdit";
import { areArraysEqual } from "../../../features/areArraysEqual";
import { search, searchData } from "../../Search/redux/searchSlice";
import { useDispatch, useSelector } from "react-redux";
import { Hidden } from "@mui/material";
import { RestaurantMenu } from "@mui/icons-material";

const App = ({
  className,
  visible,
  setVisible,
  data,
  buttony,
  objednat_data,
  objednat,
  setIsActive,
  setLoaderText,
  hlavicka,
  nacitaj_kosik,
  datum_active,
  setDatum_active,
  vynulovat_kosik,
  aktualizuj_kosik,
  layoutText,
  setLayoutText,
  layoutActive,
  setLayoutActive,
  zapis,
  zapis_,
  dataObjednavky,
  typ_objednavky,
  stop_obj_znacky,
  kosikZmena,
}) => {
  LicenseInfo.setLicenseKey(getLicensePremium());

  const darkMode = useDarkMode(false);
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );

  const [rowGroupingModel, setRowGroupingModel] = React.useState([
    "typ_polozky",
  ]);
  const [newMnozstvo, setNewMnozstvo] = useState(0);
  const [id_editable, setId_editable] = React.useState(null);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [visible_ModalEdit, setVisible_ModalEdit] = useState(false);
  const [row_ModalEdit, setRow_ModalEdit] = useState({});
  const searchdata = useSelector(searchData);
  const darkTheme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
      mode: darkMode.value ? "dark" : "light",
    },
  });
  const stylingTable = {
    border: 0,
    paddingTop: 0,
    padding: 0,
    margin: 0,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "& .MuiDataGrid-cellContent": {
      fontSize: 11,
    },
    letterSpacing: "normal",
    width: window.innerWidth > 760 ? 650 : window.innerWidth - 130,
    height: 580,
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
    color: darkMode.value ? "white" : "#666d74",
  };

  useEffect(() => {
    //console.log(dataObjednavky);
    if (document.getElementsByName("ico_title")[0] !== undefined) {
      document.getElementsByName("ico_title")[0].click();
    }
  }, [visible]);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#F1B600" },
      },
    },
    csCZ
  );

  function daj_balne_mn(znacka) {
    for (var row of dataObjednavky) {
      if (row.znacka.trim() === znacka.trim()) {
        return row.balne_mn;
      }
    }
    return 0;
  }

  function daj_nahradu(znacka) {
    for (var row of dataObjednavky) {
      if (row.znacka.trim() === znacka.trim()) {
        return row.nahrada;
      }
    }
    return "";
  }

  function vyber_stop_obj_znacky(datum) {
    let znacky = [];
    for (var row_ of dataObjednavky) {
      for (var bunka of row_.stlpce) {
        if (new Date(bunka.datum).getTime() === datum.setHours(0, 0, 0, 0)) {
          if (bunka.stop_obj) {
            znacky.push(bunka.znacka.trim());
          }
          continue;
        }
      }
    }
    return znacky;
  }

  function existuje_nahrada(znacka) {
    for (var row_ of dataObjednavky) {
      if (row_.znacka.trim() === znacka.trim() && row_.nahrada.trim() !== "") {
        return true;
      }
    }
    return false;
  }

  function ma_stop_obj_a_nahradu(znacka, datum) {
    return (
      stop_obj_znacky.includes(znacka.trim()) && existuje_nahrada(znacka.trim())
    );
  }

  function ma_stop_obj_a_nema_nahradu(znacka, datum) {
    return (
      stop_obj_znacky.includes(znacka.trim()) &&
      !existuje_nahrada(znacka.trim())
    );
  }

  const [sortModel, setSortModel] = React.useState([
    {
      field: "typ_polozky",
      sort: "asc",
    },
  ]);

  async function kontrola_na_nahradu() {
    //kontrola na nahradu
    var now = new Date();
    now.setHours(0, 0, 0, 0);
    let num_of_affected_rows = 0;
    let shouldBreak = false; // Vlajka pre opustenie cyklu
    let nahrada = false; // Vlajka pre nahradu
    for (var row_kosik of data) {
      if (shouldBreak) {
        break; // Opustí cyklus, ak je vlajka nastavená na true
      }
      if (
        existuje_nahrada(row_kosik.znacka.trim()) &&
        vyber_stop_obj_znacky(
          now.getTime() > datum_active.getTime() ? now : datum_active
        ).includes(row_kosik.znacka.trim())
      ) {
        num_of_affected_rows++;
        await zapis_(
          datum_active,
          0,
          row_kosik.znacka,
          objednat_data.scrollPosition,
          row_kosik.datum + row_kosik.znacka.trim(),
          hlavicka
        );
        // buttony.map(async (button, index) => {
        //   var date = new Date(button.datum);
        //   if (date.getTime() === datum_active.getTime()) {
        //     //console.log("Modal kosik 199")
        //     await aktualizuj_kosik(button.datum, index, true);
        //   }
        // });
        // await swal({
        //   text:
        //     "Položka " +
        //     row_kosik.znacka +
        //     " " +
        //     row_kosik.nazov +
        //     " není skladem a bude vymazána z objednávky. \n Alternativa je " +
        //     daj_nahradu(row_kosik.znacka.trim()) +
        //     ". \n Chcete přepnout na alternativu?",
        //   icon: "info",
        //   buttons: ["Ne ", "Ano"],
        // }).then(async (willShow) => {
        //   if (willShow) {
        //     await zapis_(
        //       datum_active,
        //       0,
        //       row_kosik.znacka,
        //       objednat_data.scrollPosition,
        //       row_kosik.datum + row_kosik.znacka.trim(),
        //       hlavicka
        //       //daj_nahradu(row_kosik.znacka)
        //     );
        //     await setVisible(false);
        //     num_of_affected_rows = null;
        //     shouldBreak = true;
        //     await dispatch(
        //       search({
        //         data: {
        //           ...searchdata,
        //           ["searchObjednavky_typERP_" + typ_objednavky]: daj_nahradu(
        //             row_kosik.znacka.trim()
        //           ),
        //         },
        //       })
        //     );
        //   } else {
        //     num_of_affected_rows++;
        //     await zapis_(
        //       datum_active,
        //       0,
        //       row_kosik.znacka,
        //       objednat_data.scrollPosition,
        //       row_kosik.datum + row_kosik.znacka.trim(),
        //       hlavicka
        //     );
        //     buttony.map(async (button, index) => {
        //       var date = new Date(button.datum);
        //       if (date.getTime() === datum_active.getTime()) {
        //         //console.log("Modal kosik 199")
        //         await aktualizuj_kosik(button.datum, index, true);
        //       }
        //     });
        //   }
        // });
      }
    }
    return num_of_affected_rows;
  }
  async function zmaz_vsetky_stp_obj_polozky(polozky) {
    //kontrola na nahradu
    let num_of_affected_rows = 0;
    for (var polozka of polozky) {
      num_of_affected_rows++;
      await zapis_(
        datum_active,
        0,
        polozka.znacka,
        objednat_data.scrollPosition,
        polozka.datum + polozka.znacka.trim(),
        hlavicka
      );
      // buttony.map(async (button, index) => {
      //   var date = new Date(button.datum);
      //   if (date.getTime() === datum_active.getTime()) {
      //     //console.log("Modal kosik 233")
      //     await aktualizuj_kosik(button.datum, index, true);
      //   }
      // });
      // await swal({
      //   text:
      //     "Položka " +
      //     polozka.znacka +
      //     " " +
      //     polozka.nazov +
      //     " není skladem a bude vymazána z objednávky.",
      //   icon: "info",
      // }).then(async (willShow) => {
      //   if (willShow) {
      //     num_of_affected_rows++;
      //     await zapis_(
      //       datum_active,
      //       0,
      //       polozka.znacka,
      //       objednat_data.scrollPosition,
      //       polozka.datum + polozka.znacka.trim(),
      //       hlavicka
      //     );
      //     buttony.map(async (button, index) => {
      //       var date = new Date(button.datum);
      //       if (date.getTime() === datum_active.getTime()) {
      //         //console.log("Modal kosik 233")
      //         await aktualizuj_kosik(button.datum, index, true);
      //       }
      //     });
      //   }
      // });
    }
    return num_of_affected_rows;
  }

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  let columns = [
    {
      field: "nazov",
      headerName: "Položky",
      headerAlign: "start",
      width: (window.innerWidth > 760 ? 630 : window.innerWidth - 20) * 0.4,
      renderCell: (params) => {
        return (
          <>
            {params.rowNode.type === "group" ? (
              <></>
            ) : (
              <>
                <div className={styles.nazov}>
                  <div className={styles.avatar2}>
                    <img src={"/images/content/bageta.png"} alt="Avatar" />
                  </div>
                  <div
                    className={
                      ma_stop_obj_a_nahradu(
                        params.row.znacka.trim(),
                        datum_active
                      ) ||
                      ma_stop_obj_a_nema_nahradu(
                        params.row.znacka.trim(),
                        datum_active
                      )
                        ? styles.nazov_kod_dis
                        : styles.nazov_kod
                    }
                  >
                    <div>
                      {params.row.objednane_mn !== params.row.mnozstvo &&
                      kosikZmena ? (
                        <b>{params.row.nazov}</b>
                      ) : (
                        <>{params.row.nazov}</>
                      )}
                    </div>
                    <div className={styles.znacka}>
                      <div className={styles.znackaText}>
                        {params.row.objednane_mn !== params.row.mnozstvo &&
                        kosikZmena ? (
                          <b>{params.row.znacka}</b>
                        ) : (
                          <>{params.row.znacka}</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        );
      },
    },
    {
      field: "mnozstvo",
      headerName: "Množsví",
      headerAlign: "center",
      type: "number",
      //editable: true,
      width: (window.innerWidth > 760 ? 630 : window.innerWidth - 20) * 0.3,
      renderCell: (params) => {
        if (params.id === id_editable) {
          params.isEditable = true;
        }
        const valueFormatted = Number(params.value);

        const value = valueFormatted
          .toFixed(params.row.des_mista)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");
        return (
          <>
            {params.rowNode.type === "group" ? (
              <></>
            ) : (
              <div
                className={
                  ma_stop_obj_a_nahradu(
                    params.row.znacka.trim(),
                    datum_active
                  ) ||
                  ma_stop_obj_a_nema_nahradu(
                    params.row.znacka.trim(),
                    datum_active
                  )
                    ? styles.nazov_kod_dis
                    : styles.nazov_kod
                }
              >
                {params.row.objednane_mn !== params.row.mnozstvo &&
                kosikZmena ? (
                  <b>{value}</b>
                ) : (
                  <>{value}</>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "typ_polozky",
      headerName: "aaa",
      headerAlign: "center",
      groupable: true,
      sortable: false,
    },
    {
      field: "mj",
      headerName: "MJ",
      headerAlign: "center",
      width: (window.innerWidth > 760 ? 630 : window.innerWidth - 20) * 0.1,
      renderCell: (params) => {
        return (
          <>
            {params.rowNode.type === "group" ? (
              <></>
            ) : (
              <div
                className={
                  ma_stop_obj_a_nahradu(
                    params.row.znacka.trim(),
                    datum_active
                  ) ||
                  ma_stop_obj_a_nema_nahradu(
                    params.row.znacka.trim(),
                    datum_active
                  )
                    ? styles.nazov_kod_dis
                    : styles.nazov_kod
                }
              >
                {params.row.objednane_mn !== params.row.mnozstvo &&
                kosikZmena ? (
                  <b>{params.row.mj}</b>
                ) : (
                  <>{params.row.mj}</>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      headerAlign: "center",
      //cellClassName: 'actions',
      width: (window.innerWidth > 760 ? 630 : window.innerWidth - 20) * 0.2,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.rowNode.type === "group" ? (
              <></>
            ) : (
              <>
                <div className={styles.icons}>
                  {ma_stop_obj_a_nema_nahradu(
                    params.row.znacka.trim(),
                    datum_active
                  ) ? (
                    <></>
                  ) : ma_stop_obj_a_nahradu(
                      params.row.znacka.trim(),
                      datum_active
                    ) ? (
                    <div
                      className={styles.iconn}
                      onClick={async () => {
                        await setVisible(false);
                        await dispatch(
                          search({
                            data: {
                              ...searchdata,
                              ["searchObjednavky_typERP_" + typ_objednavky]:
                                daj_nahradu(params.row.znacka.trim()),
                            },
                          })
                        );
                      }}
                    >
                      Náhrada
                    </div>
                  ) : (
                    <div
                      className={styles.icon1}
                      onClick={async () => {
                        setRow_ModalEdit(params.row);
                        setNewMnozstvo(0);
                        await setVisible_ModalEdit(true);
                        if (
                          document.getElementsByName("inp_mj")[0] !== undefined
                        ) {
                          document.getElementsByName("inp_mj")[0].select();
                          document.getElementsByName("inp_mj")[0].focus();
                        }
                      }}
                    >
                      <Icon name="edit" size="20"></Icon>
                    </div>
                  )}

                  <div
                    onClick={async () => {
                      console.log(params.row.objednane_mn, params.row.mnozstvo);
                      if (
                        params.row.objednane_mn === params.row.mnozstvo &&
                        kosikZmena
                      ) {
                        return;
                      }
                      swal({
                        title: kosikZmena
                          ? "Chcete zrušit změnu?"
                          : "Chcete smazat položku?",
                        icon: "info",
                        buttons: ["Ne ", "Ano"],
                        dangerMode: true,
                      }).then(async (willShow) => {
                        if (willShow) {
                          setLayoutActive(true);
                          setLayoutText(
                            kosikZmena
                              ? "Ruším změny na položce."
                              : "Odstraňuji položku z košíku."
                          );
                          var balne_mn = daj_balne_mn(params.row.znacka.trim());
                          await zapis(
                            kosikZmena ? params.row.objednane_mn : 0,
                            balne_mn,
                            params.row.des_mista,
                            params.row.mj,
                            datum_active,
                            params.row.znacka,
                            objednat_data.scrollPosition,
                            params.row.datum + params.row.znacka.trim(),
                            hlavicka
                          );
                        }
                      });
                    }}
                    className={
                      params.row.objednane_mn === params.row.mnozstvo &&
                      kosikZmena
                        ? styles.icon2_dis
                        : styles.icon2
                    }
                  >
                    <Icon name="trash" size="20"></Icon>
                  </div>
                </div>
              </>
            )}
          </>
        );
      },
    },
  ];

  function back() {
    setVisible(false);
  }

  function validnyDatum() {
    return moment(new Date(datum_active)).format("D.M.YYYY");
  }

  function IdDatum() {
    return moment(new Date(datum_active)).format("YYYY-MM-DDTHH:mm:ss");
  }

  function denVTydnu() {
    moment.locale("cs");
    let dayOfWeek = moment(new Date(datum_active)).format("dddd");
    return dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
  }

  function text_odoslat_objednavku(now, active, neaktivne_polozky = false) {
    var dat = moment(active, "ddd MMM DD YYYY HH:mm:ss").format("yyyy-MM-DDTHH:mm:ss")
    var head = hlavicka.find((x) => x.datum === dat);
    var dat_objDo = new Date(head.objednatDo);
    var now_ = new Date();
    console.log(now_, dat_objDo)
    if (!head.manager && now_.getTime() > dat_objDo.getTime()) {
      return (
        '</div style="font-weight: 300;">' +
        '<div style="color: red;">Objednávku na tento den již nelze odeslat!</div>' +
        '<div s">Objednávku smažte nebo přesuňte na jiný den.</div></div>'
      );
    }
    if (now.getTime() <= active.getTime()) {
      return (
        (neaktivne_polozky
          ? '</div style="font-weight: 300;"> <div style="color: red;">Některé položky v košíku nejsou dostupné.</div>'
          : '</div style="font-weight: 300;">') +
        (!head.zavozovy_den && logindata.UserData.typErp === "B"
          ? '</div style="font-weight: 300;"> <div">Chystáte se provést objednávku na den,</div><div>který není závozový!</div>'
          : '</div style="font-weight: 300;">') +
        ' <div style="color: green;"> Chcete odeslat objednávku na ' +
        moment(active, "ddd MMM DD YYYY HH:mm:ss").format("D.M.YYYY") +
        (neaktivne_polozky ? " bez nedostupných položek" : "") +
        "?</div>" +
        "</div>"
      );
    } else {
      return (
        '</div style="font-weight: 300;">' +
        '<div style="color: red;">Objednávku na tento den již nelze odeslat!</div>' +
        '<div s">Objednávku smažte nebo přesuňte na jiný den.</div></div>'
      );
    }
  }

  return (
    <>
      <Modal
        widthModal={window.innerWidth > 780 ? 780 : window.innerWidth}
        outerClassName={styles.outerZaznamProblemu}
        visible={visible}
      >
        <LoadingOverlay
          active={layoutActive}
          className={styles.LoadingOverlay}
          spinner
          text={layoutText}
        >
          <div style={{ width: "100%" }}>
            <div name={"ico_title"} className={styles.ico_Title}>
              <div className={styles.btn}></div>
              <div>
                {kosikZmena ? "Změněná" : "Nová"} objednávka na {validnyDatum()}{" "}
                ({denVTydnu()})
              </div>
              <button
                className={styles.btn}
                onClick={() => {
                  setVisible(false);
                }}
              >
                <Icon name="close" size="20" />
              </button>
            </div>
            <div
              style={{
                height: 580,
              }}
              className={styles.content}
              ref={elementRef}
              id="element"
            >
              <div className={styles.content_l}>
                <div className={styles.buttony_nadpis}>Datum závozu</div>
                <div className={styles.content_l_in}>
                  {buttony.map((button, index) => {
                    return (
                      <div
                        id={button.title + button.cis_objed}
                        onClick={() => {
                          var datum = new Date(button.datum);
                          setLayoutText(
                            "Aktualizuji na den " +
                              moment(datum, "ddd MMM DD YYYY HH:mm:ss").format(
                                "D.M.YYYY"
                              )
                          );
                          setLayoutActive(true);
                          nacitaj_kosik(datum, button.cis_objed);
                          setDatum_active(datum);
                        }}
                        className={
                          button.isActive ? styles.buttonA : styles.buttonP
                        }
                      >
                        <div>{button.title}</div>
                        <div>{button.cis_objed}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={styles.content_r}>
                <ThemeProvider theme={darkTheme}>
                  <DataGridPremium
                    sx={stylingTable}
                    localeText={{
                      ...csCZ.components.MuiDataGrid.defaultProps.localeText,
                    }}
                    hideFooter
                    disableColumnPinning
                    disableColumnMenu
                    rowHeight={40}
                    columnHeaderHeight={50}
                    rows={data}
                    columns={columns}
                    defaultGroupingExpansionDepth={-1}
                    onRowGroupingModelChange={(model) =>
                      setRowGroupingModel(model)
                    }
                    onSortModelChange={(model) => setSortModel(model)}
                    //groupBy="typ_polozky"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          typ_polozky: false,
                          nazov: false,
                        },
                      },
                      sorting: {
                        sortModel: [
                          {
                            field: "__row_group_by_columns_group__",
                            sort: "asc",
                          },
                        ],
                      },
                    }}
                    groupingColDef={{
                      leafField: "nazov",
                      width: 250,
                      sortComparator: (v1, v2) => {
                        // Custom comparator function for sorting groups
                        return v2.localeCompare(v1);
                      },
                    }}
                    rowGroupingModel={rowGroupingModel}
                  />
                </ThemeProvider>
              </div>
            </div>
          </div>
          <div className={styles.saveArea}>
            <div className={styles.tre_pok}>
              <div
                onClick={async () => {
                  swal({
                    content: {
                      element: "div",
                      attributes: {
                        innerHTML:
                          (kosikZmena
                            ? '<div style="color: red;">Chcete zrušit změny v košíku na '
                            : '<div style="color: red;">Chcete vymazat košík na ') +
                          moment(
                            datum_active,
                            "ddd MMM DD YYYY HH:mm:ss"
                          ).format("D.M.YYYY") +
                          "?</div>",
                      },
                    },
                    icon: "info",
                    buttons: ["Ne ", "Ano"],
                  }).then(async (willShow) => {
                    if (willShow) {
                      setLayoutActive(true);
                      setLayoutText(
                        (kosikZmena
                          ? "Ruším změny v košíku ze dne "
                          : "Mažu objednávku ze dne ") +
                          moment(
                            datum_active,
                            "ddd MMM DD YYYY HH:mm:ss"
                          ).format("D.M.YYYY")
                      );
                      var cis_objed_act = buttony.find(
                        (x) => x.isActive
                      ).cis_objed;
                      //console.log(cis_objed_act);
                      await vynulovat_kosik(
                        datum_active,
                        objednat_data.scrollPosition,
                        moment(datum_active, "ddd MMM DD YYYY HH:mm:ss").format(
                          "YYYY-MM-DDTHH:mm:ss"
                        ),
                        hlavicka,
                        cis_objed_act
                      );
                      buttony.map((button, index) => {
                        var date = new Date(button.datum);
                        if (date.getTime() === datum_active.getTime()) {
                          //console.log("Modal kosik 515")
                          aktualizuj_kosik(button.datum, index);
                        }
                      });
                      //setVisible(false);
                    }
                  });
                }}
                className={styles.save}
              >
                {kosikZmena ? "Vrátit změny" : "Vymazat objednávku"}
              </div>
            </div>
            <div className={styles.tre_pok}>
              <div
                onClick={async () => {
                  var now = new Date();
                  now.setHours(0, 0, 0, 0);
                  var stop_obj_znacky = vyber_stop_obj_znacky(
                    now.getTime() > datum_active.getTime() ? now : datum_active
                  );
                  var exist_stop_obj = false;

                  for (var row_kosik of data) {
                    if (stop_obj_znacky.includes(row_kosik.znacka.trim())) {
                      exist_stop_obj = true;
                    }
                  }
                  var dat = moment(datum_active, "ddd MMM DD YYYY HH:mm:ss").format("yyyy-MM-DDTHH:mm:ss")
                  var head = hlavicka.find((x) => x.datum === dat);
                  var dat_objDo = new Date(head.objednatDo);
                  var now_ = new Date();
                  if (!head.manager && now_.getTime() > dat_objDo.getTime()) {
                    await swal({
                      content: {
                        element: "div",
                        attributes: {
                          innerHTML: text_odoslat_objednavku(
                            now,
                            datum_active,
                            exist_stop_obj
                          ),
                        },
                      },
                      icon: "info",
                    });
                  }
                  else if (now.getTime() <= datum_active.getTime()) {
                    await swal({
                      content: {
                        element: "div",
                        attributes: {
                          innerHTML: text_odoslat_objednavku(
                            now,
                            datum_active,
                            exist_stop_obj
                          ),
                        },
                      },
                      // text: text_odoslat_objednavku(
                      //   now,
                      //   datum_active,
                      //   exist_stop_obj
                      // ),
                      icon: "info",
                      buttons: ["Ne ", "Ano"],
                    }).then(async (willShow) => {
                      if (willShow) {
                        let total_num_of_affected_rows = 0;
                        setLayoutActive(true);
                        setLayoutText(
                          "Kontroluji objednávku na " +
                            moment(
                              now.getTime() > datum_active.getTime()
                                ? now
                                : datum_active,
                              "ddd MMM DD YYYY HH:mm:ss"
                            ).format("D.M.YYYY")
                        );

                        //kontrola na nahradu
                        var num_kontrola_na_nahradu =
                          await kontrola_na_nahradu();

                        if (num_kontrola_na_nahradu !== null) {
                          total_num_of_affected_rows =
                            total_num_of_affected_rows +
                            num_kontrola_na_nahradu;
                        } else {
                          await setLayoutActive(false);
                          return;
                        }

                        // console.log(
                        //   now.getTime() > datum_active.getTime()
                        //     ? now
                        //     : datum_active
                        // );

                        //vytvorenie zoznamu poloziek so stop_obj
                        let zoznamu_poloziek_so_stop_obj = [];
                        for (var row_kosik of data) {
                          // console.log(
                          //   data,
                          //   existuje_nahrada(row_kosik.znacka.trim()),
                          //   row_kosik.znacka,
                          //   vyber_stop_obj_znacky(
                          //     now.getTime() > datum_active.getTime()
                          //       ? now
                          //       : datum_active
                          //   )
                          // );
                          if (
                            stop_obj_znacky.includes(row_kosik.znacka.trim()) &&
                            !existuje_nahrada(row_kosik.znacka.trim())
                          ) {
                            zoznamu_poloziek_so_stop_obj.push(row_kosik);
                          }
                        }

                        //console.log(zoznamu_poloziek_so_stop_obj);

                        //vymazanie všetkých stop_obj poloziek
                        total_num_of_affected_rows =
                          total_num_of_affected_rows +
                          (await zmaz_vsetky_stp_obj_polozky(
                            zoznamu_poloziek_so_stop_obj
                          ));

                        //
                        var helper = false;
                        if (data.length === total_num_of_affected_rows) {
                          await swal({
                            content: {
                              element: "div",
                              attributes: {
                                innerHTML:
                                  "Pro den " +
                                  moment(
                                    datum_active,
                                    "ddd MMM DD YYYY HH:mm:ss"
                                  ).format("D.M.YYYY") +
                                  " již neexistuje žádna položka. Objednávka nebude odeslaná.",
                              },
                            },
                            icon: "info",
                          }).then(async (willShow) => {
                            if (willShow) {
                              helper = true;
                              await setLayoutActive(false);
                              return;
                            }
                          });
                        }

                        if (helper) return;

                        await setLayoutText(
                          "Odesílám objednávku na " +
                            moment(
                              now.getTime() > datum_active.getTime()
                                ? now
                                : datum_active,
                              "ddd MMM DD YYYY HH:mm:ss"
                            ).format("D.M.YYYY")
                        );

                        await objednat(
                          datum_active,
                          objednat_data.scrollPosition,
                          moment(
                            datum_active,
                            "ddd MMM DD YYYY HH:mm:ss"
                          ).format("YYYY-MM-DDTHH:mm:ss"),
                          hlavicka,
                          now.getTime() > datum_active.getTime() ? "ANO" : null
                        );
                        await buttony.map(async (button, index) => {
                          var date = new Date(button.datum);
                          if (date.getTime() === datum_active.getTime()) {
                            //console.log("Modal kosik 610")
                            await aktualizuj_kosik(button.datum, index);
                          }
                        });
                      }
                      //await setLayoutActive(false);
                      //await setLayoutActive(false);
                    });
                  } else {
                    await swal({
                      content: {
                        element: "div",
                        attributes: {
                          innerHTML: text_odoslat_objednavku(
                            now,
                            datum_active,
                            exist_stop_obj
                          ),
                        },
                      },
                      icon: "info",
                    });
                  }
                }}
                className={styles.save}
              >
                Odeslat objednávku
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </Modal>
      <ToastContainer />
      <ModalKosikEdit
        visible={visible_ModalEdit}
        setVisible={setVisible_ModalEdit}
        visible_modalKosik={visible}
        setVisible_modalKosik={setVisible}
        data={data}
        buttony={buttony}
        objednat_data={objednat_data}
        objednat={objednat}
        setIsActive={setIsActive}
        setLoaderText={setLoaderText}
        hlavicka={hlavicka}
        nacitaj_kosik={nacitaj_kosik}
        datum_active={datum_active}
        setDatum_active={setDatum_active}
        vynulovat_kosik={vynulovat_kosik}
        aktualizuj_kosik={aktualizuj_kosik}
        layoutText={layoutText}
        setLayoutText={setLayoutText}
        layoutActive={layoutActive}
        setLayoutActive={setLayoutActive}
        zapis={zapis}
        zapis_={zapis_}
        dataObjednavky={dataObjednavky}
        row={row_ModalEdit}
        setRow={setRow_ModalEdit}
        newMnozstvo={newMnozstvo}
        setNewMnozstvo={setNewMnozstvo}
        typ_objednavky={typ_objednavky}
      ></ModalKosikEdit>
    </>
  );
};
export default App;
