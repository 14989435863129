import React, { useState } from "react";
import { useEffect } from "react";
import cn from "classnames";
import styles from "./Comments.module.sass";
import Card from "../../../components/Card";
import { commentsUrl } from "./../../../features/urlHelper"
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import Moment from 'moment';
import {
    login,
    loginData
} from './../../SignIn/redux/loginSlice';
import {
    commentData,
    comment,
    loadingComment
} from './redux/commentSlice';

import ContentLoader from "react-content-loader"
import useDarkMode from "@fisch0920/use-dark-mode";
import { useHistory } from "react-router";
import swal from "sweetalert";
import { errorHandleDialog } from "../../../features/errorDialog";
 
 
const Comments = ({ className }) => {
    const darkMode = useDarkMode(false);
    const navigate = useHistory()
    const dispatch = useDispatch();
    //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
    const commentdata = useSelector(commentData);
 
    useEffect(() => {
        dispatch(loadingComment())
        result()
    }, []);
 
    async function result() {
        await axios.get(commentsUrl + logindata.UserData.vybrane_stredisko.kod + "/" + logindata.UserData.datum, { headers: { Authorization: 'Bearer ' + logindata.Token } })
            .then(function (response) {
                dispatch(comment(response.data))
            })
            .catch(function (error) {
                errorHandleDialog(error.response, navigate, dispatch)
            })
    }
 
    function dateFormatter(date) {
        return Moment(date).format('DD.MM.YYYY')
    }
 
 
    return (
        <Card
            className={cn(styles.card, className)}
            title="Komentáře"
            classTitle="title-yellow"
        >
            {
 
                commentdata.loading
                    ? <ContentLoader
                        speed={2}
                        width={400}
                        height={100}
                        viewBox="0 0 400 100"
                        backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
                        foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
 
                    >
                        <rect x="20" y="8" rx="3" ry="3" width={"100"} height="12" />
                        <rect x="20" y="30" rx="3" ry="3" width={"70"} height="12" />
                        <rect x="20" y="56" rx="3" ry="3" width={"250"} height="50" />
                 
 
                    </ContentLoader>
                    : <div className={styles.comments}>
                        <div className={styles.list}>
                            {commentdata.data.komentare?.map((x, index) => (
                                <div className={styles.item} key={index}>
 
                                    <div className={styles.details}>
                                        <div className={styles.line}>
                                            <div className={styles.user}>
                                                <span className={styles.title}>Anonymous</span>{" "}
                                            </div>
 
                                            {x.type === "Pochvala" && <div className={styles.poz1}>Pozitivní</div>}
                                            {x.type === "Stížnost" && <div className={styles.poz2}>Negativní</div>}
                                            {x.type === "Námět" && <div className={styles.poz3}>Námět</div>}
                                        </div>
                                        <div className={styles.content}>{x.datum}</div>
                                        <div className={styles.comment}>{x.message}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
 
                    </div>
            }
    
        </Card>
    );
};
 
export default Comments;