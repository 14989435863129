import { createSlice } from '@reduxjs/toolkit';

export const objednavky_typERP_OSlice = createSlice({
    name: 'objednavky_typERP_O',
    initialState: {
            rows: [],
            allRows: []
    },
    reducers: {
        objednavky_typERP_ORows: (state, action) => {
            state.rows = action.payload
        },
        objednavky_typERP_OAllRows: (state, action) => {
            state.allRows = action.payload
        },

    },
});

export const { objednavky_typERP_ORows, objednavky_typERP_OAllRows } = objednavky_typERP_OSlice.actions;

export const objednavky_typERP_OData = state => state.objednavky_typERP_Odata;


export default objednavky_typERP_OSlice.reducer;
