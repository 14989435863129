import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Item.module.sass";
import moment from "moment";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";

const Item = ({ className, item, onClose, precitat }) => {
  
  const trailingActions = () => (
    <TrailingActions>
      <SwipeAction
        className={styles.swipe}
        //destructive={true}
        onClick={(e) => {
          precitat([item.id]);
        }}
      >
        <div>Označit jak přečteno</div>
      </SwipeAction>
    </TrailingActions>
  );

  return (
    <SwipeableList>
      <SwipeableListItem trailingActions={trailingActions()}>
        <div
          className={cn(
            styles.item,
            { [styles.new]: item.precitane === undefined },
            className
          )}
        >
          <div className={styles.avatar}>
            <div className={styles.bell}>
              <i class="fa fa-bell"></i>
            </div>
          </div>
          <div className={styles.details}>
            <div className={styles.line}>
              <div className={styles.subtitle}>
                <div>{item.hlavicka}</div>
                <div className={styles.time}>
                  {moment(item.datum).format("LT")}
                </div>
                {/* <div>{item.odosielatel + " -> " + item.stredisko}</div> */}
              </div>
              <div className={styles.time}>
                <div>{moment(item.datum).format("DD.MM")}</div>
              </div>
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: item.sprava }}
            ></div>
          </div>
        </div>
      </SwipeableListItem>
    </SwipeableList>
  );
};

export default Item;
