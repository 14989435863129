import { createSlice } from '@reduxjs/toolkit';

export const tableObjednavkyBBSlice = createSlice({
    name: 'objednavkyBBTable',
    initialState: {
        data: [],
        loading: true,

    },
    reducers: {
        objednavkyBBTable: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingObjednavkyBBTable: (state) => {
            console.log("loading: true")
            state.loading = true
        },
    },
});

export const { objednavkyBBTable, loadingObjednavkyBBTable} = tableObjednavkyBBSlice.actions;

export const objednavkyBBTableData = state => state.objednavkyBBTabledata;


export default tableObjednavkyBBSlice.reducer;