import Modal from "../../Modal";
import Card from "../../Card";
import Icon from "../../Icon";
import Search from "../../Search";
import styles from "./../Modaly.module.sass";
import remove_accents from "remove-accents";
import cn from "classnames";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modal, modalData } from "../redux/modalSlice";
import { search, searchData } from "../../Search/redux/searchSlice";
import {
  zmenaData,
  zmena,
  loadingZmena,
  vybrate,
  setDodaciList,
  setComment,
} from "./redux/zmenaSlice";
import axios from "axios";
import {
  dodavatelUrl,
  exDodSurovinyUrl,
  prijemPolozkyUrl,
} from "../../../features/urlHelper";
import { login, loginData } from "../../../screens/SignIn/redux/loginSlice";
import {
  loadingExternyDodavatelTable,
  externyDodavatelTable,
} from "../../../screens/Prijemky/ModalEX/Table/redux/tableExternyDodavatelSlice";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../../features/errorDialog";
import {
  loadingPrijemOdJinePobockyTable,
  prijemOdJinePobockyTable,
  setZaznamy,
} from "../../../screens/Prijemky/ModalPS/Table/redux/tablePrijemOdJinePobockySlice";

const App = ({
  className,
  url,
  visible,
  setVisible,
  searchValue,
  typ,
  nadpis,
  label,
  field,
}) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const modaldata = useSelector(modalData);
  const searchdata = useSelector(searchData);
  const zmenadata = useSelector(zmenaData);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");

  useEffect(() => {
    dispatch(search({ data: { ...searchdata, searchZmena: "" } }));
    dispatch(loadingExternyDodavatelTable());
    dispatch(externyDodavatelTable([]));
  }, []);

  async function result() {
    await axios
      .get(url, {
        headers: { Authorization: "Bearer " + logindata.Token },
      })
      .then(function (response) {
        const data = response.data.map((item) => ({ ...item, vybrane: false }));
        dispatch(loadingZmena());
        dispatch(zmena(data));
        console.log("zmena", data);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function result2(ico) {
    await axios
      .post(
        exDodSurovinyUrl,
        {
          stredisko: logindata.UserData.vybrane_stredisko.kod,
          ico: ico,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        const data = response.data.map((item) => {
          if (item.zazmluvnene) {
            return { ...item, zazmluvnene_text: "Zasmluvněno" };
          } else {
            return { ...item, zazmluvnene_text: "Nezasmluvněno" };
          }
        });
        let i_zas = data.length + 1;
        let i_nezas = data.length + 1;
        const newData = data.map((item, index) => {
          if (item.zazmluvnene && i_zas > index) {
            i_zas = index;
            return { ...item, nadpis: true };
          }
          if (item.zazmluvnene && i_zas <= index) {
            return { ...item, nadpis: false };
          }
          if (!item.zazmluvnene && i_nezas > index) {
            i_nezas = index;
            return { ...item, nadpis: true };
          }
          if (!item.zazmluvnene && i_nezas <= index) {
            return { ...item, nadpis: false };
          }
        });
        dispatch(loadingExternyDodavatelTable());
        dispatch(externyDodavatelTable(newData));
        console.log("exTable", newData);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function result3(doklad) {
    //console.log(doklad);
    await axios
      .post(
        prijemPolozkyUrl,
        {
          stredisko: logindata.UserData.vybrane_stredisko.kod,
          doklad: doklad,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        let polozky = response.data.polozky.map((row) => ({
          ...row,
          stav: false,
          problem: false,
          skupina_nazov: "0. " + (row.skupina_nazov !== undefined ? row.skupina_nazov : "Nezařazeno") ,
          default_skupina_nazov: "0. " + row.skupina_nazov,
        }));
        dispatch(loadingPrijemOdJinePobockyTable());
        dispatch(prijemOdJinePobockyTable(polozky));
        console.log(polozky);
        dispatch(setComment(response.data.poznamka));
        dispatch(setZaznamy([]));
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <Modal outerClassName={styles.outerZaznamProblemu} visible={visible}>
      <div className={styles.ico}>
        <button
          className={styles.icon}
          onClick={() => {
            dispatch(loadingZmena());
            setVisible(false);
          }}
        >
          <Icon name="close" size="20" />
        </button>
      </div>
      <Card
        bezPaddingu={false}
        className={cn(styles.card, className)}
        title={zmenadata.isVybrate ? "Změnit " + typ : "Vybrat " + typ}
        classTitle="title-red"
      >
        <Search field={"searchZmena"} />
        <br />

        {!visible || (zmenadata.loading && visible) ? (
          <></>
        ) : (
          <div
            style={{ height: window.innerHeight - 280 }}
            className={styles.upperContentScr}
          >
            {/* <button onClick={()=>{console.log(zmenadata.data)}}>test</button> */}
            {zmenadata.data
              .filter((xy) =>
                String(remove_accents(xy[searchValue].toUpperCase())).includes(
                  remove_accents(searchdata.searchZmena.toUpperCase())
                ) ||
                String(remove_accents(xy[nadpis].toUpperCase())).includes(
                  remove_accents(searchdata.searchZmena.toUpperCase())
                )
              )
              .map((x, index) => (
                <div key={index}>
                  <div
                    onClick={() => {
                      console.log(x);
                      let row = {};
                      let row2 = null;
                      let i = 0;
                      let poradie = 0;
                      let poradie_old = 0;

                      zmenadata.data.map((itemik) => {
                        if (x[field] === itemik[field]) {
                          row = { ...itemik };
                          poradie = i;
                        }
                        if (itemik.vybrane) {
                          row2 = { ...itemik };
                          poradie_old = i;
                        }
                        i++;
                      });

                      if (poradie === poradie_old && row2 !== null) return;

                      row.vybrane = true;
                      if (row2 !== null) {
                        row2.vybrane = false;
                      }
                      const tempData = [...zmenadata.data];
                      tempData[poradie] = row;
                      if (row2 !== null) {
                        tempData[poradie_old] = row2;
                      }
                      dispatch(vybrate(true));
                      if (typ === "Dodavatele") result2(row.ico);
                      else if (typ === "Doklad") {
                        //console.log(row);
                        result3(row.doklad);
                      } else result3(row.ico);

                      console.log(tempData)
                      dispatch(zmena(tempData));
                      setVisible(false);
                      clearAllBodyScrollLocks();
                    }}
                    className={styles.itemZmenaDodavatele}
                  >
                    <div className={cn(styles.avatar)}>
                      <img src={"/images/content/kosik.png"} alt="Avatar" />
                    </div>
                    <div
                      className={
                        x.vybrane
                          ? styles.upperContentRightA
                          : styles.upperContentRightP
                      }
                    >
                      <span style={{ fontWeight: "bold" }}>{x[nadpis]}</span>
                      <br />
                      <span className={styles.label_}>
                        {label.map((_label) => {
                          return (
                            <div className={styles.label}>
                              <div className={styles.label}>{_label.label}</div>
                              <div className={styles.label}>
                                {x[_label.field]}
                              </div>
                            </div>
                          );
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </Card>
    </Modal>
  );
};
export default App;
