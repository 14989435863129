import React, { useState } from "react";
import cn from "classnames";
import styles from "./Search.module.sass";
import Icon from "../Icon";
import { useSelector, useDispatch } from "react-redux";
import { search, searchData } from "./redux/searchSlice";

const Search = ({ className, field }) => {
  const dispatch = useDispatch();
  const searchdata = useSelector(searchData);
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div
        className={cn(styles.search, className, { [styles.active]: visible })}
      >
        <div className={styles.head}>
          <button className={styles.start}>
            <Icon name="search" size="24" />
          </button>
          <button className={styles.direction}>
            <Icon name="search" size="24" />
          </button>
          <input
            className={styles.input}
            type="text"
            placeholder="Hledejte"
            value={searchdata[field]}
            onChange={(e) => {
              if (e.target.value === "") {
                setVisible(false);
              } else {
                setVisible(true);
              }
              dispatch(search({data: { ...searchdata ,[field]: e.target.value }}));
            }}
            onClick={() => setVisible(true)}
          />

          <button
            className={styles.close}
            onClick={() => {
              setVisible(false);
              dispatch(search({data: { ...searchdata ,[field]: "" }}));
            }}
          >
            <Icon name="close-circle" size="24" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Search;
