import React, { useState } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Checkbox from "../../../components/Checkbox";
import Loader from "../../../components/Loader";
import Row from "./Row";
import { useSelector, useDispatch } from 'react-redux';
import {
    loginData
} from './../../SignIn/redux/loginSlice';
import {
    ludiaData,
    ludia,
    loadingLudia
} from './../../Home/Ludia/redux/ludiaSlice';


// data
import { customers } from "../../../mocks/customers";

const Table = ({ className, activeTable, setActiveTable }) => {

    //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
    const ludiadata = useSelector(ludiaData);

    const [activeId, setActiveId] = useState(customers[0].id);

    const [selectedFilters, setSelectedFilters] = useState([]);

    const handleChange = (id) => {
        if (selectedFilters.includes(id)) {
            setSelectedFilters(selectedFilters.filter((x) => x !== id));
        } else {
            setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
        }
    };

    return (
        <div className={cn(styles.wrapper, className)}>
            <div className={cn(styles.table)}>
                <div className={cn(styles.row, { [styles.active]: activeTable })}>
                    <div className={styles.col}>
                        {/*<Checkbox*/}
                        {/*    className={styles.checkbox}*/}
                        {/*    value={chooseAll}*/}
                        {/*    onChange={() => setСhooseAll(!chooseAll)}*/}
                        {/*/>*/}
                    </div>
                    <div className={styles.col}>Meno</div>
                    <div className={styles.col}>Čas</div>
                    <div className={styles.col}>Poč. hodín</div>
                    <div className={styles.col}>Plan</div>
                    <div className={styles.col}>Typ</div>
                    <div className={styles.col}>Kompetence</div>
                </div>
                {ludiadata.data.zamestnanci.map((x, index) => (
                    <Row
                        id={index}
                        item={x}
                        key={index}
                        activeTable={activeTable}
                        setActiveTable={setActiveTable}
                        activeId={activeId}
                        setActiveId={setActiveId}
                        value={selectedFilters.includes(x.id)}
                        onChange={() => handleChange(x.id)}
                    />
                ))}

            </div>
        </div>
    );
};

export default Table;
