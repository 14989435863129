import React, { useState } from "react";
import cn from "classnames";
import styles from "./ProTips.module.sass";
import Card from "../../../components/Card";
import ModalPreview from "../../../components/ModalPreview";
import Switch from "./switch";

const tips = [
    {
        title: "Bageterie Boulevard",
        icon: "/images/content/logo.png",
        statusColor: "purple",
        status: true,
        action: "3 mins read",
    },
    {
        title: "Wolt",
        icon: "/images/content/wolt.png",
        statusText: "Small label",
        status: false,
        action: "Time",
    },
    {
        title: "Bolt",
        icon: "/images/content/bolt.png",
        action: "2 mins read",
        status: false,
    },
    {
        title: "Dame Jidlo",
        icon: "/images/content/dj.png",
        statusColor: "red",
        statusText: "Hot",
        action: "3 mins read",
        status: true,
    },
 
];

const ProTips = ({ className }) => {
    const [visibleModalPreview, setVisibleModalPreview] = useState(false);

    return (
        <>
            <Card
            bezPaddingu={false}
                className={cn(styles.card, className)}
                title="Rozvozy"
                classTitle="title-green"
            >
                <div className={styles.tips}>

                    <div className={styles.list}>
                        {tips.map((x, index) => (
                            <div
                                className={styles.item}
                                key={index}
                               
                            >
                                <div className={styles.icon}>
                                    {x.title === "Bageterie Boulevard" && <img src={x.icon} className={styles.img1} />}
                                    {x.title === "Wolt" && <img src={x.icon} className={styles.img2} />}
                                    {x.title === "Bolt" && <img src={x.icon} className={styles.img3} />}
                                    {x.title === "Dame Jidlo" && <img src={x.icon} className={styles.img4} />}
                                    
                                </div>
                                <div className={styles.details}>

                                    <div className={styles.line}>
                                        <div className={styles.title}>{x.title}</div>
                                        <div className={styles.poz1}><Switch isOn={x.status} onColor="#F1B600" /></div>
                                    </div>

                                    <div className={styles.line}>
                                        <div className={styles.user}>
                                            <div className={styles.action}>{x.action}</div>
                                        </div>
                                        <div className={styles.user}>
                                            <div className={styles.action}>{x.action}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Card>
            <ModalPreview
                visible={visibleModalPreview}
                onClose={() => setVisibleModalPreview(false)}
                video="/images/content/video.mp4"
                title="Use guidelines"
            />
        </>
    );
};

export default ProTips;
