import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import { RootState } from "../../redux/store";
import styles from "./Moduly.module.sass";
import { loginData, login } from "../SignIn/redux/loginSlice";
import Card from "../../components/Card";
import { Padding } from "@mui/icons-material";

function MenuInfo() {
  const navigate = useHistory();
  const dispatch = useDispatch();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");

  function getGrid() {
    if (window.innerWidth >= 825) {
      return "repeat(3, 1fr)";
    } else if (window.innerWidth >= 480) {
      return "repeat(2, 1fr)";
    } else {
      return "repeat(1, 1fr)";
    }
  }

  return (
    <div style={{ height: window.innerHeight }}>
      <Card>
        <div className={styles.title_x}>
          <div className={styles.title}>
            <h2>Vyberte modul pro přihlášení</h2>
          </div>
          <div className={styles.x_}>
            <div
            className={styles.x}
              onClick={() => {
                navigate.push("/");
                //dispatch(login({ data: {} }));
                window.sessionStorage.setItem("logindata", JSON.stringify({}));
              }}
            >
              <i class="fa fa-close"></i>
            </div>
          </div>
        </div>

        <div
          className={styles.overlay}
          style={{
            display: "grid",
            gridTemplateColumns: getGrid(),
          }}
        >
          {logindata.UserData.moduly.map((modul, index) => {
            return (
              <div
                key={index}
                className={styles.button}
                onClick={() => {
                  let data = { ...logindata };
                  let userData = { ...logindata.UserData };
                  let vyb_str = { ...userData.vybrane_stredisko };

                  let vybrane_stredisko = {
                    ...vyb_str,
                    nazov: modul.nazov,
                    kod: modul.kod,
                  };

                  userData.vybrane_stredisko = vybrane_stredisko;
                  data.UserData = userData;
                  console.log(data);

                 //dispatch(login({ data: data }));
                 window.sessionStorage.setItem("logindata", JSON.stringify(data))

                  navigate.push("/prehled");
                }}
              >
                {modul.nazov + " (" + modul.kod + ")"}
              </div>
            );
          })}
        </div>
      </Card>
    </div>
  );
}

export default MenuInfo;
