import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Dropdown.module.sass";
import Checkbox from "../../../components/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../../components/Icon";
import Icons from "../../../components/isons";
import {
  pickerTagyObjednavky_typERP_O,
  pickerTagyObjednavky_typERP_OData,
} from "./redux/PickerTagyObjednavky_typERP_OSlice";
import { login, loginData } from "../../SignIn/redux/loginSlice";
import axios from "axios";
import { putTagyUrl } from "../../../features/urlHelper";
import remove_accents from "remove-accents";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../../features/errorDialog";
import { ClickAwayListener } from "@mui/base";

const Dropdown = ({
  className,
  classDropdownHead,
  options,
  value,
  small,
  upBody,
  setValue,
}) => {
  const [visible, setVisible] = useState(false);
  const [visiblePridat, setVisiblePridat] = useState(false);
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const pickerTagyObjednavky_typERP_Odata = useSelector(
    pickerTagyObjednavky_typERP_OData
  );
  const [tag, setTag] = useState("");
  const [id, setId] = useState(0);
  const [color, setColor] = useState("transparent");

  return (
    <>
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          onClick={() => {
            setVisible(!visible);
          }}
        >
          <Icon className={styles.icon} name="filter" size="24" />
        </div>
        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
          <div className={styles.header_plus_tagy}>
            <div className={styles.tools}>
              <div
                className={styles.textButton}
                onClick={() => {
                  let tagy = pickerTagyObjednavky_typERP_Odata.tagy.map(
                    (row) => ({
                      ...row,
                      vybrane: true,
                    })
                  );
                  dispatch(pickerTagyObjednavky_typERP_O(tagy));
                }}
              >
                Vybrat všechno <i class="fa fa-check"></i>
              </div>
              <div
                className={styles.textButton}
                onClick={() => {
                  let tagy;
                  // eslint-disable-next-line no-lone-blocks
                  {
                    pickerTagyObjednavky_typERP_Odata.tagy === undefined
                      ? (tagy = [])
                      : (tagy = pickerTagyObjednavky_typERP_Odata.tagy.map(
                          (row) => ({
                            ...row,
                            vybrane: false,
                          })
                        ));
                  }

                  dispatch(pickerTagyObjednavky_typERP_O(tagy));
                }}
              >
                Odznačit všechno <i class="fa fa-close"></i>
              </div>
            </div>
            <div className={styles.tagy}>
              {options.map((x, index) => {
                return (
                  <div
                    className={cn(styles.option, {
                      [styles.selectioned]: x.text === value,
                    })}
                    onClick={() => {
                      setValue(x.text);
                    }}
                    key={index}
                  >
                    <div
                      onClick={() => {
                        setVisiblePridat(true);
                        setColor(x.farba);
                        setTag(x.text);
                        setId(x.id);
                      }}
                      className={styles.pen}
                    >
                      <Icons name="pen" viewBox="0 0 24 24" size={"20"} />
                    </div>

                    <Checkbox
                      value={x.vybrane}
                      onChange={(e) => {
                        let poradie = 0;
                        let new_tag = {};
                        const tagy = [];
                        pickerTagyObjednavky_typERP_Odata.tagy.map(
                          (tag, index) => {
                            if (x.id === tag.id) {
                              poradie = index;
                              new_tag = { ...tag };
                              new_tag.vybrane = e.target.checked;
                              tagy.push(new_tag);
                            } else {
                              tagy.push(tag);
                            }
                          }
                        );
                        dispatch(pickerTagyObjednavky_typERP_O(tagy));
                      }}
                    />
                    <div className={styles.text}>
                      {x.text}{" "}
                      <div className={styles.icons_farba}>
                        <div
                          style={{
                            color: "white",
                            width: 20,
                            height: 20,
                            borderRadius: 4,
                            backgroundColor: x.farba,
                            marginTop: 2,
                            marginLeft: 5,
                          }}
                        ></div>
                        <div
                          onClick={async () => {
                            await axios
                              .delete(
                                putTagyUrl + "/" + x.id,
                                {
                                  headers: {
                                    Authorization: "Bearer " + logindata.Token,
                                  },
                                }
                              )
                              .then(function (response) {
                                const tagy =
                                  pickerTagyObjednavky_typERP_Odata.tagy.filter(
                                    (tag) => tag.id !== x.id
                                  );
                                dispatch(pickerTagyObjednavky_typERP_O(tagy));
                              })
                              .catch(function (error) {
                                errorHandleDialog(
                                  error.response,
                                  navigate,
                                  dispatch
                                );
                              });
                          }}
                          className={styles.trash}
                        >
                          <Icon name="trash" size={"20"} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className={styles.footer}>
            <div
              className={styles.textButton}
              name="create"
              onClick={() => {
                // let tagy = pickerTagyObjednavky_typERP_Odata.tagy.map((row) => ({
                //   ...row,
                //   vybrane: true,
                // }));
                // dispatch(pickerTagyObjednavky_typERP_O(tagy));
                setVisiblePridat(true);
                setId(0);
              }}
            >
              <i class="fa fa-plus"></i> Vytvořit
            </div>
          </div>
        </div>
      </div>
      <ClickAwayListener
        onClickAway={(e) => {
          //console.log(e)
          if (" Vytvořit" !== e.srcElement.innerText) {
            setVisiblePridat(false);
          }
        }}
      >
        <div
          className={cn(styles.pridat, className, {
            [styles.active]: visiblePridat,
          })}
        >
          <div className={styles.bodyPridat}>
            <div className={styles.upperLine}>
              <div>Přidat Tag</div>
              <div className={styles.colorSquar}>
                <div className={styles[color]}></div>
              </div>
            </div>
            <input
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
              }}
              className={styles.inputik}
            ></input>
            <div className={styles.line}>
              <div
                className={styles.colorSquar}
                onClick={() => {
                  setColor("red");
                }}
              >
                <div className={styles.red}></div>
              </div>
              <div
                className={styles.colorSquar}
                onClick={() => {
                  setColor("green");
                }}
              >
                <div className={styles.green}></div>
              </div>
              <div
                className={styles.colorSquar}
                onClick={() => {
                  setColor("yellow");
                }}
              >
                <div className={styles.yellow}></div>
              </div>
              <div
                className={styles.colorSquar}
                onClick={() => {
                  setColor("blue");
                }}
              >
                <div className={styles.blue}></div>
              </div>
              <div
                className={styles.colorSquar}
                onClick={() => {
                  setColor("brown");
                }}
              >
                <div className={styles.brown}></div>
              </div>
              <div
                className={styles.colorSquar}
                onClick={() => {
                  setColor("pink");
                }}
              >
                <div className={styles.pink}></div>
              </div>
            </div>
            <div
              onClick={async () => {
                await axios
                  .put(
                    putTagyUrl,
                    {
                      id: id,
                      stredisko:
                        logindata.UserData.vybrane_stredisko !== undefined
                          ? logindata.UserData.vybrane_stredisko.kod
                          : null,
                      ico:
                        logindata.UserData.ico !== undefined
                          ? logindata.UserData.ico
                          : null,
                      text: tag,
                      farba: color,
                    },
                    {
                      headers: { Authorization: "Bearer " + logindata.Token },
                    }
                  )
                  .then(function (response) {
                    if (id === 0) {
                      let tagy = [...pickerTagyObjednavky_typERP_Odata.tagy];
                      tagy.push({
                        id: response.data,
                        stredisko:
                          logindata.UserData.vybrane_stredisko !== undefined
                            ? logindata.UserData.vybrane_stredisko.kod
                            : null,
                        ico:
                          logindata.UserData.ico !== undefined
                            ? logindata.UserData.ico
                            : null,
                        text: tag,
                        vybrane: false,
                        farba: color,
                      });
                      tagy.sort(function (a, b) {
                        const textA = remove_accents(a.text.toUpperCase());
                        const textB = remove_accents(b.text.toUpperCase());
                        if (textA < textB) {
                          return -1;
                        }
                        if (textA > textB) {
                          return 1;
                        }
                        return 0;
                      });
                      dispatch(pickerTagyObjednavky_typERP_O(tagy));
                      setVisiblePridat(false);
                      setColor("transparent");
                      setTag("");
                      setId(0);
                    } else {
                      let tags = [...pickerTagyObjednavky_typERP_Odata.tagy];
                      for (let i = 0; i < tags.length; i++) {
                        if (tags[i].id === id) {
                          tags[i] = {
                            farba: color,
                            id: id,
                            vybrane: false,
                            text: tag,
                          };
                          break;
                        }
                      }
                      dispatch(pickerTagyObjednavky_typERP_O(tags));
                      setVisiblePridat(false);
                      setColor("transparent");
                      setTag("");
                      setId(0);
                    }
                  })
                  .catch(function (error) {
                    errorHandleDialog(error.response, navigate, dispatch);
                  });
              }}
              className={styles.check}
            >
              <i class="fa fa-check"></i>
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default Dropdown;
