import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import {
  modalData,
  modal,
} from "../../../../../components/Modaly/redux/modalSlice";
import axios from "axios";
import { formRunUrl } from "../../../../../features/urlHelper";
import {
  Form,
  loadingForm,
  FormData,
} from "../../../../../components/Modaly/ModalForm/redux/FormSlice";
import { errorHandleDialog } from "../../../../../features/errorDialog";
import { loginData } from "../../../../SignIn/redux/loginSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Row = ({
  id,
  item,
  value,
  onChange,
  activeTable,
  setActiveTable,
  activeId,
  setActiveId,
  setVisibleForm,
  setFormName,
}) => {
  const dispatch = useDispatch();
  const modaldata = useSelector(modalData);
  const Formdata = useSelector(FormData);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const navigate = useHistory();
  async function call_form(volba, data = null) {
    await axios
      .post(
        formRunUrl,
        {
          formName: "ZaznamProblemu",
          formValues: data,
          volba: volba,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        if (response.data.typ === "route") {
          navigate.push(response.data.url);
        } else if (response.data.typ === "enter") {
          console.log("enterData", response.data.enterData);
          dispatch(loadingForm());
          setFormName(response.data.enterData.formName);
          dispatch(Form(response.data.enterData));
          document
            .getElementsByName(
              "varpol4"
            )[0]
            .focus();
        }
        console.log(response);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }
  return (
    <>
      <div className={cn(styles.row)}>
        <div className={styles.col}>
          <div className={styles.item}>
            {window.innerWidth > 500 ? (
              <div className={styles.avatar}>
                <img src="/images/content/bageta.png" alt="Avatar" />
              </div>
            ) : (
              <></>
            )}

            <div>
              <span style={{ fontSize: 12 }} className={styles.nazov}>
                {item.nazov}
              </span>
              <br />
              <span
                style={{
                  color: "#C9C9C9",
                  fontSize: 12,
                }}
              >
                {item.kod}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          {item.zaslane !== item.objednane ? (
            <div className={styles.email}>
              <span style={{ color: "red" }}>{item.zaslane}!</span>
              <span>/{item.objednane}</span>
            </div>
          ) : (
            <div className={styles.email}>
              {item.zaslane}/{item.objednane}
            </div>
          )}
        </div>
        <div className={styles.col}>
          {item.prijate !== item.zaslane ? (
            <div style={{ color: "red" }} className={styles.email}>
              {item.prijate}
            </div>
          ) : (
            <div className={styles.email}>{item.prijate}</div>
          )}
        </div>

        {window.innerWidth > 700 ? (
          <div className={styles.col}>
            <div style={{ fontSize: 12 }}>{item.komentar}</div>
          </div>
        ) : (
          <></>
        )}
        <div className={styles.col1}>
          <div
            onClick={() => {
              {
                setVisibleForm(true);

                var varpol1 = {
                  ...Formdata.data.values.varpol1,
                  value: item.kod,
                  selectorLabel: item.nazov,
                };
                var varpol2 = {
                  ...Formdata.data.values.varpol2,
                  value: item.zaslane,
                };
                var varpol3 = {
                  ...Formdata.data.values.varpol3,
                  value: item.mj,
                };
                var varpol4 = {
                  ...Formdata.data.values.varpol4,
                  value: 0,
                };
                var varpol5 = {
                  ...Formdata.data.values.varpol5,
                  value: item.mj,
                };
                var varpol6 = {
                  ...Formdata.data.values.varpol6,
                  value: "",
                };
                var varpol7 = {
                  ...Formdata.data.values.varpol7,
                  value: "",
                };
                var varpol8 = {
                  ...Formdata.data.values.varpol8,
                  value: false,
                };
                var varpol9 = {
                  ...Formdata.data.values.varpol9,
                  value: "https://www.crocodille.com/",
                };
                var data = {
                  varpol1: varpol1,
                  varpol2: varpol2,
                  varpol3: varpol3,
                  varpol4: varpol4,
                  varpol5: varpol5,
                  varpol6: varpol6,
                  varpol7: varpol7,
                  varpol8: varpol8,
                  varpol9: varpol9,
                };
                call_form("edit", data);
              }
            }}
            className={styles.avatar2}
          >
            <img
              src={
                item.stav1
                  ? "/images/content/red_cirle.png"
                  : "/images/content/gray_n.png"
              }
              alt="Avatar"
            />
          </div>
          <div className={styles.avatar2}>
            <img
              src={
                item.stav2
                  ? "/images/content/green_cirle.png"
                  : "/images/content/gray_y.png"
              }
              alt="Avatar"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Row;
