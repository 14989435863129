export function getLicensePro () {
  return 'cf60e93f84b10daf2131a12c0554dad4T1JERVI6MzU5NTEsRVhQSVJZPTE2NzM3ODUzNzgwMDAsS0VZVkVSU0lPTj0x'
}

// do 21.10.2024
// export function getLicensePremium () {
//   return 'a2f5a788be74863d346b114b7246b7e9Tz03NjkzMyxFPTE3MjkzMjI0OTYwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
// }

//od 21.10.2024
export function getLicensePremium () {
  return 'bb2ad8e6514eaadf0e19a5843f66813eTz0xMDAzNjEsRT0xNzYxMTIwMTcwMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y'
}