import Modal from "../../components/Modal";
import Card from "../../components/Card";
import Icon from "../../components/Icon";
import styles from "./MalaInventura.module.sass";
import cn from "classnames";
import Search from "../../components/Search";
import Table from "./Table";
import React, { useState } from "react";

const App = ({ className }) => {
  return (
    <div>
      <Card
        bezPaddingu={false}
        title={"Malá Inventura"}
        classTitle="title-green"
        head={
          <div className={styles.headModal}>
            <button
              className={
                window.innerWidth > 700
                  ? cn("button", styles.headModalButton)
                  : styles.headModalButtons
              }
            >
              {window.innerWidth > 700 ? (
                <>
                  <Icon name="check" size="24" />
                  Dokončit
                </>
              ) : (
                <Icon name="check" size="24" />
              )}
            </button>
          </div>
        }
      >
        {/* <Search field={"searchMalaInventura"} className={styles.searchHead} />
         <br/> */}

        <Table />
      </Card>
    </div>
  );
};
export default App;
