import Card from "../../components/Card";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Search from "../../components/Search";
import Table from "./Table";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  pdfUrl,
  prevodSurovinPostUrl,
  prevodSurovinUrl,
  zoznamAktivitUrl,
} from "../../features/urlHelper";
import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../features/errorDialog";

const App = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    result();
  }, []);

  async function result() {
    await axios
      .post(
        zoznamAktivitUrl,
        { username: logindata.UserData.username },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log("users_activity", response.data);
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <Card bezPaddingu={false}>
      <div
        style={{
          height: window.innerHeight - (window.innerWidth < 767 ? 74 : 178),
        }}
      >
        <Search field={"searchUsersActivity"} className={styles.searchHead} />
        <br />
        <div className={cn(styles.row)}>
          <Table className={styles.table} data={data} loading={loading} />
        </div>
      </div>
    </Card>
  );
};
export default App;
