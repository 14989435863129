import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    value: {
      ZobrazHtml2: false,
      Html2Data: "",
      ZobrazHtml: false,
      HtmlData: "",
      ZobrazHtml_print: "",
      ZobrazHtml_text: "",
      ZobrazHtml_title: "",
      ZobrazPdf: false,
      PdfData: "",
      ExNaPs: false,
      MalaInventura: false,
      PridatSurovinu: false,
      PrijemSurovin: false,
      Reklamace: false,
      ZaznamProblemu: false,
      ZmenaDodavatela: false,
      ZmenaDokladu: false,
      ZmenaPobocky: false,
      NabidnoutPobocke: false,
      PridatSurovinuNP: false,
      StavSkladu: false,
      PrevodSurovin: false,
      ZobrazHtml: false,
      EditTagovPolozky: false,
      EditTagovPolozkyID: 0,
      EditTagovPolozkyType: "",
      Vyjimka: false,
      VyjimkaDate: {
        id: null,
        otvor_on: true,
        rozvoz_on: true,
        datum_od: new Date(),
        datum_do: new Date(),
        otvor_od: null,
        otvor_do: null,
        rozvoz_od: null,
        rozvoz_do: null,
      },
      TrvalaZmena: false,
      TrvalaZmenaDate: {
        id: null,
        datum_od: null,
        dni: [
          {
            dayOfWeek: 1,
            otvor_od: null,
            otvor_do: null,
            rozvoz_od: null,
            rozvoz_do: null,
          },
          {
            dayOfWeek: 2,
            otvor_od: null,
            otvor_do: null,
            rozvoz_od: null,
            rozvoz_do: null,
          },
          {
            dayOfWeek: 3,
            otvor_od: null,
            otvor_do: null,
            rozvoz_od: null,
            rozvoz_do: null,
          },
          {
            dayOfWeek: 4,
            otvor_od: null,
            otvor_do: null,
            rozvoz_od: null,
            rozvoz_do: null,
          },
          {
            dayOfWeek: 5,
            otvor_od: null,
            otvor_do: null,
            rozvoz_od: null,
            rozvoz_do: null,
          },
          {
            dayOfWeek: 6,
            otvor_od: null,
            otvor_do: null,
            rozvoz_od: null,
            rozvoz_do: null,
          },
          {
            dayOfWeek: 7,
            otvor_od: null,
            otvor_do: null,
            rozvoz_od: null,
            rozvoz_do: null,
          },
        ],
      },
    },
  },
  reducers: {
    modal: (state, action) => {
      console.log(action.payload);
      state.value = action.payload.data;
    },
  },
});

export const { modal } = modalSlice.actions;

export const modalData = (state) => state.modaldata.value;

export default modalSlice.reducer;
