import Card from "../../../components/Card";

import React, { useEffect, useState } from "react";

import Menu from "../../../components/Menu"

import styles from "./Modaly.module.sass"


const App = ({ className }) => {



  return (
    <div className={styles.overlay}>
      <Menu typ="manazerske&volby" />
    </div>
  );
};
export default App;
