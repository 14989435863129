import { createSlice } from '@reduxjs/toolkit';

export const pocasieSlice = createSlice({
    name: 'pocasie',
    initialState: {
        data: [],
        loading: true,
    },
    reducers: {
        pocasie: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingPocasie: (state) => {
            console.log("loading: true")
            state.loading = true
        }

    },
});

export const { pocasie, loadingPocasie } = pocasieSlice.actions;

export const pocasieData = state => state.pocasiedata;


export default pocasieSlice.reducer;

