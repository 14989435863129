import { createSlice } from '@reduxjs/toolkit';

export const pickerTagyObjednavky_typERP_OSlice = createSlice({
    name: 'pickerTagyObjednavky_typERP_O',
    initialState: {
        tagy: [],
    },
    reducers: {
        pickerTagyObjednavky_typERP_O: (state, action) => {
            console.log("data tagy", action.payload)
            state.tagy = action.payload
        },

    },
});

export const { pickerTagyObjednavky_typERP_O } = pickerTagyObjednavky_typERP_OSlice.actions;

export const pickerTagyObjednavky_typERP_OData = state => state.pickerTagyObjednavky_typERP_Odata;


export default pickerTagyObjednavky_typERP_OSlice.reducer;
