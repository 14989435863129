import React, { useState } from "react";
import cn from "classnames";
import styles from "./Users.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../../components/Icon";
import Icon2 from "../../../../components/WeatherIcon";


const Users = ({ className, data, scrollBar }) => {


    return (
        <div className={cn(styles.users, className)}>
       
            <div className={styles[scrollBar]}>
                {data?.map((x, index) => (
                    <div className={styles.item} key={index} to={x.url}>
                        <div className={styles.avatar}>
                            {/*<img src={"/images/content/person.png"} alt="Avatar" />*/}
                            <Icon2 name={"person"} alt="Avatar" size="120" />
                        </div>
                        <div className={styles.name}>{x.meno}</div>
                        <div className={styles.cas}>{x.cas}</div>
                        <div className={styles.post}>{x.typ}</div>
                    </div>
                ))}
                <div className={styles.all} to="/prehled/zamestnanci">
                    <div className={styles.icon}>
                        <Icon name="arrow-right" size="24" />
                    </div>
                    <div className={styles.text}>Všichni</div>
                </div>
            </div>
        </div>
    );
};

export default Users;
