import React, { useEffect, useState, useRef } from "react";
import Icon from "../../Icon";
import Modal from "../../Modal";
import styles from "./Modal.module.sass";
import {
  DataGridPremium,
  LicenseInfo,
  csCZ,
  GridRowModes,
} from "@mui/x-data-grid-premium";
//import {  } from "@mui/x-license-premium";
import { getLicensePremium } from "../../../features/License";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "dayjs/locale/cs";
import "moment/locale/cs";

import useDarkMode from "@fisch0920/use-dark-mode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import swal from "sweetalert";
import { INDEXEDDB } from "localforage";
import LoadingOverlay from "react-loading-overlay-nextgen";
import ModalKosikEdit from "../ModalKosikEdit";
import { areArraysEqual } from "../../../features/areArraysEqual";
import { search, searchData } from "../../Search/redux/searchSlice";
import { useDispatch, useSelector } from "react-redux";
import { Hidden } from "@mui/material";
import { RestaurantMenu } from "@mui/icons-material";
import Checkbox from "../../../components/Radio";
import Select from "react-select";
import { validateNumberWithMaxDecimals } from "../../../features/validateNumberWithMaxDecimals";
import {
  jeSurovinaStrediskaUrl,
  zapisRieseniaReklamaceUrl,
} from "../../../features/urlHelper";
import axios from "axios";
import { errorHandleDialog } from "../../../features/errorDialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const App = ({
  className,
  visible,
  setVisible,
  layoutText,
  setLayoutText,
  layoutActive,
  setLayoutActive,
  result,
  row,
  setLoading,
  jeSurovinaStrediska,
}) => {
  LicenseInfo.setLicenseKey(getLicensePremium());

  const darkMode = useDarkMode(false);
  const dispatch = useDispatch();
  const navigate = useHistory();
  const elementRef = useRef(null);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );

  const [rowGroupingModel, setRowGroupingModel] = React.useState([
    "typ_polozky",
  ]);
  const [newMnozstvo, setNewMnozstvo] = useState(0);
  const [id_editable, setId_editable] = React.useState(null);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [visible_ModalEdit, setVisible_ModalEdit] = useState(false);
  const [row_ModalEdit, setRow_ModalEdit] = useState({});
  const searchdata = useSelector(searchData);

  const [uznana, setUznana] = useState(0);
  const [uznana_mn, setUznana_mn] = useState(0);
  const [poznamka, setPoznamka] = useState("");
  const [uznana_typ, setUznana_typ] = useState(null);

  useEffect(() => {
    setUznana_typ(null);
    setUznana_mn(0);
    setPoznamka("");
  }, [visible, uznana]);

  useEffect(() => {
    setUznana_typ(null);
    setUznana(0);
    setUznana_mn(0);
    setPoznamka("");
  }, [visible]);

  function options() {
    switch (row.typ_reklamace) {
      case 0:
        return [
          {
            label:
              "Odpis na ZMT – položka se ztratila nebo poničila na skladě či cestou",
            value: "REKL/ODPIS",
          },
          {
            label:
              "Přeskladnit na ZMT – položka se našla nebo se zpracuje jinak",
            value: "INVENTURA",
          },
        ];
      case 1:
        if (jeSurovinaStrediska) {
          return [
            {
              label:
                "Odpis na ZMT – položka se ztratila nebo poničila na skladě či cestou",
              value: "REKL/ODPIS",
            },
            {
              label:
                "Přeskladnit na ZMT – položka se našla nebo se zpracuje jinak",
              value: "INVENTURA",
            },
          ];
        } else {
          return [
            {
              label:
                "Odpis na ZMT – položka se ztratila nebo poničila na skladě či cestou",
              value: "REKL/ODPIS",
            },
            {
              label:
                "Přeskladnit na ZMT – položka se našla nebo se zpracuje jinak",
              value: "INVENTURA",
            },
            {
              label:
                "Převod na středisko výroby – položka vyrobena ve špatné kvalitě",
              value: "REKL/VYR",
            },
          ];
        }

      case 2:
        return [
          {
            label: "Vytvoření objednávky REKL/OBJ",
            value: "REKL/OBJ",
          },
        ];
      default:
        return [];
    }
  }
  //0=nizsi mnozstvi, 1=kvalita, 2=vyssi mnozstvi

  const darkTheme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
      mode: darkMode.value ? "dark" : "light",
    },
  });

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#F1B600" },
      },
    },
    csCZ
  );

  function getWidth(width = 760) {
    var width = window.innerWidth > 780 ? 780 : window.innerWidth;
    if (width > 2500) {
      console.log(width - 388);
      return width - 388;
    } else if (width < 2500 && width > 767) {
      return width - 52;
    } else {
      return width - 52;
    }
  }

  function dajVysku(height) {
    if (window.innerWidth > 767) {
      return height - 178;
    } else {
      return height - 130;
    }
  }

  const stylingTable = {
    border: 0,

    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      color: darkMode.value ? "white" : "#666d74",
      borderRight: `1px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .MuiDataGrid-overlay": {
      backgroundColor: "transparent",
      color: darkMode.value ? "white" : "#ACACAC",
    },
    "& .MuiInputBase-input": {
      color: darkMode.value ? "white" : "#ACACAC",
    },
    "& .MuiSvgIcon-root": {
      color: "#a7acaf",
    },
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
      "& .MuiInputBase-root": {
        height: "100%",
      },
    },
    "& .MuiDataGrid-cell": {
      padding: 0.5,
    },
    "& .MuiDataGrid-withBorderColor": {
      borderColor: "transparent",
    },
    "& .Mui-error": {
      backgroundColor: "red",
      color: "white",
    },
    width: getWidth(window.innerWidth),
    height: 100,
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
  };

  const columns = [
    {
      field: "polozka",
      hideable: false,
      headerName: "Kód",
      minWidth: 120,
      width: (getWidth() - 60) * 0.2,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <div className={styles.cell_center}>{params?.value}</div>;
      },
    },
    {
      field: "nazov",
      hideable: false,
      headerName: "Název",
      minWidth: 120,
      width: (getWidth() - 60) * 0.4,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <div className={styles.cell_center}>{params?.value}</div>;
      },
    },
    {
      field: "mj",
      headerName: "MJ",
      minWidth: 55,
      width: (getWidth() - 60) * 0.1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <div className={styles.cell_center}>{params.value}</div>;
      },
    },
    {
      field: "objem",
      headerName: "Množství",
      minWidth: 100,
      width: (getWidth() - 60) * 0.2,
      headerAlign: "center",
      renderCell: (params) => {
        const valueFormatted = Number(params.value);

        const value = valueFormatted
          .toFixed(params.row.des_mista)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");
        return <div className={styles.cell_center}>{value}</div>;
      },
    },
    {
      field: "vyr_datum",
      headerName: "Datum spotřeby",
      minWidth: 120,
      width: (getWidth() - 60) * 0.1,
      headerAlign: "center",
      renderCell: (params) => {
        return <div className={styles.cell_center}>{params.value}</div>;
      },
    },
  ];

  function back() {
    setVisible(false);
  }

  function typReklamaceNazov(typ_reklamace) {
    switch (typ_reklamace) {
      case 0:
        return "Dodáno nižší množství";
      case 1:
        return "Špatná kvalita – položku zasílám fyzicky zpět na ZMT";
      case 2:
        return "Dodáno vyšší množství (položku si ponechám)";
    }
  }

  async function riesenie() {
    //validovanie povinnych polí
    if (uznana === 0) {
      swal("Chyba!", "Není vyplněno pole uznání reklamace!", "error");
      return;
    }
    if (uznana === 0 && poznamka === "") {
      swal("Chyba!", "Není vyplněno pole komentář!", "error");
      return;
    }
    switch (uznana) {
      case 1:
        if (uznana_mn === 0) {
          swal("Chyba!", "Není vyplněno pole uznané množství!", "error");
          return;
        }
        if (uznana_mn > row.objem) {
          swal(
            "Chyba!",
            "Zadali jste vyšší množství, než je reklamované množství!",
            "error"
          );
          return;
        }
        if (uznana_typ === null) {
          swal(
            "Chyba!",
            "Není vyplněno pole způsob uzavření reklamace!",
            "error"
          );
          return;
        }
        break;
    }
    swal({
      content: {
        element: "div",
        attributes: {
          innerHTML:
            uznana === 2
              ? '</div style="font-weight: 300;"> <div style="color: green;">Opravdu chcete uzavžít reklamaci</div> <div style="color: green;">jako neuznanou?</div></div>'
              : '</div style="font-weight: 300;"> <div style="color: green;">Opravdu chcete uzavžít reklamaci</div> <div style="color: green;">jako uznanou?</div></div>',
        },
      },
      icon: "info",
      buttons: ["Ne ", "Ano"],
    }).then(async (willShow) => {
      if (willShow) {
        await axios
          .post(
            zapisRieseniaReklamaceUrl,
            {
              poznamka: poznamka,
              objem_uznany: uznana_mn,
              objem: row.objem,
              typ_reseni: uznana,
              res_wms_typ: uznana_typ !== null ? uznana_typ.value : "",
              //stredisko: logindata.UserData.vybrane_stredisko !== undefined ? logindata.UserData.vybrane_stredisko.kod : null,
              odberatel:
                logindata.UserData.ico !== undefined
                  ? logindata.UserData.ico
                  : null,
              reklamace: row.reklamace,
            },
            {
              headers: { Authorization: "Bearer " + logindata.Token },
            }
          )
          .then(function (response) {
            setVisible(false);
            setLoading(true);
            result();
          })
          .catch(function (error) {
            errorHandleDialog(error.response, navigate, dispatch);
          });
      }
    });
  }

  return (
    <>
      <Modal
        widthModal={window.innerWidth > 780 ? 780 : window.innerWidth}
        outerClassName={styles.outerZaznamProblemu}
        visible={visible}
      >
        <LoadingOverlay
          active={layoutActive}
          className={styles.LoadingOverlay}
          spinner
          text={"Načítám reklamaci"}
        >
          <div style={{ width: "100%" }}>
            <div name={"ico_title"} className={styles.ico_Title}>
              <div className={styles.btn}></div>
              <div>
                Řešení reklamace {row.reklamace}
                {/* {kosikZmena ? "Změněná" : "Nová"} objednávka na {validnyDatum()}{" "}
                ({denVTydnu()}) */}
              </div>
              <button
                className={styles.btn}
                onClick={() => {
                  setVisible(false);
                }}
              >
                <Icon name="close" size="20" />
              </button>
            </div>
            {row.reklamace === undefined ? (
              <div
                style={{
                  height: 580,
                }}
              ></div>
            ) : (
              <div
                style={{
                  height: 580,
                  padding: 16,
                }}
                className={styles.content}
                ref={elementRef}
                id="element"
              >
                <div className={styles.line}>
                  Informace o reklamaci: {row.reklamace}
                </div>
                <div className={styles.detail}>
                  <div className={styles.detail_l}>
                    <div>Typ reklamace:</div>
                    <div>Datum reklamace:</div>
                    <div>Odběratel:</div>
                    <div>Zadavatel:</div>
                    <div>Komentář:</div>
                  </div>
                  <div className={styles.detail_r}>
                    <div>{typReklamaceNazov(row.typ_reklamace)}</div>
                    <div>{moment(row.datum).format("DD.MM.YYYY HH:mm")}</div>
                    <div>{row.odberatel_nazov}</div>
                    <div>{row.x__adduser}</div>
                    <div>{row.poznamka}</div>
                  </div>
                </div>
                <ThemeProvider theme={theme}>
                  <DataGridPremium
                    sx={stylingTable}
                    localeText={
                      csCZ.components.MuiDataGrid.defaultProps.localeText
                    }
                    {...{
                      rows: [row],
                      columns: columns,
                    }}
                    columnHeaderHeight={40}
                    disableAutoFocus
                    disableColumnMenu
                    defaultGroupingExpansionDepth={-1}
                    hideFooter
                    rowHeight={40}
                    groupingColDef={{
                      leafField: "nazov",
                      headerClassName: "weekend6_help_nadpis",
                      cellClassName: "weekend6_help_cell",
                    }}
                  />
                </ThemeProvider>
                <div className={styles.reklamace}>
                  <div className={styles.reklamace_uzn}>
                    <div className={styles.line_uzn}>
                      <div>Uznaná reklamace</div>{" "}
                      <Checkbox
                        value={uznana === 1}
                        onChange={(e) => {
                          if (e.target.checked && uznana === 1) setUznana(0);
                          else if (e.target.checked && uznana === 2)
                            setUznana(1);
                          else if (e.target.checked && uznana === 0)
                            setUznana(1);
                          console.log(e);
                        }}
                      ></Checkbox>
                    </div>
                    <div className={styles.line_uzn}>
                      <div>Neuznaná reklamace</div>
                      <Checkbox
                        value={uznana === 2}
                        onChange={(e) => {
                          if (e.target.checked && uznana === 2) setUznana(0);
                          else if (e.target.checked && uznana === 1) {
                            setUznana(2);
                            setUznana_mn(0);
                          } else if (e.target.checked && uznana === 0) {
                            setUznana(2);
                            setUznana_mn(0);
                            setUznana_typ(null);
                          }
                        }}
                      ></Checkbox>
                    </div>
                  </div>
                  <div className={styles.koment}>
                    <div>Komentář</div>
                    <textarea
                      input={poznamka}
                      className={styles.textarea}
                      onInput={(e) => {
                        setPoznamka(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  {uznana === 2 || uznana === 0 ? (
                    <div style={{ height: 140 }}></div>
                  ) : (
                    <>
                      <div className={styles.input_nadpis_3}>
                        <div>Způsob uzavření reklamace</div>
                        <div className={styles.select_area}>
                          <Select
                            //name={params_.id}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "#FAEECB ",
                                primary50: "#FAEECB ",
                                primary: "#F1B600",
                              },
                            })}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                //width: "100%",
                                borderRadius: 8,
                                borderWidth: 2,
                                width: "100%",
                                backgroundColor: !darkMode.value
                                  ? "white"
                                  : "#262b30",
                              }),
                              option: (base, state) => ({
                                ...base,
                                width: "100%",
                                borderRadius: 8,
                                color: "#6F767E",
                              }),
                              singleValue: (base, state) => ({
                                ...base,
                                width: "100%",
                                borderRadius: 3,
                                color: "#82878c",
                              }),
                              menu: (base, state) => ({
                                ...base,
                                width: "100%",
                                borderRadius: 0,
                                marginTop: 0,
                                backgroundColor: !darkMode.value
                                  ? "white"
                                  : "#262b30",
                              }),
                            }}
                            className={styles.select}
                            value={uznana_typ}
                            isClearable={false}
                            isSearchable={false}
                            options={options()}
                            onChange={(e) => {
                              setUznana_typ(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className={styles.reklamace_uzn}>
                        <div className={styles.line_input}>
                          {row.typ_reklamace === 2 ? (
                            <div>
                              <div>Vytvořit objednávku na položku:</div>
                              <div>{row.polozka + " " + row.nazov}</div>
                            </div>
                          ) : (
                            <div>
                              <div>Uznané reklamované množství</div>
                            </div>
                          )}

                          <input
                            id={"456kla7"}
                            onClick={() => {
                              document.getElementById("456kla7").select();
                            }}
                            type="number"
                            className={styles.nazov_input}
                            value={uznana_mn === 0 ? "" : uznana_mn}
                            onChange={(e) => {
                              //console.log(e.target.value, validateNumberWithMaxDecimals(Number(e.target.value), row.des_mista), Number(e.target.value) <= row.objem)
                              if (
                                Number(e.target.value) <= row.objem &&
                                validateNumberWithMaxDecimals(
                                  Number(e.target.value),
                                  row.des_mista
                                )
                              )
                                setUznana_mn(Number(e.target.value));
                              else setUznana_mn(uznana_mn);
                            }}
                          />
                          <div>{row.mj}</div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={styles.saveArea}>
            <div className={styles.tre_pok}>
              <div
                onClick={async () => {
                  riesenie();
                }}
                className={styles.save}
              >
                Potvrdit
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </Modal>
      <ToastContainer />
    </>
  );
};
export default App;
