export function validateNumberWithMaxDecimals(number, maxDecimals) {
    // Prevencia, aby nebol vstup prázdny alebo niečo iné ako číslo
    if (isNaN(number) || number === '' || number === null) {
        return true;
    }

    // Vytvorenie regulárneho výrazu pre kontrolu počtu desatinných miest
    var regex = new RegExp('^\\d+(\\.\\d{0,' + maxDecimals + '})?$');

    // Overenie, či číslo spĺňa regulárny výraz
    return regex.test(number.toString());
}