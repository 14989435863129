import { createSlice } from '@reduxjs/toolkit';

export const objednavky_typERP_BBSlice = createSlice({
    name: 'objednavky_typERP_BB',
    initialState: {
            rows: [],
            allRows: []
    },
    reducers: {
        objednavky_typERP_BBRows: (state, action) => {
            state.rows = action.payload
        },
        objednavky_typERP_BBAllRows: (state, action) => {
            state.allRows = action.payload
        },

    },
});

export const { objednavky_typERP_BBRows, objednavky_typERP_BBAllRows } = objednavky_typERP_BBSlice.actions;

export const objednavky_typERP_BBData = state => state.objednavky_typERP_BBdata;


export default objednavky_typERP_BBSlice.reducer;
