import React, { useState } from "react";
import { useEffect } from "react";
import cn from "classnames";
import styles from "./Info_o_smene.module.sass";
import Card from "../../../components/Card";
import {
  Info_o_smeneUrl,
  commentsUrl,
  prehladUrl,
  soutezUrl,
} from "./../../../features/urlHelper";
import axios from "axios";
import ListIcon from "@mui/icons-material/FormatListBulleted";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import { login, loginData } from "./../../SignIn/redux/loginSlice";
import {
  info_o_smene,
  info_o_smeneData,
  loadingInfo_o_smene,
} from "./redux/Info_o_smeneSlice";

import useDarkMode from "@fisch0920/use-dark-mode";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../../features/errorDialog";

const App = ({ className }) => {
  const dispatch = useDispatch();
  const darkMode = useDarkMode(false);
  const navigate = useHistory();

  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const info_o_smenedata = useSelector(info_o_smeneData);

  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
    dispatch(loadingInfo_o_smene());
    result();
  }, []);

  async function result() {
    console.log(logindata.UserData.vybrane_stredisko.kod);
    await axios
      .get(Info_o_smeneUrl + logindata.UserData.vybrane_stredisko.kod, {
        headers: { Authorization: "Bearer " + logindata.Token },
      })
      .then(function (response) {
        console.log("info", response.data);
        dispatch(info_o_smene(response.data));
        //dispatch(loadingSoutez({ data: false }))
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }
  return (
    <Card bezPaddingu={false} className={cn(styles.card, className)}>
      {info_o_smenedata.loading ? (
        <ContentLoader
          speed={2}
          width={400}
          height={100}
          viewBox="0 0 400 100"
          backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
          foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
        >
          <rect
            x="68"
            y="8"
            rx="3"
            ry="3"
            width={width > 1056 ? "200" : "200"}
            height="12"
          />
          <rect
            x="68"
            y="30"
            rx="3"
            ry="3"
            width={width > 1056 ? "52" : "104"}
            height="12"
          />
          <rect
            x="68"
            y="56"
            rx="3"
            ry="3"
            width={width > 1056 ? "200" : "200"}
            height="20"
          />

          <circle cx="25" cy="28" r="25" />
        </ContentLoader>
      ) : (
        <div>
          <div className={styles.title}>
            <div className={styles.cudo}></div>
            <div>{info_o_smenedata.data.title}</div>
          </div>
          {info_o_smenedata.data.info !== undefined &&
            info_o_smenedata.data.info.map((x, index) => (
              <div className={styles.item} key={index}>
                {x}
              </div>
            ))}
        </div>
      )}
    </Card>
  );
};

export default App;
