import Card from "../../components/Card";

import React, { useState } from "react";

import EvidenceTableUniversal from "../../components/EvidenceTableUniversal";

const App = ({ className }) => {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [rowsCount, setRowsCount] = React.useState(0);


  function getWidth(width) {
    if (width > 2500) {
      return width - 456;
    } else if (width < 2500 && width > 767) {
      return width - 212;
    } else {
      return width - 116;
    }
  }


  const columns = [
    {
      field: "poradie",
      headerName: "Doklad",
      width: 150,
      headerAlign: "center",
    },
 
    {
      field: "datum",
      headerName: "Datum",
      width: 110,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "mnozstvo",
      headerName: "Množství",
      width: 110,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "poznamka",
      headerName: "Poznamka",
      width: (getWidth(window.innerWidth) - 380) ,
      minWidth: 180,
      headerAlign: "center",
    },
  ];

  return (
    <div>
      <EvidenceTableUniversal
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        rowsCount={rowsCount}
        setRowsCount={setRowsCount}
        dataset="skl_dok"
        columns={columns}
      />
    </div>
  );
};
export default App;
