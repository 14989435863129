import { createSlice } from '@reduxjs/toolkit';

export const tablePrevodSlice = createSlice({
    name: 'prevodTable',
    initialState: {
        data: [],
        loading: true,
    },
    reducers: {
        prevodTable: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingPrevodTable: (state) => {
            console.log("loading: true")
            state.loading = true
        }

    },
});

export const { prevodTable, loadingPrevodTable} = tablePrevodSlice.actions;

export const prevodTableData = state => state.tablePrevoddata;


export default tablePrevodSlice.reducer;
