import { createSlice } from '@reduxjs/toolkit';

export const zmenySlice = createSlice({
    name: 'zmeny',
    initialState: {
        data: [],
        loading: true,
    },
    reducers: {
        zmeny: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingZmeny: (state) => {
            console.log("loading: true")
            state.loading = true
        }

    },
});

export const { zmeny, loadingZmeny } = zmenySlice.actions;

export const zmenyData = state => state.zmenydata;


export default zmenySlice.reducer;

