import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../Icon";
import Modal from "../../Modal";
import { modal, modalData } from "./../redux/modalSlice";
import styles from "./Modal.module.sass";
import { login, loginData } from "../../../screens/SignIn/redux/loginSlice";
import axios from "axios";
import {
  FormData as FormData_,
  loadingForm,
  Form,
  loadedForm,
} from "./redux/FormSlice";
import Switch from "./switch";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Tooltip from "@mui/material/Tooltip";
import { LocalizationProvider, csCZ as cz } from "@mui/x-date-pickers-pro";
import {
  DataGridPremium,
  LicenseInfo,
  csCZ,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
//import {  } from "@mui/x-license-premium";
import { getLicensePremium } from "../../../features/License";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "dayjs/locale/cs";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/cs";
import Select from "react-select";
import ModalSelector from "./../ModalSelectorPanel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import swal from "sweetalert";
import { useHistory } from "react-router";
import {
  EvidenceUrl,
  filesUploadUrl,
  formSaveUrl,
  paneldefUrl,
  validationFormUrl,
  validationPanelUrl,
  validationUrl,
  zapisReklamaciiBBUrl,
} from "../../../features/urlHelper";
import moment from "moment";
import useDarkMode from "@fisch0920/use-dark-mode";
import { errorHandleDialog } from "../../../features/errorDialog";
import { loadingPanel, Panel, PanelData } from "../ModalPanel/redux/PanelSlice";
import {
  nakupTable,
  nakupTableData,
} from "../../../screens/Prijemky/ModalNA/Table/redux/tableNakupSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  objednavkyOstatneObjednavkaTable,
  objednavkyOstatneObjednavkaTableData,
} from "../../../screens/Objednavky_ostatne_objednavka/Table/redux/ObjednavkyOstatneObjednavkaSlice";
import {
  prijemOdJinePobockyTable,
  prijemOdJinePobockyTableData,
  setZaznamy,
} from "../../../screens/Prijemky/ModalPS/Table/redux/tablePrijemOdJinePobockySlice";
import { validateNumberWithMaxDecimals } from "../../../features/validateNumberWithMaxDecimals";
import { DropzoneArea } from "material-ui-dropzone";

const App = ({
  className,
  visible,
  setVisible,
  formName,
  setVisiblePanel,
  setVolba,
  idFieldValue,
  idFieldName,
  dataset,
  datasetId,
  priznak,
  params_,
  params_value,
  znacka,
  validationPanel,
  height = null,
  data,
  setData,
}) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const notify_error = (text) => toast.error(text);
  LicenseInfo.setLicenseKey(getLicensePremium());
  const modaldata = useSelector(modalData);
  const nakupdata = useSelector(nakupTableData);
  const objednavkyOstatneObjednavkaTabledata = useSelector(
    objednavkyOstatneObjednavkaTableData
  );
  const prijemOdJinePobockyTabledata = useSelector(
    prijemOdJinePobockyTableData
  );
  const darkMode = useDarkMode(false);
  const elementRef = useRef(null);

  const Paneldata = useSelector(PanelData);
  const Formdata = useSelector(FormData_);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );

  const [ModalSelectorVisible, setModalSelectorVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState([]);
  const [params, setParams] = useState({});
  const [id, setId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [titleSelector, setTitleSelector] = useState("");
  const [selectorData, setSelectorData] = useState([]);
  const [selectorCount, setSelectorCount] = useState(0);
  const [selectorPage, setSelectorPage] = useState(0);

  const [files, setFiles] = useState([]);

  const rows = [];
  const columns = [];
  const darkTheme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
      mode: darkMode.value ? "dark" : "light",
    },
  });
  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };
  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  const stylingTable = {
    border: 0,
    paddingTop: 0,
    padding: 0,
    margin: 0,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    width: Formdata.loading
      ? 700
      : Formdata.data.width + 20 + 30 > window.innerWidth
      ? window.innerWidth
      : Formdata.data.width,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
    color: darkMode.value ? "white" : "#666d74",
  };

  // function focusOnOpen() {
  //   // if (
  //   //   document.getElementsByName(
  //   //     Formdata.data.values[Object.keys(Formdata.data.values)[0]].id
  //   //   )[0] !== undefined
  //   // ) {
  //   console.log(
  //     "tu som",
  //     Formdata.data.values[Object.keys(Formdata.data.values)[0]].id
  //   );
  //   document
  //     .getElementsByName(
  //       Formdata.data.values[Object.keys(Formdata.data.values)[0]].id
  //     )[0]
  //     .focus();
  //   // document
  //   //   .getElementByName(
  //   //     Formdata.data.values[Object.keys(Formdata.data.values)[0]].id
  //   //   )[0]
  //   //   .select();
  //   //}
  // }

  useEffect(() => {
    if (document.getElementsByName("ico_title")[0] !== undefined) {
      document.getElementsByName("ico_title")[0].click();
    }
  }, [visible]);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#F1B600" },
      },
    },
    csCZ
  );

  const stylingRangePicker = {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      color: "#82878c",
      fontFamily: "Inter, sans-serif",
      height: 7,
      display: "flex",
      alighnItems: "center",
      justifyContent: "center",
      fontWeight: 400,
      fontSize: 15,
      backgroundColor: !darkMode.value ? "white" : "#262b30",
      borderRadius: 2,
    },

    "& .MuiTypography-root, MuiFormLabel-root": {
      color: "#82878c",
    },
    "& .separator-true": {
      height: 30,
      borderBottom: `3px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#989899",
    },
    "& .MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled": {
      WebkitTextFillColor: "#989899",
    },
    width: "60%",
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
  };

  const stylingPicker = {
    ...stylingRangePicker,
    width: "60%",
  };

  function pocetDesatinnychMiest(cislo) {
    let retazec = cislo.toString();
    let zoznam = retazec.split(".");

    if (zoznam.length > 1) {
      return zoznam[1].length;
    } else {
      return 0;
    }
  }

  function spracujWidth(columns) {
    const new_columns = columns.map((column) => {
      return {
        ...column,
        width:
          Formdata.data.width + 50 > window.innerWidth
            ? (window.innerWidth - 50) * (column.widthPerc / 100)
            : column.width,
      };
    });
    return new_columns;
  }

  function spracujColumns(columns, params_) {
    let new_column;
    let position = 0;
    if (params_.pocDes_na_stplpci !== undefined) {
      columns.map((column, index) => {
        if (column.field === params_.pocDes_na_stplpci.stlpec) {
          new_column = {
            ...column,
            valueFormatter: (params) => {
              if (params.value === null || params.value === 0) {
                return "";
              }
              const valueFormatted = Number(params.value);

              const value = valueFormatted
                .toFixed(params_.pocDes_na_stplpci.pocDes)
                .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

              return `${value}`;
            },
          };
          position = index;
        }
      });
    }
    if (params_.pocDes_na_stplpci === undefined) return spracujWidth(columns);
    else {
      let new_columns = [...columns];
      new_columns[position] = new_column;
      return spracujWidth(new_columns);
    }
  }

  const addFieldsNVH = () => {
    let edit_i = 0;
    let idecko = 0;
    let edi = false;
    nakupdata.data.map((row, index) => {
      if (idecko < row.id) {
        idecko = row.id;
      }
      if (row.znacka.trim() === Formdata.data.values.varpol1.value.trim()) {
        edit_i = index;
        edi = true;
      }
    });

    let field = {
      id: edi ? nakupdata.data[edit_i].id : idecko + 1,
      nazov: Formdata.data.values.varpol1.selectorLabel,
      znacka: Formdata.data.values.varpol1.value,
      pocet: Formdata.data.values.varpol2.value,
      dan: Formdata.data.values.varpol6.value,
      cena: Formdata.data.values.varpol4.value,
      mj: Formdata.data.values.varpol3.value,
    };
    const tempData = [...nakupdata.data];
    if (edi) tempData[edit_i] = field;
    else tempData.push(field);

    dispatch(nakupTable(tempData));
    setVisible(false);
  };

  const addFieldsOdpisSur = () => {
    if(Formdata.data.values.varpol1.value === ""){
      var err_msg = "Musíte zadat položku!";
      swal("Chyba!", err_msg, "error");
      return;
    }
    if(Formdata.data.values.varpol2.value === 0){
      var err_msg = "Nemůžete zadat položku s nulovým množstvím!";
      swal("Chyba!", err_msg, "error");
      return;
    }

    let edit_i = 0;
    let idecko = 0;
    let edi = false;
    data.map((row, index) => {
      if (idecko < row.id) {
        idecko = row.id;
      }
      if (row.znacka.trim() === Formdata.data.values.varpol1.value.trim()) {
        edit_i = index;
        edi = true;
      }
    });

    let field = {
      id: edi ? data[edit_i].id : idecko + 1,
      nazov: Formdata.data.values.varpol1.selectorLabel,
      znacka: Formdata.data.values.varpol1.value,
      stav_skladu: Formdata.data.values.varpol2.value,
      dan: Formdata.data.values.varpol6.value,
      poznamka: Formdata.data.values.varpol5.value,
      vydaj: Formdata.data.values.varpol4.value,
      mj: Formdata.data.values.varpol3.value,
    };
    const tempData = [...data];
    if (edi) tempData[edit_i] = field;
    else tempData.push(field);

    setData(tempData);
    setVisible(false);
  };

  const addFieldsOdpisZbo = () => {
    if(Formdata.data.values.varpol1.value === ""){
      var err_msg = "Musíte zadat položku!";
      swal("Chyba!", err_msg, "error");
      return;
    }
    if(Formdata.data.values.varpol2.value === 0){
      var err_msg = "Nemůžete zadat položku s nulovým množstvím!";
      swal("Chyba!", err_msg, "error");
      return;
    }

    let edit_i = 0;
    let idecko = 0;
    let edi = false;
    data.map((row, index) => {
      if (idecko < row.id) {
        idecko = row.id;
      }
      if (row.znacka.trim() === Formdata.data.values.varpol1.value.trim()) {
        edit_i = index;
        edi = true;
      }
    });

    let field = {
      id: edi ? data[edit_i].id : idecko + 1,
      nazov: Formdata.data.values.varpol1.selectorLabel,
      znacka: Formdata.data.values.varpol1.value,
      vydaj: Formdata.data.values.varpol2.value,
      dan: Formdata.data.values.varpol6.value,
      poznamka: Formdata.data.values.varpol5.value,
      druh_peciva: Formdata.data.values.varpol4.value,
      mj: Formdata.data.values.varpol3.value,
    };
    const tempData = [...data];
    if (edi) tempData[edit_i] = field;
    else tempData.push(field);

    setData(tempData);
    setVisible(false);
  };

  const addFieldsObjednavka = () => {
    let edit_i = 0;
    let idecko = 0;
    let edi = false;
    objednavkyOstatneObjednavkaTabledata.data.map((row, index) => {
      if (idecko < row.id) {
        idecko = row.id;
      }
      if (row.znacka.trim() === Formdata.data.values.varpol1.value.trim()) {
        edit_i = index;
        edi = true;
      }
    });

    let field = {
      id: edi
        ? objednavkyOstatneObjednavkaTabledata.data[edit_i].id
        : idecko + 1,
      nazov: Formdata.data.values.varpol1.selectorLabel,
      znacka: Formdata.data.values.varpol1.value,
      mnozstvo: Formdata.data.values.varpol2.value,
      mj: Formdata.data.values.varpol3.value,
    };
    const tempData = [...objednavkyOstatneObjednavkaTabledata.data];
    if (edi) tempData[edit_i] = field;
    else tempData.push(field);

    dispatch(objednavkyOstatneObjednavkaTable(tempData));
    setVisible(false);
  };

  // async function validationPanel(
  //   params_,
  //   value,
  //   _stepValidValue = 0,
  //   odpovedAnswer = 0
  // ) {
  //   await axios
  //     .post(
  //       validationPanelUrl,
  //       {
  //         datasetId: datasetId,
  //         dataset: dataset,
  //         stredisko: logindata.UserData.vybrane_stredisko.kod,
  //         value: value,
  //         id: params_.id,
  //         formValues: Formdata.data.values,
  //         panelValues: Paneldata.data.values,
  //         stepValid: 1 + _stepValidValue,
  //         odpovedAnswer: odpovedAnswer,
  //       },
  //       {
  //         headers: { Authorization: "Bearer " + logindata.Token },
  //       }
  //     )
  //     .then(function (response) {
  //       //console.log("resp.", response.data);
  //     })
  //     .catch(function (error) {
  //       errorHandleDialog(error.response, navigate, dispatch);
  //     });
  // }

  async function reklamovat() {
    // if (mnozstvo > row.mnozstvo) {
    //   swal("Chyba!", "Nemůžete reklamovat víc, než vám přišlo.", "error");
    //   return;
    // }
    console.log(Formdata.data.values);
    if (Formdata.data.values.varpol2.value !== undefined) {
      var select_doklad = Formdata.data.values.varpol2.selectValues.find(
        (x) => x.value.trim() === Formdata.data.values.varpol2.value.trim()
      );
      if (select_doklad === undefined) {
        swal(
          "Reklamace nebyla odeslána",
          "Musíte zvolit platný doklad",
          "error"
        );
        return;
      }
    }
    swal({
      title: "Opravdu chcete zadat reklamaci?",
      icon: "info",
      buttons: ["Ne ", "Ano"],
    }).then(async (willShow) => {
      if (willShow) {
        let parts = select_doklad.label.split("/");
        let datum_parts = parts[1].split(".");
        await axios
          .post(
            zapisReklamaciiBBUrl,
            {
              znacka: Formdata.data.values.varpol1.value,
              mnozstvo: Formdata.data.values.varpol3.value,
              dl_vodica: Formdata.data.values.varpol2.value,
              datutum_rekl: null,
              datum_obj: new Date(
                Number(datum_parts[2]),
                Number(datum_parts[1]),
                Number(datum_parts[0])
              ),
              komentar: Formdata.data.values.varpol5.value,
              typ: "K",
              stredisko: logindata.UserData.vybrane_stredisko.kod,
            },
            {
              headers: { Authorization: "Bearer " + logindata.Token },
            }
          )
          .then(async function (response) {
            console.log(response);
            swal(
              "Reklamace byla odeslána",
              "O stavu vás budeme informovat",
              "info"
            );
          })
          .catch(function (error) {
            console.log(error);
            if (error.response.status === 400) {
              swal("Chyba!", error.response.data.err_msg, "error");
            } else if (error.response.status === 401) {
              navigate.push("/");
              //dispatch(login({ data: {} }));
            } else if (
              error.response.status > 401 &&
              error.response.status < 500
            ) {
              swal(
                "Chyba!",
                "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
                "error"
              );
            } else {
              swal(
                "Chyba!",
                " Nastala chyba na serveri ERP, zkuste to později.",
                "error"
              );
            }
          });
      }
    });
  }

  async function pokracovat(formdata) {
    console.log(priznak);
    if (priznak === "pridatSurovinu") addFieldsNVH();
    else if (priznak === "OdpisS") addFieldsOdpisSur();
    else if (priznak === "OdpisZ") addFieldsOdpisZbo();
    else if (priznak === "PridatPolozkuDoObjednavky") addFieldsObjednavka();
    else if (priznak === "FORM_DAJ") {
      console.log();
      validationPanel(params_, params_value, 1);
      setVisible(false);
    } else if (priznak === "ZaznamReklamace") {
      reklamovat();
      return;
    } else if (priznak === "zaznamProblemu") {
      if (
        Formdata.data.values.varpol2.value < Formdata.data.values.varpol4.value
      ) {
        swal(
          "Chyba!",
          "Neplatná hodnota! Počet nesmí přesáhnout zaslané položky!",
          "error"
        );
        return;
      }
      if (
        Formdata.data.values.varpol2.value ===
        Formdata.data.values.varpol4.value
      ) {
        swal({
          icon: "success",
          text: "Jste si jisti, že chcete přijmout všechno?",
          type: "error",
          buttons: ["Zpět na záznam", "Přijmout"],
          closeOnEsc: true,
        }).then((value) => {
          console.log(value);
          if (value) {
            const new_data = prijemOdJinePobockyTabledata.data.map((x) => {
              if (x.id.trim() === znacka.trim())
                return {
                  ...x,
                  problem: false,
                  stav: true,
                  prijate: Formdata.data.values.varpol4.value,
                  skupina_nazov: "2. Přijato - OK",
                  skupina_nazov_old: x.default_skupina_nazov,
                };
              else return x;
            });
            dispatch(prijemOdJinePobockyTable(new_data));
            setVisible(false);
          } else {
            return;
          }
        });
        return;
      }
      const zaznamy = [...prijemOdJinePobockyTabledata.zaznamy];
      console.log(Formdata.data.values);
      let typ_reklamacie;
      if (Formdata.data.values.varpol7.value === "") typ_reklamacie = "";
      else {
        typ_reklamacie = Formdata.data.values.varpol7.selectValues.find(
          (x) => x.value === Formdata.data.values.varpol7.value
        );
      }
      let max_id = 0;
      prijemOdJinePobockyTabledata.zaznamy.map((zaznam) => {
        if (zaznam.id > max_id) max_id = zaznam.id;
      });
      zaznamy.push({
        id: max_id + 1,
        nazov: Formdata.data.values.varpol1.selectorLabel,
        znacka: Formdata.data.values.varpol1.value,
        mnozstvo: Formdata.data.values.varpol2.value,
        prijate: Formdata.data.values.varpol4.value,
        komentar: Formdata.data.values.varpol6.value,
        typ_reklamacie: typ_reklamacie.label,
        mj: Formdata.data.values.varpol3.value,
        lokalita: Formdata.data.values.varpol10.value,
        cielova_lokalita: Formdata.data.values.varpol27.value,
        id_trellis: "",
        files: Formdata.data.values.varpol11.value,
      });
      dispatch(setZaznamy(zaznamy));
      const new_data = prijemOdJinePobockyTabledata.data.map((x) => {
        if (x.id.trim() === znacka.trim())
          return {
            ...x,
            problem: true,
            stav: false,
            prijate: Formdata.data.values.varpol4.value,
            komentar: Formdata.data.values.varpol6.value,
            skupina_nazov: "1. Přijato - Se záznamem",
            skupina_nazov_old: x.default_skupina_nazov,
            sort_col: 1,
          };
        else return x;
      });
      dispatch(prijemOdJinePobockyTable(new_data));
      setVisible(false);
    } else if (Formdata.data.buttonText === "Ok") {
      setVisible(false);
    } else {
      if (priznak === "zaznamProblemu") {
      }
      await axios
        .post(
          formSaveUrl,
          {
            formName: formName,
            formValues: Formdata.data.values,
          },
          {
            headers: { Authorization: "Bearer " + logindata.Token },
          }
        )
        .then(async function (response) {
          setVisible(false);
          await axios
            .post(
              paneldefUrl,
              {
                formValues: Formdata.data.values,
                step: 2,
                dataset: dataset,
                stredisko: logindata.UserData.vybrane_stredisko.kod,
                idFieldValue: idFieldValue,
                idFieldName: idFieldName,
                volba: "add",
              },
              {
                headers: { Authorization: "Bearer " + logindata.Token },
              }
            )
            .then(function (response) {
              setVolba("add");
              dispatch(loadingPanel());
              dispatch(Panel(response.data));
              setVisiblePanel(true);
              document
                .getElementsByName(
                  response.data.values[Object.keys(response.data.values)[0]].id
                )[0]
                .focus();
            })
            .catch(function (error) {
              errorHandleDialog(error.response, navigate, dispatch);
            });
        })
        .catch(function (error) {
          //console.log(error.response);
          errorHandleDialog(error.response, navigate, dispatch);
        });
    }
  }

  async function selector(selector, value, id, page_) {
    await axios
      .post(
        EvidenceUrl,
        {
          datasetIndex: selector.datasetIndex !== undefined ? selector.datasetIndex : null, //index ciselnika
          dataset: selector.dataset, // meno evidencie, ktoru prezeras
          typErp: logindata.UserData.typErp,
          stredisko: logindata.UserData.vybrane_stredisko.kod,
          pagination: {
            pageSize: 50, // pocet poloziek na stranu
            page: page_, // cislo strany – cislujeme do 0
          },
          sourceForm: {
            formName: formName,
            id: id,
            formValues: Formdata.data.values,
          },
          search: value,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        //console.log("selector");
        setTitleSelector(response.data.title);
        setFields([selector.id, selector.text]);
        setId(id);
        const array = [...selectorData, ...response.data.data];
        if (page_ === 0) setSelectorData(response.data.data);
        else setSelectorData(array);
        setSelectorCount(response.data.count);
        setModalSelectorVisible(true);
        setLoading(false);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function validationForm(params_, value) {
    let noveParams = { ...params_, value: value };
    let noveValues = {
      ...Formdata.data.values,
      [params_.fieldName]: noveParams,
    };
    let novyObjekt = { ...Formdata.data, values: noveValues };
    await axios
      .post(
        validationFormUrl,
        {
          formName: formName,
          stredisko: logindata.UserData.vybrane_stredisko.kod,
          value: value,
          id: params_.id,
          formValues: novyObjekt.values,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log("form valid values", response);
        if (response.data.waitWindow != null) {
          notify_error(response.data.waitWindow);
        }
        if (response.data.odpoved != null) {
          swal({
            title: response.data.odpoved.text,
            icon: "warning",
            buttons: { ...response.data.odpoved.buttons },
            closeOnEsc: true,
          }).then((value) => {
            switch (value) {
              case 1:
              case 2:
              case 3:
              case 4:
                validationForm(params_, params_.value, value);
                break;
              default:
                validationForm(params_, params_.value, 0);
                swal.close();
            }
          });
        }

        let newData_ = Formdata.data.values;
        console.log(response.data.newFormValues);
        if (response.data.newFormValues !== undefined) {
          const newData = {
            ...Formdata.data,
            values: response.data.newFormValues,
          };
          if (params_.selector == null) {
            //dispatch(loadingForm());
            dispatch(Form(newData));
          }
          newData_ = response.data.newFormValues;
        } else {
          if (params_.selector == null) {
            //dispatch(loadingForm());
            dispatch(Form(novyObjekt));
          }
        }

        if (params_.selector != null) {
          if (params_.selector.text.length > 1) {
            dataHandling(
              params_,
              value,
              response.data.data[params_.selector.text[0].column] +
                " " +
                response.data.data[params_.selector.text[1].column],
              newData_
            );
          } else {
            dataHandling(
              params_,
              value,
              response.data.data[params_.selector.text[0].column],
              newData_
            );
          }
        }
        console.log("ok");
      })
      .catch(function (error) {
        if (params_.selector != null) {
          dataHandling(params_, value, "");
          setParams(params_);
          setSearchValue(value);
          setLoading(true);
          setSelectorCount(0);
          selector(params_.selector, value, params_.id, 0);
        } else {
          errorHandleDialog(error.response, navigate, dispatch);
        }
      });
  }

  function dataHandling(params_, value_, value2_, data = Formdata.data.values) {
    let noveParams = {};
    if (value2_ !== null) {
      noveParams = { ...params_, value: value_, selectorLabel: value2_ };
    } else {
      noveParams = { ...params_, value: value_ };
    }

    let noveValues = {
      ...data,
      [params_.fieldName]: noveParams,
    };
    let novyObjekt = { ...Formdata.data, values: noveValues };
    console.log("novyObjekt", novyObjekt);
    dispatch(Form(novyObjekt));
  }

  async function validatioPickerRange(params_, value) {
    await axios
      .post(
        validationFormUrl,
        {
          formName: formName,
          stredisko: logindata.UserData.vybrane_stredisko.kod,
          value: value[0],
          id: params_.id,
          formValues: Formdata.data.values,
          fieldname: params_.fieldName[0],
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(async function (response) {
        console.log("ok");
        await axios
          .post(
            validationFormUrl,
            {
              formName: formName,
              stredisko: logindata.UserData.vybrane_stredisko.kod,
              value: value[1],
              id: params_.id,
              formValues: Formdata.data.values,
              fieldname: params_.fieldName[1],
            },
            {
              headers: { Authorization: "Bearer " + logindata.Token },
            }
          )
          .then(function (response) {
            if (response.data.waitWindow != null) {
              notify_error(response.data.waitWindow);
            }
            if (response.data.odpoved != null) {
              swal({
                title: response.data.odpoved.text,
                icon: "warning",
                buttons: { ...response.data.odpoved.buttons },
                closeOnEsc: true,
              }).then((value) => {
                switch (value) {
                  case 1:
                  case 2:
                  case 3:
                  case 4:
                    validationForm(params_, params_.value, value);
                    break;
                  default:
                    validationForm(params_, params_.value, 0);
                    swal.close();
                }
              });
            }

            let newData_ = Formdata.data.values;
            if (response.data.newFormValues !== undefined) {
              const newData = {
                ...Formdata.data,
                values: response.data.newFormValues,
              };
              if (params_.selector == null) {
                //dispatch(loadingForm());
                dispatch(Form(newData));
              }
              newData_ = response.data.newFormValues;
            }

            if (params_.selector != null) {
              dataHandling(
                params_,
                value,
                response.data.data[params_.selector.text[0].column],
                newData_
              );
            }
          })
          .catch(function (error) {
            errorHandleDialog(error.response, navigate, dispatch);
          });
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  function getComponent(params_) {
    //console.log(params_)
    switch (params_.inputType) {
      case "text":
        return (
          <input
            autoComplete="off"
            id={params_.id}
            name={params_.id}
            onClick={() => {
              document.getElementsByName(params_.id)[0].select();
            }}
            style={{
              width: params_.inputWidth > 560 ? "100%" : params_.inputWidth, //(params_.inputWidth !== undefined ? params_.inputWidth : 200)
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                if (params_.rules !== undefined && params_.rules.validating) {
                  validationForm(params_, params_.value);
                }
              }
            }}
            className={styles.input}
            type="text"
            disabled={params_.when_fcia === "disabled"}
            value={params_.value}
            onChange={(e) => {
              if (e.target.value.length > params_.maxLength) {
                swal(
                  "Chyba!",
                  "Neplatná hodnota! Dodržte počet desetinných míst" +
                    String(params_.rules.numericScale),
                  "error"
                );
              } else {
                dataHandling(params_, e.target.value);
              }
            }}
          />
        );
      case "textArea":
        return (
          <textarea
            autoComplete="off"
            id={params_.id}
            name={params_.id}
            onClick={() => {
              document.getElementsByName(params_.id)[0].select();
            }}
            style={{
              width: params_.inputWidth > 560 ? "100%" : params_.inputWidth,
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                if (params_.rules !== undefined && params_.rules.validating) {
                  validationForm(params_, params_.value);
                }
              }
            }}
            className={styles.input_textArea}
            type="text"
            disabled={params_.when_fcia === "disabled"}
            value={params_.value}
            onChange={(e) => {
              if (e.target.value.length > params_.maxLength) {
                swal(
                  "Chyba!",
                  "Neplatná hodnota! Dodržte počet znakú" + params_.maxLength,
                  "error"
                );
              } else {
                dataHandling(params_, e.target.value);
              }
            }}
          />
        );
      case "number":
        return (
          <input
            id={params_.id}
            style={{
              width: params_.inputWidth > 560 ? "100%" : params_.inputWidth,
            }}
            className={styles.input}
            pattern="[0-9]+([\.][0-9]{1,2})?"
            type="number"
            name={params_.id}
            onClick={() => {
              document.getElementsByName(params_.id)[0].select();
            }}
            disabled={params_.when_fcia === "disabled"}
            value={params_.value}
            onChange={(e) => {
              if (priznak === "zaznamProblemu") {
                if (
                  validateNumberWithMaxDecimals(
                    Number(e.target.value),
                    Number(
                      prijemOdJinePobockyTabledata.data.find(
                        (e) => znacka.trim() === e.znacka.trim()
                      )?.des_mista
                    )
                  )
                ) {
                  dataHandling(params_, Number(e.target.value));
                }
              } else {
                if (
                  pocetDesatinnychMiest(e.target.value) >
                  params_.rules.numericScale
                ) {
                  swal(
                    "Chyba!",
                    "Neplatná hodnota! Dodržte počet desetinných míst" +
                      String(params_.rules.numericScale),
                    "error"
                  );
                } else if (
                  params_.rules.min > Number(e.target.value) ||
                  Number(e.target.value) > params_.rules.max
                ) {
                  swal(
                    "Chyba!",
                    "Neplatná hodnota! Dodržte interval" +
                      String(params_.rules.min) +
                      " - " +
                      String(params_.rules.max),
                    "err or"
                  );
                } else {
                  dataHandling(params_, Number(e.target.value));
                }
              }
            }}
          />
        );
      case "date":
        return (
          <span>
            <ThemeProvider theme={theme}>
              <LocalizationProvider
                adapterLocale="cs"
                localeText={
                  cz.components.MuiLocalizationProvider.defaultProps.localeText
                }
                dateAdapter={AdapterMoment}
              >
                <DatePicker
                  id={params_.id}
                  name={params_.id}
                  disabled={params_.when_fcia === "disabled"}
                  value={moment.utc(params_.value)}
                  // onClose={() => {

                  //   if (
                  //     params_.rules !== undefined &&
                  //     params_.rules.validating
                  //   ) {
                  //     validationPanel(params_, params_.value);
                  //   }
                  //   //console.log("on close", e)
                  // }}
                  onChange={(param, e) => {
                    //dataHandling(params_, param._d.toISOString());
                    if (
                      params_.rules !== undefined &&
                      params_.rules.validating
                    ) {
                      validationForm(params_, param._d.toISOString());
                    }
                  }}
                  sx={stylingPicker}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </span>
        );
      case "select":
        let options = params_.selectValues;
        let value = null;
        if (params_.selectValues === undefined) {
          options = [];
          value = null;
        } else {
          params_.selectValues.map((value_) => {
            if (params_.value === value_.value) {
              value = value_;
            }
          });
        }

        //console.log(value);
        return (
          <>
            <Select
              name={params_.id}
              theme={(theme) => ({
                ...theme,
                borderRadius: 8,
                colors: {
                  ...theme.colors,
                  primary25: "#FAEECB ",
                  primary50: "#FAEECB ",
                  primary: "#F1B600",
                },
              })}
              isDisabled={params_.when_fcia === "disabled"}
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  backgroundColor: !darkMode.value ? "white" : "#262b30",
                }),
                option: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  color: "#6F767E",
                }),
                singleValue: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  color: "#82878c",
                }),
                menu: (base, state) => ({
                  ...base,
                  borderRadius: 0,
                  marginTop: 0,
                  backgroundColor: !darkMode.value ? "white" : "#262b30",
                }),
              }}
              className={styles.select}
              value={value}
              isClearable={false}
              isSearchable={false}
              options={options}
              onChange={(e) => {
                dataHandling(params_, e.value);
                validationForm(params_, e.value);
              }}
            />
          </>
        );
      case "select_group":
        let group_options = params_.selectGroupValues;
        let group_value = null;
        params_.selectGroupValues?.map((options) => {
          options.options?.map((value_) => {
            if (params_.value.toLowerCase() === value_.value.toLowerCase())
              group_value = value_;
          });
        });

        return (
          <>
            <Select
              name={params_.id}
              isSearchable={true}
              theme={(theme) => ({
                ...theme,
                borderRadius: 8,
                colors: {
                  ...theme.colors,
                  primary25: "#FAEECB ",
                  primary50: "#FAEECB ",
                  primary: "#F1B600",
                },
              })}
              isDisabled={params_.when_fcia === "disabled"}
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  backgroundColor: !darkMode.value ? "white" : "#262b30",
                }),
                option: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  color: "#6F767E",
                }),
                singleValue: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  color: "#82878c",
                }),
                menu: (base, state) => ({
                  ...base,
                  borderRadius: 0,
                  marginTop: 0,
                  backgroundColor: !darkMode.value ? "white" : "#262b30",
                }),
              }}
              className={styles.select}
              value={group_value}
              isClearable={false}
              options={group_options}
              formatGroupLabel={formatGroupLabel}
              onChange={(e) => {
                dataHandling(params_, e.value);
                validationForm(params_, e.value);
              }}
            />
          </>
        );
      case "checkbox":
        return (
          <>
            <input
              disabled={params_.when_fcia === "disabled"}
              onClick={(e) => {
                if (params_.value) {
                  dataHandling(params_, false);
                } else {
                  dataHandling(params_, true);
                }
              }}
              checked={params_.value}
              id="cb1"
              type="checkbox"
              name={params_.id}
              className={styles.checkbox}
            />
          </>
        );
      case "trellis":
        return (
          <>
            <button
              disabled={params_.when_fcia === "disabled"}
              onClick={(e) => {
                //window.open(params_.value, "Data", "height=500,width=500");
              }}
              id="cb1"
              name={params_.id}
              className={styles.link}
            >
              Odkaz <i class="fas fa-external-link-alt"></i>
            </button>
          </>
        );
      case "grid":
        console.log(params_.value.columns);
        return (
          // <div style={{ height: "calc(100hv - 94px)" }}>
          <ThemeProvider theme={darkTheme}>
            <DataGridPremium
              name={params_.id}
              sx={stylingTable}
              localeText={{
                ...csCZ.components.MuiDataGrid.defaultProps.localeText,
              }}
              hideFooter
              disableColumnPinning
              disableColumnMenu
              rowHeight={40}
              columnHeaderHeight={50}
              rows={params_.value.rows}
              columns={spracujColumns(params_.value.columns, params_)}
              initialState={{
                aggregation: {
                  model: { ...{}, [params_.aggregation]: "sum" },
                },
              }}
            />
          </ThemeProvider>

          //  </div>
        );
      case "selector":
        return (
          <div className={styles.selector}>
            <div className={styles.inptLbl}>
              <input
                id={params_.id}
                name={params_.id}
                onClick={() => {
                  document.getElementsByName(params_.id)[0].select();
                }}
                disabled={params_.when_fcia === "disabled"}
                className={styles.input}
                value={params_.value}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    validationForm(params_, params_.value);
                  }
                }}
                onChange={(param) => {
                  dataHandling(params_, param.target.value, "");
                }}
                type="text"
              />

              <div className={styles.label}>{params_.selectorLabel}</div>
            </div>

            <button
              disabled={params_.when_fcia === "disabled"}
              onClick={() => {
                //console.log("params_", params_);
                setParams(params_);
                setSearchValue("");
                setLoading(true);
                setSelectorPage(0);
                selector(params_.selector, "", params_.id, 0);
              }}
              className={styles.button}
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        );
      case "radio":
        return (
          <>
            <div selected={params_.value} onChange={() => {}}>
              {params_.selectValues.map((value) => {
                return (
                  <div className={styles.checkboxLine}>
                    <div>{value.label}</div>{" "}
                    <input
                      disabled={params_.when_fcia === "disabled"}
                      onClick={() => {
                        dataHandling(params_, value.value);
                      }}
                      checked={params_.value === value.value}
                      id="cb1"
                      type="radio"
                      value={value.value}
                      name={params_.id}
                      className={styles.checkbox}
                    />
                  </div>
                );
              })}
            </div>
          </>
        );
      case "date_interval":
        var today = new Date();
        var Od = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        var Do = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        return (
          <span>
            <ThemeProvider theme={theme}>
              <LocalizationProvider
                adapterLocale="cs"
                localeText={{
                  ...cz.components.MuiLocalizationProvider.defaultProps
                    .localeText,
                  start: "Od",
                  end: "Do",
                }}
                dateAdapter={AdapterMoment}
              >
                <DemoContainer components={["DateRangePicker"]}>
                  <DateRangePicker
                    id={params_.id}
                    name={params_.id}
                    disabled={params_.when_fcia === "disabled"}
                    onClose={() => {
                      if (
                        params_.rules !== undefined &&
                        params_.rules.validating
                      ) {
                        validatioPickerRange(params_, params_.value);
                        //validationPanel(params_, params_.value);
                      }
                    }}
                    onChange={(param) => {
                      dataHandling(params_, [
                        param[0]._d.toISOString(),
                        param[1] === null
                          ? param[0]._d.toISOString()
                          : param[1]._d.toISOString(),
                      ]);
                    }}
                    // value={[
                    //   moment.utc(params_.value[0] !== undefined ? params_.value[0] : null ),
                    //   moment.utc(params_.value[1] !== undefined ? params_.value[1] : null ),
                    // ]}
                    value={[
                      params_.value !== undefined
                        ? moment.utc(params_.value[0])
                        : moment.utc(Od),
                      params_.value !== undefined
                        ? moment.utc(params_.value[1])
                        : moment.utc(Do),
                    ]}
                    sx={stylingRangePicker}
                    calendars={window.innerWidth < 642 ? 1 : 2}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </ThemeProvider>
          </span>
        );
      case "dropDownZone":
        return (
          <>
            <DropzoneArea
              //povolene pripony na PHP
              //.gif|.jpeg|.jpg|.png|.html|.doc|.docx|.xls|.xlsx|.xlsm|.txt|.pdf|.zip|.gz|.rar|.tar|.csv|.GIF|.JPEG|.JPG|.PNG|.HTML|.DOC|.DOCX|.XLS|.XLSX|.TXT|.PDF|.ZIP|.GZ|.RAR|.TAR|.CSV|.msg|.MSG
              acceptedFiles={[
                ".gif",
                ".jpeg",
                ".jpg",
                ".png",
                ".html",
                ".doc",
                ".docx",
                ".xls",
                ".xlsx",
                ".xlsm",
                ".txt",
                ".pdf",
                ".zip",
                ".gz",
                ".rar",
                ".tar",
                ".csv",
                ".GIF",
                ".JPEG",
                ".JPG",
                ".PNG",
                ".HTML",
                ".DOC",
                ".DOCX",
                ".XLS",
                ".XLSX",
                ".TXT",
                ".PDF",
                ".ZIP",
                ".GZ",
                ".RAR",
                ".TAR",
                ".CSV",
                ".msg",
                ".MSG",
              ]}
              dropzoneText={"Vložte subory"}
              dropzoneClass={styles.dropzone}
              filesLimit={3}
              maxFileSize={4000000}
              initialFiles={[]}
              onChange={(files) => {
                setFiles(files);
                dataHandling(params_, files);
              }}
            />
          </>
        );
      default:
        return <div>{params_.value}</div>;
    }
  }

  return (
    <>
      <Modal
        widthModal={
          Formdata.loading
            ? 700
            : Formdata.data.width + 20 + 30 > window.innerWidth
            ? window.innerWidth
            : Formdata.data.width + 20 + 30
        }
        heightModal={500}
        outerClassName={styles.outerZaznamProblemu}
        //visible={visible}
        visible={visible && !Formdata.loading}
        //id={"modal"}
      >
        <>
          {Formdata.loading ? (
            <>
              <button
                onClick={() => {
                  setVisible(false);
                }}
              >
                <Icon name="close" size="20" />
              </button>
            </>
          ) : (
            <div style={{ width: "100%" }}>
              <div name={"ico_title"} className={styles.ico_Title}>
                <div>{Formdata.data.title}</div>
                <button
                  onClick={() => {
                    if (priznak === "ZaznamReklamace") {
                      navigate.push("/reklamace/prehled");
                      return;
                    }
                    setVisible(false);
                  }}
                >
                  <Icon name="close" size="20" />
                </button>
              </div>
              <div
                style={{
                  height:
                    height === null
                      ? window.innerHeight < 900
                        ? window.innerHeight - 150
                        : 800
                      : height,
                }}
                className={styles.content}
                ref={elementRef}
                id="element"
              >
                {Formdata.data.sections.map((section) => {
                  return (
                    <>
                      <div className={styles.sectionTitle}>{section.title}</div>
                      <div className={styles.panel}>
                        {section.fieldsets.map((polozky) => {
                          return (
                            <div className={styles.polozky_row}>
                              {polozky.fields.map((polozka) => {
                                return (
                                  <>
                                    {Formdata.data.values[polozka].when_fcia ===
                                    "hidden" ? (
                                      <></>
                                    ) : (
                                      <div
                                        className={
                                          Formdata.data.values[polozka]
                                            .inputType === "checkbox" ||
                                          Formdata.data.values[polozka]
                                            .inputType === "link"
                                            ? styles.polozkavyjimka
                                            : Formdata.data.values[polozka]
                                                .inputType !== "grid"
                                            ? styles.polozka
                                            : styles.polozkavyjimka2
                                        }
                                      >
                                        <div className={styles.title}>
                                          <div className={styles.label}>
                                            {
                                              Formdata.data.values[polozka]
                                                .label
                                            }
                                          </div>
                                          {Formdata.data.values[polozka]
                                            .hlp_msg === undefined ? (
                                            <></>
                                          ) : (
                                            <Tooltip
                                              title={
                                                Formdata.data.values[polozka]
                                                  .hlp_msg
                                              }
                                            >
                                              <i
                                                className={styles.info}
                                                class="fa fa-info-circle"
                                                style={{
                                                  color: "#F1B600",
                                                  cursor: "pointer",
                                                }}
                                              ></i>
                                            </Tooltip>
                                          )}
                                        </div>
                                        <div>
                                          {getComponent(
                                            Formdata.data.values[polozka]
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  );
                })}
              </div>
              {/* <button onClick={()=>{
                document
                .getElementsByName(
                  Formdata.data.values[Object.keys(Formdata.data.values)[0]].id
                )[0]
                .focus();
              }}>test</button> */}
            </div>
          )}
          <div className={styles.saveArea}>
            <div
              onClick={() => {
                pokracovat();
              }}
              className={styles.save}
            >
              {Formdata.data.buttonText}
            </div>
          </div>
        </>
      </Modal>
      <ModalSelector
        className={styles}
        visible={ModalSelectorVisible}
        setVisible={setModalSelectorVisible}
        loading={loading}
        fields={fields}
        params={params}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        titleSelector={titleSelector}
        id={id}
        validation={validationForm}
        selectorData={selectorData}
        selectorCount={selectorCount}
        search={selector}
        page={selectorPage}
        setPage={setSelectorPage}
      />
      <ToastContainer />
    </>
  );
};
export default App;
