import { createSlice } from "@reduxjs/toolkit";

export const zmenaPobockySlice = createSlice({
  name: "zmenaPobocky",
  initialState: {
    data: [],
    loading: true,
    comment: "",
    vybrane: false
  },
  reducers: {
    zmenaPobocky: (state, action) => {
      console.log("data + loading: false");
      state.data = action.payload;
      state.loading = false;
    },
    loadingZmenaPobocky: (state) => {
      console.log("loading: true");
      state.loading = true;
    },
    setComment: (state, action) => {
      state.comment = action.payload;
    },
    vybrane: (state, action) => {
      state.vybrane = action.payload;
    },
  },
});

export const { zmenaPobocky, loadingZmenaPobocky, setComment, vybrane} =
  zmenaPobockySlice.actions;

export const zmenaPobockyData = (state) => state.zmenaPobockydata;

export default zmenaPobockySlice.reducer;
