import Modal from "../../components/Modal";
import ModalEX from "./ModalEX";
import ModalNA from "./ModalNA";
import ModalPS from "./ModalPS";
import PrijemSurovin from "./PrijemSurovin";
import Card from "../../components/Card";
import Icon from "../../components/Icon";
import Icons from "../../components/isons";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  pdfUrl,
  postExDodSurovinyUrl,
  prijemsUrl,
} from "../../features/urlHelper";
import {
  externyDodavatelTable,
  externyDodavatelTableData,
  loadingExternyDodavatelTable,
} from "./ModalEX/Table/redux/tableExternyDodavatelSlice";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { login, loginData } from "../SignIn/redux/loginSlice";
import {
  setDodaciList,
  zmenaData,
  vybrate,
  zmena,
  setComment,
  loadingZmena,
} from "../../components/Modaly/ModalZmena/redux/zmenaSlice";
import swal from "sweetalert";
import {
  loadingPrijemOdJinePobockyTable,
  prijemOdJinePobockyTable,
  prijemOdJinePobockyTableData,
  setZaznamy,
} from "./ModalPS/Table/redux/tablePrijemOdJinePobockySlice";
import {
  loadingNakupTable,
  nakupTable,
  nakupTableData,
} from "./ModalNA/Table/redux/tableNakupSlice";
import { modal, modalData } from "../../components/Modaly/redux/modalSlice";
import { getPdf } from "../../features/getPdf";
import { useHistory } from "react-router";

const App = ({ className }) => {
  const dispatch = useDispatch();
  const modaldata = useSelector(modalData);
  const navigate = useHistory();
  const [zobrazTrellis, setZobrazTrellis] = useState(false);
  const [myFiles, setMyFiles] = useState([]);

  function clear_prijate() {
    setLoader(false);
    setZaplaceno(0);
    setDodlist("");
    dispatch(setComment(""));
    dispatch(loadingExternyDodavatelTable());
    dispatch(externyDodavatelTable([]));
    dispatch(loadingNakupTable());
    dispatch(nakupTable([]));
    dispatch(vybrate(false));
    dispatch(setDodaciList(""));
    dispatch(loadingPrijemOdJinePobockyTable());
    dispatch(prijemOdJinePobockyTable([]));
    dispatch(loadingZmena());
    dispatch(zmena([]));
    dispatch(setZaznamy([]));
    setZobrazTrellis(false);
    setMyFiles([]);
  }

  useEffect(() => {
    clear_prijate();
  }, []);

  const zmenadata = useSelector(zmenaData);
  const externyDodavatelTabledata = useSelector(externyDodavatelTableData);
  const prijemOdJinePobockyTabledata = useSelector(
    prijemOdJinePobockyTableData
  );
  const nakupTabledata = useSelector(nakupTableData);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );

  const [visibleZaznamyProblemov, setVisibleZaznamyProblemov] = useState(false);

  const [button1Active, setButton1Active] = useState(false);
  const [button2Active, setButton2Active] = useState(false);
  const [button3Active, setButton3Active] = useState(false);
  const [button4Active, setButton4Active] = useState(true);

  const [loader, setLoader] = useState(false);

  const [zaplaceno, setZaplaceno] = useState(0);
  const [dodList, setDodlist] = useState("");

  const [editExOver, setEditExOver] = React.useState([]);
  const [editPsOver, setEditPsOver] = React.useState([]);

  return (
    <Card bezPaddingu={false} className={cn(styles.card, className)}>
      <div className={styles.headModalENP}>
        <div className={styles.headModalTlacidla}>
          <button
            onClick={() => {
              setButton1Active(false);
              setButton2Active(false);
              setButton3Active(false);
              setButton4Active(true);
              clear_prijate();
            }}
            className={
              button4Active
                ? styles.headModalButtonsActive
                : styles.headModalButtons
            }
          >
            Příjem ze Žiželic
          </button>
          <button
            onClick={() => {
              setButton1Active(true);
              setButton2Active(false);
              setButton3Active(false);
              setButton4Active(false);
              clear_prijate();
            }}
            className={
              button1Active
                ? styles.headModalButtonsActive
                : styles.headModalButtons
            }
          >
            Externi dodavatel
          </button>
          <button
            onClick={() => {
              setButton1Active(false);
              setButton2Active(true);
              setButton3Active(false);
              setButton4Active(false);
              clear_prijate();
            }}
            className={
              button2Active
                ? styles.headModalButtonsActive
                : styles.headModalButtons
            }
          >
            Nákup v hotovosti
          </button>
          <button
            onClick={() => {
              setButton1Active(false);
              setButton2Active(false);
              setButton3Active(true);
              setButton4Active(false);
              clear_prijate();
            }}
            className={
              button3Active
                ? styles.headModalButtonsActive
                : styles.headModalButtons
            }
          >
            Příjem od jiné pobočky
          </button>
        </div>
        <div className={styles.headModalTlacidlaZ}>
          <div className={styles.center}>
            {loader ? (
              <button
                className={
                  window.innerWidth > 700
                    ? styles.buttonZmena
                    : styles.headModalButtons
                }
              >
                {window.innerWidth > 700 ? (
                  <>
                    <TailSpin
                      height="24"
                      width="24"
                      color="white"
                      ariaLabel="loading"
                      wrapperStyle
                      wrapperClass
                    />
                    Dokončit
                  </>
                ) : (
                  <TailSpin
                    height="24"
                    width="24"
                    color="white"
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass
                  />
                )}
              </button>
            ) : (
              <button
                disabled={loader}
                onClick={async () => {
                  if (button1Active) {
                    swal({
                      content: {
                        element: "div",
                        attributes: {
                          innerHTML:
                            '<div style="color: green;"><div>Chcete dokončit příjem vybraných položek</div><div>od externího dodavatele?</div></div>',
                        },
                      },
                      icon: "info",
                      buttons: ["Ne ", "Ano"],
                    }).then(async (willShow) => {
                      if (willShow) {
                        if (
                          editExOver.filter((cell) => cell.over).length !==
                          editExOver.length
                        ) {
                          swal(
                            "Neukončená editace !",
                            "Před zaevidováním příjmu dokončete úpravy.",
                            "error"
                          );
                          return;
                        }
                        setLoader(true);
                        const dodavatel = zmenadata.data.filter(
                          (dodavatel) => dodavatel.vybrane
                        );

                        if (dodavatel.length === 0) {
                          swal("Chyba!", "Vyberte dodavatele", "error");
                          setLoader(false);
                        }

                        const postData = externyDodavatelTabledata.data.filter(
                          (row) => row.pocet !== 0
                        );
                        console.log(externyDodavatelTabledata.data);

                        if (dodavatel.length !== 0) {
                          await axios
                            .post(
                              postExDodSurovinyUrl,
                              {
                                stredisko:
                                  logindata.UserData.vybrane_stredisko.kod,
                                dod_list: zmenadata.dodaciList,
                                pohyby: postData,
                                ico: dodavatel[0].ico,
                                hotovost: null,
                              },
                              {
                                headers: {
                                  Authorization: "Bearer " + logindata.Token,
                                },
                              }
                            )

                            .then(function (response) {
                              console.log(response.data);
                              if (response.data.html !== null) {
                                dispatch(
                                  modal({
                                    data: {
                                      ...modaldata,
                                      ZobrazHtml: true,
                                      HtmlData: response.data.html,
                                      ZobrazHtml_print: response.data.print,
                                      ZobrazHtml_text: response.data.text,
                                    },
                                  })
                                );
                              }
                              if (
                                response.data.html === null &&
                                response.data.print !== null
                              ) {
                                swal({
                                  title: response.data.text,
                                  text: response.data.print
                                    ? "Chcete zobrazit doklad?"
                                    : "",
                                  icon: "success",
                                  buttons: response.data.print
                                    ? ["Ne ", "Ano"]
                                    : null,
                                  dangerMode: true,
                                }).then(async (willShow) => {
                                  if (willShow && response.data.print) {
                                    console.log(response.data);

                                    getPdf(
                                      response.data.print,
                                      logindata.Token,
                                      dispatch,
                                      navigate
                                    );
                                  }
                                });
                              }

                              clear_prijate();
                            })
                            .catch(function (error) {
                              console.log(error.response.data);
                              if (error.response.status === 400) {
                                swal("Chyba!", error.response.data, "error");
                              } else if (error.response.status === 401) {
                                navigate.push("/");
                                //dispatch(login({ data: {} }));
                                window.sessionStorage.setItem(
                                  "logindata",
                                  JSON.stringify({})
                                );
                              } else if (
                                error.response.status > 401 &&
                                error.response.status < 500
                              ) {
                                swal(
                                  "Chyba!",
                                  "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
                                  "error"
                                );
                              } else {
                                swal(
                                  "Chyba!",
                                  " Nastala chyba na serveri ERP, zkuste to později.",
                                  "error"
                                );
                              }
                              setLoader(false);
                            });
                        }
                      }
                    });
                  }
                  if (button2Active) {
                    setLoader(true);

                    await axios
                      .post(
                        postExDodSurovinyUrl,
                        {
                          stredisko: logindata.UserData.vybrane_stredisko.kod,
                          dod_list: dodList,
                          pohyby: nakupTabledata.data,
                          ico: null,
                        },
                        {
                          headers: {
                            Authorization: "Bearer " + logindata.Token,
                          },
                        }
                      )
                      .then(function (response) {
                        if (response.data.html !== null) {
                          dispatch(
                            modal({
                              data: {
                                ...modaldata,
                                ZobrazHtml: true,
                                HtmlData: response.data.html,
                                ZobrazHtml_print: response.data.print,
                                ZobrazHtml_text: response.data.text,
                              },
                            })
                          );
                        }
                        if (
                          response.data.html === null &&
                          response.data.print !== null
                        ) {
                          swal({
                            title: response.data.text,
                            text: response.data.print
                              ? "Chcete zobrazit doklad?"
                              : "",
                            icon: "info",
                            buttons: response.data.print
                              ? ["Ne ", "Ano"]
                              : null,
                            dangerMode: true,
                          }).then(async (willShow) => {
                            if (willShow && response.data.print) {
                              console.log(response.data);

                              getPdf(
                                response.data.print,
                                logindata.Token,
                                dispatch,
                                navigate
                              );
                            }
                          });
                        }

                        // swal(
                        //   response.data.text,
                        //   "Tisk dokladu: " + response.data.print,
                        //   "success"
                        // );

                        // getPdf(
                        //   response.data.print,
                        //   logindata.Token,
                        //   dispatch,
                        //   navigate
                        // );

                        clear_prijate();
                      })
                      .catch(function (error) {
                        console.log(error.response.data);
                        if (error.response.status === 400) {
                          swal("Chyba!", error.response.data, "error");
                        } else if (error.response.status === 401) {
                          navigate.push("/");
                          //dispatch(login({ data: {} }));
                          window.sessionStorage.setItem(
                            "logindata",
                            JSON.stringify({})
                          );
                        } else if (
                          error.response.status > 401 &&
                          error.response.status < 500
                        ) {
                          swal(
                            "Chyba!",
                            "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
                            "error"
                          );
                        } else {
                          swal(
                            "Chyba!",
                            " Nastala chyba na serveri ERP, zkuste to později.",
                            "error"
                          );
                        }
                        setLoader(false);
                      });
                  }
                  if (button3Active || button4Active) {
                    //console.log(prijemOdJinePobockyTabledata.zaznamy);
                    swal({
                      title: "Dokončit",
                      text: "Chcete položky skutečně přijmout?",
                      icon: "success",
                      buttons: ["Ne ", "Ano"],
                    }).then(async (willShow) => {
                      if (willShow) {
                        if (
                          editPsOver.filter((cell) => cell.over).length !==
                          editPsOver.length
                        ) {
                          swal(
                            "Neukončená editace !",
                            "Před zaevidováním příjmu dokončete úpravy.",
                            "error"
                          );
                          return;
                        }

                        if (
                          prijemOdJinePobockyTabledata.data.filter(
                            (x) => x.mnozstvo < x.prijate
                          ).length > 0
                        ) {
                          swal(
                            "Chyba!",
                            "Nemůžete přijmout větší množství, než bylo posláno",
                            "error"
                          );
                          return;
                        }

                        if (
                          prijemOdJinePobockyTabledata.data.filter(
                            (x) => !x.stav && !x.problem
                          ).length > 0
                        ) {
                          swal(
                            "Chyba!",
                            "Před ukončením musíte položky buď přijmout nebo zaznamenat problém.",
                            "error"
                          );
                          return;
                        }

                        if (prijemOdJinePobockyTabledata.zaznamy.length > 0) {
                          setVisibleZaznamyProblemov(true);
                          setLoader(false);
                          return;
                        }

                        setLoader(true);
                        const doklad = zmenadata.data.filter(
                          (doklad) => doklad.vybrane
                        );
                        console.log("doklad", doklad);
                        if (doklad.length === 0) {
                          swal("Chyba!", "Vyberte doklad", "error");
                          setLoader(false);
                        }
                        //console.log()
                        if (doklad.length !== 0) {
                          await axios
                            .post(
                              prijemsUrl,
                              {
                                stredisko:
                                  logindata.UserData.vybrane_stredisko.kod,
                                pohyby: prijemOdJinePobockyTabledata.data,
                                zaznamy: prijemOdJinePobockyTabledata.zaznamy,
                                doklad: doklad[0].doklad,
                                dodavatel: doklad[0].stredisko,
                                poznamka: zmenadata.comment,
                              },
                              {
                                headers: {
                                  Authorization: "Bearer " + logindata.Token,
                                },
                              }
                            )

                            .then(function (response) {
                              console.log(response.data);
                              if (response.data.html !== null) {
                                dispatch(
                                  modal({
                                    data: {
                                      ...modaldata,
                                      ZobrazHtml: true,
                                      HtmlData: response.data.html,
                                      ZobrazHtml_print: response.data.print,
                                      ZobrazHtml_text: response.data.text,
                                      ZobrazHtml_title:
                                        "Příjem je úspěšně dokončen",
                                    },
                                  })
                                );
                              }
                              if (
                                response.data.html === null &&
                                response.data.print !== null
                              ) {
                                swal({
                                  title: response.data.text,
                                  text: response.data.print
                                    ? "Chcete zobrazit doklad?"
                                    : "",
                                  icon: "success",
                                  buttons: response.data.print
                                    ? ["Ne ", "Ano"]
                                    : null,
                                  dangerMode: true,
                                }).then(async (willShow) => {
                                  if (willShow && response.data.print) {
                                    console.log(response.data);

                                    getPdf(
                                      response.data.print,
                                      logindata.Token,
                                      dispatch,
                                      navigate
                                    );
                                  }
                                });
                              }

                              clear_prijate();
                            })
                            .catch(function (error) {
                              console.log(error.response.data);
                              if (error.response.status === 400) {
                                swal("Chyba!", error.response.data, "error");
                              } else if (error.response.status === 401) {
                                navigate.push("/");
                                //dispatch(login({ data: {} }));
                                window.sessionStorage.setItem(
                                  "logindata",
                                  JSON.stringify({})
                                );
                              } else if (
                                error.response.status > 401 &&
                                error.response.status < 500
                              ) {
                                swal(
                                  "Chyba!",
                                  "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
                                  "error"
                                );
                              } else {
                                swal(
                                  "Chyba!",
                                  " Nastala chyba na serveri ERP, zkuste to později.",
                                  "error"
                                );
                              }
                              setLoader(false);
                            });
                        }
                      }
                    });
                  }
                }}
                className={
                  window.innerWidth > 700
                    ? styles.buttonZmena
                    : styles.buttonZmena
                }
              >
                {window.innerWidth > 700 ? (
                  <>
                    <Icon name="check" size="24" />
                    Dokončit
                  </>
                ) : (
                  <Icon name="check" size="24" />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      <>
        {button1Active ? (
          <ModalEX
            editExOver={editExOver}
            setEditExOver={setEditExOver}
            myFiles={myFiles}
            setMyFiles={setMyFiles}
          />
        ) : (
          <></>
        )}
        {button2Active ? (
          <ModalNA
            setZaplaceno={setZaplaceno}
            zaplaceno={zaplaceno}
            setDodlist={setDodlist}
            dodList={dodList}
            myFiles={myFiles}
            setMyFiles={setMyFiles}
          />
        ) : (
          <></>
        )}
        {button3Active ? (
          <ModalPS
            editPsOver={editPsOver}
            setEditPsOver={setEditPsOver}
            visibleZaznamyProblemov={visibleZaznamyProblemov}
            setVisibleZaznamyProblemov={setVisibleZaznamyProblemov}
            zobrazTrellis={zobrazTrellis}
            setZobrazTrellis={setZobrazTrellis}
            priznak={"Other"}
          />
        ) : (
          <></>
        )}
        {button4Active ? (
          <ModalPS
            editPsOver={editPsOver}
            setEditPsOver={setEditPsOver}
            visibleZaznamyProblemov={visibleZaznamyProblemov}
            setVisibleZaznamyProblemov={setVisibleZaznamyProblemov}
            zobrazTrellis={zobrazTrellis}
            setZobrazTrellis={setZobrazTrellis}
            priznak={"Zizelice"}
          />
        ) : (
          <></>
        )}
      </>
    </Card>
  );
};
export default App;
