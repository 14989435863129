import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import { RootState } from "../../redux/store";
import styles from "./Moduly.module.sass";
import { loginData, login } from "../SignIn/redux/loginSlice";
import Card from "../../components/Card";
import { Padding } from "@mui/icons-material";
import {
  objednavky_typERP_OAllRows,
  objednavky_typERP_ORows,
} from "../Objednavky_typERP_O/redux/objednavky_typERP_OSlice";

function MenuInfo() {
  const navigate = useHistory();
  const dispatch = useDispatch();
  //const logindata = useSelector(loginData);
  const [search, setSearch] = useState("");
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );

  function getGrid() {
    if (window.innerWidth >= 825) {
      return "repeat(3, 1fr)";
    } else if (window.innerWidth >= 480) {
      return "repeat(2, 1fr)";
    } else {
      return "repeat(1, 1fr)";
    }
  }

  return (
    <div style={{ height: window.innerHeight }}>
      <Card>
        <div className={styles.x_}>
          <div
            className={styles.x}
            onClick={() => {
              navigate.push("/");
              //dispatch(login({ data: {} }));
              window.sessionStorage.setItem("logindata", JSON.stringify({}));
            }}
          >
            <i class="fa fa-close"></i>
          </div>
        </div>
        <div className={styles.title_x}>
          <div className={styles.title}>
            <h2>Vyberte odběratele</h2>
          </div>
          <div className={styles.search}>
            <input
              className={styles.input}
              type="text"
              placeholder="Hledejte"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>

        <div
          className={styles.overlay}
          style={{
            display: "grid",
            gridTemplateColumns: getGrid(),
          }}
        >
          {logindata.UserData.vyber_ico_zoznam
            .filter(
              (x) =>
                x.ico.toUpperCase().includes(search.toUpperCase()) ||
                x.odberatel.toUpperCase().includes(search.toUpperCase())
            )
            .map((modul, index) => {
              return (
                <div
                  key={index}
                  className={styles.button}
                  onClick={() => {
                    let data = { ...logindata };
                    let userData = { ...logindata.UserData };
                    userData.ico = modul.ico;
                    userData.nazov_ico = modul.odberatel;
                    data.UserData = userData;
                    console.log("zmena ica", data);

                    //dispatch(login({ data: data }));
                    window.sessionStorage.setItem(
                      "logindata",
                      JSON.stringify(data)
                    );
                    dispatch(objednavky_typERP_OAllRows([]));
                    dispatch(objednavky_typERP_ORows([]));
                    navigate.push("/objednavkyOstatne");
                  }}
                >
                  {modul.odberatel + " (" + modul.ico + ")"}
                </div>
              );
            })}
        </div>
      </Card>
    </div>
  );
}

export default MenuInfo;
