import React, { useEffect, useState } from "react";
import styles from "./CustomerList.module.sass";
import cn from "classnames";
import Card from "../../components/Card";
import Table from "./Table";

const CustomerList = () => {

  const [visible, setVisible] = useState(false);

  return (
    <>
      <Card bezPaddingu={false} className={cn(styles.card)}>
        <div className={cn(styles.row, { [styles.flex]: visible })}>
          <Table
            className={styles.table}
            activeTable={visible}
            setActiveTable={setVisible}
          />
        </div>
      </Card>
    </>
  );
};

export default CustomerList;
