import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { persistStore } from "redux-persist";
import store from "../../redux/store";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useHistory } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { loginUrl, notifikaciaUrl } from "./../../features/urlHelper";
import { login, loginData } from "./redux/loginSlice";
import { TailSpin } from "react-loader-spinner";
import { signalRData } from "./redux/signalRSlice";
//import { signalRData } from "./redux/signalRSlice";

const SignIn = ({ logindata, setLogindata }) => {
  let persistor = persistStore(store);
  const connection = useSelector(signalRData);
  const heightWindow = use100vh();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [log, setLog] = useState(true);
  const [logText, setLogText] = useState("");
  const dispatch = useDispatch();
  const navigate = useHistory();

  const [loading, setLoading] = useState(false);
  const [isDevelop, setIsDevelop] = useState(
    window.location.hostname === "localhost" ? true : false
  );

  useEffect(() => {
    //console.log(caches)
    // const ws = new WebSocket("ws://localhost:3000");
    // ws.close();
    // if (connection.connectionObjednavky !== undefined && connection.connectionObjednavky) {
    //   connection.connectionObjednavky.stop();
    // }
    navigate.replace("");
    if ("caches" in window) {
      caches.keys().then((cacheNames) => {
        console.log(cacheNames);
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
      //localStorage.clear();
      //sessionStorage.clear();
      window.sessionStorage.setItem("logindata", JSON.stringify({}));
      //window.location.reload(true);
      console.log("cache is deleted");
    }
    persistor.pause();
    persistor.flush().then(() => {
      persistor.purge();
      persistor.purge();
    });
  }, []);

  function verzia() {
    if (window.location.hostname === "marvin.crocodille.com") {
      return "";
    }
    if (window.location.hostname === "marvin.ad.croco.cz") {
      return "";
    }
    if (window.location.hostname === "marvintest.ad.croco.cz") {
      return "Staging";
    }
    if (window.location.hostname === "erp.iist.inver.sk") {
      return "Test Inver";
    }
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname.slice(0, 4) === "172."
    ) {
      return "Local";
    }
    if (
      window.location.hostname.slice(0, 3) === "10." ||
      window.location.hostname.slice(0, 4) === "192."
    ) {
      return "Local";
    }
    return "";
  }

  async function loginTo(username, password) {
    setLoading(true);
    await axios
      .post(loginUrl, {
        username: username,
        password: password,
      })
      .then(function (response) {
        console.log(response);
        setLoading(true);

        let data = { ...response.data };
        let userdata = { ...response.data.UserData };
        let HeaderOptions = [];

        switch (response.data.UserData.typErp) {
          case "C":
            HeaderOptions = response.data.UserData.vyber_stredisko_zoznam.map(
              (option) => {
                return { value: option.kod, label: option.nazov + "(" + option.kod + ")"  };
              }
            );
            break;
          case "B":
            HeaderOptions = response.data.UserData.vyber_stredisko_zoznam.map(
              (option) => {
                return { value: option.kod, label: option.nazov + "(" + option.kod + ")"};
              }
            );
            break;
          case "R":
            HeaderOptions = response.data.UserData.vyber_ico_zoznam.map(
              (option) => {
                return { value: option.ico, label: option.odberatel + "(" + option.ico + ")" };
              }
            );
            break;
        }

        // if (response.data.UserData.vyber_stredisko)
        //   HeaderOptions = response.data.UserData.vyber_stredisko_zoznam.map(
        //     (option) => {
        //       return { value: option.kod, label: option.nazov };
        //     }
        //   );
        // if (response.data.UserData.vyber_ico)
        //   HeaderOptions = response.data.UserData.vyber_ico_zoznam.map(
        //     (option) => {
        //       return { value: option.ico, label: option.odberatel };
        //     }
        //   );
        let userData = {
          ...userdata,
          moduly: response.data.UserData.strediska,
          HeaderOptions: HeaderOptions,
        };

        console.log(HeaderOptions);

        data.UserData = userData;

        //dispatch(login({ data: data }));

        window.sessionStorage.setItem("logindata", JSON.stringify(data));

        setLogindata(data);

        //window.sessionStorage.setItem("logindata", JSON.stringify(data))

        setLoading(false);
        switch (userData.typErp) {
          case "C":
            navigate.push("/moduly");
            break;
          case "B":
            navigate.push("/prehled");
            break;
          case "R":
            if (userData.vyber_ico) navigate.push("/moduly_vyber_ico_zoznam");
            else navigate.push("/objednavkyOstatne");
            break;
          case "W":
            navigate.push("/kodys_truckroutes_details");
            break;
          default:
            if (userData.vyber_stredisko)
              navigate.push("/moduly_vyber_stredisko_zoznam");
            else navigate.push("/objednavkyOstatne");
            break;
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
        //swal("Chyba!", error.response.data, "error");
        if ("ERR_NETWORK" === error.code) {
          setLogText(
            "Server je momentálně nedostupný. \nOpakujte přihlášení později."
          );
          setLog(false);
          return;
        }
        if (typeof error.response.data !== "string") {
          setLogText("Pole hesla a uživatelského jména jsou povinné.");
          setLog(false);
          return;
        }
        if ("ERR_BAD_REQUEST" === error.code) {
          setLogText(error.response.data);
        }

        setLog(false);
      });
  }

  function butonyDev() {
    return (
      <>
        <div className={styles.butonynaprihlasenie}>
          <button
            onClick={() => {
              loginTo("test", "test");
            }}
            className={cn("button", styles.button_sign_in_help)}
          >
            Test
          </button>

          <button
            onClick={() => {
              loginTo("testadmin", "testadmin");
            }}
            className={cn("button", styles.button_sign_in_help)}
          >
            Admintest
          </button>
        </div>
        <div className={styles.butonynaprihlasenie}>
          <button
            onClick={() => {
              loginTo("testbb", "test");
            }}
            className={cn("button", styles.button_sign_in_help)}
          >
            Test-BB
          </button>
          <button
            onClick={() => {
              loginTo("testadminbb", "testadmin");
            }}
            className={cn("button", styles.button_sign_in_help)}
          >
            Admintest-BB
          </button>
        </div>
        <div className={styles.butonynaprihlasenie}>
          <button
            onClick={() => {
              loginTo("testobj", "test");
            }}
            className={cn("button", styles.button_sign_in_help)}
          >
            Test-Obj
          </button>
          <button
            onClick={() => {
              loginTo("testrekl", "test");
            }}
            className={cn("button", styles.button_sign_in_help)}
          >
            Test-Rekl
          </button>
        </div>
        <div className={styles.butonynaprihlasenie}>
          <button
            onClick={() => {
              loginTo("testWMS", "test");
            }}
            className={cn("button", styles.button_sign_in_help)}
          >
            Test-WMS Truck Road
          </button>
        </div>
      </>
    );
  }

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      {loading ? (
        <div>
          <div className={styles.loader}>
            <TailSpin
              height="24"
              width="24"
              color="white"
              ariaLabel="loading"
              wrapperStyle
              wrapperClass
            />
          </div>
          <div>Probíhá přihlašování</div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <Link className={styles.logo} to="/">
            <Image
              className={styles.pic}
              src="/images/content/logo.png"
              srcDark="/images/content/logo.png"
              alt="Core"
            />
          </Link>
          <div className={cn("h2", styles.title)}>Marvin</div>
          <div className={styles.head2}>{verzia()}</div>
          <div className={styles.head}></div>
          <div
            onKeyPress={async (e) => {
              if (e.key === "Enter") {
                loginTo(name, password);
              }
            }}
            className={styles.body}
          >
            <div className={styles.subtitle}>
              Zadejte přihlašovací údaje k vašemu účtu
            </div>
            <TextInput
              className={styles.field1}
              name="email"
              type="email"
              placeholder="Zadejte své jméno"
              required
              icon="profile-circle"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <TextInput
              className={styles.field2}
              name="password"
              type="password"
              placeholder="Zadejte své heslo"
              required
              icon="lock"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />

            <div
              hidden={log}
              style={{ whiteSpace: "pre-wrap" }}
              className={styles.badLogin}
            >
              {logText}
            </div>
            <button
              onClick={() => {
                loginTo(name, password);
              }}
              className={cn("button", styles.button_sign_in)}
            >
              Přihlásit
            </button>

            {isDevelop ? <>{butonyDev()}</> : <></>}
            <div className={styles.info}>
              <Link className={styles.link} to="/">
                Nemáte účet?{" "}
              </Link>
              Kontaktujte vedoucího vaší pobočky.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignIn;
