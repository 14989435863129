import { createSlice } from '@reduxjs/toolkit';

export const ludiaSlice = createSlice({
    name: 'ludia',
    initialState: {
        data: [],
        loading: true,
    },
    reducers: {
        ludia: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingLudia: (state) => {
            console.log("loading: true")
            state.loading = true
        }

    },
});

export const { ludia, loadingLudia} = ludiaSlice.actions;

export const ludiaData = state => state.ludiadata;


export default ludiaSlice.reducer;