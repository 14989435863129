import Modal from "../../components/Modal";
import Card from "../../components/Card";
import Icon from "../../components/Icon";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Search from "./Search";
import React, { useState } from "react";
import File from "../../components/File";
import { DropzoneArea } from "material-ui-dropzone";

const App = ({ className }) => {

  return (
    <div>
      <Card
      bezPaddingu={false}
        className={cn(styles.card, className)}
        title={"Reklamace"}
        classTitle="title-green"
        head={
          <div className={styles.headModal}>
            <div>
              <button
                className={
                  window.innerWidth > 700
                    ? cn("button", styles.headModalButton)
                    : styles.headModalButtons
                }
              >
                {window.innerWidth > 700 ? (
                  <>
                    <Icon name="check" size="24" />
                    Dokončit
                  </>
                ) : (
                  <Icon name="check" size="24" />
                )}
              </button>
            </div>
          </div>
        }
      >
         <div>Položka</div>
        <br></br>
        {/* <Search className={styles.searchHead} /> */}
        <br></br>
        <div className={styles.areaRekl}>
          <div>Komentář</div>
          <br></br>
          <div className={styles.area}>
            <textarea className={styles.textarea}></textarea>
          </div>
          <br></br>
          <div>Fotky k problému</div>
          <br></br>
          <div>
            <DropzoneArea
              acceptedFiles={["image/*"]}
              dropzoneText={"Vložte fotky"}
              dropzoneClass={styles.dropzone}
              onChange={(files) => console.log("Files:", files)}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
export default App;
