import React, { useState } from "react";
import { useEffect } from "react";
import styles from "./ProductViews.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import useDarkMode from "@fisch0920/use-dark-mode";
import Users from "./Users";
import Tooltip from "../../../components/Tooltip";
import Icon from "../../../components/Icon";
import Balance from "./Balance";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  commentsUrl,
  prehladUrl,
  soutezUrl,
  zamestnanciUrl,
} from "./../../../features/urlHelper";
import axios from "axios";
import ContentLoader from "react-content-loader";
import { useSelector, useDispatch } from "react-redux";
import { login, loginData } from "./../../SignIn/redux/loginSlice";
import { ludiaData, ludia, loadingLudia } from "./redux/ludiaSlice";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../../features/errorDialog";

const ProductViews = ({ className }) => {
  const darkMode = useDarkMode(false);
  const dispatch = useDispatch();
  const navigate = useHistory();

  const nav = [
    {
      title: "Zaměstnanci",
      icon: "person",
      color: "#B1E5FC",
      value: 37.8,
    },
  ];
  const [scrollBar, setScrollBar] = useState("list_without_scrollbar");

  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const ludiadata = useSelector(ludiaData);

  useEffect(() => {
    dispatch(loadingLudia());
    result();
  }, []);

  async function result() {
    await axios
      .get(
        zamestnanciUrl +
          logindata.UserData.vybrane_stredisko.kod +
          "/" +
          logindata.UserData.datum,
        { headers: { Authorization: "Bearer " + logindata.Token } }
      )
      .then(function (response) {
        console.log("zamestnanci", response.data);
        dispatch(ludia(response.data));
        //dispatch(loadingLudia({ data: false }))
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <Card
      className={cn(styles.card, className)}
      title="Zaměstnanci"
      classTitle="title-blue"
    >
      <div 
      onMouseLeave={() => {
        setScrollBar("list_without_scrollbar");
      }}
      onMouseEnter={() => {
        console.log("focused")
        setScrollBar("list");
      }}>
        {ludiadata.loading ? (
          <ContentLoader
            speed={2}
            width={"100%"}
            height={200}
            viewBox="0 0 400 100"
            backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
            foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
          >
            <rect x="5%" y="2%" rx="3" ry="3" width={"90%"} height="40%" />

            <circle cx="20%" cy="70%" r="20" />
            <rect x="12.5%" y="92%" rx="3" ry="3" width={"60"} height="3" />
            <rect x="15%" y="97%" rx="3" ry="3" width={"40"} height="3" />
            <circle cx="40%" cy="70%" r="20" />
            <rect x="32.5%" y="92%" rx="3" ry="3" width={"60"} height="3" />
            <rect x="35%" y="97%" rx="3" ry="3" width={"40"} height="3" />
            <circle cx="60%" cy="70%" r="20" />
            <rect x="52.5%" y="92%" rx="3" ry="3" width={"60"} height="3" />
            <rect x="55%" y="97%" rx="3" ry="3" width={"40"} height="3" />
            <circle cx="80%" cy="70%" r="20" />
            <rect x="72.5%" y="92%" rx="3" ry="3" width={"60"} height="3" />
            <rect x="75%" y="97%" rx="3" ry="3" width={"40"} height="3" />
          </ContentLoader>
        ) : (
          <div className={styles.overview}>
            <div className={styles.nav}>
              <div className={cn(styles.item, className)}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: nav[0].color }}
                >
                  <PersonOutlineIcon className={styles.ico} />
                </div>
                <div className={styles.details}>
                  <div className={styles.subtitle}>
                    {nav[0].title}
                    <Tooltip
                      className={styles.tooltip}
                      title={ludiadata.data.pocetZamestnancov}
                      icon="info"
                      place="top"
                    />
                  </div>
                  <div className={styles.counter}>
                    {ludiadata.data.pocetZamestnancov + " x"}
                  </div>
                </div>
                <Balance
                  className={styles.balance}
                  value={ludiadata.data.produktivitaPeniaze}
                  value2={ludiadata.data.produktivita}
                  value3={ludiadata.data.odpracovaneHodiny}
                  mena={logindata.UserData.vybrane_stredisko.mena}
                  background
                />
              </div>
            </div>
            <div className={styles.body}>
              <Users scrollBar={scrollBar}  data={ludiadata.data.zamestnanci} />
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default ProductViews;
