import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useDispatch, useSelector } from "react-redux";
import { login, loginData } from "../../screens/SignIn/redux/loginSlice";
import DetekciaNeaktivity from "../DetekciaNeaktivity";

const Page = ({ wide, children, title }) => {
  const [visible, setVisible] = useState(false);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const [ldata, setLdata] = useState(
    JSON.parse(
      window.sessionStorage.getItem("logindata") !== null
        ? window.sessionStorage.getItem("logindata")
        : "{}"
    )
  );
  const navigate = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (logindata.UserData === undefined) {
      navigate.push("/");
      //dispatch(login({ data: {} }));
      window.sessionStorage.setItem("logindata", JSON.stringify({}))
    }
    let expiration_time = new Date(logindata.TokenExpirationTime * 1000);
    expiration_time = new Date(expiration_time.toUTCString());
    const current_time = new Date(new Date().toUTCString());
    if (current_time > expiration_time) {
      navigate.push("/");
      //dispatch(login({ data: {} }));
      window.sessionStorage.setItem("logindata", JSON.stringify({}))
    }
  }, []);

  return (
    <>
      {logindata.UserData === undefined ? (
        <></>
      ) : (
        <div className={styles.page}>
          <Sidebar
            typErp={logindata.UserData.typErp}
            className={cn(styles.sidebar, { [styles.visible]: visible })}
            setVisible={setVisible}
            visible={visible}
          />
          <Header
            typErp={logindata.UserData.typErp}
            onOpen={() => setVisible(true)}
            ldata={ldata}
            setLdata={setLdata}
          />
          <div className={styles.inner}>
            <div
              className={cn(styles.container, {
                [styles.wide]: wide,
              })}
            >
              {title && <div className={cn("h3", styles.title)}>{title}</div>}
              {children}
            </div>
          </div>
          <DetekciaNeaktivity />
        </div>
      )}
    </>
  );
};

export default withRouter(Page);
