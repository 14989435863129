import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import Search from "./Search";
import Messages from "./Messages";
import Create from "./Create";
import Notification from "./Notification";
import User from "./User";
import { useDispatch, useSelector } from "react-redux";
import { loginData } from "../../screens/SignIn/redux/loginSlice";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../features/errorDialog";
import {
  neprecitaneNotifikacieUrl,
  notifikaciaUrl,
} from "../../features/urlHelper";
import axios from "axios";
import {
  setConnectionLoaded,
  setConnectionNotificationsUnreadID,
  signalRData,
} from "../../screens/SignIn/redux/signalRSlice";
import { HubConnectionBuilder } from "@microsoft/signalr";

import ModalEditTagovPolozky from "./../Modaly/ModalEditTagovPolozky";
import ModalZobrazHtml from "./../Modaly/ModalZobrazHtml";
import ModalZobrazPdf from "./../Modaly/ModalZobrazPdf";
import ModalZobrazHtml2 from "./../Modaly/ModalZobrazHtml2";
import ModalZmenaPobocky from "./../Modaly/ModalZmenaPobocky";

const Header = ({ onOpen, typErp, ldata, setLdata }) => {
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const signalRdata = useSelector(signalRData);
  const navigate = useHistory();
  const dispatch = useDispatch();
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [visible, setVisible] = useState(false);
  const handleClick = () => {
    onOpen();
    setVisible(false);
  };

  function verzia() {
    if (window.location.hostname === "marvin.crocodille.com") {
      return "";
    }
    if (window.location.hostname === "marvin.ad.croco.cz") {
      return "";
    }
    if (window.location.hostname === "marvintest.ad.croco.cz") {
      return "Staging";
    }
    if (window.location.hostname === "erp.iist.inver.sk") {
      return "Test Inver";
    }
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname.slice(0, 4) === "172."
    ) {
      return "Local";
    }
    if (
      window.location.hostname.slice(0, 3) === "10." ||
      window.location.hostname.slice(0, 4) === "192."
    ) {
      return "Local";
    }
    return "";
  }

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      //event.preventDefault();
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
      dispatch(setConnectionLoaded(false));
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // function connections() {
  //   console.log("hub notification build");
  //   //.withUrl(notifikaciaUrl + '?username=' + ldata.UserData.username)

  //   const connectCiselniky = new HubConnectionBuilder()
  //     .withUrl(
  //       notifikaciaUrl +
  //         "?username=" +
  //         (logindata.UserData === undefined ? "" : logindata.UserData.username)
  //     )
  //     .withAutomaticReconnect()
  //     .build();
  //   dispatch(setConnectionCiselniky(connectCiselniky));

  //   const connectNotificationsUnread = new HubConnectionBuilder()
  //     .withUrl(
  //       notifikaciaUrl +
  //         "?username=" +
  //         (logindata.UserData === undefined ? "" : logindata.UserData.username)
  //     )
  //     .withAutomaticReconnect()
  //     .build();
  //   dispatch(setConnectionNotificationsUnread(connectNotificationsUnread));

  //   const connectNotifications = new HubConnectionBuilder()
  //     .withUrl(
  //       notifikaciaUrl +
  //         "?username=" +
  //         (logindata.UserData === undefined ? "" : logindata.UserData.username)
  //     )
  //     .withAutomaticReconnect()
  //     .build();
  //   dispatch(setConnectionNotifications(connectNotifications));
  // }

  useEffect(() => {
    setLoadingNotifications(true);
    result();
  }, [
    logindata.UserData.vybrane_stredisko !== undefined &&
      logindata.UserData.vybrane_stredisko.kod,
  ]);

  async function result() {
    await axios
      .post(
        neprecitaneNotifikacieUrl,
        {
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log(response);
        setNotifications(response.data.notifications);
        setLoadingNotifications(false);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <header className={styles.header}>
      {
        <button
          className={styles.burger}
          onClick={() => handleClick()}
        ></button>
        /*
       <Search className={cn(styles.search, { [styles.visible]: visible })} /> 
      <button
        className={cn(styles.buttonSearch, { [styles.active]: visible })}
        onClick={() => setVisible(!visible)}
      >
        <Icon name="search" size="24" />
      </button> */
      }
      <div className={styles.head2}>{verzia()}</div>
      <div className={styles.control} onClick={() => setVisible(false)}>
        {typErp === "C" ||
        typErp === "O" ||
        typErp === "R" ||
        typErp === "W" ? (
          <></>
        ) : (
          <>
            <Create className={styles.create} />
            <Notification
              setData={setNotifications}
              data={notifications}
              loading={loadingNotifications}
              result={result}
              className={styles.notification}
            />
          </>
        )}

        {/* <Messages className={styles.messages} /> */}

        <User className={styles.user} ldata={ldata} setLdata={setLdata} />
        <ModalZmenaPobocky className={styles} />
        <ModalZobrazHtml className={styles} />
        <ModalZobrazPdf className={styles} />
        <ModalZobrazHtml2 className={styles} />
        <ModalEditTagovPolozky className={styles} />
      </div>
    </header>
  );
};

export default Header;
