import { createSlice } from '@reduxjs/toolkit';

export const PanelSlice = createSlice({
    name: 'Panel',
    initialState: {
        data: [],
        loading: true,
    },
    reducers: {
        Panel: (state, action) => {
            console.log("loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingPanel: (state) => {
            console.log("loading: true")
            state.loading = true
        }
    },
});

export const { Panel, loadingPanel } = PanelSlice.actions;

export const PanelData = state => state.Paneldata;


export default PanelSlice.reducer;
