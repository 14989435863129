import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Card from "./../../../components/Card";
import { useSelector, useDispatch } from "react-redux";
import { loginData } from "./../../SignIn/redux/loginSlice";
import useDarkMode from "@fisch0920/use-dark-mode";
import Icon from "../../../components/Icon";
import axios from "axios";
import { kalendarUrl } from "../../../features/urlHelper";
import { kalendar, kalendarData, loadingKalendar } from "./redux/kalendarSlice";
import Image from "../../../components/Image";
import { modal, modalData } from "../../../components/Modaly/redux/modalSlice";
import ModalVyjimka from "../../../components/Modaly/ModalVyjimka";
import ModalTrvalaZmena from "../../../components/Modaly/ModalTrvalaZmena";
import ContentLoader from "react-content-loader";
import { ClickAwayListener } from "@material-ui/core";

const App = ({ className }) => {
  const dispatch = useDispatch();
  const darkMode = useDarkMode(false);

  const modaldata = useSelector(modalData);
  const kalendardata = useSelector(kalendarData);

  const [weeks, setWeeks] = useState([]);
  const [monthText, setMonthText] = useState("");
  const [monthArray, setMonthArray] = useState({
    array: [],
    actual_position: 0,
  });
  const [weeksArray, setWeeksArray] = useState({
    array: [],
    actual_position: 0,
  });

  const [visibleMoznost, setVisibleMoznost] = useState(false);

  const days = ["Po", "Út", "St", "Čt", "Pá", "So", "Ne"];

  useEffect(() => {
    console.log(kalendardata.data);
    getActualMonthAndWeeks(kalendardata.data.kalendar);
  }, [kalendardata.data.kalendar]);

  function getActualMonthAndWeeks(data) {
    let array_of_months = [];
    let array_of_weeks = [];
    let actual_position_ = 0;
    let i = 0;
    data.map((year) => {
      year.mesiace.map((month) => {
        array_of_months.push(month.text_mesiac + " " + month.rok);
        array_of_weeks.push(month.tyzden);
        i++;
        if (month.aktualny) {
          actual_position_ = i;
        }
      });
      if (year.aktualny) {
        year.mesiace.map((month) => {
          if (month.aktualny) {
            setMonthText(month.text_mesiac + " " + month.rok);
            setWeeks(month.tyzden);
          }
        });
      }
    });
    setMonthArray({
      array: array_of_months,
      actual_position: actual_position_ - 1,
    });
    setWeeksArray({
      array: array_of_weeks,
      actual_position: actual_position_ - 1,
    });
  }

  function renderKalendar(data) {
    return (
      <div className={styles.mesiac}>
        {data.map((dni) => {
          return (
            <div className={styles.tyzden}>
              {dni.dni.map((den, index) => {
                return (
                  <>
                    <div
                      onClick={() => {
                        console.log(dni);
                        dispatch(
                          modal({
                            data: {
                              ...modaldata,
                              Vyjimka: true,
                              VyjimkaDate: {
                                ...modaldata.VyjimkaDate,
                                id: 0,
                                otvor_on: den.otvor_od === "" ? false : true,
                                rozvoz_on: den.rozvoz_od === "" ? false : true,
                                datum_od: den.datum,
                                datum_do: den.datum,
                                otvor_od: den.otvor_od,
                                otvor_do: den.otvor_do,
                                rozvoz_od: den.rozvoz_od,
                                rozvoz_do: den.rozvoz_do,
                              },
                            },
                          })
                        );
                      }}
                      className={styles.den}
                    >
                      {new Date(den.datum).getMonth() + 1 === dni.mesiac ? (
                        <div
                          className={
                            den.aktualny
                              ? styles.day_number_aktualny
                              : styles.day_number
                          }
                        >
                          {new Date(den.datum).getDate()}
                        </div>
                      ) : (
                        <div className={styles.day_number_disable}>
                          {new Date(den.datum).getDate()}
                        </div>
                      )}

                      <div title={den.title} className={styles.shop}>
                        <div className={styles.pic_around}>
                          <Image
                            className={styles.pic}
                            src="/images/content/shop.png"
                            srcDark="/images/content/shop.png"
                          />
                        </div>
                        <div
                          style={{
                            marginLeft: 4,
                            color: den.farba_otvor,
                            fontSize: 11,
                            height: 14,
                            width: 28,
                          }}
                        >
                          {den.otvor_od}
                        </div>
                        <div
                          style={{
                            marginLeft: 4,
                            color: den.farba_otvor,
                            fontSize: 11,
                            height: 14,
                            width: 4,
                          }}
                          hidden={window.innerWidth < 889}
                        >
                          -
                        </div>
                        <div
                          style={{
                            marginLeft: 4,
                            color: den.farba_otvor,
                            fontSize: 11,
                            height: 14,
                            width: 28,
                          }}
                        >
                          {den.otvor_do}
                        </div>
                      </div>
                      <div className={styles.shop}>
                        <div className={styles.pic_around}>
                          <Image
                            className={styles.pic}
                            src="/images/content/cycle.png"
                            srcDark="/images/content/cycle.png"
                          />
                        </div>
                        <div
                          style={{
                            marginLeft: 4,
                            color: den.farba_otvor,
                            fontSize: 11,
                            height: 14,
                            width: 28,
                          }}
                        >
                          {den.rozvoz_od !== null ? den.rozvoz_od : " "}
                        </div>
                        <div
                          style={{
                            marginLeft: 4,
                            color: den.farba_otvor,
                            fontSize: 11,
                            height: 14,
                            width: 4,
                          }}
                          hidden={window.innerWidth < 889}
                        >
                          -
                        </div>
                        <div
                          style={{
                            marginLeft: 4,
                            color: den.farba_otvor,
                            fontSize: 11,
                            height: 14,
                            width: 28,
                          }}
                        >
                          {den.rozvoz_do !== null ? den.rozvoz_do : " "}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  function setForward(data) {
    data.array.map((x, index, array) => {
      if (x === monthText) {
        if (array[data.actual_position + 1] !== undefined) {
          setMonthText(array[data.actual_position + 1]);
          setMonthArray({
            ...monthArray,
            actual_position: monthArray.actual_position + 1,
          });
          setWeeks(weeksArray.array[data.actual_position + 1]);
          setWeeksArray({
            ...weeksArray,
            actual_position: weeksArray.actual_position + 1,
          });
        }
      }
    });
  }

  function setBack(data) {
    data.array.map((x, index, array) => {
      if (x === monthText) {
        if (array[data.actual_position - 1] !== undefined) {
          setMonthText(array[data.actual_position - 1]);
          setMonthArray({
            ...monthArray,
            actual_position: monthArray.actual_position - 1,
          });
          setWeeks(weeksArray.array[data.actual_position - 1]);
          setWeeksArray({
            ...weeksArray,
            actual_position: weeksArray.actual_position - 1,
          });
        }
      }
    });
  }

  return (
    <>
      <Card bezPaddingu={false} className={cn(styles.card, className)}>
        {kalendardata.loading ? (
          <ContentLoader
            speed={2}
            width={"100%"}
            height={500}
            backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
            foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
          >
            {[0, 1, 2, 3].map((i) =>
              [0, 1, 2, 3, 4, 5, 6].map((j) => (
                <rect
                  key={j}
                  x={0}
                  y={8 + i * (500 / 4)}
                  rx="3"
                  ry="3"
                  width={"100%"}
                  height={0.2 * 500}
                />
              ))
            )}
          </ContentLoader>
        ) : (
          <>
            <>
              <div className={styles.header}>
                <div
                  onClick={() => {
                    setBack(monthArray, weeksArray);
                  }}
                  className={styles.leftArrow}
                >
                  <div className={styles.icon}>
                    <Icon name="arrow-left" size="24" />
                  </div>
                </div>
                <div className={styles.text_mesiac}>{monthText}</div>

                <div className={styles.rightArrow}>
                  <div
                    onClick={() => {
                      setForward(monthArray, weeksArray);
                    }}
                    className={styles.icon}
                  >
                    <Icon name="arrow-right" size="24" />
                  </div>
                </div>
              </div>
              <div className={styles.divider}>
                {days.map((day) => {
                  return <div className={styles.day}>{day}</div>;
                })}
              </div>
              {renderKalendar(weeks)}
            </>
            <div
              onMouseLeave={() => {
                setVisibleMoznost(false);
              }}
              className={styles.button_zmena_area}
            >
              <button
                onMouseEnter={() => {
                  setVisibleMoznost(true);
                }}
                onClick={() => {
                  setVisibleMoznost(true);
                }}
                className={styles.button_zmena}
              >
                Změna +
              </button>
              <ClickAwayListener
                onClickAway={() => {
                  setVisibleMoznost(false);
                }}
              >
                <div
                  className={cn(styles.moznost, className, {
                    [styles.active]: visibleMoznost,
                  })}
                >
                  <div
                    onMouseLeave={() => {
                      setVisibleMoznost(false);
                    }}
                    className={styles.body}
                  >
                    <div
                      onClick={() => {
                        let date = new Date();
                        dispatch(
                          modal({
                            data: {
                              ...modaldata,
                              Vyjimka: true,
                              VyjimkaDate: {
                                ...modaldata.VyjimkaDate,
                                id: 0,
                                otvor_on: true,
                                rozvoz_on: true,
                                datum_od: date.toISOString(),
                                datum_do: date.toISOString(),
                                otvor_od: null,
                                otvor_do: null,
                                rozvoz_od: null,
                                rozvoz_do: null,
                              },
                            },
                          })
                        );
                      }}
                      className={styles.item}
                    >
                      <div className={styles.ico}>
                        <Icon name="edit" size="24" />{" "}
                      </div>{" "}
                      <div>Výjimka</div>
                    </div>
                    <div
                      onClick={() => {
                        dispatch(
                          modal({
                            data: {
                              ...modaldata,
                              TrvalaZmena: true,
                              TrvalaZmenaDate: {
                                datum_od: null,
                                id: 0,
                                dni: [
                                  {
                                    dayOfWeek: 1,
                                    otvor_od: null,
                                    otvor_do: null,
                                    rozvoz_od: null,
                                    rozvoz_do: null,
                                  },
                                  {
                                    dayOfWeek: 2,
                                    otvor_od: null,
                                    otvor_do: null,
                                    rozvoz_od: null,
                                    rozvoz_do: null,
                                  },
                                  {
                                    dayOfWeek: 3,
                                    otvor_od: null,
                                    otvor_do: null,
                                    rozvoz_od: null,
                                    rozvoz_do: null,
                                  },
                                  {
                                    dayOfWeek: 4,
                                    otvor_od: null,
                                    otvor_do: null,
                                    rozvoz_od: null,
                                    rozvoz_do: null,
                                  },
                                  {
                                    dayOfWeek: 5,
                                    otvor_od: null,
                                    otvor_do: null,
                                    rozvoz_od: null,
                                    rozvoz_do: null,
                                  },
                                  {
                                    dayOfWeek: 6,
                                    otvor_od: null,
                                    otvor_do: null,
                                    rozvoz_od: null,
                                    rozvoz_do: null,
                                  },
                                  {
                                    dayOfWeek: 7,
                                    otvor_od: null,
                                    otvor_do: null,
                                    rozvoz_od: null,
                                    rozvoz_do: null,
                                  },
                                ],
                              },
                            },
                          })
                        );
                      }}
                      className={styles.item}
                    >
                      <div className={styles.ico}>
                        <Icon name="calendar" size="24" />
                      </div>
                      <div>Trvalá změna</div>
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            </div>
          </>
        )}
      </Card>
      <ModalVyjimka className={styles} />
      <ModalTrvalaZmena className={styles} />
    </>
  );
};

export default App;
