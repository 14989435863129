import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../Icon";
import Modal from "../../Modal";
import { modal, modalData } from "../redux/modalSlice";
import styles from "./Modal.module.sass";
import { login, loginData } from "../../../screens/SignIn/redux/loginSlice";
import axios from "axios";
import Switch from "./switch";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Tooltip from "@mui/material/Tooltip";
import {
  LocalizationProvider,
  csCZ,
  LicenseInfo,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
//import {  } from "@mui/x-license-pro";
import { getLicensePremium } from "../../../features/License";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "dayjs/locale/cs";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/cs";
import Select from "react-select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import swal from "sweetalert";
import { useHistory } from "react-router";
import {
  EvidenceUrl,
  panelSaveUrl,
  validationPanelUrl,
  validationUrl,
} from "../../../features/urlHelper";
import moment from "moment";
import useDarkMode from "@fisch0920/use-dark-mode";
import { Key, SentimentSatisfiedAltSharp } from "@mui/icons-material";
import { errorHandleDialog } from "../../../features/errorDialog";
import {
  evidenceTable,
  evidenceTableData,
} from "../../EvidenceTableUniversal/Table/redux/tableEvidenceSlice";
import {
  PanelDetailData,
  PanelDetail,
  loadingPanelDetail,
} from "./redux/PanelDetailSlice";
import Panel from "../../ModalProduct/Product/Panel";

LicenseInfo.setLicenseKey(getLicensePremium());
const App = ({
  className,
  visible,
  setVisible,
  dataset,
  volba,
  setVolba,
  paginationData,
  datasetId,
  setPaginationData,
  rowSelectionModel,
  setRowSelectionModel,
  idFieldName,
}) => {
  const dispatch = useDispatch();
  const navigate = useHistory();

  const darkMode = useDarkMode(false);

  const evidenceTabledata = useSelector(evidenceTableData);
  const Paneldata = useSelector(PanelDetailData);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const [params, setParams] = useState({});
  const [ModalSelectorPanelData, setModalSelectorPanelData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState([]);
  const [titleSelector, setTitleSelector] = useState("");
  const [ModalSelectorPanelVisible, setModalSelectorPanelVisible] =
    useState(false);
  const [searchValue, setSearchValue] = useState("");

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#F1B600" },
      },
      width: "30%",
    },
    csCZ
  );

  function setPack(title_as_id, Paneldata, pack) {
    Paneldata.data.sections.map((section, index) => {
      if (section.title === title_as_id) {
        let newSetion = { ...Paneldata.data.sections[index], pack: !pack };
        let newSections = [...Paneldata.data.sections];
        newSections[index] = newSetion;
        let newData = { ...Paneldata.data, sections: newSections };
        dispatch(PanelDetail(newData));
      }
    });
  }

  function returnString(volba) {
    if (volba === "view") {
      return " - detail položky";
    }
    if (volba === "edit") {
      return " - editace položky";
    }
    if (volba === "add") {
      return " - přidání položky";
    }
  }

  function returnButton(volba) {
    if (volba === "view") {
      return "OK";
    }
    if (volba === "edit") {
      return "Uložit";
    }
    if (volba === "add") {
      return "Přidat";
    }
  }

  const stylingRangePicker = {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      color: "#82878c",
      fontFamily: "Inter, sans-serif",
      height: 7,
      display: "flex",
      alighnItems: "center",
      justifyContent: "center",
      fontWeight: 400,
      fontSize: 15,
      backgroundColor: !darkMode.value ? "white" : "#262b30",
      borderRadius: 2,
    },

    "& .MuiTypography-root, MuiFormLabel-root": {
      color: "#82878c",
    },
    "& .separator-true": {
      height: 30,
      borderBottom: `3px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#989899",
    },
    "& .MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled": {
      WebkitTextFillColor: "#989899",
    },
    width: "60%",
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
  };

  const stylingPicker = {
    ...stylingRangePicker,
    width: "60%",
  };

  function pocetDesatinnychMiest(cislo) {
    let retazec = cislo.toString();
    let zoznam = retazec.split(".");

    if (zoznam.length > 1) {
      return zoznam[1].length;
    } else {
      return 0;
    }
  }

  async function selector(selector, value, id) {
    await axios
      .post(
        EvidenceUrl,
        {
          dataset: selector.dataset, // meno evidencie, ktoru prezeras
          typErp: logindata.UserData.typErp,
          stredisko: logindata.UserData.vybrane_stredisko.kod,
          pagination: {
            pageSize: 50, // pocet poloziek na stranu
            page: 0, // cislo strany – cislujeme do 0
          },
          sourcePanel: {
            id: id,
            dataset: dataset,
            datasetIndex: paginationData.datasetIndex,
            panelValues: Paneldata.data.values,
            volba: volba,
          },
          search: value,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        setTitleSelector(response.data.title);
        setModalSelectorPanelVisible(true);
        setModalSelectorPanelData(response.data.data);
        setLoading(false);
        setFields([selector.id, selector.text]);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function validatioPickerRange(params_, value) {
    await axios
      .post(
        validationPanelUrl,
        {
          datasetId: datasetId,
          dataset: dataset,
          stredisko: logindata.UserData.vybrane_stredisko.kod,
          volba: volba,
          value: value[0],
          id: params_.id,
          panelValues: Paneldata.data.values,
          fieldname: params_.fieldName[0],
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(async function (response) {
        await axios
          .post(
            validationPanelUrl,
            {
              datasetId: datasetId,
              dataset: dataset,
              stredisko: logindata.UserData.vybrane_stredisko.kod,
              volba: volba,
              value: value[1],
              id: params_.id,
              panelValues: Paneldata.data.values,
              fieldname: params_.fieldName[1],
            },
            {
              headers: { Authorization: "Bearer " + logindata.Token },
            }
          )
          .then(function (response) {
            if (response.data.newPanelValues !== undefined) {
              const newData = {
                ...Paneldata.data,
                values: response.data.newPanelValues,
              };
              dispatch(loadingPanelDetail());
              dispatch(PanelDetail(newData));
            }
            console.log("ok");
          })
          .catch(function (error) {
            errorHandleDialog(error.response, navigate, dispatch);
          });
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function validationSelector(params_, value) {
    await axios
      .post(
        validationPanelUrl,
        {
          datasetId: datasetId,
          dataset: dataset,
          stredisko: logindata.UserData.vybrane_stredisko.kod,
          volba: volba,
          value: value,
          id: params_.id,
          panelValues: Paneldata.data.values,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        dataHandling(params_, value, response.data.text);
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          dataHandling(params_, value, "");
          setParams(params_);
          setSearchValue(value);
          setLoading(true);
          selector(params_.selector, value, params_.id);
        } else if (error.response.status === 401) {
          navigate.push("/");
          //dispatch(login({ data: {} }));
          window.sessionStorage.setItem("logindata", JSON.stringify({}))
        } else if (error.response.status > 401 && error.response.status < 500) {
          swal(
            "Chyba!",
            "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
            "error"
          );
        } else {
          swal(
            "Chyba!",
            " Nastala chyba na serveri ERP, zkuste to později.",
            "error"
          );
        }
      });
  }

  async function validationPanel(params_, value, _stepValidValue = 0) {
    let noveParams = { ...params_, value: value };
    let noveValues = {
      ...Paneldata.data.values,
      [params_.fieldName]: noveParams,
    };
    console.log("validation", noveValues);
    await axios
      .post(
        validationPanelUrl,
        {
          datasetId: datasetId,
          dataset: dataset,
          stredisko: logindata.UserData.vybrane_stredisko.kod,
          volba: volba,
          value: value,
          id: params_.id,
          panelValues: noveValues,
          stepValid: 1 + _stepValidValue,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        if (response.data.odpoved != null) {
          swal({
            title: response.data.odpoved.text,
            icon: "warning",
            buttons: { ...response.data.odpoved.buttons },
            closeOnEsc: true,
          }).then((value) => {
            switch (value) {
              case 1:
              case 2:
              case 3:
              case 4:
                validationPanel(params_, params_.value, value);
                break;
              default:
                validationPanel(params_, params_.value, 0);
                swal.close();
            }
          });
        }

        if (response.data.newPanelValues !== undefined) {
          const newData = {
            ...Paneldata.data,
            values: response.data.newPanelValues,
          };
          console.log("response", response.data.newPanelValues);
          dispatch(loadingPanelDetail());
          dispatch(PanelDetail(newData));
        }
        console.log("ok");
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  function dataHandling(params_, value_, value2_) {
    console.log("data handling", params_);
    let noveParams = {};
    if (value2_ !== null) {
      noveParams = { ...params_, value: value_, selectorLabel: value2_ };
    } else {
      noveParams = { ...params_, value: value_ };
    }

    let noveValues = {
      ...Paneldata.data.values,
      [params_.fieldName]: noveParams,
    };
    let novyObjekt = { ...Paneldata.data, values: noveValues };
    dispatch(PanelDetail(novyObjekt));
  }

  function getComponent(params_) {
    switch (params_.inputType) {
      case "text":
        return (
          <input
            autoComplete="off"
            id={params_.id}
            name={params_.id}
            onClick={() => {
              document.getElementsByName(params_.id)[0].select();
            }}
            style={{
              width: params_.inputWidth > 560 ? "100%" : params_.inputWidth,
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                if (params_.rules !== undefined && params_.rules.validating) {
                  validationPanel(params_, params_.value);
                }
              }
            }}
            className={styles.input}
            type="text"
            disabled={volba === "view" || params_.when_fcia === "disabled"}
            value={params_.value}
            onChange={(e) => {
              if (e.target.value.length > params_.maxLength) {
                swal(
                  "Chyba!",
                  "Neplatná hodnota! Dodržte počet desetinných míst" +
                    String(params_.rules.numericScale),
                  "error"
                );
              } else {
                dataHandling(params_, e.target.value);
              }
            }}
          />
        );
      case "password":
        return (
          <input
            autoComplete="off"
            type="password"
            id={params_.id}
            name={params_.id}
            onClick={() => {
              document.getElementsByName(params_.id)[0].select();
            }}
            style={{
              width: params_.inputWidth > 560 ? "100%" : params_.inputWidth,
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                if (params_.rules !== undefined && params_.rules.validating) {
                  validationPanel(params_, params_.value);
                }
              }
            }}
            className={styles.input}
            disabled={volba === "view" || params_.when_fcia === "disabled"}
            value={params_.value}
            onChange={(e) => {
              if (e.target.value.length > params_.maxLength) {
                swal(
                  "Chyba!",
                  "Neplatná hodnota! Dodržte počet desetinných míst" +
                    String(params_.rules.numericScale),
                  "error"
                );
              } else {
                dataHandling(params_, e.target.value);
              }
            }}
          />
        );
      case "number":
        return (
          <input
            id={params_.id}
            style={{
              width: params_.inputWidth > 560 ? "100%" : params_.inputWidth,
            }}
            className={styles.input}
            type="number"
            name={params_.id}
            onClick={() => {
              document.getElementsByName(params_.id)[0].select();
            }}
            disabled={volba === "view" || params_.when_fcia === "disabled"}
            value={params_.value}
            onChange={(e) => {
              if (
                pocetDesatinnychMiest(e.target.value) >
                params_.rules.numericScale
              ) {
                swal(
                  "Chyba!",
                  "Neplatná hodnota! Dodržte počet desetinných míst" +
                    String(params_.rules.numericScale),
                  "error"
                );
              } else if (
                params_.rules.min > Number(e.target.value) ||
                Number(e.target.value) > params_.rules.max
              ) {
                swal(
                  "Chyba!",
                  "Neplatná hodnota! Dodržte interval" +
                    String(params_.rules.min) +
                    " - " +
                    String(params_.rules.max),
                  "err or"
                );
              } else {
                dataHandling(params_, Number(e.target.value));
              }
            }}
          />
        );
      case "date":
        return (
          <span>
            <ThemeProvider theme={theme}>
              <LocalizationProvider
                adapterLocale="cs"
                localeText={
                  csCZ.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
                dateAdapter={AdapterMoment}
              >
                <DatePicker
                  id={params_.id}
                  name={params_.id}
                  disabled={
                    volba === "view" || params_.when_fcia === "disabled"
                  }
                  value={moment.utc(params_.value)}
                  // onClose={() => {

                  //   if (
                  //     params_.rules !== undefined &&
                  //     params_.rules.validating
                  //   ) {
                  //     validationPanel(params_, params_.value);
                  //   }
                  //   console.log("on close", e)
                  // }}
                  onChange={(param, e) => {
                    dataHandling(params_, param._d.toISOString());
                    if (
                      params_.rules !== undefined &&
                      params_.rules.validating
                    ) {
                      validationPanel(params_, param._d.toISOString());
                    }
                  }}
                  sx={stylingPicker}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </span>
        );

      case "select":
        let options = params_.selectValues;
        let value = null;
        params_.selectValues.map((value_) => {
          if (params_.value === value_.value) {
            value = value_;
          }
        });

        return (
          <>
            <Select
              name={params_.id}
              theme={(theme) => ({
                ...theme,
                borderRadius: 8,
                colors: {
                  ...theme.colors,
                  primary25: "#FAEECB ",
                  primary50: "#FAEECB ",
                  primary: "#F1B600",
                },
              })}
              isDisabled={volba === "view" || params_.when_fcia === "disabled"}
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  backgroundColor: !darkMode.value ? "white" : "#262b30",
                }),
                option: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  color: "#6F767E",
                }),
                singleValue: (base, state) => ({
                  ...base,
                  borderRadius: 3,
                  color: "#82878c",
                }),
                menu: (base, state) => ({
                  ...base,
                  borderRadius: 0,
                  marginTop: 0,
                  backgroundColor: !darkMode.value ? "white" : "#262b30",
                }),
              }}
              className={styles.select}
              value={value}
              isClearable={false}
              isSearchable={false}
              options={options}
              onChange={(e) => {
                dataHandling(params_, e.value);
                validationPanel(params_, e.value);
              }}
            />
          </>
        );
      case "selector":
        return (
          <div className={styles.selector}>
            <div className={styles.inptLbl}>
              <input
                id={params_.id}
                name={params_.id}
                onClick={() => {
                  document.getElementsByName(params_.id)[0].select();
                }}
                disabled={volba === "view" || params_.when_fcia === "disabled"}
                className={styles.input}
                value={params_.value}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    validationSelector(params_, params_.value);
                  }
                }}
                onChange={(param) => {
                  dataHandling(params_, param.target.value, "");
                }}
                type="text"
              />

              <div className={styles.label}>{params_.selectorLabel}</div>
            </div>

            <button
              disabled={volba === "view" || params_.when_fcia === "disabled"}
              onClick={() => {
                console.log("params_", params_);
                setParams(params_);
                setSearchValue("");
                setLoading(true);
                selector(params_.selector, "", params_.id);
              }}
              className={styles.button}
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        );
      case "checkbox":
        return (
          <>
            <input
              disabled={volba === "view" || params_.when_fcia === "disabled"}
              onClick={(e) => {
                if (params_.value) {
                  dataHandling(params_, false);
                } else {
                  dataHandling(params_, true);
                }
              }}
              checked={params_.value}
              id="cb1"
              type="checkbox"
              name={params_.id}
              className={styles.checkbox}
            />
          </>
        );
      case "radio":
        return (
          <>
            <div selected={params_.value} onChange={() => {}}>
              {params_.selectValues.map((value) => {
                //console.log(value)
                return (
                  <div className={styles.checkboxLine}>
                    <div>{value.label}</div>{" "}
                    <input
                      disabled={
                        volba === "view" || params_.when_fcia === "disabled"
                      }
                      onClick={() => {
                        dataHandling(params_, value.value);
                        validationPanel(params_, value.value);
                      }}
                      checked={params_.value === value.value}
                      id="cb1"
                      type="radio"
                      value={value.value}
                      name={params_.id}
                      className={styles.checkbox}
                    />
                  </div>
                );
              })}
            </div>
          </>
        );
      case "date_interval":
        return (
          <span>
            <ThemeProvider theme={theme}>
              <LocalizationProvider
                adapterLocale="cs"
                localeText={{
                  ...csCZ.components.MuiLocalizationProvider.defaultProps
                    .localeText,
                  start: "Od",
                  end: "Do",
                }}
                dateAdapter={AdapterMoment}
              >
                <DemoContainer components={["DateRangePicker"]}>
                  <DateRangePicker
                    id={params_.id}
                    name={params_.id}
                    disabled={
                      volba === "view" || params_.when_fcia === "disabled"
                    }
                    onClose={() => {
                      if (
                        params_.rules !== undefined &&
                        params_.rules.validating
                      ) {
                        validatioPickerRange(params_, params_.value);
                        //validationPanel(params_, params_.value);
                      }
                    }}
                    onChange={(param) => {
                      dataHandling(params_, [
                        param[0]._d.toISOString(),
                        param[1] === null
                          ? param[0]._d.toISOString()
                          : param[1]._d.toISOString(),
                      ]);
                    }}
                    // value={[
                    //   moment.utc(params_.value[0] !== undefined ? params_.value[0] : null ),
                    //   moment.utc(params_.value[1] !== undefined ? params_.value[1] : null ),
                    // ]}
                    value={[
                      params_.value !== undefined
                        ? moment.utc(params_.value[0])
                        : null,
                      params_.value !== undefined
                        ? moment.utc(params_.value[1])
                        : null,
                    ]}
                    sx={stylingRangePicker}
                    calendars={window.innerWidth < 642 ? 1 : 2}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </ThemeProvider>
          </span>
        );
      default:
        return <div>{params_.value}</div>;
    }
  }

  return (
    <>
      <Modal outerClassName={styles.outerZaznamProblemu} visible={visible}>
        <>
          {Paneldata.loading ? (
            <>
              <button
                onClick={() => {
                  setVisible(false);
                }}
              >
                <Icon name="close" size="20" />
              </button>
            </>
          ) : (
            <>
              <div className={styles.ico_Title}>
                <div>{Paneldata.data.title + returnString(volba)}</div>
                <button
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  <Icon name="close" size="20" />
                </button>
              </div>
              <div
                style={{
                  height:
                    window.innerHeight < 900 ? window.innerHeight - 150 : 800,
                }}
                className={styles.content}
              >
                {Paneldata.data.sections.map((section) => {
                  return (
                    <div>
                      <div
                        onClick={() => {
                          if (section.separator !== undefined) {
                            setPack(section.title, Paneldata, section.pack);
                          }
                        }}
                        className={
                          section.separator !== undefined
                            ? styles.sectionTitle_separator
                            : styles.sectionTitle
                        }
                      >
                        {section.title}
                        {section.separator !== undefined ? (
                          <>
                            {section.pack ? (
                              <div>
                                <i class="fa fa-angle-down"></i>
                              </div>
                            ) : (
                              <div>
                                <i class="fa fa-angle-up"></i>
                              </div>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      {section.pack ? (
                        <></>
                      ) : (
                        <div className={styles.panel}>
                          {section.fieldsets.map((polozky) => {
                            return (
                              <div className={styles.title_polozky_row}>
                                <div className={styles.title_polozky_row_title}>
                                  {polozky.title ? polozky.title : ""}
                                </div>
                                <div className={styles.polozky_row}>
                                  {polozky.fields.map((polozka) => {
                                    return (
                                      <div className={styles.help}>
                                        {Paneldata.data.values[polozka]
                                          .when_fcia === "hidden" ? (
                                          <></>
                                        ) : (
                                          <>
                                            <div
                                              className={
                                                Paneldata.data.values[polozka]
                                                  .inputType === "checkbox"
                                                  ? styles.polozkavyjimka
                                                  : styles.polozka
                                              }
                                            >
                                              <div className={styles.title}>
                                                <div>
                                                  {
                                                    Paneldata.data.values[
                                                      polozka
                                                    ].label
                                                  }
                                                </div>
                                                {Paneldata.data.values[polozka]
                                                  .hlp_msg === "" ? (
                                                  <></>
                                                ) : (
                                                  <Tooltip
                                                    title={
                                                      Paneldata.data.values[
                                                        polozka
                                                      ].hlp_msg
                                                    }
                                                  >
                                                    <i
                                                      className={styles.info}
                                                      class="fa fa-info-circle"
                                                      style={{
                                                        color: "#F1B600",
                                                        cursor: "pointer",
                                                      }}
                                                    ></i>
                                                  </Tooltip>
                                                )}
                                              </div>
                                              <div>
                                                {getComponent(
                                                  Paneldata.data.values[polozka]
                                                )}
                                              </div>
                                            </div>
                                            <div>
                                              {Paneldata.data.values[polozka]
                                                .separator ? (
                                                <div
                                                  className={
                                                    styles.separator_text
                                                  }
                                                >
                                                  {
                                                    Paneldata.data.values[
                                                      polozka
                                                    ].separator_text
                                                  }
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <div className={styles.saveArea}>
            <div
              onClick={() => {
                setVisible(false);
              }}
              className={styles.save}
            >
              {returnButton(volba)}
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default App;
