import Modal from "../../Modal";
import Card from "../../Card";
import Icon from "../../Icon";
import Search from "./../../Search";
import styles from "./../Modaly.module.sass";
import remove_accents from "remove-accents";
import axios from "axios";
import cn from "classnames";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modal, modalData } from "./../redux/modalSlice";
import { search, searchData } from "./../../Search/redux/searchSlice";
import { dodavatelUrl, pobockyUrl } from "../../../features/urlHelper";
import { login, loginData } from "../../../screens/SignIn/redux/loginSlice";
import {
  zmenaPobocky,
  zmenaPobockyData,
  loadingZmenaPobocky,
  setComment,
  vybrane,
} from "./redux/zmenaPobockySlice";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { useHistory } from "react-router";
import swal from "sweetalert";
import {errorHandleDialog} from "./../../../features/errorDialog"

const App = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const modaldata = useSelector(modalData);
  const searchdata = useSelector(searchData);
  const zmenaPobockydata = useSelector(zmenaPobockyData);

  useEffect(() => {
    dispatch(search({ data: { ...searchdata, searchZmenaPobocky: "" } }));
    result();
  }, []);

  async function result() {
    await axios
      .get(pobockyUrl, {
        headers: { Authorization: "Bearer " + logindata.Token },
      })
      .then(function (response) {
        const data = response.data.map((item) => ({ ...item, vybrane: false }));
        dispatch(loadingZmenaPobocky());
        dispatch(zmenaPobocky(data));
        console.log("pobocky", data);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch)
      });
  }

  return (
    <Modal
      outerClassName={styles.outerZaznamProblemu}
      visible={modaldata.ZmenaPobocky}
    >
      <div className={styles.ico}>
        <button
          className={styles.icon}
          onClick={() => {
            dispatch(modal({ data: { ...modaldata, ZmenaPobocky: false } }));
            clearAllBodyScrollLocks();
          }}
        >
          <Icon name="close" size="20" />
        </button>
      </div>
      <Card
      bezPaddingu={false}
        className={cn(styles.card, className)}
        title={
          zmenaPobockydata.vybrane
            ? "Změnit cílové středisko"
            : "Vybrat cílové středisko"
        }
        classTitle="title-red"
      >
        <Search field={"searchZmenaPobocky"} />
        <br />
        {zmenaPobockydata.loading ? (
          <></>
        ) : (
          <div
            style={{ height: window.innerHeight - 280 }}
            className={styles.upperContentScr}
          >
            {zmenaPobockydata.data
              .filter((xy) =>
                String(remove_accents(xy.nazov.toUpperCase())).includes(
                  remove_accents(searchdata.searchZmenaPobocky.toUpperCase())
                )
              )
              .map((x, index) => (
                <>
                  <div
                    key={index}
                    onClick={() => {
                      let row = {};

                      let row2 = null;

                      let i = 0;

                      let poradie = 0;

                      let poradie_old = 0;

                      zmenaPobockydata.data.map((itemik) => {
                        if (x.kod === itemik.kod) {
                          row = { ...itemik };
                          poradie = i;
                        }
                        if (itemik.vybrane) {
                          row2 = { ...itemik };
                          poradie_old = i;
                        }
                        i++;
                      });

                      if (poradie === poradie_old && row2 !== null) return;

                      row.vybrane = true;

                      if (row2 !== null) {
                        row2.vybrane = false;
                      }

                      const tempData = [...zmenaPobockydata.data];

                      tempData[poradie] = row;

                      if (row2 !== null) {
                        tempData[poradie_old] = row2;
                      }

                      dispatch(vybrane(true));
                      dispatch(setComment(""));

                      dispatch(zmenaPobocky(tempData));
                      dispatch(
                        modal({
                          data: { ...modaldata, ZmenaPobocky: false },
                        })
                      );

                      clearAllBodyScrollLocks();
                    }}
                    className={styles.itemZmenaDodavatele}
                  >
                    <div className={cn(styles.avatar)}>
                      <img src={"/images/content/kosik.png"} alt="Avatar" />
                    </div>
                    <div
                      className={
                        x.vybrane
                          ? styles.upperContentRightA
                          : styles.upperContentRightP
                      }
                    >
                      <span style={{ fontWeight: "bold" }}>{x.nazov}</span>
                      <br />
                      <span style={{ color: "gray" }}>
                        {"Kod: " + x.kod + " • Měna: " + x.mena}
                      </span>
                    </div>
                  </div>
                </>
              ))}
          </div>
        )}
      </Card>
    </Modal>
  );
};
export default App;
