import Card from "../../../components/Card";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Search from "../../../components/Search";
import Table from "./Table";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import {
  VypisDokladuHTMLUrl,
  menuAkciaUrl,
  notifikaciaUrl,
  reklMenuAkciaUrl,
  stornoReklamaceUrl,
  zoznamReklamaciiSQLUrl,
} from "../../../features/urlHelper";
import swal from "sweetalert";
import { useHistory } from "react-router";
import Delete from "../../../components/Delete";
import { errorHandleDialog } from "../../../features/errorDialog";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { modal, modalData } from "../../../components/Modaly/redux/modalSlice";
import {
  setConnectionReklamace,
  setConnectionReklamaceID,
  signalRData,
} from "../../SignIn/redux/signalRSlice";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";

const App = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const connection = useSelector(signalRData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const modaldata = useSelector(modalData);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [params_to_menu, setParams_to_menu] = React.useState(null);

  const open = Boolean(anchorEl);

  function handleClickListItem(event, id) {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
    setCurrentRow(id);
  }

  const handleMenuItemClick = (event, value, params) => {
    setAnchorEl(null);
    menu_akcia(value, params.row.reklamace);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  function getWidth(width) {
    if (width > 2500) {
      return width - 408;
    } else if (width < 2500 && width > 767) {
      return width - 194;
    } else {
      return width;
    }
  }

  function dajVysku(height) {
    if (window.innerWidth > 767) {
      return height - 178;
    } else {
      return height - 130;
    }
  }

  window.addEventListener("popstate", function () {
    if (
      (connection.connectionReklamace !== undefined ||
        connection.connectionReklamace !== null) &&
      connection.connectionReklamace
    ) {
      try {
        connection.connectionReklamace.stop();
      } catch (e) {
        console.log(e);
      }
    }
  });

  useEffect(() => {
    if (
      (connection.connectionReklamace !== undefined ||
        connection.connectionReklamace !== null) &&
      connection.connectionReklamace
    ) {
      try {
        connection.connectionReklamace.stop();
      } catch (e) {
        console.log(e);
      }
    }
  }, [HubConnectionState.Reconnecting]);

  useEffect(() => {
    //removeAllConn();
    const connect = new HubConnectionBuilder()
      .withUrl(
        notifikaciaUrl +
          "?username=" +
          (logindata.UserData === undefined ? "" : logindata.UserData.username)
      )
      .withAutomaticReconnect()
      .build();
    dispatch(setConnectionReklamace(connect));
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (connection.connectionReklamace) {
      try {
        console.log("hub notification start");
        connection.connectionReklamace
          .start()
          .then(() => {
            //ziskame notificationID ( kvoli update)
            connection.connectionReklamace
              .invoke("GetConnectionId")
              .then(function (connectionID) {
                dispatch(setConnectionReklamaceID(connectionID));
                //console.log("connectionID", connectionID);
              })
              .catch((error) => console.log(error));

            connection.connectionReklamace.on(
              "ReceiveMessage",
              async (message) => {
                if (message.typ === "REKLAMACE_PREHLED_REFRESH") {
                  console.log("REKLAMACE_PREHLED_REFRESH");
                  //console.log(message.data.data);
                  setData(message.data.data);
                }
              }
            );
          })
          .catch((error) => console.log(error));
      } catch (error) {
        console.log(error);
      }
    }
  }, [connection.connectionReklamace]);

  const columns = [
    {
      field: "detail",
      headerName: "",
      maxWidth: 35,
      minWidth: 35,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        //console.log("params edit", params);
        return (
          <div>
            <div
              title="Detail"
              id={params.row.id}
              className={styles.iconsis}
              onClick={(e) => {
                handleClickListItem(e, params.row.id);
                setParams_to_menu(params);
              }}
            >
              <div className={styles.iconY}>
                <i class="fa fa-bars"></i>
              </div>
            </div>
            <div>
              <Menu
                id={"lock-menu"}
                anchorEl={anchorEl}
                open={open && params.row.id === currentRow}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "lock-button",
                  role: "listbox",
                }}
              >
                {params.row.menu.map((option, index) => (
                  <MenuItem
                    key={option.value}
                    onClick={(event) => {
                      handleMenuItemClick(event, option.value, params_to_menu);
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        );
      },
    },
    {
      field: "id",
      hideable: false,
      headerName: "Id",
      width:
        (getWidth(window.innerWidth) - (logindata.UserData.admin ? 145 : 90)) *
        0.09,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div
            style={
              params.row.wms_stav === 5 || params.row.wms_stav === 6
                ? { textDecoration: "line-through", color: "red" }
                : params.row.wms_stav === 3 || params.row.wms_stav === 4
                ? { color: "green" }
                : {}
            }
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "datum",
      hideable: false,
      headerName: "Datum",
      width:
        (getWidth(window.innerWidth) - (logindata.UserData.admin ? 145 : 110)) *
        0.14,
      headerAlign: "start",
      sortable: true,
      filterable: false,
      valueFormatter: (params) => moment(params?.value).format("DD.MM.YYYY"),
    },
    {
      field: "reklamace",
      hideable: false,
      headerName: "Číslo reklamace",
      width:
        (getWidth(window.innerWidth) - (logindata.UserData.admin ? 145 : 110)) *
        0.14,
      headerAlign: "start",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className={styles.polozka_reklamace}>
            <div>{params.row.reklamace}</div>
          </div>
        );
      },
    },
    {
      field: "x__adduser",
      hideable: true,
      headerName: "Vytvořil",
      width:
        (getWidth(window.innerWidth) - (logindata.UserData.admin ? 145 : 110)) *
        0.14,
      headerAlign: "start",
      sortable: false,
      filterable: false,
    },
    {
      field: "polozka",
      hideable: false,
      headerName: "Vytvořil",
      width:
        (getWidth(window.innerWidth) - (logindata.UserData.admin ? 145 : 110)) *
        0.17,
      headerAlign: "start",
      sortable: false,
      filterable: false,
    },
    {
      field: "nazov",
      hideable: false,
      headerName: "Položky",
      width:
        (getWidth(window.innerWidth) - (logindata.UserData.admin ? 145 : 110)) *
        0.28,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className={styles.polozka}>
            <div className={styles.content}>
              <div className={styles.obrazok}>
                <img src="/images/content/bageta_1.jpg" alt={params.row.text} />
              </div>
              <div className={styles.overlay_text}>
                <p>{params.row.objem + params.row.mj}</p>
              </div>
            </div>
            <div className={styles.popis}>
              <div className={styles.nazov}>{params.row.nazov}</div>
              <div className={styles.znacka}>{params.row.polozka}</div>
            </div>
          </div>
        );
      },
    },
    {
      field: "typ_reklamace",
      hideable: true,
      headerName: "Typ reklamace",
      width:
        (getWidth(window.innerWidth) - (logindata.UserData.admin ? 145 : 110)) *
        0.16,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className={styles.polozka_reklamace}>
            <div>{typReklamaceNazov(params.value)}</div>
          </div>
        );
      },
    },
    {
      field: "stav",
      hideable: false,
      headerName: "Stav reklamace",
      width:
        (getWidth(window.innerWidth) - (logindata.UserData.admin ? 145 : 110)) *
        0.14,
      headerAlign: "start",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        //console.log(params);
        var styl = "";
        var con_styl = "";
        var text = "";
        var icon = "";
        switch (params.value) {
          case "1_ODESLANA":
            styl = "polozka_neutral";
            con_styl = "icon_neutral";
            icon = "fa fa-send-o";
            text = "Odeslaná";
            break;

          case "2_V_RESENI":
            styl = "polozka_neutral";
            con_styl = "icon_neutral";
            icon = "fa fa-clock-o";
            text = "V řešení";
            break;

          case "6_STORNOVANA":
            styl = "polozka_neuz";
            con_styl = "icon_red";
            icon = "fa fa-trash";
            text = "Stornovaná";
            break;

          case "10_UZNANA_END":
          case "10_UZNANA":
            styl = "polozka_vyb";
            con_styl = "icon_green";
            icon = "fa fa-check";
            text = "Uznaná";
            break;

          case "11_NEUZNANA_END":
          case "11_NEUZNANA":
            styl = "polozka_neuz";
            con_styl = "icon_red";
            icon = "fa fa-close";
            text = "Neuznaná";
            break;

          case "12_CUZNANA_END":
          case "12_CUZNANA":
            styl = "polozka_res";
            con_styl = "icon_orange";
            icon = "fa fa-minus";
            text = "Částečně uznaná";
            break;
        }
        return (
          <div className={styles[styl]}>
            <div className={styles[con_styl]}>
              <i class={icon}></i>
            </div>
            <div>{text}</div>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      headerAlign: "center",
      //cellClassName: 'actions',
      width: 90,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className={styles.actions}>
            <div
              title="Detail reklamace"
              className={styles.icon_eye}
              onClick={async () => {
                console.log(params.row.datum);
                navigate.push(
                  `/reklamaceSQLDetail?datum=${params.row.datum}&cis_rekl=${
                    params.row.reklamace
                  }&priznak=${"base"}`
                );
              }}
            >
              <i class="fa fa-eye"></i>
            </div>
            <div
              title={
                params.row.wms_stav_prij < 3 && params.row.stav === "1_ODESLANA"
                  ? "Stornovat reklamaci"
                  : ""
              }
              onClick={async () => {
                if (
                  params.row.wms_stav_prij < 3 &&
                  params.row.stav === "1_ODESLANA"
                ) {
                  swal({
                    title: "Opravdu chcete stornovat reklamaci?",
                    icon: "info",
                    buttons: ["Ne ", "Ano"],
                    dangerMode: true,
                  }).then(async (willShow) => {
                    if (willShow) {
                      storno(params.row.reklamace, params.row.datum);
                    }
                  });
                }
                // console.log(params.row.objednane_mn, params.row.mnozstvo);
                // if (
                //   params.row.objednane_mn === params.row.mnozstvo &&
                //   kosikZmena
                // ) {
                //   return;
                // }
                // swal({
                //   title: kosikZmena
                //     ? "Chcete zrušit změnu?"
                //     : "Chcete smazat položku?",
                //   icon: "info",
                //   buttons: ["Ne ", "Ano"],
                //   dangerMode: true,
                // }).then(async (willShow) => {
                //   if (willShow) {
                //     setLayoutActive(true);
                //     setLayoutText(
                //       kosikZmena
                //         ? "Ruším změny na položce."
                //         : "Odstraňuji položku z košíku."
                //     );
                //     var balne_mn = daj_balne_mn(params.row.znacka.trim());
                //     await zapis(
                //       kosikZmena ? params.row.objednane_mn : 0,
                //       balne_mn,
                //       params.row.des_mista,
                //       params.row.mj,
                //       datum_active,
                //       params.row.znacka,
                //       objednat_data.scrollPosition,
                //       params.row.datum + params.row.znacka.trim(),
                //       hlavicka
                //     );
                //   }
                //});
              }}
              className={
                params.row.wms_stav_prij < 3 && params.row.stav === "1_ODESLANA"
                  ? styles.icon_trash_a
                  : styles.icon_trash_d
              }
            >
              <i class="fa fa-trash"></i>
            </div>
          </div>
        );
      },
    },
  ];

  function typReklamaceNazov(typ_reklamace) {
    switch (typ_reklamace) {
      case 0:
        return "Dodáno nižší množství";
      case 1:
        return "Špatná kvalita – položku zasílám fyzicky zpět na ZMT";
      case 2:
        return "Dodáno vyšší množství (položku si ponechám)";
    }
  }

  useEffect(() => {
    result(logindata.UserData.ico);
  }, []);

  async function menu_akcia(akcia, cis_rekl) {
    await axios
      .post(
        reklMenuAkciaUrl,
        {
          menu_akcia: akcia,
          //stredisko: logindata.UserData.vybrane_stredisko !== undefined ? logindata.UserData.vybrane_stredisko.kod : null,
          odberatel:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          pobocka:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,

          reklamace: cis_rekl,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        setData(response.data);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function result() {
    //console.log(logindata.UserData.ico);
    await axios
      .post(
        zoznamReklamaciiSQLUrl,
        {
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log(response);
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function storno(rekl, datum) {
    await axios
      .post(
        stornoReklamaceUrl,
        {
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          reklamace: rekl,
          datum: datum,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log(response);
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <Card>
      <div style={{ height: dajVysku(window.innerHeight) }}>
        <div className={styles.nadpis}>
          <div>Přehled reklamací</div>
        </div>
        <Table
          columns={columns}
          data={data}
          setData={setData}
          loading={loading}
        />
      </div>
    </Card>
  );
};
export default App;
