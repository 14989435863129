import React, { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import cn from "classnames";
import styles from "./Modal.module.sass";
import Icon from "../Icon";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import Div100vh from "react-div-100vh";

const Modal = ({
  widthModal,
  heightModal = null,
  outerClassName,
  visible,
  onClose,
  children,
  padding,
}) => {
  const escFunction = useCallback((e) => {}, [onClose]);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal");
    } else {
    }
  }, [visible]);

  return createPortal(
    visible && (
      <div id="modal" className={styles.modal}>
        <div
          style={{
            maxWidth: widthModal ? widthModal : 700,
            padding: padding ? padding : 10,
            minHeight: heightModal === null ? 500 : heightModal,
          }}
          className={cn(styles.outer, outerClassName)}
        >
          <div>{children}</div>
        </div>
      </div>
    ),
    document.body
  );
};

export default Modal;
