import { clearAllBodyScrollLocks } from "body-scroll-lock";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pickerTagyData } from "../../../screens/Objednavky/DDTagy/redux/PickerTagySlice";
import { pickerTagyObjednavky_typERP_OData } from "../../../screens/Objednavky_typERP_O/DDTagy/redux/PickerTagyObjednavky_typERP_OSlice";
import Icon from "../../Icon";
import Modal from "../../Modal";
import { modal, modalData, modalSlice } from "./../redux/modalSlice";
import styles from "./Modal.module.sass";
import Checkbox from "../../Checkbox";
import {
  objednavkyAllRows,
  objednavkyData,
  objednavkyRows,
} from "../../../screens/Objednavky/redux/objednavkySlice";
import {
  objednavky_typERP_OAllRows,
  objednavky_typERP_OData,
  objednavky_typERP_ORows,
} from "../../../screens/Objednavky_typERP_O/redux/objednavky_typERP_OSlice";
import { login, loginData } from "../../../screens/SignIn/redux/loginSlice";
import axios from "axios";
import { postTagyPolozkyUrl } from "../../../features/urlHelper";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../../features/errorDialog";
import { pickerObjednavky_typERP_BBData } from "../../../screens/Objednavky_typERP_BB/Dropdown/redux/PickerObjednavky_typERP_BBSlice";
import { pickerTagyObjednavky_typERP_BBData } from "../../../screens/Objednavky_typERP_BB/DDTagy/redux/PickerTagyObjednavky_typERP_BBSlice";
import { objednavky_typERP_BBAllRows } from "../../../screens/Objednavky_typERP_BB/redux/objednavky_typERP_BBSlice";
//import { pickerObjednavkyData } from "../../../screens/Objednavky/Dropdown/redux/PickerObjednavkySlice";

const App = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const modaldata = useSelector(modalData);
  const pickerTagydata = useSelector(pickerTagyData);
  const pickerTagy_Odata = useSelector(pickerTagyObjednavky_typERP_OData);
  const pickerTagy_BBdata = useSelector(pickerTagyObjednavky_typERP_BBData);
  const objednavkydata = useSelector(objednavkyData);
  const objednavky_Odata = useSelector(objednavky_typERP_OData);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );

  function removeItemWithSlice(index, items) {
    const firstArr = items.slice(0, index);
    const secondArr = items.slice(index + 1);
    return [...firstArr, ...secondArr];
  }

  function getTags(data) {
    //console.log(data);
    let array = [];
    if (data === undefined) return [];
    data.map((row) => {
      if (row.id === modaldata.EditTagovPolozkyID) {
        if (row.tagy !== undefined) {
          array = row.tagy;
        }
        if (row.tagy_ostatne !== undefined) {
          array = row.tagy_ostatne;
        }
        if (row.tagy_BB !== undefined) {
          array = row.tagy_BB;
        }
      }
    });
    return array;
  }

  async function postData(list_tagov, znacka) {
    await axios
      .post(
        postTagyPolozkyUrl,
        {
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          znacka: znacka,
          tag: list_tagov,
        },
        {
          headers: {
            Authorization: "Bearer " + logindata.Token,
          },
        }
      )
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  function getValue(data, id) {
    let value = false;
    if (data === undefined) return false;
    data.map((tag) => {
      if (id === tag.id) {
        value = true;
      }
    });
    return value;
  }
  return (
    <Modal
      outerClassName={styles.outerZaznamProblemu}
      visible={modaldata.EditTagovPolozky}
    >
      <div className={styles.ico_Title}>
        <div>Tagy k položce</div>
        <button
          onClick={() => {
            dispatch(
              modal({ data: { ...modaldata, EditTagovPolozky: false } })
            );
          }}
        >
          <Icon name="close" size="20" />
        </button>
      </div>
      <div
        style={{
          height: window.innerHeight < 500 ? window.innerHeight - 150 : 350,
        }}
        className={styles.tagy}
      >
        {(modaldata.EditTagovPolozkyType === "C"
          ? pickerTagydata
          : modaldata.EditTagovPolozkyType === "B"
          ? pickerTagy_BBdata
          : pickerTagy_Odata
        ).tagy.map((tag) => {
          return (
            <div className={styles.row}>
              <div className={styles.tag}>
                <Checkbox
                  value={getValue(
                    getTags(
                      modaldata.EditTagovPolozkyType === "C"
                        ? objednavkydata.rows
                        : objednavky_Odata.rows
                    ),
                    tag.id
                  )}
                  onChange={async (e) => {
                    let new_tagy = [
                      ...getTags(
                        modaldata.EditTagovPolozkyType === "C"
                          ? objednavkydata.rows
                          : objednavky_Odata.rows
                      ),
                    ];
                    let rows = [
                      ...(modaldata.EditTagovPolozkyType === "C"
                        ? objednavkydata.rows
                        : objednavky_Odata.rows),
                    ];
                    let allRows = [
                      ...(modaldata.EditTagovPolozkyType === "C"
                        ? objednavkydata.allRows
                        : objednavky_Odata.allRows),
                    ];
                    let poradie = 0;
                    let poradie_tag = null;
                    let new_row;
                    if (e.target.checked) {
                      new_tagy.push({
                        id: tag.id,
                        text: tag.text,
                        farba: tag.farba,
                        stredisko: tag.stredisko,
                      });
                      rows.map((row, index) => {
                        if (modaldata.EditTagovPolozkyID === row.id) {
                          poradie = index;
                          new_row = { ...row };
                        }
                      });
                      if (modaldata.EditTagovPolozkyType === "C")
                        new_row.tagy = new_tagy;
                      else if (modaldata.EditTagovPolozkyType === "B")
                        new_row.tagy_BB = new_tagy;
                      else new_row.tagy_ostatne = new_tagy;

                      rows[poradie] = new_row;
                      dispatch(
                        modaldata.EditTagovPolozkyType === "C"
                          ? objednavkyRows(rows)
                          : objednavky_typERP_ORows(rows)
                      );
                      if (modaldata.EditTagovPolozkyType === "C")
                        postData(new_row.tagy, new_row.znacka);
                      else if (modaldata.EditTagovPolozkyType === "B")
                        postData(new_row.tagy_BB, new_row.znacka);
                      else postData(new_row.tagy_ostatne, new_row.znacka);

                      allRows.map((row, index) => {
                        if (modaldata.EditTagovPolozkyID === row.id) {
                          poradie = index;
                          new_row = { ...row };
                        }
                      });

                      if (modaldata.EditTagovPolozkyType === "C")
                        new_row.tagy = new_tagy;
                      else if (modaldata.EditTagovPolozkyType === "B")
                        new_row.tagy_BB = new_tagy;
                      else new_row.tagy_ostatne = new_tagy;

                      allRows[poradie] = new_row;
                      dispatch(
                        modaldata.EditTagovPolozkyType === "C"
                          ? objednavkyAllRows(allRows)
                          : objednavky_typERP_OAllRows(allRows)
                      );
                    } else {
                      let tagyy;
                      rows.map((row, index) => {
                        if (modaldata.EditTagovPolozkyID === row.id) {
                          poradie = index;
                          new_row = { ...row };

                          if (modaldata.EditTagovPolozkyType === "C")
                            tagyy = [...row.tagy];
                          else if (modaldata.EditTagovPolozkyType === "B")
                            tagyy = [...row.tagy_BB];
                          else tagyy = [...row.tagy_ostatne];

                          if (modaldata.EditTagovPolozkyType === "C") {
                            row.tagy.map((tages, index2) => {
                              if (tages.id === tag.id) poradie_tag = index2;
                            });
                          } else if (modaldata.EditTagovPolozkyType === "B") {
                            row.tagy_BB.map((tages, index2) => {
                              if (tages.id === tag.id) poradie_tag = index2;
                            });
                          } else {
                            row.tagy_ostatne.map((tages, index2) => {
                              if (tages.id === tag.id) poradie_tag = index2;
                            });
                          }
                        }
                      });
                      if (poradie_tag !== null) {
                        if (modaldata.EditTagovPolozkyType === "C") {
                          new_row.tagy = removeItemWithSlice(
                            poradie_tag,
                            tagyy
                          );
                        } else if (modaldata.EditTagovPolozkyType === "B") {
                          new_row.tagy_BB = removeItemWithSlice(
                            poradie_tag,
                            tagyy
                          );
                        } else {
                          new_row.tagy_ostatne = removeItemWithSlice(
                            poradie_tag,
                            tagyy
                          );
                        }

                        rows[poradie] = new_row;

                        dispatch(
                          modaldata.EditTagovPolozkyType === "C"
                            ? objednavkyRows(rows)
                            : objednavky_typERP_ORows(rows)
                        );
                        postData(
                          removeItemWithSlice(poradie_tag, tagyy),
                          new_row.znacka
                        );
                      }
                      tagyy = [];
                      allRows.map((row, index) => {
                        if (modaldata.EditTagovPolozkyID === row.id) {
                          poradie = index;
                          new_row = { ...row };
                          if (modaldata.EditTagovPolozkyType === "C")
                            tagyy = [...row.tagy];
                          else if (modaldata.EditTagovPolozkyType === "B")
                            tagyy = [...row.tagy_BB];
                          else tagyy = [...row.tagy_ostatne];

                          if (modaldata.EditTagovPolozkyType === "C") {
                            row.tagy.map((tages, index2) => {
                              if (tages.id === tag.id) poradie_tag = index2;
                            });
                          } else if (modaldata.EditTagovPolozkyType === "B") {
                            row.tagy_BB.map((tages, index2) => {
                              if (tages.id === tag.id) poradie_tag = index2;
                            });
                          } else {
                            row.tagy_ostatne.map((tages, index2) => {
                              if (tages.id === tag.id) poradie_tag = index2;
                            });
                          }
                        }
                      });

                      if (poradie_tag !== null) {
                        if (modaldata.EditTagovPolozkyType === "C") {
                          new_row.tagy = removeItemWithSlice(
                            poradie_tag,
                            tagyy
                          );
                        } else if (modaldata.EditTagovPolozkyType === "B") {
                          new_row.tagy_BB = removeItemWithSlice(
                            poradie_tag,
                            tagyy
                          );
                        } else {
                          new_row.tagy_ostatne = removeItemWithSlice(
                            poradie_tag,
                            tagyy
                          );
                        }

                        //new_row.tagy = removeItemWithSlice(poradie_tag, tagyy);
                        allRows[poradie] = new_row;
                        dispatch(
                          modaldata.EditTagovPolozkyType === "C"
                            ? objednavkyAllRows(allRows)
                            : modaldata.EditTagovPolozkyType === "B"
                            ? objednavky_typERP_BBAllRows(allRows)
                            : objednavky_typERP_OAllRows(allRows)
                        );
                      }
                    }
                  }}
                />
                <div className={styles.text}>{tag.text}</div>
              </div>
              <div
                style={{
                  color: "white",
                  width: 20,
                  height: 20,
                  borderRadius: 4,
                  backgroundColor: tag.farba,
                  marginTop: 2,
                  marginLeft: 5,
                }}
              ></div>
            </div>
          );
        })}
      </div>

      <div className={styles.buttonArea}>
        <button
          onClick={() => {
            dispatch(
              modal({ data: { ...modaldata, EditTagovPolozky: false } })
            );
          }}
          className={cn("button", styles.button)}
        >
          OK
        </button>
      </div>
    </Modal>
  );
};
export default App;
