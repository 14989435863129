import Menu from "../../../components/Menu"

import styles from "./VolbyAdministratora.module.sass"


const App = ({ className }) => {



  return (
    <div className={styles.overlay}>
      <Menu typ="mod_adm" />
    </div>
  );
};
export default App;
