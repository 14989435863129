import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./Kalendar.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Zmeny from "./Zmeny";
import Kalendar from "./Kalendar";
import { useSelector, useDispatch } from "react-redux";
import { login, loginData } from "./../../screens/SignIn/redux/loginSlice";
import {
  kalendarData,
  kalendar,
  loadingKalendar,
} from "./Kalendar/redux/kalendarSlice";
import { kalendarUrl } from "../../features/urlHelper";
import axios from "axios";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../features/errorDialog";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const kalendardata = useSelector(kalendarData);

  useEffect(() => {
    dispatch(loadingKalendar());
    result();
  }, []);

  async function result() {
    await axios
      .get(kalendarUrl + logindata.UserData.vybrane_stredisko.kod, {
        headers: { Authorization: "Bearer " + logindata.Token },
      })
      .then(function (response) {
        dispatch(kalendar(response.data));
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch)
      });
  }

  return (
    <>
      <>
        <div className={cn("h3", styles.title)}>
          Kalendář{" "}
          <div hidden={!kalendardata.data.testServer} className={styles.test}>
            (test)
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Kalendar className={styles.card} />
          </div>
          <div className={styles.col}>
            <Zmeny className={styles.card} views="1" />
          </div>
        </div>
        <TooltipGlodal />
      </>
    </>
  );
};

export default Home;
