import { createSlice } from '@reduxjs/toolkit';

export const objednavkySlice = createSlice({
    name: 'objednavky',
    initialState: {
            rows: [],
            allRows: []
    },
    reducers: {
        objednavkyRows: (state, action) => {
            state.rows = action.payload
        },
        objednavkyAllRows: (state, action) => {
            state.allRows = action.payload
        },

    },
});

export const { objednavkyRows, objednavkyAllRows } = objednavkySlice.actions;

export const objednavkyData = state => state.objednavkydata;


export default objednavkySlice.reducer;
