import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Notification.module.sass";
import Icon from "../../Icon";
import Actions from "../../Actions";
import Item from "./Item";
import ModalEditTagovPolozky from "./../../Modaly/ModalEditTagovPolozky";
// data
import { notifications } from "../../../mocks/notifications";
import { ClickAwayListener } from "@material-ui/core";
import { useHistory } from "react-router";
import {
  notifikaciaUrl,
  precitaneNotifikacieUrl,
} from "../../../features/urlHelper";
import { useDispatch, useSelector } from "react-redux";
import { loginData } from "../../../screens/SignIn/redux/loginSlice";
import { errorHandleDialog } from "../../../features/errorDialog";
import axios from "axios";
import {
  setConnectionNotificationsUnreadID,
  signalRData,
} from "../../../screens/SignIn/redux/signalRSlice";
import { useEffect } from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";

const Notification = ({ className, data, setData, loading, result }) => {
  const navigate = useHistory();
  const dispatch = useDispatch();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const signalRdata = useSelector(signalRData);
  const [visible, setVisible] = useState(false);
  const [notificationsID, setNotificationsID] = useState("");
  const [connection, setConnection] = useState(null);
  const actions = [
    {
      title: "Označit vše jak přečteno",
      icon: "check",
      action: () => {
        let ids = [];
        data.map((notification) => {
          if (ids.filter((x) => x === notification.id)[0] === undefined) {
            ids.push(Number(notification.id));
          }
        });
        precitat(ids);
      },
    },
  ];

  window.addEventListener("popstate", function () {
    if ((connection !== undefined || connection !== null) && connection) {
      connection.stop();
    }
  });

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(
        notifikaciaUrl +
          "?username=" +
          (logindata.UserData === undefined ? "" : logindata.UserData.username)
      )
      .withAutomaticReconnect()
      .build();
    setConnection(connect);
  }, []);

  useEffect(() => {
    console.log("connection", connection);
    if (connection) {
      console.log("hub notification start");
      connection
        .start()
        .then(() => {
          //ziskame notificationID ( kvoli update)
          connection.invoke("GetConnectionId").catch(function (error) {
            console.log(error);
          });
          connection
            .then(function (connectionID) {
              dispatch(setConnectionNotificationsUnreadID(connectionID));
              console.log("connectionID", connectionID);
            })
            .catch(function (error) {
              console.log(error);
            });
          connection.on("ReceiveMessage", (message) => {
            console.log("ReceiveMessage", message);
            if (
              message.typ === "NOTIFICATIONS_UNREAD_REFRESH" &&
              logindata.UserData.vybrane_stredisko.kod ===
                message.data.vybrane_stredisko
            ) {
              console.log("NOTIFICATIONS_UNREAD_REFRESH");
              console.log(
                message.data.neprecitane_notifikacie.value.notifications
              );
              // setLoadingNotifications(true);
              result();
              //setNotifications(message.data.neprecitane_notifikacie.value.notifications);
            }
          });
        })
        .catch((error) => console.log(error));
    }
  }, [connection]);

  async function precitat(id) {
    console.log(logindata.UserData.vybrane_stredisko.kod);
    await axios
      .post(
        precitaneNotifikacieUrl,
        {
          id: id,
          vybrane_stredisko: logindata.UserData.vybrane_stredisko.kod,
          connectionNotificationsUnreadID:
            signalRdata.connectionNotificationsUnreadID,
          //connectionNotificationsID: signalRdata.connectionNotificationsID,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log(response);
        if (response.data.notifications[0] === undefined) setVisible(false);
        setData(response.data.notifications);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <ClickAwayListener onClickAway={() => setVisible(false)}>
      <div
        className={cn(styles.notification, className, {
          [styles.active]: visible,
        })}
      >
        <button
          className={cn(styles.head, styles[data?.length > 0 ? "active" : ""])}
          onClick={() => {
            setVisible(!visible);
          }}
          //onClick={() => setVisible(visible)}
        >
          <Icon name="notification" size="24" />
        </button>
        <div className={styles.body}>
          <div className={styles.top}>
            <div className={styles.title}>Správy</div>
            <Actions
              precitat={precitat}
              className={styles.actions}
              classActionsHead={styles.actionsHead}
              items={actions}
              small
            />
          </div>
          <div className={styles.list}>
            {loading ? (
              <></>
            ) : (
              <>
                {data.length > 0 ? (
                  data?.map((x, index) => (
                    <Item
                      className={cn(styles.item, className)}
                      item={x}
                      key={index}
                      precitat={precitat}
                      //onClose={() => setVisible(false)}
                    />
                  ))
                ) : (
                  <div className={styles.zadne_zanamy}>Žádne záznamy</div>
                )}
              </>
            )}
          </div>
          <button
            className={cn("button", styles.button)}
            onClick={() => {
              setVisible(false);
              navigate.push("/notifications");
            }}
          >
            Všechny správy
          </button>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default Notification;
