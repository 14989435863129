import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./Detail.module.sass";
import Icon from "../Icon";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { login, loginData } from "../../screens/SignIn/redux/loginSlice";
import axios from "axios";
import { deletePanelUrl } from "../../features/urlHelper";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../features/errorDialog";
import { evidenceTable } from "../EvidenceTableUniversal/Table/redux/tableEvidenceSlice";

const App = ({
  className,
  title,
  classTitle,
  classCardHead,
  head,
  children,
  disabled,
  idFieldName,
  idFieldValue,
  paginationData,
  setPaginationData,
  rowSelectionModel,
  setRowSelectionModel,
  visiblePanel,
  setvisiblePanel,
  volba,
  setVolba,
  dataset,
}) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  //const logindata = useSelector(loginData);
  async function deleteItem() {
    await axios
      .post(
        deletePanelUrl,
        {
          data: {
            ...paginationData,
            dataset: dataset,
            typErp: logindata.UserData.typErp,
          },
          delete: {
            value: idFieldValue,
            id: idFieldName,
          },
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        setVolba("delete");
        console.log("delete response", response);
        const newData = response.data.value.data.map((row, index) => {
          return { ...row, id: row[response.data.value.idFieldName] };
        });
        dispatch(evidenceTable(newData));
        setPaginationData({
          ...paginationData,
          count: response.data.value.count,
        });
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <div
      onClick={async () => {
        if (disabled) {
          swal({
            title: "Opravdu chcete odstranit?",
            icon: "info",
            buttons: ["Ne ", "Ano"],
            dangerMode: true,
          }).then(async (willShow) => {
            if (willShow) {
              deleteItem();
            }
          });
        }
      }}
      className={!disabled ? styles.iconDis : styles.icon}
    >
      <Icon name="trash" size="24"></Icon>
    </div>
  );
};

export default App;
