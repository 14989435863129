import { createSlice } from '@reduxjs/toolkit';

export const FormSlice = createSlice({
    name: 'Form',
    initialState: {
        data: [],
        loading: true,
    },
    reducers: {
        Form: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingForm: (state) => {
            console.log("loading: true")
            state.loading = true
        },
        loadedForm: (state) => {
            console.log("loading: false")
            state.loading = false
        }
    },
});

export const { Form, loadingForm, loadedForm } = FormSlice.actions;

export const FormData = state => state.Formdata;


export default FormSlice.reducer;
