import React, { Component } from "react";
import { errorLogUrl } from "./urlHelper";
import axios from "axios";
import swal from "sweetalert";
import styles from "./Feuatures.module.sass";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  async componentDidCatch(error, errorInfo) {
    const logindata = JSON.parse(
      window.sessionStorage.getItem("logindata") !== null
        ? window.sessionStorage.getItem("logindata")
        : "{}"
    );

    const stackLines = error.stack.split("\n");
    const mainStackLine =
      stackLines.length > 1 ? stackLines[1].trim() : "No stack trace available";
    console.log(error + "\n" + mainStackLine + "\n" + errorInfo.componentStack);
    await axios
      .post(
        errorLogUrl,
        {
          error: error + "\n" + mainStackLine + "\n" + errorInfo.componentStack,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(async function (response) {
        console.log("ok");
      })
      .catch(function (error) {
        swal("Chyba!", "chyba odoslania mailu", "error");
      });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            height: window.innerHeight,
            width: window.innerWidth,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <h1>Něco se pokazilo.</h1>
          </div>
          <button
            className={styles.button}
            onClick={() => {
              window.location.replace("/");
              window.sessionStorage.setItem("logindata", JSON.stringify({}));
            }}
          >
            Odhlásit
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
