import Card from "../../../components/Card";
import React, { useEffect, useState } from "react";
import EvidenceTableUniversal from "../../../components/EvidenceTableUniversal";

const App = ({ className }) => {
  return (
    <EvidenceTableUniversal dataset={"dan_prc"} backStep={"/manazerske&volby"} />
  );
};
export default App;
