import Card from "../../components/Card";
import Icon from "../../components/Icon";
import styles from "./StavSkladu.module.sass";
import cn from "classnames";
import Search from "../../components/Search";
import Table from "./Table";
import React, { useState } from "react";

const App = ({ className }) => {
  return (
    <div>
      <Card
      bezPaddingu={false}
        className={cn(styles.card, className)}
        title="Stav Skladu"
        classTitle="title-purple"
      >
        <br />
          <Table className={styles.table} />
      </Card>
    </div>
  );
};
export default App;
