import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import remove_accents from "remove-accents";
import { useSelector, useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import useDarkMode from "@fisch0920/use-dark-mode";
import axios from "axios";
import {
  loadingPrevodSurovinTable,
  prevodSurovinTable,
  prevodSurovinTableData,
} from "./redux/tablePrevodSurovinSlice";
import { login, loginData } from "../../SignIn/redux/loginSlice";
import { searchData } from "../../../components/Search/redux/searchSlice";
import { prevodSurovinUrl } from "../../../features/urlHelper";
import { DataGridPremium, LicenseInfo, csCZ } from "@mui/x-data-grid-premium";
import { getLicensePremium } from "../../../features/License";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { errorHandleDialog } from "../../../features/errorDialog";

const Table = ({ className, activeTable, setEditRowsModel }) => {
  const darkMode = useDarkMode(false);
  const navigate = useHistory();
  const notify_error = (text) => toast.error(text);
  LicenseInfo.setLicenseKey(getLicensePremium());

  function getWidth(width) {
    if (width > 2500) {
      return width - 456;
    } else if (width < 2500 && width > 767) {
      return width - 212;
    } else {
      return width - 48;
    }
  }

  function isInt(n) {
    return n % 1 === 0;
  }

  const stylingTable = {
    border: 0,
    "& .weekend6_help_nadpis": {
      backgroundColor: darkMode.value ? "#272b30" : "#f4f4f4",
    },
    "& .weekend6_help_cell": {
      backgroundColor: darkMode.value ? "#272b30" : "#f4f4f4",
    },
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      color: darkMode.value ? "white" : "#666d74",
      borderRight: `1px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },

    "& .MuiSvgIcon-root": {
      color: "#a7acaf",
    },
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
      "& .MuiInputBase-root": {
        height: "100%",
      },
    },
    "& .MuiDataGrid-overlay": {
      backgroundColor: "transparent",
      color: darkMode.value ? "white" : "#ACACAC",
    },
    "& .MuiInputBase-input": {
      color: darkMode.value ? "white" : "#ACACAC",
    },
    "& .MuiDataGrid-cell": {
      padding: 1,
    },
    "& .Mui-error": {
      backgroundColor: "red",
      color: "white",
    },
    width: getWidth(window.innerWidth),
    height:
      window.innerWidth < 767
        ? window.innerHeight - 315
        : window.innerHeight - 365,
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
  };

  const columns = [
    {
      field: "nazov",
      headerName: "Položka",
      width: 280,
      groupable: false,
      renderCell: (params) => (
        <>
          <div className={styles.nazov}>
            <div className={styles.avatar}>
              <img src={"/images/content/bageta2.png"} alt="Avatar" />
            </div>
            <div className={styles.nazov_kod}>
              <div>{params.row.nazov}</div>
              <div className={styles.znacka}>
                <div className={styles.znackaText}>{params.row.znacka}</div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      field: "zasoba",
      headerName: "Stav skladu",
      width: (getWidth(window.innerWidth) - 280) * 0.33,
      minWidth: 90,
      type: "number",
      headerAlign: "center",
      valueFormatter: (params) => {
        const valueFormatted = Number(params.value);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
    },
    {
      field: "mj",
      headerName: "Jednotka",
      width: (getWidth(window.innerWidth) - 280) * 0.33,
      minWidth: 90,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "pocet",
      headerName: "Počet",
      width: (getWidth(window.innerWidth) - 280) * 0.34,
      editable: true,
      minWidth: 90,
      type: "number",
      headerAlign: "center",
      valueFormatter: (params) => {
        if (params.value === null || params.value === 0) {
          return "";
        }
        const valueFormatted = Number(params.value);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
      preProcessEditCellProps: (params) => {
        const hasError =
          params.props.value < 0 ||
          !isInt(params.props.value) ||
          params.row.zasoba < params.props.value;
        if (hasError) {
          if (params.props.value < 0) {
            notify_error("Počet musí být větší než 0!");
          }
          if (params.row.zasoba < params.props.value) {
            notify_error("Počet nesmí přesáhnout zásobu!");
          }
        }
        return { ...params.props, error: hasError };
      },
    },
  ];

  const theme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
    },
  });

  const dispatch = useDispatch();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const prevodSurovinTabledata = useSelector(prevodSurovinTableData);
  const searchdata = useSelector(searchData);

  const handleEditRowsModelChange = React.useCallback((model) => {
    setEditRowsModel(model);
  }, []);

  async function result() {
    await axios
      .get(prevodSurovinUrl + logindata.UserData.vybrane_stredisko.kod, {
        headers: { Authorization: "Bearer " + logindata.Token },
      })
      .then(function (response) {
        console.log("prevod Surovin", response.data);
        dispatch(loadingPrevodSurovinTable());
        dispatch(prevodSurovinTable(response.data));
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  useEffect(() => {
    result();
  }, []);

  return (
    <div className={cn(styles.wrapper1, className)}>
      {prevodSurovinTabledata.loading ? (
        <ContentLoader
          speed={2}
          width={"100%"}
          height={300}
          backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
          foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
        >
          <rect x="3%" y="5%" rx="3" ry="3" width={"100%"} height="80%" />
        </ContentLoader>
      ) : (
        <div className={cn(styles.wrapper, className)}>
          <ThemeProvider theme={theme}>
            <DataGridPremium
              sx={stylingTable}
              localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
              {...{
                rows: prevodSurovinTabledata.data.filter(
                  (xy) =>
                    String(remove_accents(xy.nazov.toUpperCase())).includes(
                      remove_accents(
                        searchdata.searchPrevodSurovin.toUpperCase()
                      )
                    ) ||
                    String(remove_accents(xy.znacka.toUpperCase())).includes(
                      remove_accents(
                        searchdata.searchPrevodSurovin.toUpperCase()
                      )
                    )
                ),
                columns: columns,
              }}
              disableAutoFocus
              disableColumnMenu
              defaultGroupingExpansionDepth={-1}
              hideFooter
              rowHeight={40}
              onCellEditStop={async function (params, event) {
                //event.defaultMuiPrevented = true;
                if (event.code === "Enter") {
                  console.log(params);

                  let row = prevodSurovinTabledata.data.filter(
                    (row) => row.id === params.id
                  );
                  let index = prevodSurovinTabledata.data.indexOf(row[0]);
                  let data = [...prevodSurovinTabledata.data];
                  let row_copy = { ...row[0] };
                  row_copy.pocet = event.target.value;
                  data[index] = row_copy;
                  dispatch(loadingPrevodSurovinTable());
                  dispatch(prevodSurovinTable(data));
                }
              }}
              onEditRowsModelChange={handleEditRowsModelChange}
              //rowGroupingModel={rowGroupingModel}
            />
          </ThemeProvider>
        </div>
        // </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Table;
