import Card from "../../components/Card";
import Icon from "../../components/Icon";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Search from "../../components/Search";
import Table from "./Table";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import { login, loginData } from "../SignIn/redux/loginSlice";
import {
  VypisDokladuHTMLUrl,
  menuAkciaUrl,
  reklMenuAkciaUrl,
  zoznamReklamaciTrellisUrl,
} from "../../features/urlHelper";
import swal from "sweetalert";
import { useHistory } from "react-router";
import Delete from "../../components/Delete";
import { errorHandleDialog } from "../../features/errorDialog";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { modal, modalData } from "../../components/Modaly/redux/modalSlice";
import { openNewWindow } from "../../features/OpenNewWindow";

const App = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const modaldata = useSelector(modalData);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [params_to_menu, setParams_to_menu] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, value, params) => {
    console.log(params);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  function getWidth(width) {
    if (width > 2500) {
      return width - 408;
    } else if (width < 2500 && width > 767) {
      return width - 144;
    } else {
      return width;
    }
  }

  const columns = [
    {
      field: "datum",
      hideable: false,
      headerName: "Datum",
      width: (getWidth(window.innerWidth) - 0) * 0.2,
      headerAlign: "start",
      sortable: false,
      filterable: false,
    },
    {
      field: "id",
      hideable: false,
      headerName: "Číslo tiketu",
      width: (getWidth(window.innerWidth) - 0) * 0.2,
      headerAlign: "start",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div 
          onClick={() => {
            openNewWindow(
              logindata,
              "http://erp-reklamace.iist.inver.sk/index.php?act=tickets&code=view&id=" +
                params.value
            );
          }} className={styles.polozka_reklamace}>
            <i class="fa fa-wrench"></i>
            <div>{params.row.id}</div>
          </div>
        );
      },
    },
    {
      field: "status",
      hideable: false,
      headerName: "Stav",
      width: (getWidth(window.innerWidth) - 0) * 0.2,
      headerAlign: "start",
      sortable: false,
      filterable: false,
    },

    {
      field: "nazov",
      hideable: false,
      headerName: "Položky",
      width: (getWidth(window.innerWidth) - 35) * 0.4,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className={styles.polozka}>
            <div className={styles.popis}>
              <div className={styles.nazov}>{params.row.nazov}</div>
              <div className={styles.znacka}>{params.row.znacka}</div>
            </div>
            <div className={styles.content}>
              <div className={styles.obrazok}>
                <img src="/images/content/bageta_1.jpg" alt={params.row.text} />
              </div>
              <div className={styles.overlay_text}>
                <p>{params.row.mnozstvo}</p>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    result(logindata.UserData.trellis_username);
  }, []);



  async function result(trellis_username) {
    await axios
      .post(
        zoznamReklamaciTrellisUrl,
        {
          trellis_username: trellis_username,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log(response);
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <div>
      <div className={styles.nadpis}>
        <div>Přehled reklamací z Trellisu</div>
      </div>

      <Table columns={columns} data={data} loading={loading} />
    </div>
  );
};
export default App;
