import styles from "./Modaly.module.sass";
import cn from "classnames";
import Table from "./Table";
import TableUzavreta from "./TableUzavreta";
import TableOtvorena from "./TableOtvorena";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailReklamaciiSQLUrl } from "../../../features/urlHelper";
import axios from "axios";
import { errorHandleDialog } from "../../../features/errorDialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const App = ({ className }) => {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );

  const [data, setData] = useState([]);
  const [prilohy, setPrilohy] = useState([]);
  const [rdl, setRdl] = useState([]);
  const [date, setDate] = useState(null);
  const [priznak, setPriznak] = useState("");
  const [typ, setTyp] = useState("");
  const [cis_rekl, setCis_rekl] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const url = window.location.href;
    const params = new URLSearchParams(new URL(url).search);
    const datum = params.get("datum");
    const cis_rekl_ = params.get("cis_rekl");
    const priznak_ = params.get("priznak");
    const typ = params.get("typ");
    setCis_rekl(cis_rekl_);
    setDate(datum);
    setPriznak(priznak_);
    setTyp(typ);
    nacitajDetail(cis_rekl_);
  }, []);

  async function nacitajDetail(cis_rekl_) {
    //console.log(logindata.UserData.ico);
    await axios
      .post(
        detailReklamaciiSQLUrl,
        {
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          doklad: cis_rekl_,
          riesitel: priznak !== "base",
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        //console.log(response)
        setPrilohy(response.data.prilohy);
        setRdl(response.data.rdl);
        setData(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  function returnTable() {
    switch (priznak) {
      case "nova":
      case "base":
        return (
          <Table
            className={styles.table}
            cis_rekl={cis_rekl}
            data={data}
            prilohy={prilohy}
            rdl={rdl}
            date={date}
            priznak={priznak}
            typ={typ}
            loading={loading}
          />
        );
      case "otvorena":
        return (
          <TableOtvorena
            className={styles.table}
            cis_rekl={cis_rekl}
            data={data}
            prilohy={prilohy}
            date={date}
            priznak={priznak}
            typ={typ}
            loading={loading}
          />
        );
      case "uzavreta":
        return (
          <Table
            className={styles.table}
            cis_rekl={cis_rekl}
            data={data}
            prilohy={prilohy}
            rdl={rdl}
            date={date}
            priznak={priznak}
            typ={typ}
            loading={loading}
          />
        );
    }
  }

  return <>{returnTable()}</>;
};
export default App;
