import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../Icon";
import Modal from "../../Modal";
import { modal, modalData } from "./../redux/modalSlice";
import styles from "./Modal.module.sass";
import { loginData } from "../../../screens/SignIn/redux/loginSlice";
import axios from "axios";
import { getPdf } from "./../../../features/getPdf";
import { useHistory } from "react-router";
import { Height, ZoomOut } from "@mui/icons-material";
const App = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();

  const modaldata = useSelector(modalData);

  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );

  return (
    <Modal
      widthModal={window.innerWidth < 880 ? window.innerWidth : 880}
      outerClassName={styles.outerZaznamProblemu}
      visible={modaldata.ZobrazHtml}
    >
      <div className={styles.ZobrazHtml_title}>
        {modaldata.ZobrazHtml_title}
      </div>
      <div className={styles.ico_Title}>
        <div
          hidden={modaldata.ZobrazHtml_print === null}
          className={styles.buttonZmena}
          onClick={() => {
            getPdf(
              modaldata.ZobrazHtml_print,
              logindata.Token,
              dispatch,
              navigate
            );
          }}
        >
          <div>Tisk</div>
        </div>
        <button
          onClick={() => {
            dispatch(modal({ data: { ...modaldata, ZobrazHtml: false } }));
          }}
        >
          <Icon name="close" size="20" />
        </button>
      </div>

      <div className={styles.ZobrazHtmlText}>{modaldata.ZobrazHtml_text}</div>

      {modaldata.HtmlData !== undefined ? (
        <div
          style={{ height: window.innerHeight - 165 }}
          className={styles.ZobrazHtml}
          dangerouslySetInnerHTML={{ __html: modaldata.HtmlData }}
          styles={{ ZoomOut: "200%" }}
        />
      ) : (
        <div className={styles.ZobrazHtml}>
          Zobrazeni neni implementováno!!!
        </div>
      )}
      <div className={styles.ZobrazHtml_title}>
        <div
          onClick={() => {
            dispatch(modal({ data: { ...modaldata, ZobrazHtml: false } }));
          }}
          className={styles.buttonCancel}
        >
          <div>Ukončit prohlížení</div>
        </div>
      </div>
    </Modal>
  );
};
export default App;
