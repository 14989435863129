import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import remove_accents from "remove-accents";
import { useSelector, useDispatch } from "react-redux";
import Icon from "../../../components/Icon";

import {
  prevodTable,
  prevodTableData,
  loadingPrevodTable,
} from "./redux/tablePrevodSlice";

import ContentLoader from "react-content-loader";
import useDarkMode from "@fisch0920/use-dark-mode";
import { modalData, modal } from "../../../components/Modaly/redux/modalSlice";
import { Form, loadingForm } from "../../../components/Modaly/ModalForm/redux/FormSlice";
import { useHistory } from "react-router/cjs/react-router.min";
import { formRunUrl } from "../../../features/urlHelper";
import axios from "axios";
import { loginData } from "../../SignIn/redux/loginSlice";
import { errorHandleDialog } from "../../../features/errorDialog";
import ModalForm from "../../../components/Modaly/ModalForm";


// data

const Table = ({ className }) => {
  const data = [];
  const navigate = useHistory();
  const dispatch = useDispatch();
  const darkMode = useDarkMode(false);
  const modaldata = useSelector(modalData);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const tablePrevoddata = useSelector(prevodTableData);
  const [visibleForm, setVisibleForm] = useState(false);
  const [formName, setFormName] = useState("");

  useEffect(() => {
    dispatch(loadingPrevodTable());
    dispatch(prevodTable([]));
  }, []);

  async function call_form(volba, data = null) {
    await axios
      .post(
        formRunUrl,
        {
          formName: "NabidnoutJinePobocke",
          formValues: data,
          volba: volba,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        if (response.data.typ === "route") {
          navigate.push(response.data.url);
        } else if (response.data.typ === "enter") {
          console.log("enterData", response.data.enterData);
          
          dispatch(loadingForm());
          setFormName(response.data.enterData.formName);
          dispatch(Form(response.data.enterData));
          setVisibleForm(true);
          document
                .getElementsByName(
                  response.data.enterData.values[Object.keys(response.data.enterData.values)[0]].id
                )[0]
                .focus();
        }
        console.log(response);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <>
      <div className={styles.pridatSurovinu_spolu}>
        <button
          onClick={() => {
            call_form("add");
            // dispatch(modal({ data: { ...modaldata, PridatSurovinuNP: true } }))
            // dispatch(
            //   pridatSurovinuEditModal({
            //     data: {
            //       id: 0,
            //       nazov: '',
            //       znacka: '',
            //       sklad: 0,
            //       new_sklad: 0,
            //       poslane: 0,
            //       mj: '',
            //       komentar: ''
            //     }
            //   })
            // )
          }}
          className={styles.buttonPS}
        >
          <div className={styles.buttonPSI}>
            <Icon name="add" size="24" />
          </div>
          <div className={styles.buttonPST}>Přidat surovinu</div>
        </button>
        <div className={styles.spolu}>spolu: 8</div>
      </div>

      <div
        style={{
          height: window.innerHeight > 550 ? window.innerHeight - 520 : 600,
        }}
        className={cn(styles.wrapper1, className)}
      >
        {tablePrevoddata.loading ? (
          <ContentLoader
            speed={2}
            width={"100%"}
            height={300}
            //viewBox="0 0 400 100"
            backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
            foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
          >
            <rect x="3%" y="5%" rx="3" ry="3" width={"90%"} height="80%" />
          </ContentLoader>
        ) : (
          <>
            <>
              <div className={cn(styles.wrapper, className)}>
                <div className={cn(styles.table)}>
                  {true ? (
                    <div className={cn(styles.row)}>
                      <div className={styles.col1}>Surovina </div>
                      <div className={styles.col}>Akt. zásoba </div>
                      <div className={styles.col}>Převod </div>
                      {window.innerWidth > 800 ? (
                        <div className={styles.col}>Komentář </div>
                      ) : (
                        <></>
                      )}

                      <div className={styles.col}>Akce </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <br />
                  {tablePrevoddata.data.map((xyz, index) => (
                    <></>
                  ))}
                </div>
              </div>
            </>
          </>
        )}
      </div>
      <ModalForm
          priznak="pridatSurovinu"
          visible={visibleForm}
          setVisible={setVisibleForm}
          className={styles}
          formName={formName}
        />
    </>
  );
};

export default Table;
