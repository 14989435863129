import { createSlice } from '@reduxjs/toolkit';

export const tableExternyDodavatelSlice = createSlice({
    name: 'externyDodavatelTable',
    initialState: {
        data: [],
        loading: true,
    },
    reducers: {
        externyDodavatelTable: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingExternyDodavatelTable: (state) => {
            console.log("loading: true")
            state.loading = true
        }

    },
});

export const { externyDodavatelTable, loadingExternyDodavatelTable} = tableExternyDodavatelSlice.actions;

export const externyDodavatelTableData = state => state.externyDodavateldata;


export default tableExternyDodavatelSlice.reducer;
