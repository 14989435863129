import { createSlice } from '@reduxjs/toolkit';

export const tableMalaInventuraSlice = createSlice({
    name: 'malaInventuraTable',
    initialState: {
        data: [],
        loading: true,
    },
    reducers: {
        malaInventuraTable: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingMalaInventuraTable: (state) => {
            console.log("loading: true")
            state.loading = true
        }
    },
});

export const { malaInventuraTable, loadingMalaInventuraTable } = tableMalaInventuraSlice.actions;

export const malaInventuraTableData = state => state.malaInventuradata;


export default tableMalaInventuraSlice.reducer;
