import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./List.module.sass";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Actions from "../../../components/Actions";
import Loader from "../../../components/Loader";
import Item from "./Item";

// data
import { notifications } from "../../../mocks/notifications";
import { notifikaciaUrl, vsetkyNotifikacieUrl } from "../../../features/urlHelper";
import { useDispatch, useSelector } from "react-redux";
import { loginData } from "../../SignIn/redux/loginSlice";
import axios from "axios";
import { errorHandleDialog } from "../../../features/errorDialog";
import { useHistory } from "react-router";
import {
  setConnectionID,
  setConnectionNotificationsID,
  signalRData,
} from "../../SignIn/redux/signalRSlice";
import { HubConnectionBuilder } from "@microsoft/signalr";

const intervals = ["Recent", "New", "This year"];

const actions = [
  {
    title: "Označit jako přečtené",
    icon: "check",
    action: () => console.log("Mark as read"),
  },
  // {
  //   title: "Go setting",
  //   icon: "setting",
  //   action: () => console.log("Go setting"),
  // },
];

const List = ({ className, setFilters }) => {
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const signalRdata = useSelector(signalRData);
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [sorting, setSorting] = useState(intervals[0]);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    const connectNotifications = new HubConnectionBuilder()
      .withUrl(
        notifikaciaUrl +
          "?username=" +
          (logindata.UserData === undefined ? "" : logindata.UserData.username)
      )
      .withAutomaticReconnect()
      .build();
    setConnection(connectNotifications);
  }, []);

  useEffect(() => {
    setLoadingNotifications(true);
    result();
  }, []);

  useEffect(async () => {
    console.log("connection", connection);
    if (connection) {
      console.log("hub notification start");
      connection
        .start()
        .then(() => {
          //ziskame notificationID ( kvoli update)
          connection
            .invoke("GetConnectionId")
            .then(function (connectionID) {
              dispatch(setConnectionNotificationsID(connectionID));
              console.log("connectionID", connectionID);
            });
            connection.on(
            "ReceiveMessage",
            async (message) => {
              console.log("ReceiveMessage", message);
              console.log(message.data.vybrane_stredisko);
              console.log(logindata.UserData.vybrane_stredisko.kod);
              console.log(message.typ);
              if (
                message.typ === "NOTIFICATIONS_ALL_REFRESH" &&
                logindata.UserData.vybrane_stredisko.kod ===
                  message.data.vybrane_stredisko
              ) {
                console.log("NOTIFICATIONS_ALL_REFRESH");
                result();
              }
            }
          );
        })
        .catch((error) => console.log(error));
    }
  }, [connection]);

  async function result() {
    await axios
      .get(vsetkyNotifikacieUrl, {
        headers: { Authorization: "Bearer " + logindata.Token },
      })
      .then(function (response) {
        let help_filter = [];
        response.data.notifications.map((notification) => {
          if (
            help_filter.filter((x) => x === notification.odosielatel)[0] ===
            undefined
          ) {
            help_filter.push(notification.odosielatel);
          }
        });
        setFilters(help_filter);
        setNotifications(response.data.notifications);
        setLoadingNotifications(false);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <Card
      className={cn(styles.card, className)}
      title="Správy"
      classTitle={cn("title-red", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          {/* <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={setSorting}
            options={intervals}
            small
          /> */}
          <Actions
            className={styles.actions}
            classActionsHead={styles.actionsHead}
            items={actions}
          />
        </>
      }
    >
      <div className={styles.notifications}>
        <div className={styles.list}>
          {loadingNotifications ? (
            <></>
          ) : (
            <>
              {notifications.map((x, index) => (
                <Item
                  className={cn(styles.item, className)}
                  item={x}
                  key={index}
                />
              ))}
            </>
          )}
        </div>
        {/* <div className={styles.foot}>
          <button className={cn("button-stroke button-small", styles.button)}>
            <Loader className={styles.loader} />
            <span>Load more</span>
          </button>
        </div> */}
      </div>
    </Card>
  );
};

export default List;
