export function areArraysEqual(arr1, arr2) {
    //ak sa nerovanju dlzky automaticky sa nerovnaju polia
    if (arr1.length !== arr2.length) {
        return false;
    }
    //zotriedenie 
    arr1.sort((a, b) => a - b);
    arr2.sort((a, b) => a - b);

    //porovnanie poli v cykle
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
}