import Modal from "../../Modal";
import Icon from "../../Icon";
import styles from "./Modal.module.sass";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modal, modalData } from "./../redux/modalSlice";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import Image from "../../Image";
import DatePicker from "react-datepicker";
import cn from "classnames";
import cs from "date-fns/locale/cs";
import {
  kalendar,
  kalendarData,
  loadingKalendar,
} from "../../../screens/Kalendar/Kalendar/redux/kalendarSlice";
import TimePicker from "./TimePicker";
import { login, loginData } from "../../../screens/SignIn/redux/loginSlice";
import axios from "axios";
import { otvSchvalenieUrl } from "../../../features/urlHelper";
import swal from "sweetalert";
import {
  loadingZmeny,
  zmeny,
} from "../../../screens/Kalendar/Zmeny/redux/zmenySlice";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../../features/errorDialog";

const App = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const modaldata = useSelector(modalData);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const kalendardata = useSelector(kalendarData);

  const day_of_week = [
    {
      den: "Pondelí",
      por: 1,
    },
    {
      den: "Úterý",
      por: 2,
    },
    {
      den: "Středa",
      por: 3,
    },
    {
      den: "Čtvrtek",
      por: 4,
    },
    {
      den: "Pátek",
      por: 5,
    },
    {
      den: "Sobota",
      por: 6,
    },
    {
      den: "Neděle",
      por: 7,
    },
  ];

  function getGrid() {
    if (window.innerWidth >= 1035) {
      return "repeat(4, 1fr)";
    } else if (window.innerWidth >= 825) {
      return "repeat(3, 1fr)";
    } else {
      return "repeat(2, 1fr)";
    }
  }

  return (
    <Modal outerClassName={styles.outer} visible={modaldata.TrvalaZmena}>
      <div className={styles.ico_Title}>
        <div className={styles.pic_around}>
          <Image
            className={styles.pic}
            src="/images/content/shop.png"
            srcDark="/images/content/shop.png"
          />
          Trvala změna
        </div>
        <div className={styles.ico}>
          <button
            className={styles.icon}
            onClick={() => {
              dispatch(modal({ data: { ...modaldata, TrvalaZmena: false } }));

              clearAllBodyScrollLocks();
            }}
          >
            <Icon name="close" size="20" />
          </button>
        </div>
      </div>
      <br />
      <div>
        Doba platnosti <Icon className={styles.icon} name="info" size="20" />
      </div>

      <div className={styles.dateArea}>
        <div className={styles.od}>Od</div>
        <div>
          <DatePicker
            locale={cs}
            dateFormat={"dd.MM.yyyy"}
            calendarClassName={styles.calendar}
            className={styles.date}
            selected={
              modaldata.TrvalaZmenaDate.datum_od === null
                ? null
                : new Date(modaldata.TrvalaZmenaDate.datum_od)
            }
            onChange={(e) => {
              if (e.getDay() === 1) {
                kalendardata.data.kalendar.map((year) => {
                  if (year.rok === e.getFullYear()) {
                    year.mesiace.map((month) => {
                      if (month.mesiac - 1 === e.getMonth()) {
                        month.tyzden.map((week) => {
                          if (
                            new Date(week.dni[0].datum).getDate() ===
                            e.getDate()
                          ) {
                            dispatch(
                              modal({
                                data: {
                                  ...modaldata,
                                  TrvalaZmenaDate: {
                                    ...modaldata.TrvalaZmenaDate,
                                    datum_od: new Date(e),
                                    dni: week.dni,
                                  },
                                },
                              })
                            );
                          }
                        });
                      }
                    });
                  }
                });
              }
            }}
          ></DatePicker>
        </div>
      </div>
      <br />
      <div>
        Nová provozní doba{" "}
        <Icon className={styles.icon} name="info" size="20" />
      </div>
      <div
        className={styles.overlay}
        style={{
          display: "grid",
          gridTemplateColumns: getGrid(),
        }}
      >
        {modaldata.TrvalaZmenaDate.dni.map((den, index) => {
          return (
            <div>
              {day_of_week.map((day) => {
                if (den.dayOfWeek === day.por) {
                  return day.den;
                }
              })}
              <div className={styles.dateAreaLine}>
                <div className={styles.dateArea}>
                  <Image
                    className={styles.pic}
                    src="/images/content/shop.png"
                    srcDark="/images/content/shop.png"
                  />
                  <TimePicker
                    dayOfWeek={den.dayOfWeek}
                    index={index}
                    title={"otvor_od"}
                  />

                  <TimePicker
                    dayOfWeek={den.dayOfWeek}
                    index={index}
                    title={"otvor_do"}
                  />
                </div>
              </div>

              <div className={styles.dateAreaLine}>
                <div className={styles.dateArea}>
                  <Image
                    className={styles.pic}
                    src="/images/content/cycle.png"
                    srcDark="/images/content/cycle.png"
                  />
                  <TimePicker
                    dayOfWeek={den.dayOfWeek}
                    index={index}
                    title={"rozvoz_od"}
                  />

                  <TimePicker
                    dayOfWeek={den.dayOfWeek}
                    index={index}
                    title={"rozvoz_do"}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.buttonArea}>
        <button
          onClick={async () => {
            console.log(modaldata.TrvalaZmenaDate.dni);
            await axios
              .put(
                otvSchvalenieUrl,
                {
                  stredisko: logindata.UserData.vybrane_stredisko.kod,
                  typ: "zmena",
                  id: modaldata.TrvalaZmenaDate.id,
                  zmena: {
                    datum_od: modaldata.TrvalaZmenaDate.datum_od,
                    dni: modaldata.TrvalaZmenaDate.dni,
                  },
                },
                {
                  headers: { Authorization: "Bearer " + logindata.Token },
                }
              )
              .then(function (response) {
                dispatch(loadingKalendar());
                dispatch(loadingZmeny());
                console.log(response.data.kalendar);
                dispatch(modal({ data: { ...modaldata, TrvalaZmena: false } }));
                dispatch(kalendar(response.data.kalendar));
                dispatch(zmeny(response.data.zmeny));
              })
              .catch(function (error) {
                errorHandleDialog(error.response, navigate, dispatch)
              });
          }}
          className={styles.button}
        >
          Potvrdit
        </button>
      </div>
    </Modal>
  );
};
export default App;
