import Card from "../../../components/Card";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Search from "../../../components/Search";
import Table from "./Table";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  zmena,
  zmenaData,
  loadingZmena,
} from "../../../components/Modaly/ModalZmena/redux/zmenaSlice";
import { modalData, modal } from "../../../components/Modaly/redux/modalSlice";
import {
  prijemOdJinePobockyTable,
  prijemOdJinePobockyTableData,
  setZaznamy,
} from "./Table/redux/tablePrijemOdJinePobockySlice";
import { setComment } from "../../../components/Modaly/ModalZmena/redux/zmenaSlice";
import { dokladyUrl } from "../../../features/urlHelper";
import ModalZmena from "../../../components/Modaly/ModalZmena";
import { loginData } from "../../SignIn/redux/loginSlice";
import axios from "axios";
import { errorHandleDialog } from "../../../features/errorDialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";
const App = ({
  className,
  editPsOver,
  setEditPsOver,
  visibleZaznamyProblemov,
  setVisibleZaznamyProblemov,
  priznak,
  zobrazTrellis,
  setZobrazTrellis,
}) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const zmenadata = useSelector(zmenaData);
  const prijemOdJinePobockyTabledata = useSelector(
    prijemOdJinePobockyTableData
  );
  const [visible, setVisible] = useState(false);

  function pocet() {
    let i = 0;
    prijemOdJinePobockyTabledata.data.map((row) => {
      if (row.prijate > 0 && row.prijate <= row.mnozstvo) {
        i++;
      }
    });
    return i;
  }

  function text_odoslat_objednavku() {
    return '</div style="font-weight: 300;"> <div style="color: red;"><div>Opravdu chcete odmítnout přijetí celého závozu</div><div>z důvodu špatné teploty ve voze</div></div>';
  }

  return (
    <>
      <div className={styles.Content}>
        <div className={styles.upperContent}>
          <div className={styles.upperContent}>
            <div className={cn(styles.avatar)}>
              <img src={"/images/content/home.png"} alt="Avatar" />
            </div>
            <div className={styles.cieloveStrediskoContent}>
              <div className={styles.cieloveStrediskoButton}>
                <div style={{ marginRight: 5 }}>Výběr dokladu</div>
                <div>
                  <button
                    className={styles.buttonZmena}
                    onClick={async () => {
                      // dispatch(
                      //   modal({ data: { ...modaldata, ZmenaDokladu: true } })
                      // );
                      await axios
                        .get(
                          dokladyUrl +
                            "/" +
                            logindata.UserData.vybrane_stredisko.kod,
                          {
                            headers: {
                              Authorization: "Bearer " + logindata.Token,
                            },
                          }
                        )
                        .then(function (response) {
                          console.log(response);
                          let data = [];
                          console.log(priznak);
                          if (priznak === "Other") {
                            data = response.data.map((item) => ({
                              ...item,
                              vybrane: false,
                            }));
                            //.filter((x) => x.stredisko !== "ZMT");
                          } else {
                            data = response.data.map((item) => ({
                              ...item,
                              vybrane: false,
                            }));
                            //.filter((x) => x.stredisko === "ZMT");
                          }

                          dispatch(loadingZmena());
                          dispatch(zmena(data));
                          setVisible(true);
                        })
                        .catch(function (error) {
                          errorHandleDialog(error.response, navigate, dispatch);
                        });
                    }}
                  >
                    {zmenadata.isVybrate ? "Změnit" : "Vybrat"}
                  </button>
                </div>
              </div>
              <div className={styles.cieloveStredisko}>
                <div style={{ fontWeight: "bold" }}>
                  <div>
                    {zmenadata.data.map((item) => {
                      if (item.vybrane) {
                        return item.nazov;
                      }
                    })}
                  </div>
                </div>
                <div style={{ color: "gray" }}>
                  {zmenadata.data.map((item) => {
                    if (item.vybrane) {
                      return item.info;
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          {priznak === "Other" ? (
            <></>
          ) : (
            <div className={styles.upperContent}>
              <div className={styles.cieloveStrediskoContent}>
                <div className={styles.cieloveStrediskoButton}>
                  <div className={styles.odmit_prijem}>
                    <div>Odmítnout příjem - </div>
                    <div>špatná tělota ve voze</div>
                  </div>
                  <button
                    className={styles.buttonOdmitnout}
                    onClick={async () => {
                      // dispatch(
                      //   modal({ data: { ...modaldata, ZmenaDokladu: true } })
                      // );
                      swal({
                        content: {
                          element: "div",
                          attributes: {
                            innerHTML: text_odoslat_objednavku(),
                          },
                        },
                        // text: text_odoslat_objednavku(
                        //   now,
                        //   datum_active,
                        //   exist_stop_obj
                        // ),
                        icon: "info",
                        buttons: ["Ne ", "Ano"],
                      }).then(async (willShow) => {
                        if (willShow) {
                          var zaznamy = [];
                          console.log(prijemOdJinePobockyTabledata.data);
                          prijemOdJinePobockyTabledata.data.map(
                            (polozka, index) => {
                              zaznamy.push({
                                id: index,
                                nazov: polozka.nazov,
                                znacka: polozka.znacka,
                                mnozstvo: polozka.mnozstvo,
                                prijate: 0,
                                komentar:
                                  "Odmítnout příjem - špatná tělota ve voze",
                                typ_reklamacie: "špatná tělota ve voze",
                                mj: polozka.mj,
                                lokalita: zmenadata.data.find(
                                  (x) => x.vybrane.nazov
                                ),
                                cielova_lokalita:
                                  logindata.UserData.vybrane_stredisko.kod +
                                  " " +
                                  logindata.UserData.vybrane_stredisko.nazov,
                                id_trellis: "",
                                files: null,
                              });
                            }
                          );
                          dispatch(setZaznamy(zaznamy));
                          const new_data =
                            prijemOdJinePobockyTabledata.data.map((x) => {
                              return {
                                ...x,
                                problem: true,
                                stav: false,
                                prijate: 0,
                                komentar:
                                  "Odmítnout příjem - špatná tělota ve voze",
                                skupina_nazov: "1. Přijato - Se záznamem",
                                skupina_nazov_old: x.default_skupina_nazov,
                                sort_col: 1,
                              };
                            });
                          dispatch(prijemOdJinePobockyTable(new_data));
                        }
                      });
                    }}
                  >
                    Odmítnout
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={styles.textarea2_title}>
          Komentář od vysílající pobočky
          <br></br>
          <textarea
            className={styles.textarea2}
            value={zmenadata.comment !== undefined ? zmenadata.comment : ""}
            onChange={(e) => {
              dispatch(setComment(e.target.value));
            }}
          ></textarea>
        </div>
      </div>
      <div className={styles.headModal}>
        <div className={styles.count}>Přijatých položek: {pocet()}</div>
      </div>
      <Table
        priznak={priznak}
        editPsOver={editPsOver}
        setEditPsOver={setEditPsOver}
        visibleZaznamyProblemov={visibleZaznamyProblemov}
        setVisibleZaznamyProblemov={setVisibleZaznamyProblemov}
        zobrazTrellis={zobrazTrellis}
        setZobrazTrellis={setZobrazTrellis}
      />
      <ModalZmena
        field={"doklad"}
        typ={"Doklad"}
        searchValue={"doklad"}
        visible={visible}
        setVisible={setVisible}
        nadpis={"nazov"}
        label={[{ label: "", field: "info" }]}
      />
    </>
  );
};
export default App;
