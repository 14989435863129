//import { logoutUser } from "../../redux/actions/authActions";
import { login } from "../screens/SignIn/redux/loginSlice";
import swal from "sweetalert";

export function errorHandleDialog(response, navigate, dispatch) {
  console.log(response)
  if (response.status === 400) {
    swal(
      "Chyba!",
      response.data ? response.data : "Neznama chyba na polozke XY",
      "error"
    );
  } else if (response.status === 401) {
    console.log("error 401")
    navigate.push("/");
    //dispatch(login({ data: {} }));
    window.sessionStorage.setItem("logindata", JSON.stringify({}))
  } else if (response.status > 401 && response.status < 500) {
    swal(
      "Chyba!",
      "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
      "error"
    );
  } else {
    swal(
      "Chyba!",
      " Nastala chyba na serveri ERP, zkuste to později.",
      "error"
    );
  }
  // if (response.status === 401) {
  //   logout(history, dispatch);
  // } else if (response.status >= 400 && response.status < 500) {
  //   setError_dialog(response.data);
  // } else {
  //   setError_dialog(
  //     "Nastala chyba v komunikaci se serverem , zkuste to později"
  //  );
}
