import React, { useState, useEffect } from "react";
import cn from "classnames";

import styles from "./Dropdown.module.sass";
import remove_accents from "remove-accents";
import { useDispatch, useSelector } from "react-redux";
import {
  pickerObjednavky_typERP_OData,
  pickerObjednavky_typERP_O,
} from "./redux/PickerObjednavky_typERP_OSlice";
import axios from "axios";
import { Objednavky_typERP_OUrl } from "../../../features/urlHelper";
import { loginData } from "../../SignIn/redux/loginSlice";


const Dropdown = ({ className, classDropdownHead, options, small, upBody }) => {
  const pickerObjednavky_typERP_Odata = useSelector(pickerObjednavky_typERP_OData);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  //const logindata = useSelector(loginData)

  return (
    // <ClickAwayListener
    //   onClickAway={() => {
    //     setVisible(false)
    //   }}
    // >
    <div
      className={cn(
        styles.dropdown,
        className,
        { [styles.small]: small },
        {
          [styles.active]: visible,
        }
      )}
    >
      <div
        className={cn(styles.head, classDropdownHead)}
        onClick={() => {
          setVisible(!visible);
        }}
        title={window.innerWidth > 1058 ? "" : pickerObjednavky_typERP_Odata}
      >
        {window.innerWidth > 1058
          ? pickerObjednavky_typERP_Odata
          : pickerObjednavky_typERP_Odata.slice(0, 9)}
      </div>
      <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
        {options.map((x, index) => {
          return (
            <div
              className={cn(styles.option, {
                [styles.selectioned]: x === pickerObjednavky_typERP_Odata,
              })}
              onClick={() => {
                setVisible(false);
                dispatch(pickerObjednavky_typERP_O({ data: x }));
              }}
              key={index}
            >
              {x}
            </div>
          );
        })}
      </div>
    </div>
    // </ClickAwayListener>
  );
};

export default Dropdown;
