import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import localforage from "localforage";

import loginReducer from "./../screens/SignIn/redux/loginSlice";
import signalRReducer from "./../screens/SignIn/redux/signalRSlice";
import commentReducer from "./../screens/Home/Comments/redux/commentSlice";
import soutezReducer from "./../screens/Home/Soutez/redux/soutezSlice";
import ludiaReducer from "./../screens/Home/Ludia/redux/ludiaSlice";
import overviewReducer from "./../screens/Home/Overview/redux/overviewSlice";
import pocasieReducer from "./../screens/Home/Pocasie/redux/pocasieSlice";
import prijemSurovinTable from "../screens/Prijemky/PrijemSurovin/Table/redux/tablePrijemSurovinSlice";
import externyDodavatelTable from "../screens/Prijemky/ModalEX/Table/redux/tableExternyDodavatelSlice";
import malaInventuraTable from "../screens/MalaInventura/Table/redux/tableMalaInventuraSlice";
import nakupTable from "../screens/Prijemky/ModalNA/Table/redux/tableNakupSlice";
import tablePrevod from "../components/Modaly/ModalNabidnoutPobocke/Table/redux/tablePrevodSlice";
import prijemOdJinePobockyTable from "../screens/Prijemky/ModalPS/Table/redux/tablePrijemOdJinePobockySlice";
import searchReducer from "./../components/Search/redux/searchSlice";
import modalReducer from "../components/Modaly/redux/modalSlice";
import PickerObjednavkyReducer from "../screens/Objednavky/Dropdown/redux/PickerObjednavkySlice";
import PickerObjednavky_typERP_OReducer from "../screens/Objednavky_typERP_O/Dropdown/redux/PickerObjednavky_typERP_OSlice";
import PickerObjednavky_typERP_BBReducer from "../screens/Objednavky_typERP_BB/Dropdown/redux/PickerObjednavky_typERP_BBSlice";
import zmenaPobockyReducer from "../components/Modaly/ModalZmenaPobocky/redux/zmenaPobockySlice";
import stavSkladuReducer from "../components/Modaly/ModalStavSkladu/Table/redux/tableStavSkladuSlice";
import pickerTagySlice from "../screens/Objednavky/DDTagy/redux/PickerTagySlice";
import PickerTagyObjednavky_typERP_OSlice from "../screens/Objednavky_typERP_O/DDTagy/redux/PickerTagyObjednavky_typERP_OSlice";
import PickerTagyObjednavky_typERP_BBSlice from "../screens/Objednavky_typERP_BB/DDTagy/redux/PickerTagyObjednavky_typERP_BBSlice";
import objednavkySlice from "../screens/Objednavky/redux/objednavkySlice";
import objednavky_typERP_OSlice from "../screens/Objednavky_typERP_O/redux/objednavky_typERP_OSlice";
import objednavky_typERP_BBSlice from "../screens/Objednavky_typERP_BB/redux/objednavky_typERP_BBSlice";
import zmenySlice from "../screens/Kalendar/Zmeny/redux/zmenySlice";
import kalendarSlice from "../screens/Kalendar/Kalendar/redux/kalendarSlice";
import prevodSurovinTable from "../screens/PrevodSurovin/Table/redux/tablePrevodSurovinSlice";
import prohlizeniSestavSlice from "../screens/ProhlizeniSestav/Table/redux/ProhlizeniSestavSlice";
import tableEvidenceSlice from "../components/EvidenceTableUniversal/Table/redux/tableEvidenceSlice";
import PanelSlice from "../components/Modaly/ModalPanel/redux/PanelSlice";
import PanelDetailSlice from "../components/Modaly/ModalPanelDetail/redux/PanelDetailSlice";
import FormSlice from "../components/Modaly/ModalForm/redux/FormSlice";
import Info_o_smeneReducer from "./../screens/Home/Info_o_smene/redux/Info_o_smeneSlice";
import zmenaSlice from "../components/Modaly/ModalZmena/redux/zmenaSlice";
import tableObjednavkyOstatneReducer from "../screens/Objednavky_ostatne/Table/redux/tableObjednavkyOstatneSlice";
import ObjednavkyOstatneObjednavkaReducer from "../screens/Objednavky_ostatne_objednavka/Table/redux/ObjednavkyOstatneObjednavkaSlice";
import tableObjednavkyBBReducer from "../screens/Objednavky_BB/Table/redux/tableObjednavkyBBSlice";
import ObjednavkyBBObjednavkaReducer from "../screens/Objednavky_BB_objednavka/Table/redux/ObjednavkyBBObjednavkaSlice";

const reducers = combineReducers({
  logindata: loginReducer,
  signalRdata: signalRReducer,
  commentdata: commentReducer,
  soutezdata: soutezReducer,
  ludiadata: ludiaReducer,
  overviewdata: overviewReducer,
  pocasiedata: pocasieReducer,
  prijemSurovinTabledata: prijemSurovinTable,
  externyDodavateldata: externyDodavatelTable,
  malaInventuradata: malaInventuraTable,
  nakupdata: nakupTable,
  tablePrevoddata: tablePrevod,
  searchdata: searchReducer,
  modaldata: modalReducer,
  prijemOdJinePobockyTabledata: prijemOdJinePobockyTable,
  zmenaPobockydata: zmenaPobockyReducer,
  pickerObjednavkydata: PickerObjednavkyReducer,
  pickerObjednavky_typERP_Odata: PickerObjednavky_typERP_OReducer,
  pickerObjednavky_typERP_BBdata: PickerObjednavky_typERP_BBReducer,
  stavSkladuTabledata: stavSkladuReducer,
  pickerTagydata: pickerTagySlice,
  pickerTagyObjednavky_typERP_Odata: PickerTagyObjednavky_typERP_OSlice,
  pickerTagyObjednavky_typERP_BBdata: PickerTagyObjednavky_typERP_BBSlice,
  objednavkydata: objednavkySlice,
  objednavky_typERP_Odata: objednavky_typERP_OSlice,
  objednavky_typERP_BBdata: objednavky_typERP_BBSlice,
  zmenydata: zmenySlice,
  kalendardata: kalendarSlice,
  prevodSurovinTabledata: prevodSurovinTable,
  prohlizeniSestavdata: prohlizeniSestavSlice,
  evidenceTabledata: tableEvidenceSlice,
  Paneldata: PanelSlice,
  PanelDetaildata: PanelDetailSlice,
  Formdata: FormSlice,
  info_o_smenedata: Info_o_smeneReducer,
  zmenadata: zmenaSlice,
  objednavkyOstatneTabledata: tableObjednavkyOstatneReducer,
  objednavkyOstatneObjednavkadata: ObjednavkyOstatneObjednavkaReducer,
  objednavkyBBTabledata: tableObjednavkyBBReducer,
  objednavkyBBObjednavkadata: ObjednavkyBBObjednavkaReducer,
});

const persistConfig = {
  key:
    process.env.REACT_APP_PROD_URL === window.location.hostname
      ? "root2"
      : process.env.REACT_APP_PROD2_URL === window.location.hostname ||
        process.env.REACT_APP_PROD3_URL === window.location.hostname
      ? "root3"
      : "root",
  storage: localforage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
