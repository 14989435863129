import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Icon from "../../../../components/Icon";
import Balance1 from "../Balance1";
import Balance2 from "../Balance2";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';

const Item = ({ className, onActive, item, color, icon, data, tooltip, p_, p_4, t_, mena }) => {
   
    return (
        <div className={cn(styles.item, className)} onClick={onActive}>
            <div className={styles.icon} style={{ backgroundColor: color }}>
                <Icon name={icon} size="24" />
            </div>
            <div className={styles.details}>
                <div className={styles.subtitle}>
                    {item.title}
                    {/*<Tooltip*/}
                    {/*    className={styles.tooltip}*/}
                    {/*    title={data}*/}
                    {/*    icon="info"*/}
                    {/*    place="top"*/}
                    {/*/>*/}
                    {
                        t_ === "trzby"
                            ? <Tooltip enterTouchDelay={0} leaveTouchDelay={100000} title={<span style={{ whiteSpace: 'pre-line' }}><p style={{ color: '#FFBC99', fontSize: "medium" }}>{"Tržba bez DPH v " + mena + "\n"}</p>{"Dnes: " + tooltip?.dnes.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, '$1 ') + " " + mena + "\n"} {"Minulý týden: " + tooltip?.minulyTyzden.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, '$1 ') + " " + mena + "\n"} {"Minulý rok: " + tooltip?.minulyRok.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, '$1 ') + " " + mena + "\n"}</span>} >
                                <IconButton>
                                    <InfoIcon className={styles.ico} />
                                </IconButton>
                            </Tooltip>
                            : <Tooltip enterTouchDelay={0} leaveTouchDelay={100000} title={<span style={{ whiteSpace: 'pre-line' }}><p style={{ color: '#FFBC99', fontSize: "medium" }}>{"Počet transakci \n"}</p>{"Dnes: " + tooltip?.dnes.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, '$1 ') + " x\n"} {"Minulý týden: " + tooltip?.minulyTyzden.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, '$1 ') + " x\n"} {"Minulý rok: " + tooltip?.minulyRok.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, '$1 ') + " x\n"}</span>} >
                                <IconButton>
                                    <InfoIcon className={styles.ico} />
                                </IconButton>
                            </Tooltip>
                            }
                    
                </div>
                <div className={styles.counter}>{data}</div>
            </div>

            {item.title === "Transakce"
                ? <Balance2 className={styles.balance2} value={p_}  background />
                : <Balance1 className={styles.balance1} value1={p_} value2={p_4}  background />
            }

        </div>
    );
};

export default Item;
