import Card from "../../components/Card";
import Icon from "../../components/Icon";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Search from "../../components/Search";
import Table from "./Table";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  pdfUrl,
  prevodSurovinPostUrl,
  prevodSurovinUrl,
} from "../../features/urlHelper";
import axios from "axios";
import {
  loadingPrevodSurovinTable,
  prevodSurovinTable,
  prevodSurovinTableData,
} from "./Table/redux/tablePrevodSurovinSlice";
import { TailSpin } from "react-loader-spinner";
import { modalData, modal } from "../../components/Modaly/redux/modalSlice";
import { login, loginData } from "../SignIn/redux/loginSlice";
import {
  setComment,
  vybrane,
  zmenaPobocky,
  zmenaPobockyData,
} from "../../components/Modaly/ModalZmenaPobocky/redux/zmenaPobockySlice";
import { useEffect } from "react";
import swal from "sweetalert";
import { getPdf } from "../../features/getPdf";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../features/errorDialog";

const App = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const modaldata = useSelector(modalData);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const zmenaPobockydata = useSelector(zmenaPobockyData);
  const prevodSurovinTabledata = useSelector(prevodSurovinTableData);
  const [loader, setLoader] = useState(false);
  const [editRowsModel, setEditRowsModel] = React.useState([]);

  useEffect(() => {
    dispatch(vybrane(false));
    dispatch(setComment(""));
    result();
  }, []);

  async function result() {
    await axios
      .get(prevodSurovinUrl + logindata.UserData.vybrane_stredisko.kod, {
        headers: { Authorization: "Bearer " + logindata.Token },
      })
      .then(function (response) {
        console.log("prevod Surovin", response.data);
        dispatch(loadingPrevodSurovinTable());
        dispatch(prevodSurovinTable(response.data));
        const newData2 = zmenaPobockydata.data.map((item) => ({
          ...item,
          vybrane: false,
        }));
        dispatch(zmenaPobocky(newData2));
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  function pocet() {
    let i = 0;
    prevodSurovinTabledata.data.map((row) => {
      if (row.pocet > 0 && row.pocet <= row.zasoba) {
        i++;
      }
    });
    return i;
  }

  return (
    <Card bezPaddingu={false}>
      <div className={styles.header}>
        <div className={styles.title}>Převod surovin na jiné středisko</div>
        <div>
          {loader ? (
            <button className={styles.buttonZmena}>
              <>
                <TailSpin
                  height="20"
                  width="20"
                  color="white"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
                Dokončit
              </>
            </button>
          ) : (
            <button
              disabled={loader}
              onClick={async () => {
                setLoader(true);
                const pobocka = zmenaPobockydata.data.filter(
                  (pobocka) => pobocka.vybrane
                );
                if (pobocka.length === 0) {
                  swal("Chyba!", "Vyberte cílové středisko", "error");
                  setLoader(false);
                }

                // if (editRowsModel === {}) {
                //   swal("Chyba!", "Stále editujete nějakou z poloěk.", "error");
                //   setLoader(false);
                // }

                if (
                  pobocka.length !== 0
                  //&& editRowsModel !== {}
                ) {
                  const postData = prevodSurovinTabledata.data.filter(
                    (row) => row.pocet !== 0 && row.pocet <= row.zasoba
                  );
                  await axios
                    .post(
                      prevodSurovinPostUrl,
                      {
                        stredisko: logindata.UserData.vybrane_stredisko.kod,
                        poznamka: zmenaPobockydata.comment,
                        suroviny: postData,
                        ciel: pobocka[0].kod,
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + logindata.Token,
                        },
                      }
                    )
                    .then(function (response) {
                      console.log(response);

                      swal({
                        title: response.data.text,
                        text: response.data.print
                          ? "Chcete zobrazit doklad?"
                          : "",
                        icon: "success",
                        buttons: response.data.print ? ["Ne ", "Ano"] : null,
                        dangerMode: true,
                      }).then(async (willShow) => {
                        if (willShow && response.data.print) {
                          getPdf(
                            response.data.print,
                            logindata.Token,
                            dispatch,
                            navigate
                          );
                        }
                      });

                      dispatch(vybrane(false));
                      dispatch(setComment(""));
                      result();
                      setLoader(false);
                    })
                    .catch(function (error) {
                      console.log(error);
                      if (error.response.status === 400) {
                        swal("Chyba!", error.response.data, "error");
                      } else if (error.response.status === 401) {
                        navigate.push("/");
                        //dispatch(login({ data: {} }));
                        window.sessionStorage.setItem(
                          "logindata",
                          JSON.stringify({})
                        );
                      } else if (
                        error.response.status > 401 &&
                        error.response.status < 500
                      ) {
                        swal(
                          "Chyba!",
                          "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
                          "error"
                        );
                      } else {
                        swal(
                          "Chyba!",
                          " Nastala chyba na serveri ERP, zkuste to později.",
                          "error"
                        );
                      }
                      setLoader(false);
                    });
                }
              }}
              className={styles.buttonZmena}
            >
              <Icon name="check" size="20" />
              Dokončit
            </button>
          )}
        </div>
      </div>
      <br></br>
      <div className={styles.Content}>
        <div className={styles.upperContent}>
          <div className={cn(styles.avatar)}>
            <img src={"/images/content/home.png"} alt="Avatar" />
          </div>
          <div className={styles.cieloveStrediskoContent}>
            <div className={styles.cieloveStrediskoButton}>
              <div style={{ marginRight: 5 }}>Cílové středisko</div>
              <div>
                <button
                  className={styles.buttonZmena}
                  onClick={() => {
                    dispatch(
                      modal({ data: { ...modaldata, ZmenaPobocky: true } })
                    );
                  }}
                >
                  {zmenaPobockydata.vybrane ? "Změnit" : "Vybrat"}
                </button>
              </div>
            </div>
            <div className={styles.cieloveStredisko}>
              <div style={{ fontWeight: "bold" }}>
                <div>
                  {zmenaPobockydata.data.map((item) => {
                    if (item.vybrane) {
                      return item.nazov;
                    }
                  })}
                </div>
              </div>
              <div style={{ color: "gray" }}>
                {zmenaPobockydata.data.map((item) => {
                  if (item.vybrane) {
                    return "Kód: " + item.kod;
                  }
                })}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.textarea2_title}>
          Komentář
          <br></br>
          <textarea
            className={styles.textarea2}
            value={zmenaPobockydata.comment}
            onChange={(e) => {
              dispatch(setComment(e.target.value));
            }}
          ></textarea>
        </div>
      </div>
      <div className={styles.headModal}>
        {prevodSurovinTabledata.loading ? (
          <></>
        ) : (
          <div className={styles.count}>Počet položek: {pocet()}</div>
        )}
      </div>
      <Table className={styles.table} setEditRowsModel={setEditRowsModel} />
    </Card>
  );
};
export default App;
