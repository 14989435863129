import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import remove_accents from "remove-accents";
import { useSelector, useDispatch } from "react-redux";
import Icon from "../../../components/Icon";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  objednavkyOstatneObjednavkaTable,
  objednavkyOstatneObjednavkaTableData,
  loadingObjednavkyOstatneObjednavkaTable,
} from "./redux/ObjednavkyOstatneObjednavkaSlice";
import { DataGridPremium, LicenseInfo, csCZ } from "@mui/x-data-grid-premium";
import { getLicensePremium } from "../../../features/License";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ContentLoader from "react-content-loader";
import useDarkMode from "@fisch0920/use-dark-mode";
import swal from "sweetalert";
import {
  Form,
  FormData,
  loadingForm,
} from "../../../components/Modaly/ModalForm/redux/FormSlice";
import ModalForm from "../../../components/Modaly/ModalForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import {
  //ObjednavkyOstatneObjednavkaUrl,
  formRunUrl,
  menuRunUrl,
  VypisDokladuHTMLObjOstUrl,
  VypisDokladuHTMLUrl,
  VypisDokladuPDFObjOstUrl,
} from "../../../features/urlHelper";
import { loginData } from "../../SignIn/redux/loginSlice";
import { errorHandleDialog } from "../../../features/errorDialog";
import {
  DatePicker,
  LocalizationProvider,
  csCZ as cz,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import {
  loadingObjednavkyOstatneTable,
  objednavkyOstatneTable,
} from "./../../Objednavky_ostatne/Table/redux/tableObjednavkyOstatneSlice";
import { modal, modalData } from "../../../components/Modaly/redux/modalSlice";
// data

const data = [];

const Table = ({ className, zaplaceno, setDetail_visible, result }) => {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const darkMode = useDarkMode(false);
  const modaldata = useSelector(modalData);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const Formdata = useSelector(FormData);
  const objednavkyOstatneObjednavkaTabledata = useSelector(
    objednavkyOstatneObjednavkaTableData
  );
  const notify_error = (text) => toast.error(text);
  LicenseInfo.setLicenseKey(getLicensePremium());
  const [visibleForm, setVisibleForm] = useState(false);
  const [formName, setFormName] = useState("");
  const [date, setDate] = useState(objednavkyOstatneObjednavkaTabledata.date);
  const [width, setWidth] = useState(window.innerWidth);

  const removeFields = (index) => {
    let data = [...objednavkyOstatneObjednavkaTabledata.data];
    data.splice(index, 1);
    dispatch(objednavkyOstatneObjednavkaTable(data));
  };

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  const stylingPicker = {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      color: "#82878c",
      fontFamily: "Inter, sans-serif",
      height: 7,
      display: "flex",
      alighnItems: "center",
      justifyContent: "center",
      fontWeight: 400,
      fontSize: 15,
      backgroundColor: !darkMode.value ? "white" : "#262b30",
      borderRadius: 2,
    },

    "& .MuiTypography-root, MuiFormLabel-root": {
      color: "#82878c",
    },
    "& .MuiSvgIcon-root": {
      color: !darkMode.value ? "#9ea2a6" : "#262b30",
    },
    "& .separator-true": {
      height: 30,
      borderBottom: `3px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#989899",
    },
    "& .MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled": {
      WebkitTextFillColor: "#989899",
    },
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
  };

  function getWidth(width) {
    if (width > 2500) {
      console.log(width - 388);
      return width - 388;
    } else if (width < 2500 && width > 767) {
      return width - 144;
    } else {
      return width - 48;
    }
  }

  async function call_form(volba, data = null) {
    await axios
      .post(
        formRunUrl,
        {
          formName: "PridatPolozkuDoObjednavky",
          formValues: data,
          volba: volba,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        if (response.data.typ === "route") {
          navigate.push(response.data.url);
        } else if (response.data.typ === "enter") {
          console.log("enterData", response.data.enterData);

          dispatch(loadingForm());
          setFormName(response.data.enterData.formName);
          dispatch(Form(response.data.enterData));
          setVisibleForm(true);
          document
            .getElementsByName(
              response.data.enterData.values[
                Object.keys(response.data.enterData.values)[0]
              ].id
            )[0]
            .focus();
        }
        console.log(response);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  // async function objednat(volba, datum) {
  //   await axios
  //     .post(
  //       ObjednavkyOstatneObjednavkaUrl,
  //       {
  //         datum: datum,
  //         polozky: objednavkyOstatneObjednavkaTabledata.data,
  //         volba: volba,
  //         stredisko:
  //           logindata.UserData.vybrane_stredisko !== undefined
  //             ? logindata.UserData.vybrane_stredisko.kod
  //             : null,
  //         ico:
  //           logindata.UserData.ico !== undefined
  //             ? logindata.UserData.ico
  //             : null,
  //       },
  //       {
  //         headers: { Authorization: "Bearer " + logindata.Token },
  //       }
  //     )
  //     .then(function (response) {
  //       console.log(response);
  //       //dispatch(objednavkyOstatneTable(response.data.value))
  //     })
  //     .catch(function (error) {
  //       errorHandleDialog(error.response, navigate, dispatch);
  //     });
  //}

  const stylingTable = {
    border: 0,

    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      color: darkMode.value ? "white" : "#666d74",
      borderRight: `1px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .MuiDataGrid-overlay": {
      backgroundColor: "transparent",
      color: darkMode.value ? "white" : "#ACACAC",
    },
    "& .MuiInputBase-input": {
      color: darkMode.value ? "white" : "#ACACAC",
    },
    "& .MuiSvgIcon-root": {
      color: "#a7acaf",
    },
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
      "& .MuiInputBase-root": {
        height: "100%",
      },
    },
    "& .MuiDataGrid-cell": {
      padding: 0.5,
    },
    "& .Mui-error": {
      backgroundColor: "red",
      color: "white",
    },
    width: getWidth(window.innerWidth),
    height: window.innerHeight - 250,
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
  };

  const theme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
    },
  });
  const columns = [
    {
      field: "nazov",
      headerName: "Položka",
      width: (getWidth(window.innerWidth) - 50) * 0.6,
      groupable: false,
      renderCell: (params) => (
        <>
          <div className={styles.nazov}>
            <div className={styles.avatar}>
              <img src={"/images/content/bageta2.png"} alt="Avatar" />
            </div>
            <div className={styles.nazov_kod}>
              <div
                style={
                  params.row.wms_stav === 5 || params.row.wms_stav === 6
                    ? { textDecoration: "line-through", color: "red" }
                    : {}
                }
              >
                {params.row.nazov}
              </div>
              <div className={styles.znacka}>
                <div
                  style={
                    params.row.wms_stav === 5 || params.row.wms_stav === 6
                      ? { textDecoration: "line-through", color: "red" }
                      : {}
                  }
                  className={styles.znackaText}
                >
                  {params.row.znacka}
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      field: "mnozstvo",
      headerName: "Množství",
      width: (getWidth(window.innerWidth) - 50) * 0.4,
      type: "number",
      headerAlign: "center",
      renderCell: (params) => {
        const valueFormatted = Number(params.value);

        const value = valueFormatted
          .toFixed(params.row.des_mista)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");
        return (
          <div
            style={
              params.row.wms_stav === 5 || params.row.wms_stav === 6
                ? { textDecoration: "line-through", color: "red" }
                : {}
            }
          >
            {value}
          </div>
        );
      },
    },
    {
      field: "mj",
      headerName: "Mj",
      width: 30,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={
              params.row.wms_stav === 5 || params.row.wms_stav === 6
                ? { textDecoration: "line-through", color: "red" }
                : {}
            }
          >
            {params.value}
          </div>
        );
      },
    },
  ];

  function formatujDatum(isoString) {
    const date = new Date(isoString);

    // Extracting day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Měsíce jsou indexovány od 0, proto +1
    const year = date.getFullYear();

    // Formatting into dd.mm.yyyy
    return `${day}.${month}.${year}`;
  }

  return (
    <>
      <div className={styles.nadpis}>
        <div>{objednavkyOstatneObjednavkaTabledata.title}</div>
      </div>
      <div className={styles.spacebeetween}>
        {/* <button
          disabled
          onClick={() => {
            call_form("add");
          }}
          className={styles.buttonPS}
        >
          <div className={styles.buttonPSI_dis}>
            <Icon name="add" size="20" />
          </div>
          <div className={styles.buttonPST_dis}>Přidat</div>
        </button> */}
        <button
          onClick={() => {
            setDetail_visible(false);
            result();
          }}
          className={styles.buttonPS}
        >
          <div className={styles.buttonPSI}>
            <Icon name="arrow-left" size="20" />
          </div>
          <div className={styles.buttonPST}>Zpět</div>
        </button>
        <div>
          Datum závozu {formatujDatum(date)}
          {/* <ThemeProvider theme={theme}>
            <LocalizationProvider
              adapterLocale="cs"
              localeText={
                cz.components.MuiLocalizationProvider.defaultProps.localeText
              }
              dateAdapter={AdapterMoment}
            >
              <DatePicker

                value={moment.utc(date)}
                sx={stylingPicker}
              />
            </LocalizationProvider>
          </ThemeProvider> */}
        </div>
      </div>

      <div>
        {objednavkyOstatneObjednavkaTabledata.loading ? (
          <ContentLoader
            speed={2}
            width={"100%"}
            height={300}
            backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
            foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
          >
            <rect x="3%" y="5%" rx="3" ry="3" width={"100%"} height="80%" />
          </ContentLoader>
        ) : (
          <>
            <ThemeProvider theme={theme}>
              <DataGridPremium
                sx={stylingTable}
                localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                {...{
                  rows: objednavkyOstatneObjednavkaTabledata.data,
                  columns: columns,
                }}
                columnHeaderHeight={40}
                disableAutoFocus
                disableColumnMenu
                defaultGroupingExpansionDepth={-1}
                hideFooter
                rowHeight={40}
                groupingColDef={{
                  leafField: "nazov",
                  headerClassName: "weekend6_help_nadpis",
                  cellClassName: "weekend6_help_cell",
                }}
                // onCellEditStop={async function (params, event) {
                //   //event.defaultMuiPrevented = true;
                //   if (event.code === "Enter") {
                //     console.log(params);

                //     let row = objednavkyOstatneObjednavkaTabledata.data.filter(
                //       (row) => row.id === params.id
                //     );

                //     let index =
                //       objednavkyOstatneObjednavkaTabledata.data.indexOf(row[0]);
                //     let data = [...objednavkyOstatneObjednavkaTabledata.data];
                //     let row_copy = { ...row[0] };
                //     row_copy.mnozstvo = event.target.value;
                //     data[index] = row_copy;
                //     dispatch(loadingObjednavkyOstatneObjednavkaTable());
                //     dispatch(objednavkyOstatneObjednavkaTable(data));
                //   }
                // }}
                //rowGroupingModel={rowGroupingModel}
              />
            </ThemeProvider>
          </>
        )}
        <div className={styles.objednat_area}>
          <button
            onClick={async () => {
              //console.log(params);
              await axios
                .post(
                  VypisDokladuPDFObjOstUrl,
                  {
                    doklad: objednavkyOstatneObjednavkaTabledata.cis_obj,
                  },
                  {
                    headers: { Authorization: "Bearer " + logindata.Token },
                  }
                )
                .then(function (response) {
                  //console.log(response);
                  if (response.data.html !== null) {
                    dispatch(
                      modal({
                        data: {
                          ...modaldata,
                          ZobrazHtml: true,
                          HtmlData: response.data.html,
                          ZobrazHtml_print: response.data.print,
                          ZobrazHtml_text: response.data.text,
                        },
                      })
                    );
                  }
                })
                .catch(function (error) {
                  errorHandleDialog(error.response, navigate, dispatch);
                });
            }}
            className={styles.polozka_doklad}
          >
            <div className={styles.icon_red}>
              <i class="fa fa-file-pdf-o"></i>
            </div>

            <div>
              Doklad k objednávce {objednavkyOstatneObjednavkaTabledata.cis_obj}
            </div>
          </button>
          <button
            onClick={() => {
              if (objednavkyOstatneObjednavkaTabledata.volba === "detail") {
                setDetail_visible(false);
                result();
                return;
              }
              if (date === null) {
                notify_error("Zadejte datum !!!");
                return;
              }
              if (objednavkyOstatneObjednavkaTabledata.data[0] === undefined) {
                notify_error("Nemůžete poslat prázdnou objednávku !!!");
                return;
              }
              dispatch(loadingObjednavkyOstatneTable());
              //objednat("new", date);
              //
            }}
            className={styles.button_objednat}
          >
            {objednavkyOstatneObjednavkaTabledata.volba === "new" ? (
              <i class="fas fa-cart-arrow-down"></i>
            ) : (
              <></>
            )}
            {objednavkyOstatneObjednavkaTabledata.button}
          </button>
        </div>
        {/* <ToastContainer /> */}
        <ModalForm
          priznak="PridatPolozkuDoObjednavky"
          visible={visibleForm}
          setVisible={setVisibleForm}
          className={styles}
          formName={formName}
        />
      </div>
    </>
  );
};

export default Table;
