import { createSlice } from '@reduxjs/toolkit';

export const info_o_smeneSlice = createSlice({
    name: 'info_o_smene',
    initialState: {
        data: [],
        loading: true,
    },
    reducers: {
        info_o_smene: (state, action) => {
            console.log("data imfo o smene + loading: false", action.payload)
            state.data = action.payload
            state.loading = false
        },
        loadingInfo_o_smene: (state) => {
            console.log("loading: true")
            state.loading = true
        }

    },
});

export const { info_o_smene, loadingInfo_o_smene } = info_o_smeneSlice.actions;

export const info_o_smeneData = state => state.info_o_smenedata;


export default info_o_smeneSlice.reducer;

