import React from "react";
import cn from "classnames";
import styles from "./Balance.module.sass";
import Icon from "../../../../components/Icon";
import Icon2 from "../../../../components/WeatherIcon";
import Tooltip from '@mui/material/Tooltip';

const Balance = ({ className, value, value2, value3, background, mena }) => {
    return (
        <div
            className={cn(
                styles.positive,
                className
            )}
        >
            <div className={styles.box2}>
                <Tooltip enterTouchDelay={0} leaveTouchDelay={100000} title={"Produktivita"} >
                    <div className={styles.box}>
                        <div className={styles.icon}>
                            <Icon2 name="zap" size="32" />
                        </div>

                        <div style={{ marginLeft: 5 }}>{value2}</div>
                    </div>
                </Tooltip>


            </div>

            <div className={styles.box3}>
                <Tooltip enterTouchDelay={0} leaveTouchDelay={100000} title={"Odpracovane hodiny"} >
                    <div className={styles.box}>
                        <div className={styles.icon}>
                            <Icon2 name="clock" size="48" />
                        </div>

                        <div style={{ marginLeft: 5 }}>{value3 + " h"}</div>
                    </div>
                </Tooltip>

            </div>

            <div className={styles.box1}>
                <Tooltip enterTouchDelay={0} leaveTouchDelay={100000} title={"Produktivita v peňezích"} >
                    <div className={styles.box}>
                        <div className={styles.icon}>
                            <img src={"/images/content/cash.png"} alt="Avatar" className={styles.icon1} style={{ width: 15, marginBottom: 3, marginRight: 5 }} />
                        </div>
                        <div style={{ marginLeft: 5 }}>{value + " " + mena}</div>
                    </div>
                </Tooltip>

            </div>




        </div>
    )
};

export default Balance;
