import Card from "../../components/Card";
import Icon from "../../components/Icon";
import Icons from "../../components/isons";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Table from "./Table";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const App = ({ className, setDetail_visible, result }) => {
  return (
      <Table className={styles.table} setDetail_visible={setDetail_visible} result={result}/>
  );
};
export default App;
