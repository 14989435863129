import React, { useState, useEffect, useRef } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import remove_accents from "remove-accents";
import { useSelector, useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import useDarkMode from "@fisch0920/use-dark-mode";
import axios from "axios";
import { searchData } from "../../../components/Search/redux/searchSlice";
import {
  DataGridPremium,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  LicenseInfo,
  csCZ,
} from "@mui/x-data-grid-premium";
import { getLicensePremium } from "../../../features/License";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login, loginData } from "../../SignIn/redux/loginSlice";
import swal from "sweetalert";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../../features/errorDialog";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { width } from "@mui/system";
import { objednavkyBBTableData } from "./redux/tableObjednavkyBBSlice";
import { styled } from "@mui/material/styles";
import Select from "react-select";

const Table = ({
  className,
  columns,
  title,
  button,
  options,
  value,
  setValue,
  result_zmena
}) => {
  const darkMode = useDarkMode(false);
  const notify_error = (text) => toast.error(text);
  LicenseInfo.setLicenseKey(getLicensePremium());
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const objednavkyBBTabledata = useSelector(objednavkyBBTableData);
  const searchdata = useSelector(searchData);
  const darkTheme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
      mode: darkMode.value ? "dark" : "light",
    },
  });

  function getWidth(width) {
    if (width > 2500) {
      return width - 388;
    } else if (width < 2500 && width > 767) {
      return width - 144;
    } else {
      return width;
    }
  }

  const stylingTable = {
    border: 0,
    paddingTop: 0,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-selectedRowCount": {
      visibility: "hidden",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      color: darkMode.value ? "white" : "#666d74",
      borderRight: `1px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .MuiToolbar-root-MuiTablePagination-toolbar": {
      color: darkMode.value ? "white" : "#666d74",
    },

    "& .MuiSvgIcon-root": {
      color: "#a7acaf",
    },
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
      "& .MuiInputBase-root": {
        height: "100%",
      },
    },
    "& .MuiDataGrid-cell": {
      padding: 1,
    },
    "& .MuiBadge-badge": {
      //color: "transparent",
      fontSize: 8,
    },
    "& .MuiSvgIcon-root": {
      padding: "0.8px",
    },
    "& .Mui-error": {
      backgroundColor: "red",
      color: "white",
    },
    width: getWidth(window.innerWidth),
    height: window.innerHeight - 152,
    justifyContent: "center",
    alighnItems: "center",
    color: darkMode.value ? "white" : "#666d74",
  };

  const CustomQuickFilter = styled(GridToolbarQuickFilter)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 250,
    "& .MuiInputBase-root": {
      fontSize: "1rem",
      color: theme.palette.text.primary,
    },
  }));

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className={styles.GridToolbarContainer}>
          {/* <Select
            name={"select"}
            isSearchable={true}
            theme={(theme) => ({
              ...theme,
              borderRadius: 8,
              colors: {
                ...theme.colors,
                primary25: "#FAEECB ",
                primary50: "#FAEECB ",
                primary: "#F1B600",
              },
            })}
            //isOptionDisabled={params_.when_fcia === "disabled"}
            styles={{
              height: 40,
              control: (base, state) => ({
                ...base,
                borderRadius: 3,
                height: 42,
                width: 250,
                backgroundColor: !darkMode.value ? "white" : "#262b30",
              }),
              option: (base, state) => ({
                ...base,
                borderRadius: 3,
                color: "#6F767E",
              }),
              singleValue: (base, state) => ({
                ...base,
                borderRadius: 3,
                color: "#82878c",
              }),
              menu: (base, state) => ({
                ...base,
                borderRadius: 0,
                marginTop: 0,
                backgroundColor: !darkMode.value ? "white" : "#262b30",
              }),
            }}
            className={styles.select}
            value={value}
            isClearable={false}
            options={options}
            //formatGroupLabel={formatGroupLabel}
            onChange={(e) => {
              //console.log(e);
              setValue(e);
              if(logindata.UserData.vybrane_stredisko !== undefined){
                result_zmena(e.value, null)
              }else{
                result_zmena(null, e.value)
              }
              
            }}
          /> */}
          <CustomQuickFilter autoComplete="off" />
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <div className={styles.card} style={{ height: "100%" }}>
      {objednavkyBBTabledata.loading ? (
        <ContentLoader
          speed={2}
          width={getWidth(window.innerWidth)}
          height={"100%"}
          backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
          foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
        >
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((i) =>
            [0, 1, 2, 3, 4, 5, 6].map((j) => (
              <rect
                key={j}
                x={0}
                y={2 + i * (window.innerHeight / 20)}
                rx="3"
                ry="3"
                width={"100%"}
                height={10}
              />
            ))
          )}
        </ContentLoader>
      ) : (
        <ThemeProvider theme={darkTheme}>
          <DataGridPremium
            sx={stylingTable}
            localeText={{
              ...csCZ.components.MuiDataGrid.defaultProps.localeText,
              toolbarColumns: "",
              toolbarFilters: "",
            }}
            disableColumnPinning
            disableColumnMenu
            rowHeight={39}
            columnHeaderHeight={36}
            rows={objednavkyBBTabledata.data}
            columns={columns}
            sortingOrder={["desc", "asc"]}
            slots={{ toolbar: CustomToolbar }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                  text: false,
                  detail:
                    logindata.UserData.admin || logindata.UserData.admin_obj
                      ? true
                      : false,
                },
              },
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </ThemeProvider>
        // </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Table;
