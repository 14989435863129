import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import remove_accents from "remove-accents";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  externyDodavatelTable,
  externyDodavatelTableData,
  loadingExternyDodavatelTable,
} from "./redux/tableExternyDodavatelSlice";

import ContentLoader from "react-content-loader";
import useDarkMode from "@fisch0920/use-dark-mode";
import { searchData } from "../../../../components/Search/redux/searchSlice";
import {
  DataGridPremium,
  LicenseInfo,
  csCZ,
  GridApi,
  useGridApiRef,
  useGridApiEventHandler,
  useGridApiContext,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridCellEditStopReasons,
} from "@mui/x-data-grid-premium";
import { getLicensePremium } from "../../../../features/License";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Table = ({ className, editExOver, setEditExOver }) => {
  const apiRef = useGridApiRef();
  const darkMode = useDarkMode(false);
  const notify_error = (text) => toast.error(text);
  const notify_info = (text) => toast.info(text);
  LicenseInfo.setLicenseKey(getLicensePremium());
  const [rowGroupingModel, setRowGroupingModel] = React.useState([
    "zazmluvnene_text",
  ]);
  const [value, setValue] = React.useState(0);

  const [valueId, setValueId] = React.useState("");
  const [valueField, setValueField] = React.useState("");
  const dispatch = useDispatch();

  const searchdata = useSelector(searchData);
  const externyDodavatelTabledata = useSelector(externyDodavatelTableData);

  function getWidth(width) {
    if (width > 2500) {
      return width - 456;
    } else if (width < 2500 && width > 767) {
      return width - 212;
    } else {
      return width - 48;
    }
  }

  const theme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
    },
  });

  function isInt(n) {
    return n % 1 === 0;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className={styles.toolbar}>
          <div className={styles.search}>
            <GridToolbarQuickFilter
              autoComplete="off"
              name="quicksearch"
              placeholder="Hledejte"
            />
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  const stylingTable = {
    border: 0,
    "& .weekend6_help_nadpis": {
      backgroundColor: darkMode.value ? "#272b30" : "#f4f4f4",
    },
    "& .weekend6_help_cell": {
      backgroundColor: darkMode.value ? "#272b30" : "#f4f4f4",
    },
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      color: darkMode.value ? "white" : "#666d74",
      borderRight: `1px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },

    "& .MuiSvgIcon-root": {
      color: "#a7acaf",
    },
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
      "& .MuiInputBase-root": {
        height: "100%",
      },
    },
    "& .MuiDataGrid-overlay": {
      backgroundColor: "transparent",
      color: darkMode.value ? "white" : "#ACACAC",
    },
    "& .MuiInputBase-input": {
      color: darkMode.value ? "white" : "#ACACAC",
    },
    "& .MuiDataGrid-cell": {
      padding: 1,
    },
    "& .Mui-error": {
      backgroundColor: "red",
      color: "white",
    },
    width: getWidth(window.innerWidth),
    height:
      window.innerWidth < 767
        ? window.innerHeight - 315
        : window.innerHeight - 365,
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
  };

  const columns = [
    {
      field: "nazov",
      headerName: "Položka",
      width: getWidth(window.innerWidth) * 0.5,
      groupable: false,
      renderCell: (params) => (
        <>
          <div className={styles.nazov}>
            {window.innerWidth > 767 ? (
              <div className={styles.avatar}>
                <img src={"/images/content/bageta2.png"} alt="Avatar" />
              </div>
            ) : (
              <> </>
            )}
            <div className={styles.nazov_kod}>
              <div>{params.row.nazov}</div>
              <div className={styles.znacka}>
                <div className={styles.znackaText}>{params.row.znacka}</div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    // {
    //   field: "dan",
    //   headerName: "Daň",
    //   width: getWidth(window.innerWidth) * 0.2,
    //   minWidth: 90,
    //   type: "number",
    //   headerAlign: "center",
    //   sortable: false,
    //   valueFormatter: (params) => {
    //     if (params.value === undefined) {
    //       return "";
    //     }
    //     const valueFormatted = Number(params.value);

    //     const value = valueFormatted
    //       .toFixed(0)
    //       .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

    //     return `${value}`;
    //   },
    // },
    {
      field: "zazmluvnene_text",
      headerName: "aaa",
      type: "number",
      headerAlign: "center",
      groupable: true,
      sortable: false,
    },
    {
      field: "mj",
      headerName: "Mj",
      width: getWidth(window.innerWidth) * 0.2,
      minWidth: 90,
      type: "number",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return <div className={styles.nazov_kod}>{params.value}</div>;
      },
    },
    {
      field: "pocet",
      headerName: "Množtví",
      width: getWidth(window.innerWidth) * 0.3,
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 90,
      type: "number",
      headerAlign: "center",
      valueFormatter: (params) => {
        if (params.value === undefined) {
          return "";
        }
        if (params.value === null || params.value === 0) {
          return "";
        }
        const valueFormatted = Number(params.value);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");
        return `${value}`;
      },
      preProcessEditCellProps: (params) => {
        const hasError = params.props.value < 0 || !isInt(params.props.value);
        if (hasError) {
          if (params.props.value < 0) {
            notify_error("Počet musí být větší než 0!");
          }
        }
        // } else {
        //   console.log(params);
        //   let row = externyDodavatelTabledata.data.filter(
        //     (row) => row.id === params.id
        //   );
        //   console.log(row);
        //   let index = externyDodavatelTabledata.data.indexOf(row[0]);
        //   let data = [...externyDodavatelTabledata.data];
        //   let row_copy = { ...row[0] };
        //   console.log("podozrive data row", row_copy);
        //   row_copy.pocet = params.props.value;
        //   console.log(row_copy);
        //   data[index] = row_copy;
        //   dispatch(loadingExternyDodavatelTable());
        //   console.log("podozrive data", data);
        //   dispatch(externyDodavatelTable(data));
        // }

        return { ...params.props, error: hasError };
      },
      renderCell: (params) => {
        return <div className={styles.nazov_kod}>{params.value}</div>;
      },
    },
  ];

  return (
    <div>
      {externyDodavatelTabledata.loading ? (
        <ContentLoader
          speed={2}
          width={"100%"}
          height={300}
          //viewBox="0 0 400 100"
          backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
          foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
        >
          <rect x="3%" y="5%" rx="3" ry="3" width={"100%"} height="80%" />
        </ContentLoader>
      ) : (
        <>
          <ThemeProvider theme={theme}>
            {" "}
            <DataGridPremium
              sx={stylingTable}
              apiRef={apiRef}
              localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
              {...{
                rows: externyDodavatelTabledata.data.filter(
                  (xy) => xy.zazmluvnene
                  // &&
                  // (String(remove_accents(xy.nazov.toUpperCase())).includes(
                  //   remove_accents(searchdata.searchexDod.toUpperCase())
                  // ) ||
                  //   String(remove_accents(xy.znacka.toUpperCase())).includes(
                  //     remove_accents(searchdata.searchexDod.toUpperCase())
                  //   ))
                ),
                columns: columns,
              }}
              columnHeaderHeight={25}
              disableAutoFocus
              disableColumnMenu
              defaultGroupingExpansionDepth={-1}
              hideFooter
              slots={{
                toolbar: CustomToolbar,
              }}
              slotProps={{
                row: {
                  onFocus: (event) => {
                    setValueId(event.currentTarget.getAttribute("data-id"));
                  },
                },
                cell: {
                  onFocus: (event) => {
                    setValueField(
                      event.currentTarget.getAttribute("data-field")
                    );
                  },
                },
              }}
              rowGroupingModel={rowGroupingModel}
              onRowGroupingModelChange={(model) => setRowGroupingModel(model)}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    zazmluvnene_text: false,
                    nazov: false,
                  },
                },
              }}
              rowHeight={40}
              groupingColDef={{
                leafField: "nazov",
                headerClassName: "weekend6_help_nadpis",
                cellClassName: "weekend6_help_cell",
              }}
              onCellKeyDown={(params, event) => {
                console.log(event);
                let new_value;
                switch (event.key) {
                  case "0":
                  case "1":
                  case "2":
                  case "3":
                  case "4":
                  case "5":
                  case "6":
                  case "7":
                  case "8":
                  case "9":
                    new_value = value * 10 + Number(event.key);
                    setValue(new_value);
                    break;
                  case "Backspace":
                    new_value = parseInt(value / 10);
                    setValue(new_value);
                    break;
                }
              }}
              onCellEditStart={(params, event) => {
                console.log("onCellEditStart", editExOver);
                let zoznam_cell = editExOver;
                if (
                  editExOver.filter(
                    (cell) => cell.cell === valueId + valueField
                  )[0] === undefined
                ) {
                  zoznam_cell.push({ cell: valueId + valueField, over: false });
                  console.log(zoznam_cell);
                  setEditExOver(zoznam_cell);
                } else {
                  let new_zoznam_cell = [];
                  zoznam_cell.map((cell) => {
                    cell.cell === valueId + valueField
                      ? new_zoznam_cell.push({
                          cell: valueId + valueField,
                          over: false,
                        })
                      : new_zoznam_cell.push(cell);
                  });
                  console.log(new_zoznam_cell);
                  setEditExOver(new_zoznam_cell);
                }
              }}
              onCellEditStop={async function (params, event, detail) {
                if (params.reason === GridCellEditStopReasons.cellFocusOut) {
                  event.defaultMuiPrevented = true;
                  notify_info(
                    "Pokud chcete potvrdit množství do tabulky stiskněte enter!"
                  );
                } else {
                  let row = externyDodavatelTabledata.data.filter(
                    (row) => row.id === params.id
                  );

                  let index = externyDodavatelTabledata.data.indexOf(row[0]);

                  let data = [...externyDodavatelTabledata.data];

                  let row_copy = { ...row[0] };

                  row_copy.pocet = Number(event.target.value);

                  data[index] = row_copy;
                  dispatch(loadingExternyDodavatelTable());

                  dispatch(externyDodavatelTable(data));

                  let zoznam_cell = [];
                  editExOver.map((cell) => {
                    cell.cell === valueId + valueField
                      ? zoznam_cell.push({
                          cell: valueId + valueField,
                          over: true,
                        })
                      : zoznam_cell.push(cell);
                  });
                  console.log(zoznam_cell);
                  setEditExOver(zoznam_cell);
                }
              }}

              //rowGroupingModel={rowGroupingModel}
            />
          </ThemeProvider>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default Table;
