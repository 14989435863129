import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Dropdown from "../../components/Dropdown";
import Icon from "../../components/Icon";
import DetekciaNeaktivity from "../../components/DetekciaNeaktivity";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import cs from "date-fns/locale/cs";
import moment from "moment";

import { useSelector, useDispatch } from "react-redux";
import { loginData, login } from "../SignIn/redux/loginSlice";
import {
  comment,
  commentData,
  loadingComment,
} from "./../Home/Comments/redux/commentSlice";
import {
  soutez,
  soutezData,
  loadingSoutez,
} from "./../Home/Soutez/redux/soutezSlice";
import {
  ludia,
  ludiaData,
  loadingLudia,
} from "./../Home/Ludia/redux/ludiaSlice";
import {
  overview,
  overviewData,
  loadingOverview,
} from "./../Home/Overview/redux/overviewSlice";
import {
  commentsUrl,
  prehladUrl,
  soutezUrl,
  zamestnanciUrl,
  zmenaStrediskaUrl,
  pocasieUrl,
  Info_o_smeneUrl,
} from "./../../features/urlHelper";
import axios from "axios";
import { pocasie, loadingPocasie } from "../Home/Pocasie/redux/pocasieSlice";
import swal from "sweetalert";
import { errorHandleDialog } from "../../features/errorDialog";
import {
  info_o_smene,
  loadingInfo_o_smene,
} from "../Home/Info_o_smene/redux/Info_o_smeneSlice";

const Page = ({ wide, children, title }) => {
  registerLocale("cs", cs);
  const dispatch = useDispatch();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const navigate = useHistory();

  const [visible, setVisible] = useState(false);

  const [intervals, setIntervals] = useState([]);

  const [ldata, setLdata] = useState(
    JSON.parse(
      window.sessionStorage.getItem("logindata") !== null
        ? window.sessionStorage.getItem("logindata")
        : "{}"
    )
  );

  useEffect(() => {
    if (logindata.UserData === undefined) {
      navigate.push("/");
      //dispatch(login({ data: {} }));
      window.sessionStorage.setItem("logindata", JSON.stringify({}));
    } else {
      let expiration_time = new Date(logindata.TokenExpirationTime * 1000);
      expiration_time = new Date(expiration_time.toUTCString());
      const current_time = new Date(new Date().toUTCString());
      if (current_time > expiration_time) {
        navigate.push("/");
        //dispatch(login({ data: {} }));
        window.sessionStorage.setItem("logindata", JSON.stringify({}));
      }
      const inter = [];
      let i = 0;
      while (logindata.UserData.strediska.length > i) {
        inter.push(
          logindata.UserData.strediska[i].kod +
            " - " +
            logindata.UserData.strediska[i].nazov
        );
        i++;
      }
      setIntervals(inter);
    }
  }, []);

  return (
    <>
      {logindata.UserData !== undefined ? (
        <div className={styles.page}>
          <Sidebar
            typErp={logindata.UserData.typErp}
            className={cn(styles.sidebar, { [styles.visible]: visible })}
            setVisible={setVisible}
            visible={visible}
          />
          <Header
            typErp={logindata.UserData.typErp}
            onOpen={() => setVisible(true)}
            ldata={ldata}
            setLdata={setLdata}
          />

          <div className={styles.inner}>
            <div
              className={cn(styles.container, {
                [styles.wide]: wide,
              })}
            >
              {logindata.UserData.typErp === "C" ? (
                <></>
              ) : (
                <>
                  {title && (
                    <div className={styles.pageTitle}>
                      <div className={cn("h3", styles.title)}>{title}</div>
                      <div className={styles.box}>
                        <div className={styles.box}>
                          <DatePicker
                            locale={"cs"}
                            dateFormat={
                              window.innerWidth > 639
                                ? "dd.MM.yyyy (E)"
                                : "dd.MM.yyyy"
                            }
                            calendarClassName={styles.calendar}
                            className={styles.datepicker}
                            selected={new Date(logindata.UserData.datum)}
                            onChange={(date) => {
                              dispatch(loadingComment());
                              dispatch(loadingSoutez());
                              dispatch(loadingLudia());
                              dispatch(loadingOverview());
                              dispatch(loadingPocasie());
                              let data = {
                                Id: logindata.Id,
                                Token: logindata.Token,
                                TokenExpirationDate:
                                  logindata.TokenExpirationDate,
                                TokenExpirationTime:
                                  logindata.TokenExpirationTime,
                                UserData: {
                                  ...logindata.UserData,
                                  datum: date.toISOString(),
                                },
                              };
                              //dispatch(login({ data: data }));
                              window.sessionStorage.setItem(
                                "logindata",
                                JSON.stringify(data)
                              );

                              axios
                                .get(
                                  commentsUrl +
                                    logindata.UserData.vybrane_stredisko.kod +
                                    "/" +
                                    date.toISOString(),
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " + logindata.Token,
                                    },
                                  }
                                )
                                .then(function (response) {
                                  dispatch(comment(response.data));
                                  //dispatch(loadingComment({ data: false }))
                                })
                                .catch(function (error) {
                                  errorHandleDialog(
                                    error.response,
                                    navigate,
                                    dispatch
                                  );
                                });
                              axios
                                .get(
                                  soutezUrl +
                                    logindata.UserData.vybrane_stredisko.kod +
                                    "/" +
                                    date.toISOString(),
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " + logindata.Token,
                                    },
                                  }
                                )
                                .then(function (response) {
                                  dispatch(soutez(response.data));
                                  //dispatch(loadingSoutez({ data: false }))
                                })
                                .catch(function (error) {
                                  errorHandleDialog(
                                    error.response,
                                    navigate,
                                    dispatch
                                  );
                                });
                              axios
                                .get(
                                  Info_o_smeneUrl +
                                    logindata.UserData.vybrane_stredisko.kod,
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " + logindata.Token,
                                    },
                                  }
                                )
                                .then(function (response) {
                                  console.log(response);
                                  dispatch(info_o_smene(response.data));
                                  //dispatch(loadingSoutez({ data: false }))
                                })
                                .catch(function (error) {
                                  errorHandleDialog(
                                    error.response,
                                    navigate,
                                    dispatch
                                  );
                                });
                              axios
                                .get(
                                  zamestnanciUrl +
                                    logindata.UserData.vybrane_stredisko.kod +
                                    "/" +
                                    date.toISOString(),
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " + logindata.Token,
                                    },
                                  }
                                )
                                .then(function (response) {
                                  dispatch(ludia(response.data));
                                  //dispatch(loadingLudia({ data: false }))
                                })
                                .catch(function (error) {
                                  errorHandleDialog(
                                    error.response,
                                    navigate,
                                    dispatch
                                  );
                                });
                              axios
                                .get(
                                  prehladUrl +
                                    logindata.UserData.vybrane_stredisko.kod +
                                    "/" +
                                    date.toISOString(),
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " + logindata.Token,
                                    },
                                  }
                                )
                                .then(function (response) {
                                  dispatch(overview(response.data));
                                  //dispatch(loadingOverview({ data: false }))
                                })
                                .catch(function (error) {
                                  errorHandleDialog(
                                    error.response,
                                    navigate,
                                    dispatch
                                  );
                                });
                              axios
                                .get(
                                  pocasieUrl +
                                    logindata.UserData.vybrane_stredisko.kod +
                                    "/" +
                                    date.toISOString(),
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " + logindata.Token,
                                    },
                                  }
                                )
                                .then(function (response) {
                                  dispatch(pocasie(response.data));
                                  //dispatch(loadingPocasie({ data: false }))
                                })
                                .catch(function (error) {
                                  errorHandleDialog(
                                    error.response,
                                    navigate,
                                    dispatch
                                  );
                                });
                            }}
                          ></DatePicker>
                        </div>
                        {/* <div
                          className={styles.drpdwn}
                          hidden={
                            logindata.UserData.strediska.length > 1
                              ? false
                              : true
                          }
                        >
                          <Dropdown
                            className={styles.dropdown}
                            value={
                              logindata.UserData.vybrane_stredisko !==
                                undefined &&
                              logindata.UserData.vybrane_stredisko.kod +
                                " - " +
                                logindata.UserData.strediska[0].nazov
                            }
                            title="prehlad"
                            setValue={async (e) => {
                              dispatch(loadingComment());
                              dispatch(loadingSoutez());
                              dispatch(loadingLudia());
                              dispatch(loadingOverview());
                              dispatch(loadingPocasie());
                              dispatch(loadingInfo_o_smene());

                              // let row = logindata.UserData.strediska.filter(
                              //   (row) => row.kod === e.slice(0, 3)
                              // );
                              // console.log(e.slice(0, 3))
                              // let data = {
                              //   Id: logindata.Id,
                              //   Token: logindata.Token,
                              //   TokenExpirationDate:
                              //     logindata.TokenExpirationDate,
                              //   TokenExpirationTime:
                              //     logindata.TokenExpirationTime,
                              //   UserData: {
                              //     admin: logindata.UserData.admin,
                              //     email: logindata.UserData.email,
                              //     skupina: logindata.UserData.skupina,
                              //     fullName: logindata.UserData.fullName,
                              //     avatar: logindata.UserData.avatar,
                              //     strediska: logindata.UserData.strediska,
                              //     username: logindata.UserData.username,
                              //     datum: logindata.UserData.datum,
                              //     vybrane_stredisko: {
                              //       kod: row[0].kod,
                              //       nazov: row[0].nazov,
                              //       mena: row[0].mena,
                              //     },
                              //   },
                              // };

                              // dispatch(login({ data: data }));
                              // window.sessionStorage.setItem(
                              //   "logindata",
                              //   JSON.stringify(data)
                              // );
                              axios
                                .post(
                                  zmenaStrediskaUrl + e.slice(0, 3),
                                  {},
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " + logindata.Token,
                                    },
                                  }
                                )
                                .then(function (response) {
                                  //dispatch(login({ data: response.data }));
                                  window.sessionStorage.setItem(
                                    "logindata",
                                    JSON.stringify(response.data)
                                  );
                                  setLdata(response.data);
                                })
                                .catch(function (error) {
                                  errorHandleDialog(
                                    error.response,
                                    navigate,
                                    dispatch
                                  );
                                });
                              axios
                                .get(
                                  commentsUrl +
                                    e.slice(0, 3) +
                                    "/" +
                                    logindata.UserData.datum,
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " + logindata.Token,
                                    },
                                  }
                                )
                                .then(function (response) {
                                  dispatch(comment(response.data));
                                  //dispatch(loadingComment())
                                })
                                .catch(function (error) {
                                  errorHandleDialog(
                                    error.response,
                                    navigate,
                                    dispatch
                                  );
                                });
                              axios
                                .get(
                                  soutezUrl +
                                    e.slice(0, 3) +
                                    "/" +
                                    logindata.UserData.datum,
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " + logindata.Token,
                                    },
                                  }
                                )
                                .then(function (response) {
                                  dispatch(soutez(response.data));
                                  //dispatch(loadingSoutez({ data: false }))
                                })
                                .catch(function (error) {
                                  console.log(error);
                                  errorHandleDialog(
                                    error.response,
                                    navigate,
                                    dispatch
                                  );
                                });
                              axios
                                .get(Info_o_smeneUrl + e.slice(0, 3), {
                                  headers: {
                                    Authorization: "Bearer " + logindata.Token,
                                  },
                                })
                                .then(function (response) {
                                  console.log(response);
                                  dispatch(info_o_smene(response.data));
                                  //dispatch(loadingSoutez({ data: false }))
                                })
                                .catch(function (error) {
                                  console.log(error);
                                  errorHandleDialog(
                                    error.response,
                                    navigate,
                                    dispatch
                                  );
                                });
                              axios
                                .get(
                                  zamestnanciUrl +
                                    e.slice(0, 3) +
                                    "/" +
                                    logindata.UserData.datum,
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " + logindata.Token,
                                    },
                                  }
                                )
                                .then(function (response) {
                                  dispatch(ludia(response.data));
                                  //dispatch(loadingLudia({ data: false }))
                                })
                                .catch(function (error) {
                                  errorHandleDialog(
                                    error.response,
                                    navigate,
                                    dispatch
                                  );
                                });
                              axios
                                .get(
                                  prehladUrl +
                                    e.slice(0, 3) +
                                    "/" +
                                    logindata.UserData.datum,
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " + logindata.Token,
                                    },
                                  }
                                )
                                .then(function (response) {
                                  dispatch(overview(response.data));
                                  //dispatch(loadingOverview({ data: false }))
                                })
                                .catch(function (error) {
                                  errorHandleDialog(
                                    error.response,
                                    navigate,
                                    dispatch
                                  );
                                });
                              axios
                                .get(
                                  prehladUrl +
                                    e.slice(0, 3) +
                                    "/" +
                                    logindata.UserData.datum,
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " + logindata.Token,
                                    },
                                  }
                                )
                                .then(function (response) {
                                  dispatch(overview(response.data));
                                  //dispatch(loadingOverview({ data: false }))
                                })
                                .catch(function (error) {
                                  errorHandleDialog(
                                    error.response,
                                    navigate,
                                    dispatch
                                  );
                                });
                              axios
                                .get(
                                  pocasieUrl +
                                    e.slice(0, 3) +
                                    "/" +
                                    logindata.UserData.datum,
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " + logindata.Token,
                                    },
                                  }
                                )
                                .then(function (response) {
                                  dispatch(pocasie(response.data));
                                  //dispatch(loadingPocasie({ data: false }))
                                })
                                .catch(function (error) {
                                  errorHandleDialog(
                                    error.response,
                                    navigate,
                                    dispatch
                                  );
                                });
                            }}
                            options={intervals}
                            small
                          />
                        </div> */}
                      </div>
                    </div>
                  )}
                </>
              )}

              {children}
            </div>
          </div>
          <DetekciaNeaktivity />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default withRouter(Page);
