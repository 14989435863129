import { createSlice } from "@reduxjs/toolkit";

export const signalRSlice = createSlice({
  name: "signalR",
  initialState: {
    connectionCiselniky: null,
    connectionCiselnikyID: "",
    connectionNotificationsUnread: null,
    connectionNotificationsUnreadID: "",
    connectionNotifications: null,
    connectionNotificationsID: "",
    connectionLoaded: false,
    connectionObjednavky: null,
    connectionObjednavkyID: "",
    connectionReklamace: null,
    connectionReklamaceID: "",

  },
  reducers: {
    setConnectionCiselniky: (state, action) => {
      //console.log(action.payload);
      state.connectionCiselniky = action.payload;
    },
    setConnectionCiselnikyID: (state, action) => {
      //console.log(action.payload);
      state.connectionCiselnikyID = action.payload;
    },
    setConnectionNotificationsUnread: (state, action) => {
      //console.log(action.payload);
      state.connectionNotificationsUnread = action.payload;
    },
    setConnectionNotificationsUnreadID: (state, action) => {
      //console.log(action.payload);
      state.connectionNotificationsUnreadID = action.payload;
    },
    setConnectionNotifications: (state, action) => {
      //console.log(action.payload);
      state.connectionNotifications = action.payload;
    },
    setConnectionNotificationsID: (state, action) => {
      //console.log(action.payload);
      state.connectionNotificationsID = action.payload;
    },
    setConnectionObjednavky: (state, action) => {
      //console.log(action.payload);
      state.connectionObjednavky = action.payload;
    },
    setConnectionObjednavkyID: (state, action) => {
      //console.log(action.payload);
      state.connectionObjednavkyID = action.payload;
    },
    setConnectionReklamace: (state, action) => {
      //console.log(action.payload);
      state.connectionReklamace = action.payload;
    },
    setConnectionReklamaceID: (state, action) => {
      //console.log(action.payload);
      state.connectionReklamaceID = action.payload;
    },
    setConnectionLoaded: (state, action) => {
      state.connectionLoaded = action.payload;
    },
  },
});

export const {
  setConnectionCiselniky,
  setConnectionCiselnikyID,
  setConnectionNotificationsUnread,
  setConnectionNotificationsUnreadID,
  setConnectionNotifications,
  setConnectionNotificationsID,
  setConnectionLoaded,
  setConnectionObjednavkyID,
  setConnectionObjednavky,
  setConnectionReklamaceID,
  setConnectionReklamace,
} = signalRSlice.actions;

export const signalRData = (state) => state.signalRdata;

export default signalRSlice.reducer;
