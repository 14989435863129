import Modal from "../../Modal";
import Card from "../../Card";
import Icon from "../../Icon";
import Search from "./Search";
import styles from "./Modal.module.sass";
import remove_accents from "remove-accents";
import cn from "classnames";
import React, { useState, useEffect } from "react";
import { Panel, PanelData } from "../ModalPanel/redux/PanelSlice";
import { useSelect } from "@mui/base";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "./../../../features/UseDebounce";
import swal from "sweetalert";
import { login, loginData } from "../../../screens/SignIn/redux/loginSlice";
import axios from "axios";
import { useHistory } from "react-router";
import { EvidenceUrl } from "../../../features/urlHelper";
import Detail from "../../Detail";
import { errorHandleDialog } from "./../../../features/errorDialog";

const App = ({
  className,
  visible,
  setVisible,
  loading,
  fields,
  params,
  searchValue,
  setSearchValue,
  titleSelector,
  validation,
  selectorData,
  selectorCount,
  search,
  id,
  page,
  setPage
}) => {
  useDebounce(
    () => {
      
      if (params.selector !== undefined) {
        
        search(params.selector, searchValue, id, 0);
        setPage(0)
      }
    },
    [searchValue],
    400
  );

  return (
    <Modal outerClassName={styles.outer} visible={visible}>
      <div className={styles.ico_Title}>
        <div>{titleSelector}</div>
        <button
          onClick={() => {
            setVisible(false);
          }}
        >
          <Icon name="close" size="20" />
        </button>
      </div>
      <Card bezPaddingu={false}>
        <Search value={searchValue} setValue={setSearchValue} />
        <br />
        <div
          style={{
            height: window.innerHeight < 1000 ? window.innerHeight - 200 : 800,
          }}
          className={styles.content}
        >
          {loading ? (
            <></>
          ) : (
            <div className={styles.searcheable}>
              <div className={styles.content}>
                {selectorData.map((row) => {
                  return (
                    <div className={styles.row}>
                      <div
                        onClick={() => {
                          let selectorLable = "";
                          fields[1]?.map((polozka) => {
                            selectorLable +=
                              polozka.label + row[polozka.column];
                          });

                          validation(params, row[fields[0]]);
                          setVisible(false);
                        }}
                        className={styles.left}
                      >
                        <div className={styles.avatarArea}>
                          <div className={styles.avatar}></div>
                        </div>
                        <div>
                          <div className={styles.upperText}>
                            {row[fields[0]]}
                          </div>

                          <div className={styles.downText}>
                            {fields[1]?.map((polozka) => {
                              return (
                                <div>
                                  {polozka.label}
                                  {row[polozka.column]}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className={styles.right}>
                        <Detail
                          dataset={params.selector.dataset}
                          idFieldName={params.selector.id}
                          idFieldValue={row[params.selector.id]}
                          volba={"view"}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={styles.nacitaj}>
                <div className={styles.text}></div>
                <div
                  onClick={() => {
                    search(params.selector, searchValue, id, page + 1);
                    setPage(page + 1)
                  }}
                  className={styles.textB}
                >
                  {selectorData.length === selectorCount ? (
                    <></>
                  ) : (
                    <>Načti další</>
                  )}
                </div>
                <div className={styles.pocet}>
                  {selectorData.length + " "}/{" " + selectorCount}
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    </Modal>
  );
};
export default App;
