import React, { useEffect, useState } from "react";
import styles from "./Menu.module.sass";
import {
  csCZ,
  DataGridPremium,
  LicenseInfo,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import swal from "sweetalert";
import { login, loginData } from "../../../screens/SignIn/redux/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  menuOblubeneUrl,
  menuRunUrl,
  menuUrl,
} from "../../../features/urlHelper";
import { useHistory } from "react-router";
import { getLicensePremium } from "../../../features/License";
import useDarkMode from "@fisch0920/use-dark-mode";
import ContentLoader from "react-content-loader";
import ModalForm from "./../../../components/Modaly/ModalForm";
import { Form, loadingForm } from "../../Modaly/ModalForm/redux/FormSlice";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { errorHandleDialog } from "../../../features/errorDialog";
import { ClickAwayListener } from "@material-ui/core";

const App = ({
  className,
  typ,
  rows,
  setRows,
  visible,
  setVisible,
  loading,
}) => {
  const apiRef = useGridApiRef();
  const [visibleForm, setVisibleForm] = useState(false);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const [formName, setFormName] = useState("");

  const dispatch = useDispatch();
  const navigate = useHistory();
  const darkMode = useDarkMode(false);
  LicenseInfo.setLicenseKey(getLicensePremium());

  const theme = createTheme({
    palette: {
      primary: { main: "#nnn" },
    },
  });

  async function spustit(key) {
    await axios
      .post(
        menuRunUrl,
        {
          key: key,
          typ: typ,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log("spustit", response);
        if (response.data.typ === "route") {
          navigate.push(response.data.url);
        } else if (response.data.typ === "enter") {
          console.log("enterData", response.data.enterData);
          dispatch(loadingForm());
          setFormName(response.data.enterData.formName);
          dispatch(Form(response.data.enterData));
          setVisibleForm(true);
          document
                .getElementsByName(
                  response.data.enterData.values[Object.keys(response.data.enterData.values)[0]].id
                )[0]
                .focus();
          console.log(response.data.enterData);
        }
        console.log(response);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (e.target.innerHTML !== "Menu") setVisible(false);
      }}
    >
      <div
        className={styles.outer}
        style={{
          visibility: visible ? "visible" : "hidden",
          maxHeight: 500,
          width: 330,
        }}
      >
        {loading ? (
          <></>
        ) : (
          <>
            {rows?.map((row, index) => {
              return (
                <div className={styles.f}>
                  {row.expand === undefined ? (
                    <>
                      {row.values.map((value) => {
                        return (
                          <>
                            <div className={styles.row_menu}>{value.text}</div>
                            {value.hasSeparator ? (
                              <div className={styles.row_menu_divider}>
                                <div className={styles.divider}></div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <div>
                      <div
                        onClick={() => {
                          let hlp_row = { ...row, expand: !row.expand };
                          let hlp_rows = rows;
                          hlp_rows[index] = hlp_row;
                          setRows(hlp_rows);
                        }}
                        className={styles.row_menu_exp}
                      >
                        <div>{row.label}</div> <i class="fas fa-angle-down"></i>
                      </div>
                      {row.expand ? (
                        row.values.map((value) => {
                          return (
                            <>
                              <div className={styles.row_menu2}>
                                <div className={styles.buletPoint}><i class='fas fa-caret-right'></i></div>
                                {value.text}
                              </div>
                              {value.hasSeparator ? (
                                <div className={styles.row_menu_divider}>
                                  <div className={styles.divider}></div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </>
        )}

        <ModalForm
          visible={visibleForm}
          setVisible={setVisibleForm}
          className={styles}
          formName={formName}
        />
      </div>
    </ClickAwayListener>
  );
};
export default App;
