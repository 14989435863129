import Card from "../../components/Card";
import Icon from "../../components/Icon";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Search from "../../components/Search";
import Table from "./Table";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import { login, loginData } from "../../screens/SignIn/redux/loginSlice";
import { EvidenceUrl } from "../../features/urlHelper";
import {
  evidenceTable,
  loadingEvidenceTable,
  setDataset,
} from "./Table/redux/tableEvidenceSlice";
import { searchData } from "../Search/redux/searchSlice";
import swal from "sweetalert";
import { useHistory } from "react-router";
import Detail from "../../components/Detail";
import Menu_del_edi_det from "../../components/Menu_del_edi_det";
import Delete from "../../components/Delete";
import Edit from "../../components/Edit";
import AddNew from "../../components/AddNew";
import moment from "moment";
import { errorHandleDialog } from "./../../features/errorDialog";
import { Form, FormData } from "../Modaly/ModalForm/redux/FormSlice";
import Menu from "./menu";
import ClickAwayListener from "@mui/base/ClickAwayListener";

const App = ({ className, dataset, backStep }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const Formdata = useSelector(FormData);

  //const [PanelStep, setPanelStep] = useState(0);

  const [paginationData, setPaginationData] = useState({
    dataset: dataset,
    stredisko:
      logindata.UserData.vybrane_stredisko !== undefined
        ? logindata.UserData.vybrane_stredisko.kod
        : null,
    order: [],
    filter: {
      items: [],
      logicOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    },
    datasetIndex: null,
    count: 0,
    pagination: {
      pageSize: 10,
      page: 0,
    },
  });

  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [visible, setVisible] = useState(false);
  const [activate, setActivate] = useState(false);
  const [rows, setRows] = useState([]);
  const [loadingMenu, setLoadingMenu] = useState(false);

  const [testcan, setTestcan] = React.useState({
    add: true,
    delete: true,
    write: true,
  });
  const [idFieldName, setIdFieldName] = React.useState("");

  const [volba, setVolba] = React.useState("view");

  const [columns, setColumns] = React.useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [title, setTitle] = React.useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [visiblePanel, setvisiblePanel] = useState(false);
  const [visibleForm, setVisibleForm] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [formName, setFormName] = React.useState("");

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  function testCiNemazemSamSeba(disabled, row) {
    if (
      row.username !== undefined &&
      row.username === logindata.UserData.username
    ) {
      return !disabled;
    }
    return disabled;
  }

  useEffect(() => {
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  function spracujHierarchy(data, title) {
    console.log("hierarchy", data);

    let menu = [];

    data.map((menu_row) => {
      if (
        menu.find((xxx) => xxx.label === menu_row.hierarchy[1]) === undefined
      ) {
        menu.push({ label: menu_row.hierarchy[1], values: [] });
      }
    });
    data.map((menu_row) => {
      menu.map((xxx, index) => {
        if (xxx.label === menu_row.hierarchy[1]) {
          menu[index].values.push(menu_row);
        }
      });
    });

    menu.map((xxx, index) => {
      if (xxx.values.length > 1) {
        let values = menu[index].values.filter(
          (e) => e.hierarchy[1] !== e.hierarchy[e.hierarchy.length - 1]
        );
        menu[index] = { ...xxx, expand: false, values: values };
      }
    });
    setRows(menu);
    console.log(menu);
    setLoadingMenu(false);
  }

  function getWidth(width) {
    if (width > 2500) {
      return width - 456;
    } else if (width < 2500 && width > 767) {
      return width - 212;
    } else {
      return width;
    }
  }
  function createColumns() {
    if (visiblePanel) return;
    if (columns.length > 0) {
      let newColumns = [];
      newColumns.push({
        field: "detail",
        hideable: false,
        headerName: "",
        width: 30,
        headerAlign: "center",
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <>
              {window.innerWidth < 767 ? (
                <Menu_del_edi_det
                  dataset={dataset}
                  idFieldValue={params.row[idFieldName]}
                  idFieldName={idFieldName}
                  disabled_edi={testcan.write}
                  disabled_del={testCiNemazemSamSeba(
                    testcan.delete,
                    params.row
                  )}
                  paginationData={paginationData}
                  setPaginationData={setPaginationData}
                  rowSelectionModel={rowSelectionModel}
                  setRowSelectionModel={setRowSelectionModel}
                  visiblePanel={visiblePanel}
                  setvisiblePanel={setvisiblePanel}
                  volba={volba}
                  setVolba={setVolba}
                />
              ) : (
                <Detail
                  dataset={dataset}
                  idFieldName={idFieldName}
                  idFieldValue={params.row[idFieldName]}
                  paginationData={paginationData}
                  setPaginationData={setPaginationData}
                  rowSelectionModel={rowSelectionModel}
                  setRowSelectionModel={setRowSelectionModel}
                  volba={volba}
                />
              )}
            </>
          );
        },
      });

      columns.map((column) => {
        if (column.field !== "edit" && column.field !== "detail") {
          newColumns.push({
            ...column,
            width:
              (getWidth(window.innerWidth) - 138) * (column.widthPerc / 100),

            valueFormatter: (params) => {
              // return column.type === "date"
              //   ? moment(params.value).format("DD.MM.YYYY")
              //   : params.value;
              if (column.type === "number") {
                if (params.value === undefined) {
                  return "";
                }
                const valueFormatted = Number(params.value);

                const value = valueFormatted
                  .toFixed(column.desMiest)
                  .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");
                return `${value}`;
              } else return params.value;
            },
          });
        }
      });
      newColumns.push({
        field: "edit",
        headerName: "",
        width: 70,
        hideable: false,
        headerAlign: "center",
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          //console.log("params edit", params);

          return (
            <>
              <Edit
                dataset={dataset}
                idFieldValue={params.row[idFieldName]}
                idFieldName={idFieldName}
                disabled={testcan.write}
                paginationData={paginationData}
                setPaginationData={setPaginationData}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                visiblePanel={visiblePanel}
                setvisiblePanel={setvisiblePanel}
                volba={volba}
                setVolba={setVolba}
              />
              <Delete
                dataset={dataset}
                idFieldValue={params.row[idFieldName]}
                idFieldName={idFieldName}
                disabled={testCiNemazemSamSeba(testcan.delete, params.row)}
                //disabled={true}
                paginationData={paginationData}
                setPaginationData={setPaginationData}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                visiblePanel={visiblePanel}
                setvisiblePanel={setvisiblePanel}
                volba={volba}
                setVolba={setVolba}
              />
            </>
          );
        },
      });
      console.log(newColumns);
      setColumns(newColumns);
    }
  }

  useEffect(() => {
    createColumns();
  }, [idFieldName, paginationData, width]);

  useEffect(() => {
    dispatch(loadingEvidenceTable());
    setLoadingMenu(true);
    result();
  }, []);

  async function result() {
    await axios
      .post(
        EvidenceUrl,
        { ...paginationData, typErp: logindata.UserData.typErp },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log(" evidence create", response.data);
        setPaginationData({
          dataset: response.data.dataset,
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          order: response.data.order,
          filter: response.data.filter,
          datasetIndex: response.data.datasetIndex,
          count: response.data.count,
          pagination: {
            pageSize: 10,
            page: 0,
          },
        });

        if (response.data.menus !== undefined) {
          spracujHierarchy(response.data.menus.menu);
          setShowMenu(true);
        }
        setTestcan(response.data.testcan);
        setTitle(response.data.title);
        setColumns(response.data.columns);
        setIdFieldName(response.data.idFieldName);
        const newData = response.data.data.map((row, index) => {
          return { ...row, id: row[response.data.idFieldName] };
        });
        //console.log(newData);
        dispatch(evidenceTable(newData));
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <div className={styles.card}>
      <div
        style={{
          height: window.innerHeight - (window.innerWidth < 767 ? 74 : 170),
        }}
      >
        <div className={styles.header}>
          <div className={styles.row1}>
            <div
              onClick={() => {
                if (backStep !== "") navigate.push(backStep);
                else navigate.goBack();
              }}
              className={styles.icon}
            >
              <i class="fa fa-chevron-circle-left"></i>
            </div>
          </div>

          <div className={styles.row2}>{title === "" ? "..." : title}</div>
          <div className={styles.row3}>
            {showMenu ? (
              <div
                onClick={() => {
                  setVisible(!visible);
                }}
                className={styles.icon2}
              >
                <i class="fa fa-bars"></i>
                <div>Menu</div>
              </div>
            ) : (
              <></>
            )}

            <Menu
              rows={rows}
              setRows={setRows}
              visible={visible}
              setVisible={setVisible}
              loading={loadingMenu}
            />

            <div>
              <AddNew
                paginationData={paginationData}
                dataset={dataset}
                idFieldValue={
                  selectedRow !== null ? selectedRow.row[idFieldName] : null
                }
                idFieldName={idFieldName}
                disabled={testcan.add}
                setPaginationData={setPaginationData}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                visiblePanel={visiblePanel}
                setvisiblePanel={setvisiblePanel}
                volba={volba}
                setVolba={setVolba}
              />
            </div>
          </div>
        </div>

        <Table
          columns={columns}
          paginationData={paginationData}
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
          setPaginationData={setPaginationData}
          rowSelectionModel={rowSelectionModel}
          setRowSelectionModel={setRowSelectionModel}
          visiblePanel={visiblePanel}
          setvisiblePanel={setvisiblePanel}
          visibleForm={visibleForm}
          setVisibleForm={setVisibleForm}
          volba={volba}
          setVolba={setVolba}
          dataset={dataset}
          idFieldName={idFieldName}
          formName={formName}
          setFormName={setFormName}
        />
      </div>
    </div>
  );
};
export default App;
