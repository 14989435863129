import React from "react";
import cn from "classnames";
import styles from "./Balance.module.sass";
import Tooltip from '@mui/material/Tooltip';

const Balance = ({ className, value1, value2, background }) => {
    return (
        <div
            className={cn(
                styles.positive,
                { [styles.background]: background },
                className
            )}
        >
            <div className={value1 >= 0 ? styles.avgP : styles.avgN}>
                <Tooltip enterTouchDelay={0} leaveTouchDelay={100000} title={"Průměrná účtenka za den"} >
                    <div>
                    <img src={"/images/content/cash.png"} alt="Avatar" className={styles.icon1} style={{ width: 15, margin: 2 }} />
                        {String(value1)}Kč
                        </div>
                </Tooltip>
              
                
            </div>
            <div className={value2 >= 0 ? styles.avgP : styles.avgN}>
                <Tooltip enterTouchDelay={0} leaveTouchDelay={100000} title={"Průměrná účtenka za 4 tydny"} >
                    <div>
                        <img src={"/images/content/cash.png"} alt="Avatar" className={styles.icon1} style={{ width: 15, margin: 2}} />
                        {String(value2)}Kč
                    </div>
                </Tooltip>


            </div>
         
        </div>
        )
};

export default Balance;
