import { createSlice } from '@reduxjs/toolkit';

export const tablePrijemSurovinSlice = createSlice({
    name: 'prijemSurovinTable',
    initialState: {
        data: [],
        loading: true,
    },
    reducers: {
        prijemSurovinTable: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingPrijemSurovinTable: (state) => {
            console.log("loading: true")
            state.loading = true
        }
    },
});

export const { prijemSurovinTable, loadingPrijemSurovinTable } = tablePrijemSurovinSlice.actions;

export const prijemSurovinTableData = state => state.prijemSurovinTabledata;


export default tablePrijemSurovinSlice.reducer;
