import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Search.module.sass";
import Icon from "../../../Icon";

const Search = ({ className, value, setValue }) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    document.getElementsByName("search")[0].focus();
  }, []);

  return (
    <>
      <div
        className={cn(styles.search, className, { [styles.active]: visible })}
      >
        <div className={styles.head}>
          <button className={styles.start}>
            <Icon name="search" size="24" />
          </button>
          <button className={styles.direction}>
            <Icon name="search" size="24" />
          </button>
          <input
            className={styles.input}
            type="text"
            placeholder="Hledejte"
            value={value}
            name="search"
            onChange={(e) => {
              if (e.target.value === "") {
                setVisible(false);
              } else {
                setVisible(true);
              }
              setValue(e.target.value);
            }}
            onClick={() => setVisible(true)}
          />

          <button
            className={styles.close}
            onClick={() => {
              setVisible(false);
              setValue("");
            }}
          >
            <Icon name="close-circle" size="24" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Search;
