import React, { useState } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Help from "./Help";
import Image from "../Image";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Dropdown from "./Dropdown";

const Sidebar = ({ className, onClose, setVisible, visible, typErp }) => {
  const [visibleHelp, setVisibleHelp] = useState(false);
  const navigate = useHistory();
  let navigation = [];
  switch (typErp) {
    case "C":
      navigation = [
        {
          title: "Přehled",
          icon: "home",
          url: "/prehled",
        },
        {
          title: "1. Sklad",
          url: "/sklad",
          icon: "",
        },
        {
          title: "2. Vyroba",
          url: "/vyroba",
          icon: "",
        },
        {
          title: "3. Dochádzka",
          url: "/dochadzka",
          icon: "",
        },
        {
          title: "A. Adresáře a číselníky",
          icon: "",
          url: "/ciselniky",
        },
        {
          title: "V. Výpisy a reporty",
          icon: "",
          url: "/reporty",
        },
        {
          title: "M. Manažerské voby",
          icon: "",
          url: "/manazerske&volby",
        },
        {
          title: "X. Volby administrátora",
          icon: "",
          url: "/mod_adm",
        },
      ];
      break;
    case "B":
      navigation = [
        {
          title: "Přehled",
          icon: "home",
          url: "/prehled",
        },
        {
          title: "Kalendář",
          icon: "calendar",
          url: "/kalendar",
        },
        {
          title: "Zaměstnanci",
          url: "/zamestnanci",
          icon: "users",
        },
        {
          title: "Objednávky BB",
          slug: "products",
          icon: "shopping-basket",
          dropdown: [
            {
              title: "Objednávky",
              url: "/objednavkyBB",
            },
            {
              title: "Přehled objednávek",
              url: "/objednavkyBBPrehled",
            },
          ],
        },
        {
          title: "Objednávky",
          url: "/objednavky",
          icon: "cart-plus",
        },

        {
          title: "0. Sklad",
          slug: "products",
          icon: "warehouse",
          dropdown: [
            {
              title: "1. Příjem surovin",
              url: "/vytvorit/prijemky",
            },
            // {
            //   title: "2. Příjem surovin - Žiželice",
            //   url: "/vytvorit/prijemSurovin",
            // },
            {
              title: "2. Reklamace",

              dropdown: [
                {
                  title: "Nová reklamace",
                  url: "/reklamaceSQL_nova",
                },
                {
                  title: "Přehled reklamací",
                  url: "/reklamaceSQL",
                },
                // {
                //   title: "Přehled tiketov z Trellisu",
                //   url: "/reklamaceTrellis",
                // },
              ],
            },
            {
              title: "3. Malá inventura",
              url: "/vytvorit/malaInventura",
            },
            {
              title: "4. Převod surovin",
              url: "/vytvorit/prevodSurovin",
            },
            {
              title: "5. Nabídnout surovinu",
              url: "/vytvorit/nabidnoutPobocke",
            },
            {
              title: "6. Stav skladu",
              url: "/vytvorit/stavSkladu",
            },
            {
              title: "7. Odpisy",

              dropdown: [
                {
                  title: "Nový odpis",
                  url: "/odpisy",
                },
                {
                  title: "Přehled odpisů",
                  url: "/odpisy/prehled",
                },
                // {
                //   title: "Přehled tiketov z Trellisu",
                //   url: "/reklamaceTrellis",
                // },
              ],
            },
          ],
        },
        {
          title: "3. Dodavatele a smlouvy",
          slug: "products",
          icon: "file-contract",
          url: "/dodavatele&smlouvy",
          // dropdown: [
          //   {
          //     title: "1. Adresář dodavatelů",
          //     url: "/ciselniky/icod_prc",
          //   },
          // ],
        },
        {
          title: "A. Adresáře a číselníky",
          slug: "products",
          icon: "list-ol",
          url: "/ciselniky",
          // dropdown: [
          //   {
          //     title: "1. Adresář dodavatelů",
          //     url: "/ciselniky/icod_prc",
          //   },
          // ],
        },
        {
          title: "V. Reporty",
          slug: "products",
          icon: "file-zip-o",
          url: "/reporty",
        },
        {
          title: "M. Manažerské voby",
          icon: "address-card",
          url: "/manazerske&volby",
        },

        {
          title: "X. Nástroje",
          slug: "products",
          icon: "cog",
          dropdown: [
            {
              title: "1. Prohlížení již vytvořených sestav",
              url: "/nastroje/prohlizeniSestav",
            },
            {
              title: "2. Test page",
              url: "/nastroje/testPage",
            },
          ],
        },
      ];
      break;
    case "R":
      navigation = [
        {
          title: "Objednávky",
          url: "/objednavkyOstatne",
          icon: "cart-plus",
        },
        {
          title: "Přehled objednávek",
          url: "/objednavkyOstatnePrehled",
          icon: "stream",
        },
        {
          title: "Nová reklamace",
          url: "/reklamaceSQL_nova",
          icon: "plus-square",
        },
        {
          title: "Přehled reklamací",
          url: "/reklamaceSQL",
          icon: "stream",
        },
        {
          title: "Otevřené reklamace",
          url: "/reklamaceSQL/resitel/otvorena",
          icon: "bars",
        },
        {
          title: "Uzavřené reklamace",
          url: "/reklamaceSQL/resitel/uzavreta",
          icon: "lock",
        },
      ];
      break;
    case "W":
      navigation = [
        {
          title: "WMS Truck Road",
          url: "/kodys_truckroutes",
          icon: "truck",
        },
        {
          title: "WMS Truck Road Detail",
          url: "/kodys_truckroutes_details",
          icon: "stream",
        },
      ];
      break;
    default:
      navigation = [
        {
          title: "Objednávky",
          url: "/objednavkyOstatne",
          icon: "cart-plus",
        },
        {
          title: "Přehled objednávek",
          url: "/objednavkyOstatnePrehled",
          icon: "stream",
        },
      ];
      break;
  }
  return (
    <>
      <div
        style={{ height: window.innerHeight }}
        className={cn(styles.sidebar, className, { [styles.active]: visible })}
      >
        <div className={styles.logo_x}>
          <div
            className={styles.logo}
            onClick={() => {
              setVisible(!visible);
            }}
          >
            <Image
              className={styles.pic}
              src={
                typErp === "C" ||
                typErp === "O" ||
                typErp === "R" ||
                typErp === "W"
                  ? "/images/logo_cro.png"
                  : "/images/logo.png"
              }
              srcDark={
                typErp === "C" ||
                typErp === "O" ||
                typErp === "R" ||
                typErp === "W"
                  ? "/images/logo_cro.png"
                  : "/images/logo.png"
              }
              alt="Core"
            />
          </div>
          <div
            className={visible ? styles.x : styles.x_hidden}
            onClick={() => {
              setVisible(!visible);
            }}
          >
            <i class="fa fa-close" style={{ fontSize: 20 }}></i>
          </div>
        </div>

        <div className={styles.menu}>
          {navigation.map((x, index) =>
            x.url ? (
              <NavLink
                className={styles.item}
                activeClassName={styles.active}
                to={x.url}
                key={index}
                title={x.title}
                exact
                onClick={() => setVisible(false)}
              >
                {x.icon === "" ? (
                  <div className={styles.FontIco}>{x.title.slice(0, 1)}</div>
                ) : (
                  <div className={styles.icon}>
                    <i
                      class={"fa fa-" + `${x.icon}`}
                      style={{ fontSize: 20 }}
                    ></i>
                  </div>
                )}
                {x.title[1] === "."
                  ? x.title.slice(2, x.title.length)
                  : x.title}
              </NavLink>
            ) : (
              <Dropdown
                className={styles.dropdown}
                visibleSidebar={visible}
                setValue={setVisible}
                key={index}
                item={x}
                title={x.title}
                // onClick={() => {
                //   setVisible(!visible);
                // }}
              />
            )
          )}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
        </button>
        <div className={styles.foot}>
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>
      <Help
        visible={visibleHelp}
        setVisible={setVisibleHelp}
        onClose={onClose}
      />
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
