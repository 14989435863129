import React from "react";
import cn from "classnames";
import styles from "./Balance.module.sass";
import Icon from "../../../../components/Icon";
import Tooltip from '@mui/material/Tooltip';

const Balance = ({ className, value, background }) => {
    return (
        <div
            className={cn(
                styles.positive,
                { [styles.background]: background },
                className
            )}
        >
        
            <div className={styles.avg}>
                <Tooltip enterTouchDelay={0} leaveTouchDelay={100000} title={"Procento zpožděných účtenek"} >
                    <div>
                        <Icon name="clock" />
                        {value}%
                    </div>
                </Tooltip>
                
            </div>
        </div>
    )
};

export default Balance;
