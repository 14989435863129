import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../Icon";
import Modal from "../../Modal";
import { modal, modalData } from "./../redux/modalSlice";
import styles from "./Modal.module.sass";
import { login, loginData } from "../../../screens/SignIn/redux/loginSlice";
import axios from "axios";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Tooltip from "@mui/material/Tooltip";
import { LocalizationProvider, csCZ as cz } from "@mui/x-date-pickers-pro";
import {
  DataGridPremium,
  LicenseInfo,
  csCZ,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
//import {  } from "@mui/x-license-premium";
import { getLicensePremium } from "../../../features/License";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "dayjs/locale/cs";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/cs";
import Select from "react-select";
import ModalSelector from "./../ModalSelectorPanel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import swal from "sweetalert";
import { useHistory } from "react-router";
import {
  EvidenceUrl,
  filesUploadUrl,
  formSaveUrl,
  paneldefUrl,
  prijemsUrl,
  trellisReklamacieUrl,
  validationFormUrl,
  validationPanelUrl,
  validationUrl,
} from "../../../features/urlHelper";
import moment from "moment";
import useDarkMode from "@fisch0920/use-dark-mode";
import { errorHandleDialog } from "../../../features/errorDialog";
import { loadingPanel, Panel, PanelData } from "../ModalPanel/redux/PanelSlice";
import {
  loadingNakupTable,
  nakupTable,
  nakupTableData,
} from "../../../screens/Prijemky/ModalNA/Table/redux/tableNakupSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  objednavkyOstatneObjednavkaTable,
  objednavkyOstatneObjednavkaTableData,
} from "../../../screens/Objednavky_ostatne_objednavka/Table/redux/ObjednavkyOstatneObjednavkaSlice";
import {
  loadingPrijemOdJinePobockyTable,
  prijemOdJinePobockyTable,
  prijemOdJinePobockyTableData,
  setZaznamy,
} from "../../../screens/Prijemky/ModalPS/Table/redux/tablePrijemOdJinePobockySlice";
import { getPdf } from "../../../features/getPdf";
import {
  loadingZmena,
  setComment,
  setDodaciList,
  vybrate,
  zmena,
  zmenaData,
} from "../ModalZmena/redux/zmenaSlice";
import {
  externyDodavatelTable,
  loadingExternyDodavatelTable,
} from "../../../screens/Prijemky/ModalEX/Table/redux/tableExternyDodavatelSlice";
import LoadingOverlay from "react-loading-overlay-nextgen";
import { openNewWindow } from "../../../features/OpenNewWindow";

const App = ({
  className,
  visible,
  setVisible,
  data,
  zobrazTrellis,
  setZobrazTrellis,
}) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const notify_error = (text) => toast.error(text);
  LicenseInfo.setLicenseKey(getLicensePremium());

  const darkMode = useDarkMode(false);
  const elementRef = useRef(null);

  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const zmenadata = useSelector(zmenaData);
  const modaldata = useSelector(modalData);
  const prijemOdJinePobockyTabledata = useSelector(
    prijemOdJinePobockyTableData
  );
  const [rowGroupingModel, setRowGroupingModel] = React.useState([
    "typ_reklamacie",
  ]);
  const [layoutActive, setLayoutActive] = React.useState(false);
  const darkTheme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
      mode: darkMode.value ? "dark" : "light",
    },
  });
  const stylingTable = {
    border: 0,
    paddingTop: 0,
    padding: 0,
    margin: 0,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "& .MuiDataGrid-cellContent": {
      fontSize: 11,
    },
    letterSpacing: "normal",
    width: window.innerWidth > 760 ? 760 : window.innerWidth - 20,
    height: 580,
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
    color: darkMode.value ? "white" : "#666d74",
  };

  useEffect(() => {
    if (document.getElementsByName("ico_title")[0] !== undefined) {
      document.getElementsByName("ico_title")[0].click();
    }
  }, [visible]);

  function skrat_popis_typ_reklamace(typ_reklamace) {
    // eslint-disable-next-line default-case
    switch (typ_reklamace) {
      case "Reklamace (nedodané zboží neohrožující provoz / poškozený obal / rozdíl systémový vs. fyzický)":
        return "Reklamace";
      case "Nedodáno nedodané zboží ohrožující provoz":
        return "Nedodáno";
      case "Vrácení přepravek":
        return "Vrácení přepravek";
      case "Přeposlání zboží na jiné středisko":
        return "Přeposlání";
    }
  }

  function IsEmpty(text) {
    if (text === "" || text === null || text === undefined) return true;
    return false;
  }

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#F1B600" },
      },
    },
    csCZ
  );

  let columns = [
    {
      field: "nazov",
      headerName: "Nazev",
      headerAlign: "center",
      width: 300,
      renderCell: (params) => (
        <>
          <div className={styles.nazov}>
            <div className={styles.avatar2}>
              <img src={"/images/content/bageta2.png"} alt="Avatar" />
            </div>
            <div className={styles.nazov_kod}>
              <div>{params.row.nazov}</div>
              <div className={styles.znacka}>
                <div className={styles.znackaText}>{params.row.znacka}</div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    // {
    //   field: "mnozstvo",
    //   headerName: "Posláno",
    //   headerAlign: "center",
    //   type: "number",
    //   width: 70,
    // },
    {
      field: "prijate",
      headerName: "Množstvo",
      headerAlign: "center",
      type: "number",
      width: 150,
    },
    // {
    //   field: "komentar",
    //   headerName: "Komentář",
    //   headerAlign: "center",
    //   width: 290,
    // },
    {
      field: "typ_reklamacie",
      headerName: "Typ reklamace",
      headerAlign: "center",
      width: 180,
      renderCell: (params) => {
        return <div>{skrat_popis_typ_reklamace(params.value)}</div>;
      },
    },
    {
      field: "id_trellis",
      headerName: "Trellis tiket",
      headerAlign: "center",
      width: 180,
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return <></>;
        }
        return (
          <div
            className={styles.ticket}
            onClick={() => {
              openNewWindow(
                logindata,
                "http://erp-reklamace.iist.inver.sk/index.php?act=tickets&code=view&id=" +
                  params.value
              );
            }}
          >
            {IsEmpty(params.value) ? <></> : <i class="fa fa-file"></i>}
            {params.value}
          </div>
        );
      },
    },
  ];

  function back() {
    setVisible(false);
  }

  function clear_prijate() {
    dispatch(setComment(""));
    dispatch(loadingExternyDodavatelTable());
    dispatch(externyDodavatelTable([]));
    dispatch(loadingNakupTable());
    dispatch(nakupTable([]));
    dispatch(vybrate(false));
    dispatch(setDodaciList(""));
    dispatch(loadingPrijemOdJinePobockyTable());
    dispatch(prijemOdJinePobockyTable([]));
    dispatch(loadingZmena());
    dispatch(zmena([]));
    dispatch(setZaznamy([]));
    setZobrazTrellis(false);
    //setMyFiles([]);
  }

  return (
    <>
      <Modal
        widthModal={window.innerWidth > 780 ? 780 : window.innerWidth}
        outerClassName={styles.outerZaznamProblemu}
        visible={visible}
      >
        <LoadingOverlay
          active={layoutActive}
          className={styles.LoadingOverlay}
          spinner
          text={"Zápis tiketů do trellisu"}
        >
          <div style={{ width: "100%" }}>
            <div name={"ico_title"} className={styles.ico_Title}>
              <div>Záznamy Problémů</div>
              <button
                onClick={() => {
                  setVisible(false);
                }}
              >
                <Icon name="close" size="20" />
              </button>
            </div>
            <div
              style={{
                height: 580,
              }}
              className={styles.content}
              ref={elementRef}
              id="element"
            >
              <ThemeProvider theme={darkTheme}>
                <DataGridPremium
                  sx={stylingTable}
                  localeText={{
                    ...csCZ.components.MuiDataGrid.defaultProps.localeText,
                  }}
                  hideFooter
                  disableColumnPinning
                  disableColumnMenu
                  rowHeight={40}
                  columnHeaderHeight={50}
                  rows={data}
                  columns={columns}
                  defaultGroupingExpansionDepth={-1}
                  onRowGroupingModelChange={(model) =>
                    setRowGroupingModel(model)
                  }
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        nazov: false,
                      },
                    },
                    // pinnedColumns: {
                    //   left: ["__row_group_by_columns_group__"],
                    //},
                  }}
                  groupingColDef={{
                    width: 250,
                    leafField: "nazov",
                  }}
                  rowGroupingModel={rowGroupingModel}
                />
              </ThemeProvider>
            </div>
          </div>
          <div className={styles.saveArea}>
            <div className={styles.tre_pok}>
              <div
                onClick={() => {
                  back();
                }}
                className={styles.save}
              >
                Zpět
              </div>
            </div>
            <div className={styles.tre_pok}>
              {zobrazTrellis ? (
                <div
                  onClick={async () => {
                    var skonci_cyklus = false;
                    setLayoutActive(true);
                    const doklad = zmenadata.data.filter(
                      (doklad) => doklad.vybrane
                    );
                    let newData = [];
                    let counter = 0;
                    for (const pohyb of data) {
                      const formData = new FormData();
                      pohyb.files.map((file) => {
                        counter++;
                        formData.append("files", file);
                      });

                      if (skonci_cyklus) break;

                      await axios
                        .post(
                          trellisReklamacieUrl,
                          {
                            doklad: doklad[0].doklad,
                            dodavatel: doklad[0].stredisko,
                            stredisko:
                              logindata.UserData.vybrane_stredisko !== undefined
                                ? logindata.UserData.vybrane_stredisko.kod
                                : null,
                            username:
                              logindata.UserData.fakeusername !== undefined
                                ? logindata.UserData.fakeusername
                                : logindata.UserData.username,
                            pohyb: pohyb,
                            //files: formData,
                          },
                          {
                            headers: {
                              Authorization: "Bearer " + logindata.Token,
                            },
                          }
                        )
                        .then(async function (response) {
                          console.log(counter);
                          if (counter > 0) {
                            await axios
                              .post(
                                filesUploadUrl +
                                  "/" +
                                  logindata.UserData.trellis_username +
                                  "/" +
                                  response.data.toString(),
                                formData,
                                {
                                  headers: {
                                    Authorization: "Bearer " + logindata.Token,
                                  },
                                }
                              )
                              .then(function (response) {
                                //console.log("resp.", response.data);
                              })
                              .catch(function (error) {
                                errorHandleDialog(
                                  error.response,
                                  navigate,
                                  dispatch
                                );
                                skonci_cyklus = true;
                              });
                          }
                          var new_poh = {
                            ...pohyb,
                            id_trellis: response.data.toString(),
                          };
                          newData.push(new_poh);
                        })
                        .catch(function (error) {
                          console.log(error);
                          errorHandleDialog(error.response, navigate, dispatch);
                          dispatch(setZaznamy(data));
                          setLayoutActive(false);
                          return;
                        });
                    }
                    dispatch(setZaznamy(newData));
                    setLayoutActive(false);
                    setZobrazTrellis(false);
                  }}
                  className={styles.save}
                >
                  Vytvoření tiketů
                </div>
              ) : (
                <></>
              )}

              <div
                onClick={async () => {
                  const doklad = zmenadata.data.filter(
                    (doklad) => doklad.vybrane
                  );
                  //console.log(doklad);
                  //console.log()
                  if (doklad.length !== 0) {
                    await axios
                      .post(
                        prijemsUrl,
                        {
                          stredisko: logindata.UserData.vybrane_stredisko.kod,
                          pohyby: prijemOdJinePobockyTabledata.data,
                          zaznamy: prijemOdJinePobockyTabledata.zaznamy,
                          doklad: doklad[0].doklad,
                          dodavatel: doklad[0].stredisko,
                          poznamka: zmenadata.comment,
                        },
                        {
                          headers: {
                            Authorization: "Bearer " + logindata.Token,
                          },
                        }
                      )
                      .then(function (response) {
                        console.log(response.data);
                        if (response.data.html !== null) {
                          dispatch(
                            modal({
                              data: {
                                ...modaldata,
                                ZobrazHtml: true,
                                HtmlData: response.data.html,
                                ZobrazHtml_print: response.data.print,
                                ZobrazHtml_text: response.data.text,
                              },
                            })
                          );
                        }
                        if (
                          response.data.html === null &&
                          response.data.print !== null
                        ) {
                          swal({
                            title: response.data.text,
                            text: response.data.print
                              ? "Chcete zobrazit doklad?"
                              : "",
                            icon: "success",
                            buttons: response.data.print
                              ? ["Ne ", "Ano"]
                              : null,
                            dangerMode: true,
                          }).then(async (willShow) => {
                            if (willShow && response.data.print) {
                              console.log(response.data);

                              getPdf(
                                response.data.print,
                                logindata.Token,
                                dispatch,
                                navigate
                              );
                            }
                          });
                        }
                        clear_prijate();
                      })
                      .catch(function (error) {
                        console.log(error.response.data);
                        if (error.response.status === 400) {
                          swal("Chyba!", error.response.data, "error");
                        } else if (error.response.status === 401) {
                          navigate.push("/");
                          //dispatch(login({ data: {} }));
                          window.sessionStorage.setItem(
                            "logindata",
                            JSON.stringify({})
                          );
                        } else if (
                          error.response.status > 401 &&
                          error.response.status < 500
                        ) {
                          swal(
                            "Chyba!",
                            "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
                            "error"
                          );
                        } else {
                          swal(
                            "Chyba!",
                            " Nastala chyba na serveri ERP, zkuste to později.",
                            "error"
                          );
                        }
                      });
                  }
                  setVisible(false);
                }}
                className={styles.save}
              >
                Pokračovat
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </Modal>
      <ToastContainer />
    </>
  );
};
export default App;
