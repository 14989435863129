import React, { useState } from "react";
import cn from "classnames";
import styles from "./Detail.module.sass";
import Icon from "../Icon";
import { useDispatch, useSelector } from "react-redux";
import { login, loginData } from "../../screens/SignIn/redux/loginSlice";
import { modal, modalData } from "../Modaly/redux/modalSlice";
import axios from "axios";
import { paneldefUrl } from "../../features/urlHelper";
import { loadingPanel, Panel } from "../Modaly/ModalPanel/redux/PanelSlice";
import swal from "sweetalert";
import { useHistory } from "react-router";
import ModalPanel from "../Modaly/ModalPanel";
import { errorHandleDialog } from "./../../features/errorDialog";

const App = ({
  className,
  title,
  classTitle,
  classCardHead,
  head,
  children,
  disabled,
  dataset,
  idFieldName,
  idFieldValue,
  paginationData,
  setPaginationData,
  rowSelectionModel,
  setRowSelectionModel,
  visiblePanel,
  setvisiblePanel,
  volba,
  setVolba,
}) => {
  const dispatch = useDispatch();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const modaldata = useSelector(modalData);
  const navigate = useHistory();

  return (
    <>
      <div
        onClick={async () => {
          if (disabled) {
            await axios
              .post(
                paneldefUrl,
                {
                  dataset: dataset,
                  stredisko:
                    logindata.UserData.vybrane_stredisko !== undefined
                      ? logindata.UserData.vybrane_stredisko.kod
                      : null,
                  idFieldValue: idFieldValue,
                  idFieldName: idFieldName,
                  volba: "edit",
                },
                {
                  headers: { Authorization: "Bearer " + logindata.Token },
                }
              )
              .then(function (response) {
                console.log(response.data);
                dispatch(loadingPanel());
                dispatch(Panel(response.data));
                setvisiblePanel(true);
                setVolba("edit");
                document
                  .getElementsByName(
                    response.data.values[Object.keys(response.data.values)[0]]
                      .id
                  )[0]
                  .focus();
              })
              .catch(function (error) {
                errorHandleDialog(error.response, navigate, dispatch);
              });
          }
        }}
        className={!disabled ? styles.iconDis : styles.icon}
      >
        <Icon name={"edit"} size="24" />
      </div>

      <ModalPanel
        visible={visiblePanel}
        setVisible={setvisiblePanel}
        className={styles}
        dataset={dataset}
        volba={volba}
        setVolba={setVolba}
        paginationData={paginationData}
        datasetId={idFieldValue}
        setPaginationData={setPaginationData}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        idFieldName={idFieldName}
      />
    </>
  );
};

export default App;
