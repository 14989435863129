import { createSlice } from '@reduxjs/toolkit';

export const pickerObjednavky_typERP_BBSlice = createSlice({
    name: 'pickerObjednavky_typERP_BB',
    initialState: {
        value: "Všechno",
    },
    reducers: {
        pickerObjednavky_typERP_BB: (state, action) => {
            state.value = action.payload.data
        },

    },
});

export const { pickerObjednavky_typERP_BB } = pickerObjednavky_typERP_BBSlice.actions;

export const pickerObjednavky_typERP_BBData = state => state.pickerObjednavky_typERP_BBdata.value;


export default pickerObjednavky_typERP_BBSlice.reducer;
