import React, { useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Item from "./Item";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";


const intervals = ["All time", "In a year", "Per month"];

const nav = [
    {
        title: "Udělat",
        counter: "-",
    },
    {
        title: "Hotové",
        counter: "-",
    },

];

const ukoly = [
    {
        title: "Příjem Surovin",
        downTitle: "Žiželice • 50 surovin",
    },
    {
        title: "Příjem Surovin",
        downTitle: "Vodičkova • 5 surovin",
    },
    {
        title: "Směnárna",
        downTitle: "3 nabízené produkty",
    },
    {
        title: "Objednat suroviny",
        downTitle: "Do 10:00",
    },
    {
        title: "Velká Inventura",
        downTitle: "18/112",
    },
    {
        title: "Zavřít směnu",
        downTitle: "Nehotové",
    },
    {
        title: "Odpis surovin",
        downTitle: "Nehotové",
    },
    {
        title: "Plán směny, produktivity, třžby",
        downTitle: "Nehotové",
    },

];

const Overview = ({ className }) => {
    const [sorting, setSorting] = useState(intervals[0]);
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <Card
        bezPaddingu={false}
            className={cn(styles.card, className)}
            title="Úkoly"
            classTitle="title-red"

        >
            <div className={styles.overview}>
                <div className={styles.nav}>
                    <Item
                        className={cn(styles.item, {
                            [styles.active]: 0 === activeIndex,
                        })}

                        onActive={() => setActiveIndex(0)}
                        item={nav[0]}
                        image={"/images/content/exclamation.png"}
                        color={"#eafae5"}
                    />
                    <Item
                        className={cn(styles.item, {
                            [styles.active]: 1 === activeIndex,
                        })}

                        onActive={() => setActiveIndex(1)}
                        item={nav[1]}
                        image={"/images/content/select.png"}
                        color={"#ffbc99"}
                    />
                </div>
                <div className={styles.body}>

                    {
                        ukoly.map((xyz, index) => (
                            <div key={index} >
                                <div  className={styles.items}>
                                    <div className={cn(styles.avatar)}>
                                        <img src={"/images/content/kosik.png"} alt="Avatar" />
                                    </div>
                                    <div className={styles.upperContentRight}>
                                        <span style={{ fontWeight: 'bold' }}>{xyz.title} </span><br /><span style={{ color: 'gray' }}>{xyz.downTitle}</span>
                                    </div>

                                </div>
                                <br />
                            </div>
                        ))
                    }


                </div>
            </div>
        </Card>
    );
};

export default Overview;
