import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { login } from "../../screens/SignIn/redux/loginSlice";

export default function App() {
  const [state, setState] = useState("Active");
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const navigate = useHistory();
  const dispatch = useDispatch();

  const onIdle = () => {
    setState("Idle");
    navigate.push("/");
    //dispatch(login({ data: {} }));
    window.sessionStorage.setItem("logindata", JSON.stringify({}))
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 600_000 * 15, //15 minut
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return <></>;
}
