import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        value: {},
    },
    reducers: {
        login: (state, action) => {
            console.log(action.payload.data)
            state.value = action.payload.data

        },

    },
});

export const { login } = loginSlice.actions;

export const loginData = state => state.logindata.value;


export default loginSlice.reducer;
