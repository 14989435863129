import Icon from "../../../Icon";
import styles from "./../Modal.module.sass";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modal, modalData } from "./../../redux/modalSlice";
import cn from "classnames";
import { ClickAwayListener } from "@material-ui/core";

const App = ({ className, index, dayOfWeek, title }) => {
  const dispatch = useDispatch();

  const modaldata = useSelector(modalData);
  const [visibleSetTime, setVisibleSetTime] = useState(false);

  const [indexClick, setIndexClick] = useState(0);
  const [minute, setMinute] = useState(null);
  const [hour, setHour] = useState(null);

  const hours = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];

  const minutes = ["00", "15", "30", "45"];

  function getHourAndMinute(time) {
    setHour(time.slice(0, 2));
    setMinute(time.slice(3, 5));
  }

  function addZero(num) {
    if (num.length === 1) {
      return "0" + num;
    } else {
      return num;
    }
  }

  function timeOrDots(time) {
    if (time !== null) {
      return time;
    } else {
      return "--:--";
    }
  }

  function plusHour(timeIn) {
    if (timeIn !== null) {
      let editHour = parseInt(timeIn.slice(0, 2)) + 1;
      if (editHour > 23) {
        editHour = 0; 
      }
      let time = addZero(editHour.toString()) + ":" + timeIn.slice(3, 5);

      let newData = { ...modaldata };

      let newDataH = {
        ...newData.TrvalaZmenaDate,
      };
      let newDataArray = [...newDataH.dni];
      let newDataArrayObj = {
        ...newDataArray[index],
      };
      newDataArrayObj[title] = time;
      newDataArray[index] = newDataArrayObj;
      newDataH.dni = newDataArray;
      newDataH.datum_od = modaldata.TrvalaZmenaDate.datum_od;
      newData.TrvalaZmenaDate = newDataH;

      console.log(newData);
      dispatch(
        modal({
          data: newData,
        })
      );
    }
  }

  function minusHour(timeIn) {
    if (timeIn !== null) {
      let editHour = parseInt(timeIn.slice(0, 2)) - 1;
      if (editHour < 0) {
        editHour = 23;
      }
      let time = addZero(editHour.toString()) + ":" + timeIn.slice(3, 5);

      let newData = { ...modaldata };

      let newDataH = {
        ...newData.TrvalaZmenaDate,
      };
      let newDataArray = [...newDataH.dni];
      let newDataArrayObj = {
        ...newDataArray[index],
      };
      newDataArrayObj[title] = time;
      newDataArray[index] = newDataArrayObj;
      newDataH.dni = newDataArray;
      newDataH.datum_od = modaldata.TrvalaZmenaDate.datum_od;
      newData.TrvalaZmenaDate = newDataH;

      console.log(newData);
      dispatch(
        modal({
          data: newData,
        })
      );
    }
  }

  return (
    <>
      <div>
        <div className={styles.timeLine}>
          <div
            onClick={() => {
              plusHour(modaldata.TrvalaZmenaDate.dni[index][title]);
            }}
            className={styles.punctuation}
          >
            +
          </div>
          <div
            onClick={(e) => {
              getHourAndMinute(e.target.innerHTML);
              console.log(e.target);
              setVisibleSetTime(true);
              setIndexClick(index + 1);
            }}
            className={styles.time}
          >
            {timeOrDots(modaldata.TrvalaZmenaDate.dni[index][title])}
          </div>
          <div
            onClick={() => {
              minusHour(modaldata.TrvalaZmenaDate.dni[index][title]);
            }}
            className={styles.punctuation}
          >
            -
          </div>
        </div>
      </div>
      <ClickAwayListener
        onClickAway={() => {
          setVisibleSetTime(false);
        }}
      >
        <div
          className={cn(styles.setTime, className, {
            [styles.active]: visibleSetTime && indexClick === dayOfWeek,
          })}
        >
          <div className={styles.body}>
            <div className={styles.ico2}>
              <button
                className={styles.icon}
                onClick={() => {
                  setVisibleSetTime(false);
                }}
              >
                <Icon name="close" size="20" />
              </button>
            </div>
            <div className={styles.timePicker}>
              <div className={styles.hour}>{hour}</div>
              <div className={styles.minute}>{minute}</div>
            </div>
            <div className={styles.timePicker}>
              <div id="scrollDemo" className={styles.hours}>
                {hours.map((hodina) => {
                  return (
                    <div
                      onClick={() => {
                        setHour(hodina);
                      }}
                      className={styles.h}
                    >
                      {hodina === hour ? (
                        <div className={styles.colorText}>{hodina}</div>
                      ) : (
                        <div>{hodina}</div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className={styles.minutes}>
                {minutes.map((minuta) => {
                  return (
                    <div
                      onClick={() => {
                        setMinute(minuta);
                      }}
                      className={styles.m}
                    >
                      {minuta === minute ? (
                        <div className={styles.colorText}>{minuta}</div>
                      ) : (
                        <div>{minuta}</div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.buttonArea}>
              <button
                onClick={() => {
                  if (hour !== "--" && minute !== "--") {
                    let newData = { ...modaldata };

                    let newDataH = {
                      ...newData.TrvalaZmenaDate,
                    };
                    let newDataArray = [...newDataH.dni];
                    let newDataArrayObj = {
                      ...newDataArray[index],
                    };
                    newDataArrayObj[title] = hour + ":" + minute;
                    newDataArray[index] = newDataArrayObj;
                    newDataH.dni = newDataArray;
                    newDataH.datum_od = modaldata.TrvalaZmenaDate.datum_od;
                    newData.TrvalaZmenaDate = newDataH;

                    console.log(newData);
                    dispatch(
                      modal({
                        data: newData,
                      })
                    );
                    setVisibleSetTime(false);
                  }
                }}
                className={styles.buttonTime}
              >
                Potvrdit
              </button>
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </>
  );
};
export default App;
