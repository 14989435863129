import { createSlice } from '@reduxjs/toolkit';

export const tableNakupSlice = createSlice({
    name: 'nakupTable',
    initialState: {
        data: [],
        loading: true,
    },
    reducers: {
        nakupTable: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingNakupTable: (state) => {
            console.log("loading: true")
            state.loading = true
        }

    },
});

export const { nakupTable, loadingNakupTable } = tableNakupSlice.actions;

export const nakupTableData = state => state.nakupdata;


export default tableNakupSlice.reducer;
