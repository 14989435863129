import Card from "../../../components/Card";
import React, { useEffect, useState } from "react";
import EvidenceTableUniversal from "../../../components/EvidenceTableUniversal";

const App = ({ className }) => {
  return (
    <EvidenceTableUniversal
      dataset={"dcen_prc"}
      backStep={"/dodavatele&smlouvy"}
    />
  );
};
export default App;
