import React, { useState } from "react";
import Detail from "../Detail";
import Delete from "../Delete";
import Edit from "../Edit";
import cn from "classnames";
import styles from "./Style.module.sass";
import Icon from "../Icon";
import { useDispatch, useSelector } from "react-redux";
import { login, loginData } from "../../screens/SignIn/redux/loginSlice";
import { modal, modalData } from "../Modaly/redux/modalSlice";
import axios from "axios";
import { loadingPanel, Panel } from "../Modaly/ModalPanel/redux/PanelSlice";
import swal from "sweetalert";
import { useHistory } from "react-router";
import ModalPanel from "../Modaly/ModalPanel";
import { errorHandleDialog } from "../../features/errorDialog";
import Modal from "../Modal";
import { ClickAwayListener } from "@material-ui/core";

const App = ({
  className,
  title,
  classTitle,
  classCardHead,
  head,
  children,
  disabled_del,
  disabled_edi,
  dataset,
  idFieldName,
  idFieldValue,
  paginationData,
  setPaginationData,
  rowSelectionModel,
  setRowSelectionModel,
  visiblePanel,
  setvisiblePanel,
  volba,
  setVolba,
}) => {
  const [visible, setVisible] = useState(false);
  function modal() {
    return (
      <div
        style={{ visibility: visible ? "visible" : "hidden" }}
        className={styles.modal}
      >
        <div className={styles.volba}>
          <Detail
            dataset={dataset}
            idFieldValue={idFieldValue}
            idFieldName={idFieldName}
            //disabled={disabled}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
            volba={volba}
          />
        </div>
        <div className={styles.volba}>
          <Edit
            dataset={dataset}
            idFieldValue={idFieldValue}
            idFieldName={idFieldName}
            disabled={disabled_edi}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
            visiblePanel={visiblePanel}
            setvisiblePanel={setvisiblePanel}
            volba={volba}
            setVolba={setVolba}

          />
        </div>
        <div className={styles.volba}>
          <Delete
            dataset={dataset}
            idFieldValue={idFieldValue}
            idFieldName={idFieldName}
            disabled={disabled_del}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
            visiblePanel={visiblePanel}
            setvisiblePanel={setvisiblePanel}
            volba={volba}
            setVolba={setVolba}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setVisible(false);
        }}
      >
        <div
          onClick={() => {
            setVisible(true);
          }}
          className={styles.icon}
        >
          <i class="fa fa-bars" style={{ fontSize: 20 }}></i>
          {modal()}
        </div>
      </ClickAwayListener>
    </>
  );
};

export default App;
