import { createSlice } from '@reduxjs/toolkit';

export const overviewSlice = createSlice({
    name: 'overview',
    initialState: {
        data: [],
        loading: true,
    },
    reducers: {
        overview: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingOverview: (state) => {
            console.log("loading: true")
            state.loading = true
        }
    },
});

export const { overview, loadingOverview } = overviewSlice.actions;

export const overviewData = state => state.overviewdata;


export default overviewSlice.reducer;

