import Modal from "../../components/Modal";
import Card from "../../components/Card";
import Icon from "../../components/Icon";
import styles from "./ProchazeniSestav.module.sass";
import cn from "classnames";
import Search from "../../components/Search";
import Table from "./Table";
import React, { useState } from "react";

const App = ({ className }) => {
  return (
    <div>
      <Card
      bezPaddingu={false}
        className={cn(styles.card, className)}
        title={"Prohlížení již vytvořených sestav"}
        classTitle="title-green"
      >
        <Search field={"searchMalaInventura"} className={styles.searchHead} />
        <br />
        <div className={cn(styles.row)}>
          <Table className={styles.table} />
        </div>
      </Card>
    </div>
  );
};
export default App;
