import React, { useState } from "react";
import cn from "classnames";
import styles from "./Detail.module.sass";
import Icon from "../Icon";
import { useDispatch, useSelector } from "react-redux";
import { modal, modalData } from "../Modaly/redux/modalSlice";
import axios from "axios";
import { paneldefUrl } from "../../features/urlHelper";
import swal from "sweetalert";
import { login, loginData } from "../../screens/SignIn/redux/loginSlice";
import { useHistory } from "react-router";
import { loadingPanel, Panel } from "../Modaly/ModalPanel/redux/PanelSlice";
import ModalPanelDetail from "../Modaly/ModalPanelDetail";
import { errorHandleDialog } from "../../features/errorDialog";
import { paginationClasses } from "@mui/material";
import Modal from "../Modal";
import {
  PanelDetail,
  loadingPanelDetail,
} from "../Modaly/ModalPanelDetail/redux/PanelDetailSlice";

const App = ({
  dataset,
  idFieldValue,
  idFieldName,
  paginationData,
  setPaginationData,
  rowSelectionModel,
  setRowSelectionModel,
  volba,
}) => {
  const [visiblePanel, setvisiblePanel] = useState(false);
  const dispatch = useDispatch();
  const navigate = useHistory();
  const modaldata = useSelector(modalData);
  // = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");

  return (
    <>
      <div
        onClick={async () => {
          console.log(idFieldValue, idFieldName);
          await axios
            .post(
              paneldefUrl,
              {
                dataset: dataset,
                stredisko:
                  logindata.UserData.vybrane_stredisko !== undefined
                    ? logindata.UserData.vybrane_stredisko.kod
                    : null,
                idFieldValue: idFieldValue,
                idFieldName: idFieldName,
                volba: "view",
              },
              {
                headers: { Authorization: "Bearer " + logindata.Token },
              }
            )
            .then(function (response) {
              console.log("detail", response)
              setvisiblePanel(true);
              dispatch(loadingPanelDetail());
              dispatch(PanelDetail(response.data));
            })
            .catch(function (error) {
              console.log(error);
              errorHandleDialog(error.response, navigate, dispatch);
            });
        }}
        className={styles.icon}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M19 9C19 9.55229 19.4477 10 20 10C20.5523 10 21 9.55229 21 9V5C21 3.89543 20.1046 3 19 3H15C14.4477 3 14 3.44772 14 4C14 4.55228 14.4477 5 15 5L17.5858 5L11.2929 11.2929C10.9024 11.6834 10.9024 12.3166 11.2929 12.7071C11.6834 13.0976 12.3166 13.0976 12.7071 12.7071L19 6.41421V9Z" />
          <path d="M3 6C3 4.34315 4.34315 3 6 3H11C11.5523 3 12 3.44772 12 4C12 4.55228 11.5523 5 11 5H6C5.44772 5 5 5.44772 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V13C19 12.4477 19.4477 12 20 12C20.5523 12 21 12.4477 21 13V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6Z" />
        </svg>
      </div>
      <ModalPanelDetail
        visible={visiblePanel}
        setVisible={setvisiblePanel}
        className={styles}
        dataset={dataset}
        volba={"view"}
        datasetId={idFieldValue}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        idFieldName={idFieldName}
      />
    </>
  );
};

export default App;
