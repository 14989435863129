import axios from "axios";
import swal from "sweetalert";
import { login } from "../screens/SignIn/redux/loginSlice";
import { pdfUrl } from "./urlHelper";



export async function getPdf(guid, token, dispatch, navigate) {

  //window.open(pdfUrl + guid, '_blank', 'noreferrer');
  try {
    await axios
      .get(pdfUrl + guid, {
        headers: { Authorization: "Bearer " + token },
        responseType: "blob",
      })
      .then(async (response) => {
        //console.log(response.data)
        // dispatch(modal({ data: { ...modaldata, ZobrazPdf: true, PdfData: response.data  } }));

        const file = new Blob([response.data], { type: "application/pdf" });
        console.log("file: ", file);
        const fileURL = URL.createObjectURL(file);
        console.log("fileURL: ", fileURL);
        const pdfWindow = await window.open("");
        console.log("pdfWindow,: ", pdfWindow);
        pdfWindow.location.href = fileURL;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          swal("Chyba!", "Document not exist" , "error");
        } else if (error.response.status === 401) {
          navigate.push("/");
          //dispatch(login({ data: {} }));
          window.sessionStorage.setItem("logindata", JSON.stringify({}))
        } else if (error.response.status > 401 && error.response.status < 500) {
          swal(
            "Chyba!",
            "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
            "error"
          );
        } else {
          swal(
            "Chyba!",
            " Nastala chyba na serveri ERP, zkuste to později.",
            "error"
          );
        }
      });
  } catch (error) {
    return { error };
  }
}
