import { createSlice } from '@reduxjs/toolkit';

export const tableObjednavkyOstatneObjednavkaSlice = createSlice({
    name: 'objednavkyOstatneObjednavkaTable',
    initialState: {
        data: [],
        loading: true,
        title: "Obejdnávka",
        button: "Odeslat objednávku",
        date: null,
        volba: "",
        cis_obj: ""
    },
    reducers: {
        objednavkyOstatneObjednavkaTable: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingObjednavkyOstatneObjednavkaTable: (state) => {
            console.log("loading: true")
            state.loading = true
        },
        setButton: (state, action) => {
            state.button = action.payload
        },
        setTitle: (state, action) => {
            state.title = action.payload
        },
        setCis_obj: (state, action) => {
            state.cis_obj = action.payload
        },
        setDate: (state, action) => {
            state.date = action.payload
        },
        setVolba: (state, action) => {
            state.volba = action.payload
        }

    },
});

export const { objednavkyOstatneObjednavkaTable, loadingObjednavkyOstatneObjednavkaTable, setButton, setTitle, setCis_obj, setDate, setVolba} = tableObjednavkyOstatneObjednavkaSlice.actions;

export const objednavkyOstatneObjednavkaTableData = state => state.objednavkyOstatneObjednavkadata;


export default tableObjednavkyOstatneObjednavkaSlice.reducer;
