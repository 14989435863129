import React, { useState, useEffect } from "react";
import styles from "./Sklad.module.sass";
import Card from "../../components/Card";
import { getLicensePremium } from "../../features/License";
import { DataGridPremium, csCZ } from "@mui/x-data-grid-premium";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import Search from "../../components/Search";
import { objednavkyUrl } from "../../features/urlHelper";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { login, loginData } from "../SignIn/redux/loginSlice";
import Dropdown from "./Dropdown";
import DDTagy from "./DDTagy";
import useDarkMode from "@fisch0920/use-dark-mode";
import { searchData } from "../../components/Search/redux/searchSlice";
import remove_accents from "remove-accents";
import ContentLoader from "react-content-loader";
import { pickerObjednavkyData } from "./Dropdown/redux/PickerObjednavkySlice";
import { modalData, modal } from "../../components/Modaly/redux/modalSlice";
import { pickerTagy, pickerTagyData } from "./DDTagy/redux/PickerTagySlice";
import {
  objednavkyData,
  objednavkyRows,
  objednavkyAllRows,
} from "./redux/objednavkySlice";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { errorHandleDialog } from "../../features/errorDialog";

const App = () => {
  const darkMode = useDarkMode(false);
  const navigate = useHistory();
  const dispatch = useDispatch();
  const searchdata = useSelector(searchData);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const pickerObjednavkydata = useSelector(pickerObjednavkyData);
  const modaldata = useSelector(modalData);
  const pickerTagydata = useSelector(pickerTagyData);
  const objednavkydata = useSelector(objednavkyData);

  const [columns, setColumns] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("...");
  const [tagy, setTagy] = useState([]);

  const [rowGroupingModel, setRowGroupingModel] = React.useState(["skupina"]);

  const styling = {
    border: 0,
    "& .MuiDataGrid-cell, .MuiDataGrid-columnHeader": {
      padding: 0,
    },
    "& .MuiDataGrid-row Mui-hovered": {
      backgroundColor: "red",
    },
    "& .weekend6_help_nadpis": {
      padding: 1,
      backgroundColor: darkMode.value ? "#272b30" : "#f4f4f4",
    },
    "& .weekend6_help_cell": {
      backgroundColor: darkMode.value ? "#272b30" : "#f4f4f4",
    },

    "& .MuiDataGrid-overlayWrapperInner": {
      display: "none",
    },
    height: window.innerHeight > 500 ? window.innerHeight - 290 : 500,
    MuiTableRow: {
      root: {
        "&$selected": {
          backgroundColor: "yellow",
        },
      },
    },
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      color: darkMode.value ? "white" : "#666d74",
      borderRight: `1px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },

    "& .MuiSvgIcon-root": {
      color: "#a7acaf",
    },

    "& .MuiDataGrid-cell": {
      padding: 0,
    },
    "& .MuiDataGrid-overlay": {
      display: "none",
    },
    "& .weekend6": {
      backgroundColor: darkMode.value ? "#272b30" : "#f4f4f4",
    },

    "& .MuiDataGrid-pinnedColumns": {
      backgroundColor: darkMode.value ? "#1a1d1f" : "#fcfcfc",
      borderRight: `0px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .weekend7": {
      backgroundColor: darkMode.value ? "#272b30" : "#f4f4f4",
      borderRight: `5px solid ${darkMode.value ? "white" : "#888888"}`,
    },
    width: "100%",
    justifyContent: "center",
  };

  const theme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
    },
  });

  useEffect(() => {
    filter(objednavkydata.allRows, pickerTagydata.tagy);
  }, [searchdata.searchObjednavky]);

  useEffect(() => {
    filter(objednavkydata.allRows, pickerTagydata.tagy);
  }, [pickerObjednavkydata]);

  useEffect(() => {
    filter(objednavkydata.allRows, pickerTagydata.tagy);
  }, [pickerTagydata]);

  useEffect(() => {
    setLoading(true);
    result();
  }, []);

  async function result() {
    await axios
      .get(objednavkyUrl + logindata.UserData.vybrane_stredisko.kod, {
        headers: { Authorization: "Bearer " + logindata.Token },
      })
      .then(function (response) {
        console.log("totot potrebujem vidiet", response.data);
        let tagy = response.data.tagy.map((row) => ({
          ...row,
          vybrane: false,
        }));

        dispatch(pickerTagy(tagy));
        dispatch(objednavkyAllRows(response.data.riadky));
        filter(response.data.riadky, tagy);
        createColumns(response.data);
        let skupiny = [];
        skupiny.push("Všechno");
        response.data.riadky.map((row) => {
          if (row.skupina !== skupiny[skupiny.length - 1]) {
            skupiny.push(row.skupina);
          }
        });
        setOptions(skupiny);
        setLoading(false);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  function arraysEqualF(array1, array2) {
    let found = false;
    let array1_update = array1 === undefined ? [] : array1;
    array2.map((tag) => {
      if (tag.vybrane) found = true;
    });
    if (!found) {
      return true;
    }
    for (let i = 0; i < array1_update.length; i++) {
      found = false;
      for (let j = 0; j < array2.length; j++) {
        if (array1_update[i].id === array2[j].id && array2[j].vybrane) {
          found = true;
          break;
        }
      }
      if (found) {
        return true;
      }
    }
    return false;
  }

  function filter(data, tagy) {
    let newRows = [];
    if (pickerObjednavkydata === "Všechno") {
      data.map((row) => {
        if (
          (String(remove_accents(row.nazov.toUpperCase())).includes(
            remove_accents(searchdata.searchObjednavky.toUpperCase())
          ) ||
            String(remove_accents(row.znacka.toUpperCase())).includes(
              remove_accents(searchdata.searchObjednavky.toUpperCase())
            )) &&
          arraysEqualF(row.tagy, tagy)
        ) {
          newRows.push(row);
        }
      });
      console.log("newRows", newRows);
      dispatch(objednavkyRows(newRows));
      return;
    }

    data.map((row) => {
      if (
        (String(remove_accents(row.nazov.toUpperCase())).includes(
          remove_accents(searchdata.searchObjednavky.toUpperCase())
        ) ||
          String(remove_accents(row.znacka.toUpperCase())).includes(
            remove_accents(searchdata.searchObjednavky.toUpperCase())
          )) &&
        pickerObjednavkydata === row.skupina &&
        arraysEqualF(row.tagy, tagy)
      ) {
        newRows.push(row);
      }
    });
    console.log("newRows", newRows);
    dispatch(objednavkyRows(newRows));
  }

  function createColumns(data) {
    let newColumns = [];
    newColumns.push({
      field: "skupina",
      headerName: "Skupina",
      width: 180,
      groupable: true,
      sortable: false,
    });
    newColumns.push({
      field: "nazov",
      headerName: "Položky",
      width: 300,
      groupable: false,
      headerClassName: "weekend6",
      cellClassName: "weekend6",
      renderHeader: () => (
        <div className={styles.nazov}>
          <div className={styles.nazovTitle}>Položky</div>
          <div className={styles.pokladna}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              height="1.15em"
              width="1.15em"
            >
              <path d="M288 0c17.7 0 32 14.33 32 32v64c0 17.7-14.3 32-32 32h-80v32h216.1c32.5 0 59.4 23.1 64.1 54.4l22.7 149.7c.7 4.7 1.1 9.5 1.1 14.3V448c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64v-69.6c0-4.8.362-9.6 1.083-14.3L23.76 214.4C28.5 183.1 55.39 160 87.03 160h56.07v-32h-80c-16.77 0-32-14.3-32-32V32c0-17.67 15.23-32 32-32H288zM96 48c-8.84 0-16 7.16-16 16s7.16 16 16 16h160c8.8 0 16-7.16 16-16s-7.2-16-16-16H96zM80 448h352c8.8 0 16-7.2 16-16s-7.2-16-16-16H80c-8.84 0-16 7.2-16 16s7.16 16 16 16zm32-232c-13.25 0-24 10.7-24 24s10.75 24 24 24c13.3 0 24-10.7 24-24s-10.7-24-24-24zm96 48c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm-48 32c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm144-32c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm-48 32c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm144-32c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm-48 32c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24z" />
            </svg>
          </div>
        </div>
      ),
      renderCell: (params) => (
        <>
          {params.row.stlpce ? (
            <div className={styles.nazov}>
              <div className={styles.avatar}>
                <img src={"/images/content/bageta2.png"} alt="Avatar" />
              </div>
              <div className={styles.nazov_kod}>
                <div>{params.row.nazovgrid}</div>
                <div className={styles.znacka}>
                  <div className={styles.znackaText}>{params.row.znacka}</div>
                  <button
                    onClick={() => {
                      dispatch(
                        modal({
                          data: {
                            ...modaldata,
                            EditTagovPolozkyID: params.row.id,
                            EditTagovPolozky: true,
                            EditTagovPolozkyType: "C"
                          },
                        })
                      );
                    }}
                    className={styles.addButton}
                  >
                    <div className={styles.plus}>+</div>{" "}
                  </button>
                  <div className={styles.tagy}>
                    {params.row.tagy === undefined
                      ? []
                      : params.row.tagy.map((tag, index) => {
                          if (index <= 3) {
                            return (
                              <div
                                title={tag.text}
                                style={{
                                  color:
                                    tag.farba === "yellow" ? "black" : "white",
                                  width: 20,
                                  height: 20,
                                  borderRadius: 4,
                                  backgroundColor: tag.farba,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  marginLeft: 5,
                                }}
                              >
                                {tag.text !== undefined
                                  ? tag.text.slice(0, 1) + "."
                                  : ""}
                              </div>
                            );
                          }
                        })}
                  </div>
                </div>
              </div>
              <div className={styles.S_O}>
                <div title="Spotřeba">S:</div>
                <div title="Objednáno">O:</div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
        //console.log(params)
      ),
    });
    data.hlavicka.map((head) => {
      newColumns.push({
        field: head.datum,
        sortable: false,
        groupable: false,
        headerClassName:
          head.den === 6 || head.den == 7
            ? head.den === 6
              ? "weekend6"
              : "weekend7"
            : "week",
        cellClassName:
          head.den === 6 || head.den == 7
            ? head.den === 6
              ? "weekend6"
              : "weekend7"
            : "week",
        renderHeader: () => (
          <div className={styles.headerTable}>
            <div className={styles.headerTable_datum}>{head.s_datum}</div>
            <div className={styles.headerTable_trzba}>
              {head.trzba.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, "$1 ")}
            </div>
            <div className={styles.headerTable_datum}>
              {head.trzba % 2 === 0 ? (
                <button className={styles.hotovo}>Hotovo</button>
              ) : (
                <button className={styles.nova}>Nová</button>
              )}
            </div>
          </div>
        ),
        width: 70,
        renderCell: (params) => (
          <>
            {params.row.stlpce &&
              params.row.stlpce.map((stlpec, index) => {
                if (stlpec.datum === head.datum) {
                  return (
                    <>
                      <div
                        name={stlpec.znacka + stlpec.datum + "cell"}
                        title={stlpec.tooltip}
                        key={index}
                        className={styles.cell}
                        onClick={() => {
                          const name = stlpec.znacka + stlpec.datum;
                          document.getElementsByName(name)[0].focus();
                          document.getElementsByName(name)[0].select();
                        }}
                      >
                        <div>
                          <div className={styles.text}>
                            {stlpec.spotreba
                              .toFixed(0)
                              .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ")}
                          </div>
                          <div className={styles.blby_text}>
                            <input
                              name={stlpec.znacka + stlpec.datum}
                              value={
                                stlpec.objednane !== undefined
                                  ? stlpec.objednane
                                      .toFixed(0)
                                      .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ")
                                  : null
                              }
                              className={
                                stlpec.farba === null
                                  ? styles.text
                                  : styles["text" + stlpec.farba]
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
          </>
        ),
      });
    });
    console.log(newColumns);
    setColumns(newColumns);
  }

  LicenseInfo.setLicenseKey(getLicensePremium());
  return (
    <Card bezPaddingu={false}>
      {loading ? (
        <ContentLoader
          speed={2}
          width={"100%"}
          height={window.innerHeight - 230}
          backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
          foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
        >
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((i) =>
            [0, 1, 2, 3, 4, 5, 6].map((j) => (
              <rect
                key={j}
                x={0}
                y={2 + i * (window.innerHeight / 20)}
                rx="3"
                ry="3"
                width={"100%"}
                height={40}
              />
            ))
          )}
        </ContentLoader>
      ) : (
        <div className={styles.Sklad}>
          {window.innerWidth < 600 ? (
            <Card bezPaddingu={false}>
              <div
                style={{ height: window.innerHeight - 230 }}
                className={styles.mobile}
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-device-mobile-rotated"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    height="5em"
                    width="5em"
                  >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <rect x="3" y="6" width="18" height="12" rx="2" />
                    <path d="M20 11v2M7 12h-.01" />
                  </svg>
                </div>

                <div>Otočte si telefon na šířku.</div>
              </div>
            </Card>
          ) : (
            <Card bezPaddingu={false}>
              <div className={styles.cardHead}>
                <div className={styles.search}>
                  <Search field={"searchObjednavky"}></Search>
                </div>
                <div className={styles.dd}>
                  <div className={styles.dditem}>
                    <div className={styles.ddtitle}>Skupina: </div>

                    <Dropdown
                      title=""
                      className={styles.dropdown}
                      classDropdownHead={styles.dropdownHead}
                      options={options}
                    />
                  </div>
                  <div className={styles.dditem}>
                    <div className={styles.ddtitle}>Tagy: </div>

                    <DDTagy
                      title=""
                      className={styles.icon}
                      classDropdownHead={styles.dropdownHead}
                      options={pickerTagydata.tagy}
                      value={value}
                      setValue={setValue}
                    />
                  </div>
                </div>
              </div>

              <br />
              <div className={styles.zoomout}>
                <ThemeProvider theme={theme}>
                  <DataGridPremium
                    sx={styling}
                    localeText={
                      csCZ.components.MuiDataGrid.defaultProps.localeText
                    }
                    {...{ rows: objednavkydata.rows, columns: columns }} //Dano: toto zakomentuj
                    disableRowSelectionOnClick
                    disableAutoFocus
                    disableColumnMenu
                    defaultGroupingExpansionDepth={-1}
                    hideFooter
                    rowHeight={50}
                    rowGroupingModel={rowGroupingModel}
                    onRowGroupingModelChange={(model) =>
                      setRowGroupingModel(model)
                    }
                    initialState={{
                      columns: {
                        columnVisibilityModel: { skupina: false, nazov: false },
                      },
                      pinnedColumns: {
                        left: ["__row_group_by_columns_group__"],
                      },
                    }}
                    groupingColDef={{
                      leafField: "nazov",
                      headerClassName: "weekend6_help_nadpis",
                      cellClassName: "weekend6_help_cell",
                      renderHeader: () => (
                        <div className={styles.nazov}>
                          <div className={styles.nazovTitle}>Položky</div>
                          <div className={styles.pokladna}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              height="1.15em"
                              width="1.15em"
                            >
                              <path d="M288 0c17.7 0 32 14.33 32 32v64c0 17.7-14.3 32-32 32h-80v32h216.1c32.5 0 59.4 23.1 64.1 54.4l22.7 149.7c.7 4.7 1.1 9.5 1.1 14.3V448c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64v-69.6c0-4.8.362-9.6 1.083-14.3L23.76 214.4C28.5 183.1 55.39 160 87.03 160h56.07v-32h-80c-16.77 0-32-14.3-32-32V32c0-17.67 15.23-32 32-32H288zM96 48c-8.84 0-16 7.16-16 16s7.16 16 16 16h160c8.8 0 16-7.16 16-16s-7.2-16-16-16H96zM80 448h352c8.8 0 16-7.2 16-16s-7.2-16-16-16H80c-8.84 0-16 7.2-16 16s7.16 16 16 16zm32-232c-13.25 0-24 10.7-24 24s10.75 24 24 24c13.3 0 24-10.7 24-24s-10.7-24-24-24zm96 48c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm-48 32c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm144-32c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm-48 32c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm144-32c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm-48 32c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24z" />
                            </svg>
                          </div>
                        </div>
                      ),
                    }}
                  />
                </ThemeProvider>
              </div>
            </Card>
          )}
        </div>
      )}
    </Card>
  );
};

export default App;
