import React, { useState } from "react";
import cn from "classnames";
import styles from "./Detail.module.sass";
import Icon from "../Icon";
import { useDispatch, useSelector } from "react-redux";
import { modal, modalData } from "../Modaly/redux/modalSlice";
import axios from "axios";
import { paneldefUrl, PanelUrl } from "../../features/urlHelper";
import swal from "sweetalert";
import { login, loginData } from "../../screens/SignIn/redux/loginSlice";
import { useHistory } from "react-router";
import {
  loadingPanel,
  Panel,
  PanelData,
} from "../Modaly/ModalPanel/redux/PanelSlice";
import ModalPanel from "../Modaly/ModalPanel";
import ModalForm from "../Modaly/ModalForm";
import { errorHandleDialog } from "../../features/errorDialog";
import { evidenceTableData } from "../EvidenceTableUniversal/Table/redux/tableEvidenceSlice";
import { Form, loadingForm } from "../Modaly/ModalForm/redux/FormSlice";

const App = ({
  className,
  title,
  classTitle,
  classCardHead,
  head,
  children,
  disabled,
  dataset,
  idFieldName,
  idFieldValue,
  setIdFieldValue,
  paginationData,
  setPaginationData,
  rowSelectionModel,
  setRowSelectionModel,
  visiblePanel,
  setvisiblePanel,
  volba,
  setVolba,
}) => {
  const dispatch = useDispatch();
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  //const logindata = useSelector(loginData);
  const modaldata = useSelector(modalData);
  const navigate = useHistory();
  const evidenceTabledata = useSelector(evidenceTableData);
  const [visibleForm, setVisibleForm] = useState(false);
  const [formName, setFormName] = useState("");
  return (
    <div className={styles.addNew}>
      {evidenceTabledata.loading ? (
        <></>
      ) : (
        <div
          onClick={async () => {
            console.log(idFieldValue, idFieldName);
            if (disabled) {
              await axios
                .post(
                  paneldefUrl,
                  {
                    dataset: dataset,
                    stredisko:
                      logindata.UserData.vybrane_stredisko !== undefined
                        ? logindata.UserData.vybrane_stredisko.kod
                        : null,
                    idFieldValue: idFieldValue,
                    idFieldName: idFieldName,
                    volba: "add",
                  },
                  {
                    headers: { Authorization: "Bearer " + logindata.Token },
                  }
                )
                .then(function (response) {
                  setVolba("add");
                  console.log("add", response.data);
                  if (response.data.action === undefined) {
                    dispatch(loadingPanel());
                    dispatch(Panel(response.data));
                    setvisiblePanel(true);
                    document
                      .getElementsByName(
                        response.data.values[
                          Object.keys(response.data.values)[0]
                        ].id
                      )[0]
                      .focus();
                  } else {
                    if (response.data.action.name === "form") {
                      dispatch(loadingForm());
                      setFormName(response.data.action.enterData.formName);
                      dispatch(Form(response.data.action.enterData));
                      setVisibleForm(true);
                      document
                        .getElementsByName(
                          response.data.action.enterData.values[
                            Object.keys(
                              response.data.action.enterData.values
                            )[0]
                          ].id
                        )[0]
                        .focus();
                    }
                  }
                })
                .catch(function (error) {
                  errorHandleDialog(error.response, navigate, dispatch);
                });
            }
          }}
          className={!disabled ? styles.iconDis : styles.icon}
        >
          <Icon name={"add"} size="20" />
          <div>Přidat</div>
        </div>
      )}
      <ModalPanel
        visible={visiblePanel}
        setVisible={setvisiblePanel}
        className={styles}
        dataset={dataset}
        volba={volba}
        setVolba={setVolba}
        datasetId={idFieldValue}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        idFieldName={idFieldName}
      />
      <ModalForm
        dataset={dataset}
        visible={visibleForm}
        setVisible={setVisibleForm}
        className={styles}
        formName={formName}
        setVisiblePanel={setvisiblePanel}
        setVolba={setVolba}
        idFieldValue={idFieldValue}
        idFieldName={idFieldName}
      />
    </div>
  );
};

export default App;
