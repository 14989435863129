import { createSlice } from '@reduxjs/toolkit';

export const pickerObjednavky_typERP_OSlice = createSlice({
    name: 'pickerObjednavky_typERP_O',
    initialState: {
        value: "Všechno",
    },
    reducers: {
        pickerObjednavky_typERP_O: (state, action) => {
            state.value = action.payload.data
        },

    },
});

export const { pickerObjednavky_typERP_O } = pickerObjednavky_typERP_OSlice.actions;

export const pickerObjednavky_typERP_OData = state => state.pickerObjednavky_typERP_Odata.value;


export default pickerObjednavky_typERP_OSlice.reducer;
