import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../components/Checkbox";
import Balance from "../../../../components/Balance";

const Row = ({
    id,
    item,
    value,
    onChange,
    activeTable,
    setActiveTable,
    activeId,
    setActiveId,
}) => {
    const handleClick = (id) => {
        setActiveTable(false);
        //setActiveTable(true); ked to odkomentujem bude sa zobrazovat detail 
        setActiveId(id);
    };
    console.log(item)
    return (
        <>
            <div
                className={cn(
                    styles.row,
                    { [styles.selected]: activeId === item.id },
                    { [styles.active]: activeTable }
                )}
            >
                <div className={styles.col}>
                    {/*<Checkbox*/}
                    {/*    className={styles.checkbox}*/}
                    {/*    value={value}*/}
                    {/*    onChange={onChange}*/}
                    {/*/>*/}
                </div>
                {/*<div className={styles.col}>*/}
                {/*    <div className={styles.item} onClick={() => handleClick(item.id)}>*/}
                {/*        <div className={styles.avatar}>*/}
                {/*            <img src={item.avatar} alt="Avatar" />*/}
                {/*        </div>*/}
              
                {/*    </div>*/}

                {/*</div>*/}
                <div className={styles.col} onClick={() => handleClick(id)}>
                    <div className={styles.email}>{item.meno}</div>
                </div>
                <div className={styles.col}>
                    <div className={styles.email}>{item.cas}</div>

                </div>
                <div className={styles.col}>
                    <div className={styles.email}>{item.pracCas}</div>
                </div>
                <div className={styles.col}>
                    <div className={styles.email}>{item.plan}</div>
                </div>
                <div className={styles.col}>
                    <div className={styles.email}>{item.typ}</div>
                </div>
                <div className={styles.col}>
                    <div className={styles.email}>{item.kompetencie}</div>
                </div>
               
            </div>

        </>
    );
};

export default Row;
