import { createSlice } from '@reduxjs/toolkit';

export const soutezSlice = createSlice({
    name: 'soutez',
    initialState: {
        data: [],
        loading: true,
    },
    reducers: {
        soutez: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingSoutez: (state) => {
            console.log("loading: true")
            state.loading = true
        }

    },
});

export const { soutez, loadingSoutez } = soutezSlice.actions;

export const soutezData = state => state.soutezdata;


export default soutezSlice.reducer;

