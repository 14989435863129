import { createSlice } from '@reduxjs/toolkit';

export const tableStavSkladuSlice = createSlice({
    name: 'stavSkladuTable',
    initialState: {
        data: [],
        loading: true,
    },
    reducers: {
        stavSkladuTable: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingStavSkladuTable: (state) => {
            console.log("loading: true")
            state.loading = true
        }
    },
});

export const { stavSkladuTable, loadingStavSkladuTable } = tableStavSkladuSlice.actions;

export const stavSkladuTableData = state => state.stavSkladuTabledata;


export default tableStavSkladuSlice.reducer;
