import { createSlice } from '@reduxjs/toolkit';

export const tableObjednavkyOstatneSlice = createSlice({
    name: 'objednavkyOstatneTable',
    initialState: {
        data: [],
        loading: true,

    },
    reducers: {
        objednavkyOstatneTable: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingObjednavkyOstatneTable: (state) => {
            console.log("loading: true")
            state.loading = true
        },
    },
});

export const { objednavkyOstatneTable, loadingObjednavkyOstatneTable} = tableObjednavkyOstatneSlice.actions;

export const objednavkyOstatneTableData = state => state.objednavkyOstatneTabledata;


export default tableObjednavkyOstatneSlice.reducer;