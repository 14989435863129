import React, { useState } from "react";
import { useEffect } from "react";
import cn from "classnames";
import styles from "./Soutez.module.sass";
import Card from "../../../components/Card";
import {
  commentsUrl,
  prehladUrl,
  soutezUrl,
} from "./../../../features/urlHelper";
import axios from "axios";
import ListIcon from "@mui/icons-material/FormatListBulleted";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import { login, loginData } from "./../../SignIn/redux/loginSlice";
import { soutez, soutezData, loadingSoutez } from "./redux/soutezSlice";

import useDarkMode from "@fisch0920/use-dark-mode";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../../features/errorDialog";

const Comments = ({ className }) => {
  const dispatch = useDispatch();
  const darkMode = useDarkMode(false);
  const navigate = useHistory();

  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const soutezdata = useSelector(soutezData);

  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
    console.log("soutezdata.data", soutezdata.data)
    dispatch(loadingSoutez());
    result();
  }, []);

  async function result() {
    await axios
      .get(
        soutezUrl +
          logindata.UserData.vybrane_stredisko.kod +
          "/" +
          logindata.UserData.datum,
        { headers: { Authorization: "Bearer " + logindata.Token } }
      )
      .then(function (response) {
        console.log("soutez", response.data);
        dispatch(soutez(response.data));
        //dispatch(loadingSoutez({ data: false }))
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }
  return (
    <Card
      bezPaddingu={false}
      className={cn(styles.card, className)}
      title="Soutež"
      classTitle="title-yellow"
    >
      {soutezdata.loading ? (
        <ContentLoader
          speed={2}
          width={400}
          height={100}
          viewBox="0 0 400 100"
          backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
          foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
        >
          <rect
            x="68"
            y="8"
            rx="3"
            ry="3"
            width={width > 1056 ? "200" : "200"}
            height="12"
          />
          <rect
            x="68"
            y="30"
            rx="3"
            ry="3"
            width={width > 1056 ? "52" : "104"}
            height="12"
          />
          <rect
            x="68"
            y="56"
            rx="3"
            ry="3"
            width={width > 1056 ? "200" : "200"}
            height="20"
          />

          <circle cx="25" cy="28" r="25" />
        </ContentLoader>
      ) : (
        <div className={styles.comments}>
          <div className={styles.list}>
            {soutezdata.data?.map((x, index) => (
              <div className={styles.item} key={index}>
                <div className={styles.avatar}>
                  <img src={"/images/content/bageta.png"} alt="Avatar" />
                </div>

                <div className={styles.details}>
                  <div className={styles.title}>
                    {x.nazov}
                    <Tooltip
                      enterTouchDelay={0}
                      leaveTouchDelay={100000}
                      title={
                        <span style={{ whiteSpace: "pre-line" }}>
                          {x.produkty.join("\r\n")}
                        </span>
                      }
                    >
                      <IconButton>
                        <ListIcon className={styles.ico} />
                      </IconButton>
                    </Tooltip>
                  </div>

                  <div className={styles.content}>
                    Typ: {x.centralna ? "Centrální soutěž" : "Lokální soutěž"}{" "}
                  </div>
                  <div className={styles.line}>
                    <div className={styles.comment1}>stav:</div>
                    <Tooltip
                      enterTouchDelay={0}
                      leaveTouchDelay={100000}
                      title={"Počet produktů na 1000 transakcí"}
                    >
                      <div className={styles.comment2}>
                        <img
                          src={"/images/content/sip.png"}
                          alt="Avatar"
                          className={styles.icon1}
                          style={{ width: 18, margin: 2, marginBottom: 5 }}
                        />
                        {x.skore}/{x.pocetTransakcii}t
                      </div>
                    </Tooltip>
                    <Tooltip
                      enterTouchDelay={0}
                      leaveTouchDelay={100000}
                      title={"Pořadí restaurace / počet soutěžících restaurací"}
                    >
                      <div className={styles.comment3}>
                        <img
                          src={"/images/content/cup.png"}
                          alt="Avatar"
                          className={styles.icon1}
                          style={{ width: 15, margin: 2, marginBottom: 5 }}
                        />
                        {x.poradie}/{x.pocetStredisk}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Card>
  );
};

export default Comments;
