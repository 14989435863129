import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import remove_accents from "remove-accents";
import { useSelector, useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import useDarkMode from "@fisch0920/use-dark-mode";

import { searchData } from "../../../components/Search/redux/searchSlice";
import { DataGridPremium, LicenseInfo, csCZ } from "@mui/x-data-grid-premium";
import { getLicensePremium } from "../../../features/License";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Table = ({ className, data, loading }) => {
  const darkMode = useDarkMode(false);
  const navigate = useHistory();
  const notify_error = (text) => toast.error(text);
  LicenseInfo.setLicenseKey(getLicensePremium());

  function getWidth(width) {
    if (width > 2500) {
      return width - 456;
    } else if (width < 2500 && width > 767) {
      return width - 212;
    } else {
      return width - 48;
    }
  }

  function isInt(n) {
    return n % 1 === 0;
  }

  const stylingTable = {
    border: 0,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      color: darkMode.value ? "white" : "#666d74",
      borderRight: `1px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .MuiDataGrid-overlay": {
      backgroundColor: "transparent",
      color: darkMode.value ? "white" : "#ACACAC",
    },
    "& .MuiInputBase-input": {
      color: darkMode.value ? "white" : "#ACACAC",
    },
    "& .MuiSvgIcon-root": {
      color: "#a7acaf",
    },
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
      "& .MuiInputBase-root": {
        height: "100%",
      },
    },
    "& .MuiDataGrid-cell": {
      padding: 2,
    },
    "& .Mui-error": {
      backgroundColor: "red",
      color: "white",
    },
    width: getWidth(window.innerWidth),
    height: window.innerHeight - (window.innerWidth < 767 ? 115 : 202),
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
  };

  const columns = [
    {
      field: "username",
      headerName: "Uživatel",
      width: 280,
    },
    {
      field: "typErp",
      headerName: "Typ uživatele",
      width: (getWidth(window.innerWidth) - 280) * 0.33,
      minWidth: 90,
      headerAlign: "center",
    },
    {
      field: "x__lastlogin",
      headerName: "Poslední přihlášení",
      width: (getWidth(window.innerWidth) - 280) * 0.33,
      minWidth: 90,
      headerAlign: "center",
    },
    {
      field: "last_activity_str",
      headerName: "Poslední aktivita",
      width: (getWidth(window.innerWidth) - 280) * 0.34,
      minWidth: 90,
      headerAlign: "center",
    },
  ];

  const theme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
    },
  });

  const dispatch = useDispatch();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const searchdata = useSelector(searchData);

  return (
    <div>
      {loading ? (
        <ContentLoader
          speed={2}
          width={"100%"}
          height={300}
          backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
          foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
        >
          <rect x="3%" y="5%" rx="3" ry="3" width={"100%"} height="80%" />
        </ContentLoader>
      ) : (
        <ThemeProvider theme={theme}>
          <DataGridPremium
            sx={stylingTable}
            localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
            {...{
              rows: data.filter(
                (xy) =>
                  String(remove_accents(xy.username.toUpperCase())).includes(
                    remove_accents(searchdata.searchUsersActivity.toUpperCase())
                  ) ||
                  String(remove_accents(xy.typErp.toUpperCase())).includes(
                    remove_accents(searchdata.searchUsersActivity.toUpperCase())
                  )
              ),
              columns: columns,
            }}
            disableAutoFocus
            disableColumnMenu
            defaultGroupingExpansionDepth={-1}
            hideFooter
            rowHeight={40}
          />
        </ThemeProvider>

        // </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Table;
