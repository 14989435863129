import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import remove_accents from "remove-accents";
import { useSelector, useDispatch } from "react-redux";
import Icon from "../../../../components/Icon";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  nakupTable,
  nakupTableData,
  loadingNakupTable,
} from "./redux/tableNakupSlice";
import { DataGridPremium, LicenseInfo, csCZ } from "@mui/x-data-grid-premium";
import { getLicensePremium } from "../../../../features/License";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ContentLoader from "react-content-loader";
import useDarkMode from "@fisch0920/use-dark-mode";
import {
  modalData,
  modal,
} from "../../../../components/Modaly/redux/modalSlice";
import swal from "sweetalert";
import {
  Form,
  FormData,
  loadingForm,
} from "../../../../components/Modaly/ModalForm/redux/FormSlice";
import ModalForm from "../../../../components/Modaly/ModalForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { formRunUrl, menuRunUrl } from "../../../../features/urlHelper";
import { loginData } from "../../../SignIn/redux/loginSlice";
import { errorHandleDialog } from "../../../../features/errorDialog";

// data

const data = [];

const Table = ({ className, zaplaceno }) => {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const darkMode = useDarkMode(false);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const Formdata = useSelector(FormData);
  const modaldata = useSelector(modalData);
  const nakupdata = useSelector(nakupTableData);
  const notify_error = (text) => toast.error(text);
  LicenseInfo.setLicenseKey(getLicensePremium());
  const [visibleForm, setVisibleForm] = useState(false);
  const [formName, setFormName] = useState("");

  const removeFields = (index) => {
    let data = [...nakupdata.data];
    data.splice(index, 1);
    dispatch(nakupTable(data));
  };

  function getWidth(width) {
    if (width > 2500) {
      return width - 456;
    } else if (width < 2500 && width > 767) {
      return width - 212;
    } else {
      return width - 48;
    }
  }

  function zbyva() {
    let ostava_zaplatit = 0;
    nakupdata.data.map((data) => {
      ostava_zaplatit = ostava_zaplatit + data.cena * data.pocet;
    });
    return zaplaceno - ostava_zaplatit;
  }

  async function call_form(volba, data = null) {
    await axios
      .post(
        formRunUrl,
        {
          formName: "NakupVHotovosti",
          formValues: data,
          volba: volba,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        if (response.data.typ === "route") {
          navigate.push(response.data.url);
        } else if (response.data.typ === "enter") {
          console.log("enterData", response.data.enterData);

          dispatch(loadingForm());
          setFormName(response.data.enterData.formName);
          dispatch(Form(response.data.enterData));
          setVisibleForm(true);
          document
                .getElementsByName(
                  response.data.enterData.values[Object.keys(response.data.enterData.values)[0]].id
                )[0]
                .focus();
        }
        console.log(response);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  const stylingTable = {
    border: 0,

    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      color: darkMode.value ? "white" : "#666d74",
      borderRight: `1px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .MuiDataGrid-overlay": {
      backgroundColor: "transparent",
      color: darkMode.value ? "white" : "#ACACAC",
    },
    "& .MuiInputBase-input": {
      color: darkMode.value ? "white" : "#ACACAC",
    },
    "& .MuiSvgIcon-root": {
      color: "#a7acaf",
    },
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
      "& .MuiInputBase-root": {
        height: "100%",
      },
    },
    "& .MuiDataGrid-cell": {
      padding: 0.5,
    },
    "& .Mui-error": {
      backgroundColor: "red",
      color: "white",
    },
    width: getWidth(window.innerWidth),
    height:
      window.innerWidth < 767
        ? window.innerHeight - 315
        : window.innerHeight - 365,
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
  };

  const theme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
    },
  });
  const columns = [
    {
      field: "nazov",
      headerName: "Položka",
      width: 280,
      groupable: false,
      renderCell: (params) => (
        <>
          <div className={styles.nazov}>
            <div className={styles.avatar}>
              <img src={"/images/content/bageta2.png"} alt="Avatar" />
            </div>
            <div className={styles.nazov_kod}>
              <div>{params.row.nazov}</div>
              <div className={styles.znacka}>
                <div className={styles.znackaText}>{params.row.znacka}</div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      field: "dan",
      headerName: "Daň",
      width: (getWidth(window.innerWidth) - 400) * 0.33,
      minWidth: 90,
      type: "number",
      headerAlign: "center",
      valueFormatter: (params) => {
        const valueFormatted = Number(params.value);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
    },
    {
      field: "pocet",
      headerName: "Množství",
      width: (getWidth(window.innerWidth) - 400) * 0.33,
      minWidth: 90,
      type: "number",
      headerAlign: "center",
      valueFormatter: (params) => {
        const valueFormatted = Number(params.value);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
    },

    {
      field: "mj",
      headerName: "Mj",
      width: 30,
      headerAlign: "center",
    },
    {
      field: "cena",
      headerName: "Cena",
      width: (getWidth(window.innerWidth) - 400) * 0.34,
      minWidth: 90,
      headerAlign: "center",
      type: "number",
    },
    {
      field: "stav",
      headerName: "",
      width: 60,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <div className={styles.icons}>
            <div
              onClick={() => {
                var varpol1 = {
                  ...Formdata.data.values.varpol1,
                  value: params.row.znacka,
                  selectorLabel: params.row.nazov,
                };
                var varpol2 = {
                  ...Formdata.data.values.varpol2,
                  value: params.row.pocet,
                };
                var varpol3 = {
                  ...Formdata.data.values.varpol3,
                  value: params.row.mj,
                };
                var varpol4 = {
                  ...Formdata.data.values.varpol4,
                  value: params.row.cena,
                };
                var varpol5 = {
                  ...Formdata.data.values.varpol5,
                  value: params.row.mena,
                };
                var varpol6 = {
                  ...Formdata.data.values.varpol6,
                  value: params.row.dan,
                };
                var data = {
                  varpol1: varpol1,
                  varpol2: varpol2,
                  varpol3: varpol3,
                  varpol4: varpol4,
                  varpol5: varpol5,
                  varpol6: varpol6,
                };
                call_form("edit", data);
                // dispatch(
                //   pridatSurovinuEditModal({
                //     data: {
                //       id: params.row.id,
                //       nazov: params.row.nazov,
                //       znacka: params.row.znacka,
                //       pocet: params.row.pocet,
                //       dan: params.row.dan,
                //       cena: params.row.cena,
                //       mj: params.row.mj,
                //     },
                //   })
                // );
                // dispatch(
                //   modal({ data: { ...modaldata, PridatSurovinu: true } })
                // );
              }}
              className={styles.icon1}
            >
              <Icon name="edit" size="20"></Icon>
            </div>
            <div
              onClick={() => {
                swal({
                  title: "Chcete smazat položku?",
                  icon: "info",
                  buttons: ["Ne ", "Ano"],
                  dangerMode: true,
                }).then(async (willShow) => {
                  if (willShow) {
                    removeFields(nakupdata.data.indexOf(params.row));
                  }
                });
              }}
              className={styles.icon2}
            >
              <Icon name="trash" size="20"></Icon>
            </div>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(loadingNakupTable());
    dispatch(nakupTable(data));
  }, []);

  return (
    <>
      <div className={styles.spacebeetween}>
        <button
          onClick={() => {
            call_form("add");
          }}
          className={styles.buttonPS}
        >
          <div className={styles.buttonPSI}>
            <Icon name="add" size="20" />
          </div>
          <div className={styles.buttonPST}>Přidat surovinu</div>
        </button>
      </div>

      <div>
        {nakupdata.loading ? (
          <ContentLoader
            speed={2}
            width={"100%"}
            height={300}
            backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
            foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
          >
            <rect x="3%" y="5%" rx="3" ry="3" width={"100%"} height="80%" />
          </ContentLoader>
        ) : (
          <>
            <ThemeProvider theme={theme}>
              <DataGridPremium
                sx={stylingTable}
                localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                {...{
                  rows: nakupdata.data,
                  columns: columns,
                }}
                columnHeaderHeight={25}
                disableAutoFocus
                disableColumnMenu
                defaultGroupingExpansionDepth={-1}
                hideFooter
                rowHeight={40}
                groupingColDef={{
                  leafField: "nazov",
                  headerClassName: "weekend6_help_nadpis",
                  cellClassName: "weekend6_help_cell",
                }}
                onCellEditStop={async function (params, event) {
                  //event.defaultMuiPrevented = true;
                  if (event.code === "Enter") {
                    console.log(params);

                    let row = nakupdata.data.filter(
                      (row) => row.id === params.id
                    );

                    let index = nakupdata.data.indexOf(row[0]);
                    let data = [...nakupdata.data];
                    let row_copy = { ...row[0] };
                    row_copy.pocet = event.target.value;
                    data[index] = row_copy;
                    dispatch(loadingNakupTable());
                    dispatch(nakupTable(data));
                  }
                }}
                //rowGroupingModel={rowGroupingModel}
              />
            </ThemeProvider>
          </>
        )}
        <ToastContainer />
        <ModalForm
          priznak="pridatSurovinu"
          visible={visibleForm}
          setVisible={setVisibleForm}
          className={styles}
          formName={formName}
          height={400}
        />
      </div>
    </>
  );
};

export default Table;
