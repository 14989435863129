import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Row from "./Row";
import remove_accents from "remove-accents";
import { useSelector, useDispatch } from "react-redux";

import {
  stavSkladuTable,
  stavSkladuTableData,
  loadingStavSkladuTable,
} from "./redux/tableStavSkladuSlice";

import ContentLoader from "react-content-loader";
import useDarkMode from "@fisch0920/use-dark-mode";

import axios from "axios";
import { stavSkladuUrl } from "../../../features/urlHelper";
import { login, loginData } from "../../SignIn/redux/loginSlice";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { errorHandleDialog } from "./../../../features/errorDialog";

const Table = ({ className, activeTable }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const darkMode = useDarkMode(false);

  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const stavSkladuTabledata = useSelector(stavSkladuTableData);

  async function result() {
    await axios
      .get(stavSkladuUrl + logindata.UserData.vybrane_stredisko.kod, {
        headers: { Authorization: "Bearer " + logindata.Token },
      })
      .then(function (response) {
        console.log("stav skladu", response.data);
        dispatch(loadingStavSkladuTable());
        dispatch(stavSkladuTable(response.data));
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  useEffect(() => {
    result();
  }, []);

  return (
    <div
      style={{
        height: window.innerHeight > 550 ? window.innerHeight - 256 : 600,
      }}
      className={cn(styles.wrapper1, className)}
    >
      {stavSkladuTabledata.loading ? (
        <ContentLoader
          speed={2}
          width={"100%"}
          height={300}
          //viewBox="0 0 400 100"
          backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
          foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
        >
          <rect x="3%" y="5%" rx="3" ry="3" width={"100%"} height="80%" />
        </ContentLoader>
      ) : (
        <div className={cn(styles.wrapper, className)}>
          <div className={cn(styles.table)}>
            {true ? (
              <div className={cn(styles.row)}>
                <div className={styles.col}>Surovina</div>
                <div className={styles.col}>Stav</div>
                <div className={styles.col}>Jedn.</div>
                <div className={styles.col}>Daň</div>
              </div>
            ) : (
              <></>
            )}

            <br />
            {stavSkladuTabledata.data.map((xyz, index) => (
              <>
                <div>{xyz.hlskupina ? xyz.skupina : ""}</div>
                <Row id={index} item={xyz} key={index}></Row>
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
