import React from "react";
import cn from "classnames";

const icons = {
    camera: "M864 248H728l-32.4-90.8a32.07 32.07 0 0 0-30.2-21.2H358.6c-13.5 0-25.6 8.5-30.1 21.2L296 248H160c-44.2 0-80 35.8-80 80v456c0 44.2 35.8 80 80 80h704c44.2 0 80-35.8 80-80V328c0-44.2-35.8-80-80-80zm8 536c0 4.4-3.6 8-8 8H160c-4.4 0-8-3.6-8-8V328c0-4.4 3.6-8 8-8h186.7l17.1-47.8 22.9-64.2h250.5l22.9 64.2 17.1 47.8H864c4.4 0 8 3.6 8 8v456zM512 384c-88.4 0-160 71.6-160 160s71.6 160 160 160 160-71.6 160-160-71.6-160-160-160zm0 256c-53 0-96-43-96-96s43-96 96-96 96 43 96 96-43 96-96 96z",
    pen: "M4 21a1 1 0 0 0 .24 0l4-1a1 1 0 0 0 .47-.26L21 7.41a2 2 0 0 0 0-2.82L19.42 3a2 2 0 0 0-2.83 0L4.3 15.29a1.06 1.06 0 0 0-.27.47l-1 4A1 1 0 0 0 3.76 21 1 1 0 0 0 4 21zM18 4.41 19.59 6 18 7.59 16.42 6zM5.91 16.51 15 7.41 16.59 9l-9.1 9.1-2.11.52z",
    cash: "M17 9V7a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2m2 4h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2Zm7-5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z",
    weight: "M16 5c-1.645 0-3 1.355-3 3 0 .352.074.684.188 1h-5l-.157.813-3 15-.031.093V27h22v-2.094l-.031-.093-3-15L23.812 9h-5A2.95 2.95 0 0 0 19 8c0-1.645-1.355-3-3-3Zm0 2c.563 0 1 .438 1 1 0 .563-.438 1-1 1-.563 0-1-.438-1-1 0-.563.438-1 1-1Zm-6.188 4h12.376L25 25H7Z",
    material: "m15.89 10.188-4-5A.5.5 0 0 0 11.5 5h-7a.497.497 0 0 0-.39.188l-4 5A.5.5 0 0 0 0 10.5V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4.5a.497.497 0 0 0-.11-.312zM15 11h-3.5l-2 2h-3l-2-2H1v-.325L4.74 6h6.519l3.74 4.675V11z",
    kasa:"M288 0c17.7 0 32 14.33 32 32v64c0 17.7-14.3 32-32 32h-80v32h216.1c32.5 0 59.4 23.1 64.1 54.4l22.7 149.7c.7 4.7 1.1 9.5 1.1 14.3V448c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64v-69.6c0-4.8.362-9.6 1.083-14.3L23.76 214.4C28.5 183.1 55.39 160 87.03 160h56.07v-32h-80c-16.77 0-32-14.3-32-32V32c0-17.67 15.23-32 32-32H288zM96 48c-8.84 0-16 7.16-16 16s7.16 16 16 16h160c8.8 0 16-7.16 16-16s-7.2-16-16-16H96zM80 448h352c8.8 0 16-7.2 16-16s-7.2-16-16-16H80c-8.84 0-16 7.2-16 16s7.16 16 16 16zm32-232c-13.25 0-24 10.7-24 24s10.75 24 24 24c13.3 0 24-10.7 24-24s-10.7-24-24-24zm96 48c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm-48 32c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm144-32c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm-48 32c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm144-32c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm-48 32c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24z"
};

const Icon = (props) => {
    const size = props.size ? props.size : 16;
    const viewBox = props.viewBox ? props.viewBox : "";
    return (
        <svg
            className={cn(props.className)}
            width={size}
            height={size}
            viewBox={viewBox}
            fill={props.fill}
        >
            <path d={icons[props.name]}></path>
        </svg>
    );
};

export default Icon;
