import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Row from "./Row";
import remove_accents from "remove-accents";
import { useSelector, useDispatch } from "react-redux";
import {
  prijemSurovinTableData,
  prijemSurovinTable,
  loadingPrijemSurovinTable,
} from "./redux/tablePrijemSurovinSlice";

import ContentLoader from "react-content-loader";
import useDarkMode from "@fisch0920/use-dark-mode";
import {
  Form,
  loadingForm,
} from "../../../../components/Modaly/ModalForm/redux/FormSlice";
import { useHistory } from "react-router/cjs/react-router.min";
import axios from "axios";
import { formRunUrl } from "../../../../features/urlHelper";
import { loginData } from "../../../SignIn/redux/loginSlice";
import ModalForm from "../../../../components/Modaly/ModalForm";
import { errorHandleDialog } from "../../../../features/errorDialog";
// data

const data = [
  {
    id: 0,
    nazov: "Tmavé Pečivo",
    kod: "BCP.7634",
    mj: "ks",
    zaslane: 1908,
    objednane: 2000,
    prijate: 1258,
    komentar: "“méně kusů než bylo očekáváno :(”",
    stav1: true,
    stav2: true,
    skupina: "nedostatecne_zaslane",
    hlskupina: true,
  },
  {
    id: 1,
    nazov: "Svetlé Pečivo",
    kod: "BCP.783",
    mj: "ks",
    zaslane: 10000,
    objednane: 200,
    prijate: 100,
    komentar: "“očekáváno”",
    stav1: true,
    stav2: false,
    skupina: "nedostatecne_zaslane",
    hlskupina: false,
  },
  {
    id: 3,
    nazov: "Tmavé Pečivo",
    kod: "BCP.369",
    mj: "ks",
    zaslane: 190,
    objednane: 200,
    prijate: 180,
    komentar: "“méně kusů než bylo očekáváno”",
    stav1: false,
    stav2: false,
    skupina: "nedostatecne_zaslane",
    hlskupina: false,
  },
  {
    id: 4,
    nazov: "Tmavé Pečivo",
    kod: "BCP.763",
    mj: "ks",
    zaslane: 190,
    objednane: 200,
    prijate: 190,
    komentar: "“méně kusů než bylo očekáváno”",
    stav1: true,
    stav2: true,
    skupina: "nedostatecne_zaslane",
    hlskupina: false,
  },
  {
    id: 5,
    nazov: "Svetlé Pečivo",
    kod: "BCP.783",
    mj: "ks",
    zaslane: 190,
    objednane: 200,
    prijate: 180,
    komentar: "“očekáváno”",
    stav1: true,
    stav2: false,
    skupina: "nedostatecne_zaslane",
    hlskupina: false,
  },
  {
    id: 6,
    nazov: "Tmavé Pečivo",
    kod: "BCP.369",
    mj: "ks",
    zaslane: 190,
    objednane: 200,
    prijate: 190,
    komentar: "“méně kusů než bylo očekáváno”",
    stav1: false,
    stav2: false,
    skupina: "nedostatecne_zaslane",
    hlskupina: false,
  },
  {
    id: 10,
    nazov: "Kečup",
    kod: "BCP.763",
    mj: "ks",
    zaslane: 200,
    objednane: 200,
    prijate: 180,
    komentar: "“méně kusů než bylo očekáváno”",
    stav1: true,
    stav2: true,
    skupina: "omacky",
    hlskupina: true,
  },
  {
    id: 11,
    nazov: "Tatarka",
    kod: "BCP.783",
    mj: "ks",
    zaslane: 190,
    objednane: 200,
    prijate: 180,
    komentar: "“očekáváno”",
    stav1: true,
    stav2: false,
    skupina: "omacky",
    hlskupina: false,
  },
  {
    id: 12,
    nazov: "Sweet chilly",
    kod: "BCP.369",
    mj: "ks",
    zaslane: 190,
    objednane: 200,
    prijate: 180,
    komentar: "“méně kusů než bylo očekáváno”",
    stav1: true,
    stav2: false,
    skupina: "omacky",
    hlskupina: false,
  },
];

const Table = ({ className, activeTable }) => {
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const navigate = useHistory();
  const dispatch = useDispatch();
  const darkMode = useDarkMode(false);
  const [formName, setFormName] = useState("");
  const [visibleForm, setVisibleForm] = useState(false);

  useEffect(() => {
    dispatch(loadingPrijemSurovinTable());
    dispatch(prijemSurovinTable(data));
    call_form("new");
  }, []);

  async function call_form(volba, data = null) {
    await axios
      .post(
        formRunUrl,
        {
          formName: "ZaznamProblemu",
          formValues: data,
          volba: volba,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        if (response.data.typ === "route") {
          navigate.push(response.data.url);
        } else if (response.data.typ === "enter") {
          console.log("enterData", response.data.enterData);
          dispatch(loadingForm());
          setFormName(response.data.enterData.formName);
          dispatch(Form(response.data.enterData));
          document
            .getElementsByName(
              "varpol4"
            )[0]
            .focus();
        }
        console.log(response);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  const prijemSurovinTabledata = useSelector(prijemSurovinTableData);

  return (
    <div
      style={{
        height: window.innerHeight > 550 ? window.innerHeight - 450 : 600,
      }}
      className={cn(styles.wrapper1, className)}
    >
      {prijemSurovinTabledata.loading ? (
        <ContentLoader
          speed={2}
          width={"100%"}
          height={300}
          //viewBox="0 0 400 100"
          backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
          foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
        >
          <rect x="3%" y="5%" rx="3" ry="3" width={"100%"} height="80%" />
        </ContentLoader>
      ) : (
        <div className={cn(styles.wrapper, className)}>
          <div className={cn(styles.table)}>
            {true ? (
              <div className={cn(styles.row)}>
                <div className={styles.col1}>Surovina</div>
                <div className={styles.col}>
                  {window.innerWidth > 700 ? "Zaslané/Objednané" : "Zas/Obj"}
                </div>
                <div className={styles.col}>Přijaté</div>
                {window.innerWidth > 700 ? (
                  <div className={styles.col}>Komentář</div>
                ) : (
                  <></>
                )}

                <div className={styles.col}>Stav</div>
              </div>
            ) : (
              <></>
            )}

            <br />
            {prijemSurovinTabledata.data.map((xyz, index) => (
              <>
                <div>{xyz.hlskupina ? xyz.skupina : ""}</div>
                <Row
                  setFormName={setFormName}
                  setVisibleForm={setVisibleForm}
                  id={index}
                  item={xyz}
                  key={index}
                ></Row>
              </>
            ))}
          </div>
        </div>
      )}
      <ModalForm
        priznak="zaznamProblemu"
        visible={visibleForm}
        setVisible={setVisibleForm}
        className={styles}
        formName={formName}
      />
    </div>
  );
};

export default Table;
