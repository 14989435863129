import Card from "../../components/Card";
import Icon from "../../components/Icon";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Search from "../../components/Search";
import Table from "./Table";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modalData, modal } from "../../components/Modaly/redux/modalSlice";

const App = ({ className }) => {
  const dispatch = useDispatch();

  const modaldata = useSelector(modalData);

  return (
    <div>
      <Card
      bezPaddingu={false}
        className={cn(styles.card, className)}
        title={"Nabídnout Jiné Pobočke"}
        classTitle="title-green"
        head={
          <div className={styles.headModal}>
            <div>
              <button
                className={
                  window.innerWidth > 500
                    ? cn("button", styles.headModalButton)
                    : styles.headModalButtons
                }
              >
                {window.innerWidth > 500 ? (
                  <>
                    <Icon name="check" size="24" />
                    Dokončit
                  </>
                ) : (
                  <Icon name="check" size="24" />
                )}
              </button>
            </div>
          </div>
        }
      >
        <div className={styles.Content}>
          <div className={styles.upperContent2}>
            <div className={cn(styles.avatar)}>
              <img src={"/images/content/home.png"} alt="Avatar" />
            </div>
            <div className={styles.upperContentRight}>
              <span style={{ fontWeight: "bold" }}>
                Vodičkova{" "}
                <button
                  className={styles.buttonZmena}
                  onClick={() => {
                    dispatch(
                      modal({ data: { ...modaldata, ZmenaPobocky: true } })
                    );
                  }}
                >
                  Změnit
                </button>
              </span>
              <br />
              <span style={{ color: "gray" }}>Crocodille </span>
            </div>
          </div>
          <div className={styles.textarea2_title}>
            Komentář
            <br></br>
            <textarea className={styles.textarea2}></textarea>
          </div>
        </div>
        <Table className={styles.table} />
      </Card>
    </div>
  );
};
export default App;
