import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Tooltip from "../../../../components/Tooltip";
const Item = ({ className, onActive, item, image, color}) => {
  return (
    <div className={cn(styles.item, className)} onClick={onActive}>
          <div className={styles.icon} style={{ backgroundColor: color }}>
              <div className={styles.avatar}><img src={image} className={styles.img} /></div>
        
      </div>
      <div className={styles.details}>
        <div className={styles.subtitle}>
          {item.title}
          <Tooltip
            className={styles.tooltip}
            title={item.counter}
            icon="info"
            place="top"
          />
        </div>
        <div className={styles.counter}>{item.counter}</div>
          </div>
         
      
    </div>
  );
};

export default Item;
