import Card from "../../components/Card";
import Icon from "../../components/Icon";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Search from "../../components/Search";
import Table from "./Table";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import { login, loginData } from "../SignIn/redux/loginSlice";
import {
  ObjednavkyOstatneDetailUrl,
  ObjednavkyOstatneUrl,
  formRunUrl,
  zoznamObjednanychPoloziekExtUrl,
} from "../../features/urlHelper";
import swal from "sweetalert";
import { useHistory } from "react-router";
import Delete from "../../components/Delete";
import { errorHandleDialog } from "../../features/errorDialog";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import moment from "moment";
import ModalForm from "../../components/Modaly/ModalForm";
import {
  Form,
  FormData,
  loadingForm,
} from "../../components/Modaly/ModalForm/redux/FormSlice";
import Reks_prc from "../Ciselniky_prc/Reks_prc";

const App = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}");
  const Formdata = useSelector(FormData);

  const [width, setWidth] = useState(window.innerWidth);
  const [visible, setVisible] = useState(false);
  const [formName, setFormName] = useState("");
  const [volba, setVolba] = useState("new");

  async function call_form(volba, data = null) {
    await axios
      .post(
        formRunUrl,
        {
          formName: "ZaznamReklamace",
          formValues: data,
          volba: volba,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        if (response.data.typ === "enter") {
          console.log("enterData", response.data.enterData);
          dispatch(loadingForm());
          setFormName(response.data.enterData.formName);
          dispatch(Form(response.data.enterData));
          if (document.getElementsByName("varpol1")[0] !== undefined) {
            document.getElementsByName("varpol1")[0].focus();
            document.getElementsByName("varpol1")[0].select();
          }
          setVisible(true);
        }
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  useEffect(() => {
    var varpol1 = {
      ...Formdata.data.values.varpol1,
      value: "",
    };
    var varpol2 = {
      ...Formdata.data.values.varpol2,
      value: null,
    };
    var varpol3 = {
      ...Formdata.data.values.varpol3,
      value: 0,
    };
    var varpol4 = {
      ...Formdata.data.values.varpol4,
      value: "",
    };
    var varpol5 = {
      ...Formdata.data.values.varpol5,
      value: "",
    };
    var varpol6 = {
      ...Formdata.data.values.varpol6,
      value: null,
    };
    var varpol7 = {
      ...Formdata.data.values.varpol7,
      value: 0,
    };
    var varpol8 = {
      ...Formdata.data.values.varpol8,
      value: "",
    };
    var varpol9 = {
      ...Formdata.data.values.varpol9,
      value:
        logindata.UserData.vybrane_stredisko !== undefined
          ? logindata.UserData.vybrane_stredisko.kod
          : null,
    };
    var varpol10 = {
      ...Formdata.data.values.varpol10,
      value: null,
    };
    var data = {
      varpol1: varpol1,
      varpol2: varpol2,
      varpol3: varpol3,
      varpol4: varpol4,
      varpol5: varpol5,
      varpol6: varpol6,
      varpol7: varpol7,
      varpol8: varpol8,
      varpol9: varpol9,
      varpol10: varpol10,
    };
    call_form("new", data);
  }, []);

  return (
    <div>
      <ModalForm
        visible={visible}
        setVisible={setVisible}
        formName={formName}
        setVolba={setVolba}
        priznak={"ZaznamReklamace"}
      ></ModalForm>
    </div>
  );
};
export default App;
