import { createSlice } from '@reduxjs/toolkit';

export const tablePrijemOdJinePobockySlice = createSlice({
    name: 'prijemOdJinePobockyTable',
    initialState: {
        data: [],
        zaznamy: [],
        loading: true,
    },
    reducers: {
        prijemOdJinePobockyTable: (state, action) => {
            console.log("data + loading: false", action.payload)
            state.data = action.payload
            state.loading = false
        },
        loadingPrijemOdJinePobockyTable: (state) => {
            console.log("loading: true")
            state.loading = true
        },
        setZaznamy: (state, action) => {
            state.zaznamy = action.payload
        },
    },
});

export const { prijemOdJinePobockyTable, loadingPrijemOdJinePobockyTable, setZaznamy } = tablePrijemOdJinePobockySlice.actions;

export const prijemOdJinePobockyTableData = state => state.prijemOdJinePobockyTabledata;


export default tablePrijemOdJinePobockySlice.reducer;
